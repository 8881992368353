import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    buttonOccupated: {
      border: "none",
      backgroundColor: "#007bff",
      borderRadius: 10,
      color: "white",
      width: 40,
      height: 50,
      "&:hover": {
        backgroundColor: "#007bff"
      }
    },
    buttonNotOccupated: {
      border: "none",
      backgroundColor: "transparent",
      borderRadius: 10,
      color: "black",
      width: 40,
      height: 50,
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    dotOccupated: {
      backgroundColor: "#ffad33",
      width: 5,
      height: 5,
      borderRadius: 50
    },
    dotNotOccupated: {
      backgroundColor: "grey",
      width: 5,
      height: 5,
      borderRadius: 50
    },
    buttonOrder: {
      backgroundColor: "#007bff",
      width: 80,
      height: 20,
      fontSize: 10,
      color: "white",
      "&:hover": {
        backgroundColor: "#007bff"
      }
    },
    tooltip: {
      fontSize: 15,
    },
    buttonUnclickable: {
      border: "none",
      backgroundColor: "#f2f2f2",
      borderRadius: 10,
      color: "white",
      width: 40,
      height: 50,
      "&:hover": {
        backgroundColor: "#f2f2f2"
      }
    },
    buttonMaintenance: {
      border: "none",
      backgroundColor: "#ffcc00",
      borderRadius: 10,
      color: "white",
      width: 40,
      height: 50,
      "&:hover": {
        backgroundColor: "#ffcc00"
      }
    },
    buttonNotDisp: {
      border: "none",
      backgroundColor: "#e60000",
      borderRadius: 10,
      color: "white",
      width: 40,
      height: 50,
      "&:hover": {
        backgroundColor: "#e60000"
      }
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

export const buttonUnclickable = {
  border: "none",
  backgroundColor: "#f2f2f2",
  borderRadius: 10,
  color: "white",
  width: 40,
  height: 50,
  "&:hover": {
    backgroundColor: "#f2f2f2"
  }
}

export const buttonOccupated = {
  border: "none",
  backgroundColor: "#007bff",
  borderRadius: 10,
  color: "white",
  width: 40,
  height: 50,
  "&:hover": {
    backgroundColor: "#007bff"
  }
}

export const buttonNotOccupated = {
  border: "none",
  backgroundColor: "transparent",
  borderRadius: 10,
  color: "black",
  width: 40,
  height: 50,
  "&:hover": {
    backgroundColor: "transparent"
  }
}

export const buttonMaintenance = {
  border: "none",
  backgroundColor: "#ffcc00",
  borderRadius: 10,
  color: "white",
  width: 40,
  height: 50,
  "&:hover": {
    backgroundColor: "#ffcc00"
  }
}

export const buttonNotDisp = {
  border: "none",
  backgroundColor: "#e60000",
  borderRadius: 10,
  color: "white",
  width: 40,
  height: 50,
  "&:hover": {
    backgroundColor: "#e60000"
  }
}


// export const modifiers = {
//   //daysOfWeek:{}, ///<---- vuole solo number[]
//   // thursdays: { daysOfWeek: [4] },
//   // days: startDate,
//   //highlighted: startDate
// };
export const modifiersStyles = {
  highlighted: {
    color: 'white',
    backgroundColor: '#2196F3',
    borderRadius: 10,
  },
  outside: {
    backgroundColor: 'white',
  },
};

export const renderDay = (day: any) => {
  const date = day.getDate();
  const dateStyle = {
    position: 'absolute',
    //color: 'lightgray',
    bottom: 1,
    right: 5,
    //fontSize: 17,
  } as any
  const cellStyle = {
    height: 30,
    width: 30,
    position: 'relative',
    //backgroundColor: "transparent"
  } as any
  return (
    <div style={cellStyle}>
      <div style={dateStyle}>{date}</div>
    </div>
  )
}

export const quarterStatusMaintenance = (isAfter: any, isOccupated: any, info: any, deletedMainten: any, quattordicina: any) => {
  // console.log("quarterStatusMaintenance", deletedMainten)
  if (isAfter === true) {
    return buttonUnclickable
  } else {
    if (info) {
      // console.log("uscita in manutenzione")
      if (info.is_maintenance) {
        return buttonMaintenance
      }
      if (deletedMainten) {
        if (deletedMainten.includes(quattordicina)) {
          /// NON PRENDE QUESTO COLORE
          // console.log("quarterStatusMaintenance true", deletedMainten, deletedMainten.includes(quattordicina))
          return buttonNotOccupated
        } else {
          return buttonNotDisp
        }
      } else {
        return buttonNotDisp
      }
    } else {
      if (isOccupated === true) {
        return buttonNotOccupated
      } else {
        return {
          border: "none",
          backgroundColor: "#ffcc00",
          borderRadius: 10,
          color: "white",
          width: 40,
          height: 50,
          "&:hover": {
            backgroundColor: "#ffcc00"
          }
        }
      }
    }
  }
}

export const quarterStatusAvailability = (isAfter: any, isOccupated: any, info: any, deletedMainten: any, quattordicina: any) => {
  // console.log("quarterStatusAvailability", deletedMainten)
  if (isAfter === true) {
    return buttonUnclickable
  } else {
    if (info) {
      // console.log("uscita in manutenzione")
      if (info.is_maintenance) {
        return buttonMaintenance
      }
      if (deletedMainten) {
        if (deletedMainten.includes(quattordicina)) {
          // console.log("quarterStatusAvailability true", deletedMainten, deletedMainten.includes(quattordicina))
          return buttonNotOccupated
        } else {
          return buttonNotDisp
        }
      } else {
        return buttonNotDisp
      }
    } else {
      if (isOccupated === true) {
        return buttonNotOccupated
      } else {
        return {
          border: "none",
          backgroundColor: "#e60000",
          borderRadius: 10,
          color: "white",
          width: 40,
          height: 50,
          "&:hover": {
            backgroundColor: "#e60000"
          }
        }
      }
    }
  }
}

// export const quarterStatus = (isAfter: any, isOccupated: any, info: any) => {
//   // console.log("quarterStatus", isMaintenance, isAfter, isOccupated, info)

//   if (info) {
//     // console.log("uscita in manutenzione")
//     if (info.is_maintenance) {
//       return buttonMaintenance
//     } else {
//       return buttonNotDisp
//     }
//   } else {
//     if (isAfter === true) {
//       return buttonUnclickable
//     } else {
//       if (isOccupated === true) {
//         return buttonNotOccupated
//       } else {
//         return buttonOccupated
//       }
//     }
//   }
// }