import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as PaymentActions, selectors as PaymentSelectors } from '../store/slices/payment';
import { actions as ProfileActions, selectors as ProfileSelectors } from '../store/slices/profile';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { Grid, Modal, Chip } from '@material-ui/core';
import Autosuggest, { BlurEvent, ChangeEvent } from 'react-autosuggest';
import { useSuggestion } from "../components/Suggestion.hooks";
import Icon from '../components/IconsComponent';
import { Company } from "../components/profile/Company";
import { Clients } from "../components/profile/Clients";
import { Team } from "../components/profile/Team";
import { PersonalProfile } from "../components/profile/PersonalProfile";
import { ResetPassword } from "../components/profile/modals/ResetPassword";
import { ReactComponent as StripeIcon } from "../assets/icons/Stripe_black.svg";
import { ReactComponent as StripeMulticolorIcon } from "../assets/icons/Stripe_logo_multicolor.svg";
import { ActivityButton } from '../components/ActivityButton';
import { NotificationContainer } from '../components/notification-layer/NotificationContainer'
import { useStyles, modalStyle, buttonSelected, buttonNotSelected } from '../components/profile/Profile.css';
import placeholder from '../assets/placeholder_profile.png';
import moment from 'moment';
import _ from 'lodash';


export const Profile = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  // const userProfile = useSelector(ProfileSelectors.getMediaOwner)
  // const profile = useSelector(ProfileSelectors.getProfile)
  // const orders: any[] = useSelector(ContentSelectors.listOrders)
  const openModal = useSelector(UiSelectors.switchCloseAddNewPlant)

  const [selected, setSelected] = useState("aziendale")
  const [modalType, setModalType] = useState('')

  useEffect(() => {
    dispatch(UiActions.setSelectedPage(""))
    dispatch(PaymentActions.willListConditionUsers())
    dispatch(ProfileActions.willGetMediaOwnerUsers())
  }, [])

  // const saveProfile = (profile: any) => {
  //   dispatch(ProfileActions.willAddMediaOwner(profile))
  // }

  return (
    <>
      <Modal className={classes.modalInProfile} open={openModal} onClose={() => {/* dispatch(UiActions.switchOpenModal(false)) */ }}>
        <div style={{ ...modalStyle, flexDirection: 'column', position: 'relative' }} >
          <Grid className={classes.modalInProfileHeader} container>
            <Grid item xs={11}>Modifica profilo</Grid>
            <Grid className="d-sm-flex flex-row-reverse hoverPointer" item xs={1} onClick={() => dispatch(UiActions.switchCloseAddNewPlant())} >
              <Icon size={24} color="#92929D" icon="close" />
            </Grid>
          </Grid>
          {modalType === 'reset-password' && <ResetPassword />}
        </div>
      </Modal>

    <NotificationContainer />
      <Grid style={{ overflow: 'auto', height: 'calc(100vh - 83px)' }} className="poppinsFont" container >
        <Grid className="d-sm-flex align-self-start bg-white mt-7" style={{ borderRadius: 13, fontSize: 14 }} container item xs={2} direction="column" alignItems="center" justify="center">
          <Grid container style={selected === "aziendale" ? buttonSelected : buttonNotSelected} className="d-sm-flex hoverPointer" item xs={12} onClick={() => setSelected("aziendale")}>
            <Grid item xs={2}><Icon size={20} color={selected === "aziendale" ? "#0062FF" : "#B5B5BE"} icon="dashboard" /></Grid>
            <Grid item xs={10}>Profilo Aziendale</Grid>
          </Grid>
          <Grid container style={selected === "personale" ? buttonSelected : { ...buttonNotSelected }} onClick={() => { setSelected("personale") }} className="d-sm-flex hoverPointer" item xs={12}>
            <Grid item xs={2}><Icon size={20} color={selected === "personale" ? "#0062FF" : "#B5B5BE"} icon="working" /></Grid>
            <Grid item xs={10}>Personale</Grid>
          </Grid>
          <Grid container style={selected === "team" ? buttonSelected : { ...buttonNotSelected }} onClick={() => { setSelected("team") }} className="d-sm-flex hoverPointer" item xs={12}>
            <Grid item xs={2}><Icon size={20} color={selected === "team" ? "#0062FF" : "#B5B5BE"} icon="goal" /></Grid>
            <Grid item xs={10}>Team</Grid>
          </Grid>
          <Grid container style={selected === "clienti" ? buttonSelected : buttonNotSelected} onClick={() => setSelected("clienti")} className="d-sm-flex hoverPointer" item xs={12}>
            <Grid item xs={2}><Icon size={20} color={selected === "clienti" ? "#0062FF" : "#B5B5BE"} icon="friends" /></Grid>
            <Grid item xs={10}>Clienti</Grid>
          </Grid>
        </Grid>

        <Grid container item xs={10} justify="center">
          {selected === "aziendale" && <Company setModalType={setModalType} />}
          {selected === "clienti" && <Clients setModalType={setModalType} />}
          {selected === "team" && <Team setModalType={setModalType} />}
          {selected === "personale" && <PersonalProfile setModalType={setModalType} />}
        </Grid>
      </Grid>
    </>
  )
}