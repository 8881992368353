import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { Form, Input, ModalBody, ModalFooter, ModalHeader, Label } from "reactstrap";
import { Button, TextField, InputAdornment, Grid, IconButton, Tooltip, Zoom } from '@material-ui/core';
import { useStyles, uploadImages, deleteImage, confirmButton } from './plant-list/PlantListCss';
import { ActivityButton } from './ActivityButton';
import Icon from './IconsComponent';
import moment from 'moment';


export const UploadImages = (props: any) => {
  console.log("in UploadImages with:", props)
  const { orderProps, orderLeft } = props

  const dispatch = useDispatch()
  const classes = useStyles()

  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("") as any

  let orderImages = useSelector(ContentSelectors.listOrderImages)
  console.log("orderImages", orderImages)

  const maxNumber = orderImages ? orderProps.billboards.length - orderImages.length : orderProps.billboards.length; /// numero massimo di foto inseribili pari al numero di impianti presenti nell'ordine
  let now = moment().format("YYYY-MM-DD")

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    // data for submit
    console.log("imageList and addUpdateIndex", imageList, addUpdateIndex);
    setImages(imageList as never[]);
  };

  useEffect(() => {
    if (images.length === 0) { /// fa sparire l'immagine grande quando non ce ne sono caricate
      setSelectedImage("")
    }
  }, [images, orderImages])

  return (
    <div className="poppinsFont">
      <Grid container>
        <Grid item xs={12}>
          <div className="d-sm-flex justify-content-between align-items-center border-bottom mb-3">
            <b className="ml-3">Foto impianti</b>
            <IconButton className="bg-transparent border-0 mr-1" onClick={() => {
              if (!orderLeft) {
                dispatch(ContentActions.didListOrderImages("empty"));
              }
              dispatch(UiActions.switchCloseAddNewPlant());
            }}>
              <Icon color="#bfbfbf" size={20} icon="close" />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12}>
          Clicca sul tasto o trascina le foto su di esso. <br />
          Numero massimo di foto caricabili: {maxNumber} <br />
          <span style={{ fontSize: 13, opacity: 0.6 }}>{"(inserire una foto per impianto)"}</span> <br />
          {orderImages.length > 0 ? <>Foto presenti nell'ordine: {orderImages.length} <br /></> : null}
          Foto caricate: {orderImages.length > 0 ? orderImages.length : images.length}/{maxNumber} <br />
        </Grid>
        {selectedImage ? <Grid className="d-sm-flex mt-3" item xs={12}>
          <img style={{ position: "relative", borderRadius: 18 }} src={selectedImage} alt="" width="100%" height="100%" />
          <div style={{ position: "absolute" }}>
            <button className="mt-2 ml-1" style={deleteImage} onClick={() => setSelectedImage("")}>
              <Icon size={30} color="gray" icon="close" />
            </button>
          </div>
        </Grid> : null}
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          acceptType={["png", "jpeg", "svg", "jpg"]}
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps
          }) => (
            // creazione ui delle foto
            <div className="ml-2">
              <Grid container className="d-sm-flex mb-3" spacing={4}>
                {imageList.map((image, index) => (
                  <div key={index} className="d-sm-flex flex-column mt-5">
                    <Grid item xs={3} className="d-sm-flex p-2 hoverPointer">
                      <div> {/* questo div serve per tenere l'immagine sempre centrata */}
                        <img style={{ position: "relative", borderRadius: 18 }} src={image.dataURL} alt="" width="130" height="100" onClick={() => setSelectedImage(image.dataURL)} />
                      </div>
                      <div className="mt-2 ml-1" style={{ position: "absolute" }}>
                        <button style={deleteImage} onClick={() => onImageUpdate(index)}>
                          <Icon size={20} color="white" icon="photo" />
                        </button>
                        <button style={deleteImage} onClick={() => onImageRemove(index)}>
                          <Icon size={20} color="white" icon="trash" />
                        </button>
                      </div>
                    </Grid>
                    {/* <input style={{ width: 130, marginLeft: 10 }} type="text" placeholder="descrizione.." onChange={(e: any) => {
                      image["caption"] = e.target.value
                      // console.log("image", image)
                    }} /> */}
                    <TextField style={{ width: 130, marginLeft: 10 }} className={classes.borderColor} id={`imageDescription${index}`} label="descrizione.." size="small" onChange={(e: any) => {
                      image["caption"] = e.target.value
                      // console.log("image", image)
                    }} />
                  </div>
                ))}
                <Grid item xs={3} className="p-4">
                  <button
                    style={uploadImages}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    {/* Clicca o trashina la foto qui */}
                    <Icon size={25} color={isDragging ? "red" : "#007bff"} icon="add" />
                  </button>
                </Grid>
                &nbsp;
              </Grid>
              {/* <button onClick={onImageRemoveAll}>Rimuovi tutte le immagini</button> */}
            </div>)}
        </ImageUploading>
        {images.length === maxNumber ?
          <Grid style={{ color: "red" }} item xs={12}>
            Numero massimo di foto raggiunto
          </Grid> : null}
        {images.length >= maxNumber / 2 ?
          <Grid style={{ color: "#00e68a" }} item xs={12}>
            Numero minimo richiesto di foto raggiunto
          </Grid> : null}
        <Grid container item xs={12}>
          <ActivityButton className="poppinsFont" data-cy="confirm" style={confirmButton} name="confermaAggiuntaFoto" color="primary" disabled block onClick={() => {
            // console.log("images array", images, orderProps)
            dispatch(ContentActions.willUploadOrderImage({ order_id: orderProps.id, campaign: orderProps.campaign, images: images }))
          }}>
            Carica foto
          </ActivityButton>
        </Grid>
      </Grid>
    </div>
  )
}