import React, { useState, useEffect } from 'react';
import './SignUp.css';
import { Grid, TextField, Checkbox } from '@material-ui/core';
import { Formik, Form, Field, useFormikContext } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStyles, signupSchema, signupData, adornmentIcon, EmptyCheck, FilledCheck, options } from './SignUpUtils';


export const Signup = () => {
  const classes = useStyles()
  const [step, setStep] = useState("signUp")
  const [error, setError] = useState(false)
  const [client, setClient] = useState("Concessionaria Pubblica")
  const [hidePassword, setHidePassword] = useState(true)
  const [firstJob, setFirstJob] = useState(null) as any
  const [lastYearCampaign, setLastYearCampaign] = useState(null) as any
  const [founded_by, setFounded_by] = useState("")


  const [affissioni, setAffissioni] = useState(null) as any
  const [cartelloni, setCartelloni] = useState(null) as any
  const [camionVela, setCamionVela] = useState(null) as any
  const [autobus, setAutobus] = useState(null) as any
  const [altro, setAltro] = useState(null) as any

  const requiredQuestions = (value: any) => {
    if (founded_by) {
      // console.log("questions selected: ", { firstJob: firstJob, lastYearCampaign: lastYearCampaign, founded_by: founded_by })
      setStep("end")
    } else {
      setError(true)
    }
  }

  return (
    <div className="background" >
      <div className="containerSignup poppinsFont">
        <h1 className="bigText">Benvenuto nell'Advertising Intelligence per l'Out of Home</h1>
        <h4 style={{ color: "white", textAlign: "center" }}>Lore ipsum dolor sit amet et coniscitu elit dolor sit amet et coniscitu elit</h4>
        <div className="clientSelectContainer" >
          {step === "end" ? null : <div className="steps">
            <div className="stepDone" />
            <div className="stepDone" />
            <div className={step === "questions" ? "stepDone" : "stepUndone"} />
            <span className="stepNumber">{step === "questions" ? "Step 3" : "Step 2"}</span>
          </div>}
          {step === "signUp" && <> <div style={{ width: "70%" }} >
            <h3 style={{ textAlign: "center" }} className="mt-3 mb-4" >Inizia la registrazione con l'anagrafica Aziendale</h3>
            <div className="clientSelectSignUp" >
              <b style={{ opacity: client === "Concessionaria Pubblica" ? 1 : 0.2, marginRight: 15 }} >Concessionaria Pubblica</b>
              <label className="switch">
                <input type="checkbox" checked={client === "Concessionaria Privata" ? true : false} onClick={() =>
                  setClient(client === "Concessionaria Privata" ? "Concessionaria Pubblica" : "Concessionaria Privata")
                } />
                <span className="slider round"></span>
              </label>
              <b style={{ opacity: client === "Concessionaria Privata" ? 1 : 0.2, marginLeft: 15 }} >Concessionaria Privata</b>
            </div>
          </div>
            <Formik
              initialValues={signupData}
              validationSchema={signupSchema}
              validateOnBlur={true}
              onSubmit={values => {
                values.type = client
                setStep("questions")
                console.log('in onsubmit with: ', values)
                // dispatch(AuthActions.willSignupUser({ email: values.email, password: values.password, family_name: values.familyName, given_name: values.givenName, history: history }));
              }}
            >
              {({ errors, touched, values, handleChange, setFieldValue }) => {
                //console.log(city)
                return (
                  <Form>
                    <Grid className="mt-4" container alignItems="center" justify="center" spacing={4} >
                      <Grid item xs={6}>
                        <Field as={TextField}
                          className={classes.borderColor}
                          id="social"
                          value={values.social}
                          placeholder="Ragione Sociale"
                          InputProps={{ startAdornment: (adornmentIcon("someone_activity")) }}
                          helperText={touched.social ? errors.social : ""}
                          error={touched.social && Boolean(errors.social)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field as={TextField}
                          fullWidth
                          className={classes.borderColor}
                          id="p_iva"
                          value={values.p_iva}
                          placeholder="P.IVA"
                          InputProps={{ startAdornment: (adornmentIcon("someone_activity")) }}
                          helperText={touched.p_iva ? errors.p_iva : ""}
                          error={touched.p_iva && Boolean(errors.p_iva)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field as={TextField}
                          className={classes.borderColor}
                          id="social_form"
                          value={values.social_form}
                          placeholder="Forma societaria"
                          InputProps={{ startAdornment: (adornmentIcon("achievements_fill")) }}
                          helperText={touched.social_form ? errors.social_form : ""}
                          error={touched.social_form && Boolean(errors.social_form)}
                        />
                      </Grid>
                      <Grid item xs={6}></Grid>
                      <Grid item xs={12}>
                        {/* lascio commentato l'Autocomplete perchè potrebbe servire */}
                        {/* <Autocomplete
                        className={classes.borderColor}
                        options={options}
                        getOptionLabel={(option: any) => option.opzione}
                        // style={{ width: 300 }}
                        renderInput={(params) =>  */}
                        <Field as={TextField}
                          // {...params} 
                          id="pec"
                          placeholder="PEC"
                          value={values.pec}
                          variant="standard"
                          InputProps={{ startAdornment: (adornmentIcon("student_outline")) }}
                          helperText={touched.pec ? errors.pec : ""}
                          error={touched.pec && Boolean(errors.pec)}
                        // />}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field as={TextField}
                          fullWidth
                          className={classes.borderColor}
                          id="email"
                          value={values.email}
                          placeholder="Email"
                          InputProps={{ startAdornment: (adornmentIcon("email")) }}
                          helperText={touched.email ? errors.email : ""}
                          error={touched.email && Boolean(errors.email)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field as={TextField}
                          fullWidth
                          className={classes.borderColor}
                          id="password"
                          value={values.password}
                          placeholder="Password"
                          type={hidePassword ? "password" : "text"}
                          InputProps={{ startAdornment: <div className="hoverPointer" onClick={() => setHidePassword(!hidePassword)}>{(adornmentIcon(hidePassword ? "not_visible" : "visible"))}</div> }}
                          helperText={touched.password ? errors.password : ""}
                          error={touched.password && Boolean(errors.password)}
                        />
                      </Grid>
                      {/* lascio commentato il confirmPassword perchè potrebbero servire */}
                      {/* <Grid item xs={12}>
                        <Field as={TextField}
                          fullWidth
                          className={classes.borderColor}
                          id="passwordConfirm"
                          value={values.passwordConfirm}
                          placeholder="Confirm password"
                          type="password"
                          InputProps={{ startAdornment: (adornmentIcon("not_visible")) }}
                          helperText={touched.passwordConfirm ? errors.passwordConfirm : ""}
                          error={touched.passwordConfirm && Boolean(errors.passwordConfirm)}
                        />
                      </Grid> */}
                      <Grid item xs={12}>
                        <Field as={TextField}
                          fullWidth
                          className={classes.borderColor}
                          id="phone"
                          type="number"
                          value={values.phone}
                          placeholder="Telefono"
                          InputProps={{ startAdornment: (adornmentIcon("someone_activity")) }}
                          helperText={touched.phone ? errors.phone : ""}
                          error={touched.phone && Boolean(errors.phone)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field as={TextField}
                          className={classes.borderColor}
                          id="legal_municipality"
                          value={values.legal_municipality}
                          placeholder="Sede legale (Comune)"
                          InputProps={{ startAdornment: (adornmentIcon("achievements_fill")) }}
                          helperText={touched.legal_municipality ? errors.legal_municipality : ""}
                          error={touched.legal_municipality && Boolean(errors.legal_municipality)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field as={TextField}
                          fullWidth
                          className={classes.borderColor}
                          id="legal_address"
                          value={values.legal_address}
                          placeholder="Sede legale (Indirizzo)"
                          InputProps={{ startAdornment: (adornmentIcon("achievements_fill")) }}
                          helperText={touched.legal_address ? errors.legal_address : ""}
                          error={touched.legal_address && Boolean(errors.legal_address)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field as={TextField}
                          className={classes.borderColor}
                          id="legal_cap"
                          value={values.legal_cap}
                          type="number"
                          placeholder="Sede legale (CAP)"
                          InputProps={{ startAdornment: (adornmentIcon("achievements_fill")) }}
                          helperText={touched.legal_cap ? errors.legal_cap : ""}
                          error={touched.legal_cap && Boolean(errors.legal_cap)}
                        />
                      </Grid>
                      <Grid item xs={6}></Grid>
                    </Grid>
                    <button className="buttonSignUp" type="submit">Continua</button>
                  </Form>
                )
              }}
            </Formik> </>}
          {step === "questions" && <>
            <div style={{ width: "70%" }} >
              <h3 style={{ textAlign: "center", fontSize: 25 }} className="mt-3 mb-4" >Inventario impianti pubblicitari</h3>
              <h3 style={{ textAlign: "center", opacity: 0.4, fontSize: 12 }} className="mt-3 mb-4" >Da quali e quanti impianti pubblicitari è composto il tuo inventario?</h3>
            </div>
            <Grid className="mt-4" container alignItems="center" justify="center" spacing={4} >
              <Grid container item xs={12} alignItems="center" >
                <Grid item xs={4}></Grid>
                <Grid style={{ fontSize: 13 }} container item xs={2} alignItems="center" justify="center">Nessuna</Grid>
                <Grid style={{ fontSize: 13 }} container item xs={2} alignItems="center" justify="center">Da 1 A 20</Grid>
                <Grid style={{ fontSize: 13 }} container item xs={2} alignItems="center" justify="center">Da 20 A 50</Grid>
                <Grid style={{ fontSize: 13 }} container item xs={2} alignItems="center" justify="center">Da 50 A 150</Grid>
                {/* <Grid item xs={2}><span style={{ marginLeft: 10 }}>No</span></Grid> */}
              </Grid>
              <Grid container item xs={12} alignItems="center" >
                <Grid style={{ fontSize: 15 }} item xs={4}>
                  {"Pubblicità temporanea (affissioni)"}
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="affissioni_nessuna"
                    checked={affissioni === "Nessuna" ? true : false}
                    onClick={() => setAffissioni("Nessuna")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="affissioni_1-10"
                    checked={affissioni === "Da 1 a 20" ? true : false}
                    onClick={() => setAffissioni("Da 1 a 20")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="affissioni_20-50"
                    checked={affissioni === "Da 20 a 50" ? true : false}
                    onClick={() => setAffissioni("Da 20 a 50")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="affissioni_50-150"
                    checked={affissioni === "Da 50 a 150" ? true : false}
                    onClick={() => setAffissioni("Da 50 a 150")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
              </Grid>
              <Grid container item xs={12} alignItems="center" >
                <Grid style={{ fontSize: 15 }} item xs={4}>
                  {"Pubblicità permanente (cartelloni stradali)"}
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="cartelloni_nessuna"
                    checked={cartelloni === "Nessuna" ? true : false}
                    onClick={() => setCartelloni("Nessuna")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="cartelloni_1-10"
                    checked={cartelloni === "Da 1 a 20" ? true : false}
                    onClick={() => setCartelloni("Da 1 a 20")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="cartelloni_20-50"
                    checked={cartelloni === "Da 20 a 50" ? true : false}
                    onClick={() => setCartelloni("Da 20 a 50")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="cartelloni_50-150"
                    checked={cartelloni === "Da 50 a 150" ? true : false}
                    onClick={() => setCartelloni("Da 50 a 150")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
              </Grid>
              <Grid container item xs={12} alignItems="center" >
                <Grid style={{ fontSize: 15 }} item xs={4}>
                  {"Camion Vela"}
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="camionVela_nessuna"
                    checked={camionVela === "Nessuna" ? true : false}
                    onClick={() => setCamionVela("Nessuna")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="camionVela_1-10"
                    checked={camionVela === "Da 1 a 20" ? true : false}
                    onClick={() => setCamionVela("Da 1 a 20")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="camionVela_20-50"
                    checked={camionVela === "Da 20 a 50" ? true : false}
                    onClick={() => setCamionVela("Da 20 a 50")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="camionVela_50-150"
                    checked={camionVela === "Da 50 a 150" ? true : false}
                    onClick={() => setCamionVela("Da 50 a 150")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
              </Grid>
              <Grid container item xs={12} alignItems="center" >
                <Grid style={{ fontSize: 15 }} item xs={4}>
                  {"Autobus e altri mezzi pubblici"}
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="autobus_nessuna"
                    checked={autobus === "Nessuna" ? true : false}
                    onClick={() => setAutobus("Nessuna")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="autobus_1-10"
                    checked={autobus === "Da 1 a 20" ? true : false}
                    onClick={() => setAutobus("Da 1 a 20")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="autobus_20-50"
                    checked={autobus === "Da 20 a 50" ? true : false}
                    onClick={() => setAutobus("Da 20 a 50")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="autobus_50-150"
                    checked={autobus === "Da 50 a 150" ? true : false}
                    onClick={() => setAutobus("Da 50 a 150")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
              </Grid><Grid container item xs={12} alignItems="center" >
                <Grid style={{ fontSize: 15 }} item xs={4}>
                  {"Altro"}
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="altro_nessuna"
                    checked={altro === "Nessuna" ? true : false}
                    onClick={() => setAltro("Nessuna")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="altro_1-10"
                    checked={altro === "Da 1 a 20" ? true : false}
                    onClick={() => setAltro("Da 1 a 20")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="altro_20-50"
                    checked={altro === "Da 20 a 50" ? true : false}
                    onClick={() => setAltro("Da 20 a 50")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
                <Grid container item xs={2} alignItems="center" justify="center">
                  <Checkbox
                    id="altro_50-150"
                    checked={altro === "Da 50 a 150" ? true : false}
                    onClick={() => setAltro("Da 50 a 150")}
                    // disabled={firstJob ? false : true}
                    icon={<EmptyCheck />}
                    checkedIcon={<FilledCheck />} />
                </Grid>
              </Grid>
              <Grid container item xs={12} alignItems="center" >
                <h3 style={{ textAlign: "center", opacity: 0.4, fontSize: 12 }} className="mt-3 mb-4" >In quali regioni italiane è distribuito il tuo inventario di impianti pubblicitari?</h3>
                <Grid item xs={12}>
                  <Autocomplete
                    className={classes.borderColor}
                    options={options}
                    getOptionLabel={(option: any) => option.opzione}
                    onChange={(e: any, value: any) => { setFounded_by(value.value); setError(false) }}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        id="founded_by"
                        placeholder="Seleziona"
                        value={founded_by}
                        variant="standard"
                      />}
                  />
                </Grid>
              </Grid>
            </Grid>
            {error ? <Grid style={{ color: "red", marginTop: 15 }} container item xs={12} alignItems="center" >Per favore, rispondere alle domande</Grid> : null}
            <button className="buttonSignUp" onClick={requiredQuestions} >Continua</button>
          </>}
          {step === "end" && <>
            <div style={{ width: "90%" }} >
              <h3 style={{ textAlign: "center" }} className="mt-3 mb-4" >FANTASTICO!<br /> HAI APPENA COMPLETATO L'ULTIMO STEP!</h3>
            </div>
            <div style={{ width: "90%", fontSize: 14 }} >
              <span style={{ textAlign: "center", opacity: 0.4 }} className="mt-3 mb-4" >Scarica ora il template Exel, compilato e ricaricarlo da qui. Troverai tutti i tuoi impianti pubblicitari in pochi minuti nella piattaforma.</span>
            </div>
            <div className="mt-4 mb-2" style={{ width: "80%", fontSize: 14, textAlign: "center", opacity: 0.8 }} >
              <span >Puoi anche procedere al caricamento manualmente, inserendo informazioni dei tuoi impianti (consigliato fino a 50 impianti)</span>
            </div>
            <div className="mt-2 mb-4" style={{ width: "80%", fontSize: 14, textAlign: "center", opacity: 0.8 }} >
              <span >Ora non ti resta che caricare il tuo inventario di impianti pubblicitari su Billalo e definire le disponibilità sul calendario</span>
            </div>
            <Grid className="d-sm-flex justify-content-center" container item xs={12}>
              <Grid className="mr-4" item xs={5}>
                <button className="buttonSignUpGrey" onClick={() => { }}>Caricamento manuale</button></Grid>
              <Grid item xs={5}>
                <button className="buttonSignUpBlue" onClick={() => { }}>Scarica il template Excel</button></Grid>
            </Grid>
          </>}
        </div>
      </div>
    </div>
  )
}