import React from 'react';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 400,
      margin: 10
    },
    typography: {
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    borderColor: {
      '& label.Mui-focused': {
        color: '#00bfff',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#00bfff',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: 10
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00bfff',
        },
      },
    },
    cancelButton: {
      border: '1px solid rgba(0, 0, 0, .125)',
      backgroundColor: "transparent",
      padding: '7px 17px 7px 17px',
      //color: "white",
      marginRight: 20,
      borderRadius: 10,
      // "&:hover": {
      //   backgroundColor: "#66b0ff",
      // }
    },
    confirmButton: {
      padding: '7px 17px 7px 17px',
      borderColor: "transparent",
      backgroundColor: "#007bff",
      color: "white",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "#007bff",
      }
    },
    popover: {
      marginTop: 10,
    },
    autosuggest: {
      border: "none"
    },
    inputFocused: {
      outlineStyle: "none"
    },
    absolute: {
      position: "absolute",
      zIndex: 4,
      backgroundColor: "white",
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      //border: "1 solid #bfbfbf",
      overflow: "auto",
      width: 385,
      maxHeight: 225,
    },
    billaloIndexSlide: {
      color: '#00e68a',
    }
  }),
);

export const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 6,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#00e68a',
    },
  }),
)(LinearProgress);