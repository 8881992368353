import React, { useState, useEffect } from 'react';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import Icon from './IconsComponent';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import * as Yup from 'yup';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    borderColor: {
      '& label.Mui-focused': {
        color: '#00bfff',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#00bfff',
      },
      '& .MuiOutlinedInput-root': {
        // opacity: 0.4,
        '& fieldset': {
          borderRadius: 10,
          borderColor: '#e6e6e6'
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00bfff',
        },
      },
    },
  }),
);

export const signupSchema = Yup.object().shape({
  social: Yup.string()
    .required('Required'),
  p_iva: Yup.string()
    .required('Required'),
  social_form: Yup.string()
    .required('Required'),
  pec: Yup.string()
    .required('Required'),
  email: Yup.string()
    .email()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  phone: Yup.number()
    .required('Required'),
  legal_municipality: Yup.string()
    .required('Required'),
  legal_address: Yup.string()
    .required('Required'),
  legal_cap: Yup.string()
    .required('Required'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  // passwordConfirm: Yup.string()
  //   .test('passwords-match', 'Passwords must match', function (value) {
  //     return this.parent.password === value
  //   })
  //   .min(2, 'Too Short!')
  //   .max(50, 'Too Long!')
  //   .required('Required'),
  // emailConfirm: Yup.string()
  //   .test('email-match', 'Emails must match', function (value) {
  //     return this.parent.email === value
  //   })
  //   .min(2, 'Too Short!')
  //   .max(50, 'Too Long!')
  //   .required('Required'),
});

export const signupData = {
  type: "",
  social: "",
  p_iva: "",
  social_form: "",
  pec: "",
  email: "",
  password:"",
  // passwordConfirm:"",
  phone: "",
  legal_municipality: "",
  legal_address: "",
  legal_cap: ""
}

export const adornmentIcon = (icon: any) => {
  return <InputAdornment position="start">
    <Icon size={25} icon={icon} />
  </InputAdornment>
}

export const EmptyCheck = () => {
  return (
    <div data-cy="emptyCheck" style={{ width: 25, height: 25, borderWidth: 1, borderStyle: "solid", borderColor: "#92929D", borderRadius: 15 }} />
  )
}
export const FilledCheck = () => {
  return (
    <div style={{ width: 25, height: 25, borderWidth: 1, borderStyle: "solid", borderColor: "#00e68a", borderRadius: 15, position: "relative" }} >
      <div className="d-sm-flex align-items-center">
        <Icon size={25} color="#00e68a" icon="check" />
      </div>
    </div>
  )
}

export const options = [
  { opzione: "Pubblicità Online", value: "Pubblicità Online" },
  { opzione: "Passaparola", value: "Passaparola" },
  // { opzione: "opzione 3", value: "opzione 3" },
  // { opzione: "opzione 4", value: "opzione 4" },
  // { opzione: "opzione 5", value: "opzione 5" },
  // { opzione: "opzione 6", value: "opzione 6" },
]