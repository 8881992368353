import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  forwardRef,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Button,
  Form,
  Modal,
  ModalBody,
} from "reactstrap";
import {
  useTable,
  useSortBy,
  useFlexLayout,
  useExpanded,
  useRowSelect,
  useGroupBy,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import { VariableSizeList, FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from "react-window-infinite-loader";
import ReactTooltip from "react-tooltip";
import debounce from "lodash/debounce";
import {
  actions as ContentActions,
  selectors as ContentSelectors,
} from "../../store/slices/content";
import {
  actions as UiActions,
  selectors as UiSelectors,
} from "../../store/slices/ui";
import {
  TextField,
  InputAdornment,
  Grid,
  Badge,
  Tooltip,
  Zoom,
  Paper,
} from "@material-ui/core";
import Icon from "../IconsComponent";
import billboards_placeholder from "../../assets/images/billboards_placeholder.svg";
import {
  Styles,
  useStyles,
  actionButton,
  csvButton,
  addButton,
} from "./PlantListCss";
import Popover from "material-ui-popup-state/HoverPopover";
import {
  usePopupState,
  bindHover,
  bindPopover,
} from "material-ui-popup-state/hooks";
import { AddNewBillboardContainer } from "../add-new-billboard/AddNewBillboardContainer";
import { AddNewPlant } from "../AddNewPlant";
import { ListFilter } from "./ListFilter";
import { Maintenance } from "../Maintenance";
import { Disponibility } from "../Disponibility";
import { Availabilities } from "../Availabilities";
import { CSVUpload } from "../CSVUpload";
import { GlobalFilter } from "./GlobalFilter";
import { SortColumn } from "./SortColumn";
import { PlantDetailsInList } from "./PlantDetailsInList";
import _ from "lodash";
import moment from "moment";
import { customBillboardIcon } from '../CustomBillboardIcon';

const currencyFormatter = (value: number) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(value);
};

const CSVTooltip = (
  <Grid
    style={{ borderRadius: 10, width: 310 }}
    className="bg-white text-dark p-3 robotoFont"
    container
  >
    <Grid item xs={12}>
      <b>Caricamento massivo</b>
    </Grid>
    <Grid style={{ opacity: 0.5, marginTop: 10 }} item xs={12}>
      Clicca qui per caricare più impianti attraverso la compilazione di un
      foglio Excel.
    </Grid>
    {/* <Grid item xs={12} className="d-sm-flex flex-row-reverse mt-1"><button style={buttonOrder} >Ho capito</button></Grid> */}
  </Grid>
);

const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
  leading: false,
  trailing: true,
});

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = useRef() as any;
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </div>
    );
  }
);

const Table = ({
  renderRowSubComponent,
  setPlantData,
  setBillboardId,
  setMaintenanceGroup,
  setModalType,
}: any) => {
  const listRef = useRef(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [orderColumn, setOrderColumn] = useState([] as any);
  const [actionOpen, setActionOpen] = useState(false);
  const toggleAction = () => setActionOpen((prevState) => !prevState);
  const [expandedRows, setExpandedRows] = useState({});
  const [dropdownMenu, setDropdownOpen] = useState(false);
  const toggleMenu = () => setDropdownOpen(!dropdownMenu);
  // const [modalType, setModalType] = useState("")
  const [hideIcons, setHideIcons] = useState(true);

  const billboardList = useSelector(ContentSelectors.listMyBillboards);
  const sortColumnValue = useSelector(ContentSelectors.getSortColumnValue);
  const sortOrderAsc = useSelector(ContentSelectors.getSortOrderAsc);
  let listView = useSelector(UiSelectors.getMapListView);
  let switchCloseAddNewPlant = useSelector(UiSelectors.switchCloseAddNewPlant);

  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoPopover",
  });

  const defaultColumn = useMemo(
    () => ({
      width: 50,
      //expanded: false
    }),
    []
  );

  const onExpand = useCallback((row: any) => {
    row.isExpanded = !row.isExpanded;
    listRef.current && (listRef.current! as any).resetAfterIndex(0, true);
    rebuildTooltip();
  }, []);

  const handleExpanded = (row: any) => {
    onExpand(row);
    // console.log("ROW CONTENT", row)
    Object.assign(expandedRows, { [row.id]: row.isExpanded });
    setExpandedRows(expandedRows);
    // console.log("Expanded Rows:", expandedRows)
  };

  const closeRow = () => {
    listRef.current && (listRef.current! as any).resetAfterIndex(0, true);
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        width: 15,
        Cell: ({ row }: any) => (
          <button
            data-cy="detailButton"
            className="button"
            onClick={() => handleExpanded(row)}
          >
            {row.isExpanded ? (
              <Icon size={15} color="blue" icon="chevronDown" />
            ) : (
              <Icon className="right" size={15} icon="chevronRight" />
            )}
          </button>
        ),
      },
      {
        id: "Indirizzo",
        Header: "Indirizzo",
        width: 125,
        accessor: (props: any) => {
          let invisible: boolean = true;

          const address = props
            ? props.location_name
              ? props.location_name
              : props.address
            : ''

          if (props.creation_date) {
            if (moment().isAfter(moment(props.creation_date).add(1, "weeks"))) {
              invisible = true;
            } else {
              invisible = false;
            }
          } else {
            invisible = true;
          }

          // let plantImage = props.imageUrl
          //   ? props.imageUrl
          //   : customBillboardIcon(props.media_type)

          return (
            <div className="d-sm-flex align-items-center mt-3 text-dark poppinsFont">
              <Badge
                color="secondary"
                badgeContent={"new"}
                invisible={invisible}
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
              >
                {props.imageUrl
                  ? <img
                    id={"image-" + props.imageUrl}
                    data-cy="avatar2"
                    src={props.imageUrl}
                    className="mr-3 plantAvatar"
                    loading="lazy"
                    onError={(e: any) => {
                      // console.log("image error. Reloading..");
                      // let imageElement = (document.getElementById("image-" + props.imageUrl) as HTMLImageElement)
                      // imageElement.src = props.imageUrl;
                      setTimeout(() => {
                        let imageElement = document.getElementById("image-" + props.imageUrl) as HTMLImageElement;

                        if (imageElement && imageElement.src) {
                          imageElement.src = props.imageUrl;
                        }
                      }, 500);
                    }}
                  />
                  : <span className='mr-3'>{customBillboardIcon(props.media_type)}</span>
                }
              </Badge>
              <div>
                {props.municipality} - {address}
              </div>
            </div>
          );
        },
      },
      {
        id: "Tipologia",
        Header: "Tipologia",
        accessor: "media_type",
      },
      {
        id: "Formato",
        Header: "Formato",
        accessor: (props: any) => (
          <div className="row d-sm-flex align-items-center mt-3 poppinsFont">
            <div className="mb-3 ml-2">
              {props.base} x {props.height}
            </div>
          </div>
        ),
      },
      {
        id: "Cimasa",
        Header: "Cimasa",
        accessor: "cod_cimasa",
        width: 50,
      },
      {
        id: "Prezzo",
        Header: "Prezzo",
        accessor: (props: any) => <span>{currencyFormatter(props.price)}</span>,
        width: 40,
      },
      {
        id: "Visibilità",
        Header: "Visibilità",
        width: 35,
        accessor: (props: any) => (
          <div className="row d-sm-flex align-items-center ml-3 poppinsFont">
            <div>
              {props.billalo_index !== null
                ? _.ceil(props.billalo_index.iV_normalized, 2)
                : 0}
            </div>
          </div>
        ),
      },
      {
        accessor: "lighting",
      },
      {
        id: "icons",
        width: 30,
        accessor: (props: any) => (
          <>
            <div className="d-sm-flex">
              <button
                className="d-sm-flex flex-row-reverse text-secondary bg-transparent border-0"
                data-cy="availabilities"
                onClick={() => {
                  dispatch(
                    ContentActions.willListBillboardAvailabilities({
                      billboard_id: props.id,
                    })
                  );
                  setPlantData(props);
                  setModalType("availabilities");
                  dispatch(UiActions.switchCloseAddNewPlant());
                  dispatch(ContentActions.willListOrders());
                }}
              >
                <Icon color="gray" size={27} icon="calendar" />
              </button>
              {props.pricing_id ? (
                <button
                  className="d-sm-flex flex-row-reverse text-secondary bg-transparent border-0"
                  data-cy="pricingPlant"
                  onClick={() => {
                    if (props.pricing_id) {
                      dispatch(
                        ContentActions.willGetBillboardPricing({
                          pricing_id: props.pricing_id,
                        })
                      );
                      dispatch(
                        UiActions.switchPricing({
                          items: [
                            {
                              id: props.id,
                              city_code: props.city_code,
                              city: props.municipality,
                              address: props.address,
                            },
                          ],
                        })
                      );
                    }
                  }}
                >
                  <Icon size={27} color="#00e68a" icon="administration" />
                </button>
              ) : (
                <Tooltip
                  title={
                    <span style={{ fontSize: 15, color: "black" }}>
                      Nessuna regola di pricing impostata
                    </span>
                  }
                  TransitionComponent={Zoom}
                >
                  <button
                    className="d-sm-flex flex-row-reverse text-secondary bg-transparent border-0"
                    data-cy="pricingDisabled"
                    onClick={() => {
                      dispatch(
                        UiActions.switchPricing({
                          items: [
                            {
                              id: props.id,
                              city_code: props.city_code,
                              city: props.municipality,
                              address: props.address,
                            },
                          ],
                        })
                      );
                    }}
                  >
                    <Icon size={27} color="gray" icon="administration" />
                  </button>
                </Tooltip>
              )}
            </div>
          </>
        ),
      },
      {
        id: "Opzioni",
        width: 20,
        accessor: (props: any) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                data-cy="plantMenu"
                className="bg-transparent border-0"
              >
                <button className="bg-transparent border-0 right">
                  <Icon
                    className="backgroundIconColor"
                    color="gray"
                    size={25}
                    icon="more"
                  />
                </button>
              </DropdownToggle>
              <DropdownMenu data-cy="billboard-menu" className={classes.drowdownMenu}>
                <DropdownItem
                  className="text-secondary"
                  data-cy="modifyButton"
                  onClick={() => {
                    setPlantData(props);
                    if (props.billboard_class) {
                      setModalType("addNewBillboard");
                    } else {
                      setModalType('addNewPlant')
                    }
                    dispatch(UiActions.switchCloseAddNewPlant());
                  }}
                >
                  <Icon size={15} icon="write" /> Modifica impianto
                </DropdownItem>
                <DropdownItem
                  className="text-secondary"
                  data-cy="deleteBillboard"
                  onClick={() => {
                    setBillboardId(props.id);
                    setPlantData(props);
                    setModalType("deleteBillboard");
                    dispatch(UiActions.switchCloseAddNewPlant());
                  }}
                >
                  <Icon
                    data-cy="availability"
                    size={15}
                    icon="trash"
                  />{" "}
                  Rimuovi impianto
                </DropdownItem>
                {/* <DropdownItem className="text-secondary" disabled onClick={() => { }} ><Icon color="gray" size={15} icon="trash" /> Rimuovi</DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
      {
        id: "I più recenti", ///questo oggetto serve solo per mettere la voce nel menu dropdown e utilizzarlo
        // Header: "I più recenti",
        width: 0,
      },
      {
        accessor: "municipality",
      },
      {
        accessor: "billalo_index.iV_normalized",
      },
      {
        accessor: "address",
      },
      {
        accessor: "traffic",
      },
      {
        accessor: "id",
        setGlobalFilter: false,
      },
      {
        accessor: "city_code",
        setGlobalFilter: false,
      },
      {
        accessor: "billalo_index.indice_visibilita",
        setGlobalFilter: false,
      },
      {
        accessor: "authorization_expiration",
        setGlobalFilter: false,
      },
      {
        accessor: "available",
        setGlobalFilter: false,
      },
      {
        accessor: "creationDate",
        setGlobalFilter: false,
      },
      {
        accessor: "height",
      },
      {
        accessor: "base",
      },
      {
        accessor: "height_ground",
      },
      {
        accessor: "last_maintenance",
      },
      {
        accessor: "last_payment",
        setGlobalFilter: false,
      },
      {
        accessor: "latitude",
      },
      {
        accessor: "longitude",
      },
      {
        accessor: "media_owner",
      },
      {
        accessor: "n_authorization",
      },
      {
        accessor: "n_sheet",
      },
      {
        accessor: "orientation",
      },
      {
        accessor: "property_type",
      },
      {
        accessor: 'facade'
      },
      {
        accessor: "updateDate",
      },
      {
        accessor: "taxes.last_payment",
        setGlobalFilter: false,
      },
      {
        accessor: "taxes.municipal_tax",
        setGlobalFilter: false,
      },
      {
        accessor: "billboard_class",
        setGlobalFilter: false,
      },
      {
        accessor: "media_owner_type",
        setGlobalFilter: false,
      },
      {
        accessor: "visible",
        setGlobalFilter: false,
      },
      {
        accessor: "min_period",
        setGlobalFilter: false,
      },
      {
        accessor: "location",
        setGlobalFilter: false,
      },
      {
        accessor: "location_name",
        setGlobalFilter: false,
      },
      {
        accessor: "press_type",
        setGlobalFilter: false,
      },
    ],
    [onExpand, billboardList, hideIcons]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    toggleSortBy,
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
    selectedFlatRows,
    state,
  } = useTable(
    {
      columns,
      data: billboardList,
      defaultColumn,
      initialState: {
        expanded: {},
        sortBy: orderColumn,
        hiddenColumns: [
          "municipality",
          "address",
          "available",
          "taxes.last_payment",
          "billalo_index.iV_normalized",
          "taxes.municipal_tax",
          "city_code",
          "base",
          "cod_cimasa",
          "lighting",
          "last_payment",
          "billalo_index.indice_visibilita",
          "authorization_expiration",
          "creationDate",
          "height",
          "height_ground",
          "id",
          "last_maintenance",
          "latitude",
          "longitude",
          "media_owner",
          "n_authorization",
          "n_sheet",
          "orientation",
          "property_type",
          "facade",
          "traffic",
          "updateDate",
          "billboard_class",
          "media_owner_type",
          "visible",
          "min_period",
          "location",
          "location_name",
          "press_type",
        ],
      },
      autoResetSelectedRows: false,
      // autoResetExpanded: false,
      // expandSubRows: false
    } as any,
    useFlexLayout,
    useGroupBy,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          sortable: false,
          filterable: false,
          width: 5,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div data-cy="selectAllrows">
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        <div
          className={`${row.isSelected ? "borderSelected" : ""} tr-group`}
          {...row.getRowProps()}
          style={style}
        >
          <div className="d-flex align-items-center tr">
            {row.cells.map((cell) => {
              return (
                <div {...cell.getCellProps()} className="td">
                  {cell.render("Cell")}
                </div>
              );
            })}
          </div>
          {row.isExpanded ? renderRowSubComponent({ row }) : null}
        </div>
      );
    },
    [prepareRow, rows, renderRowSubComponent]
  );

  const fetchMoreData = () => {
    console.log("fetch More data... plantlist");
    // aggiungere condizione per evitare fetch dopo il filtro
    dispatch(
      ContentActions.willFetchMoreFirstBillboards({
        filters: {},
        fetchMore: true,
      })
    );
  };

  useEffect(() => {
    if (switchCloseAddNewPlant == false) {
      setPlantData(null);
    }

    listRef.current && (listRef.current! as any).resetAfterIndex(0, true);

    return () => { };
  }, [billboardList, listView, switchCloseAddNewPlant]);

  const itemData = {
    rows,
    prepareRow,
  };

  return (
    <>
      <div className="d-sm-flex align-items-center bd-highlight mb-2 mt-2 w-100">
        <Dropdown
          disabled={selectedFlatRows[0] ? false : true}
          isOpen={actionOpen}
          toggle={toggleAction}
        >
          <DropdownToggle
            style={actionButton}
            data-cy="actionButton"
            className={`${selectedFlatRows[0]
              ? "backgroundSelected text-white"
              : "bg-transparent text-dark"
              }`}
            caret
          >
            Azioni{" "}
          </DropdownToggle>
          <DropdownMenu className={classes.drowdownMenu}>
            <DropdownItem
              onClick={() => {
                dispatch(ContentActions.willListOrders());
                dispatch(
                  ContentActions.willListPlantsAvailabilities(
                    _.map(selectedFlatRows, "original.id")
                  )
                );
                // console.log('selectedFlatRows', _.map(selectedFlatRows, "original.id"))
                setPlantData(selectedFlatRows);
                setMaintenanceGroup(true);
                setModalType("disponibilityGroup");
                dispatch(UiActions.switchCloseAddNewPlant());
              }}
            >
              <Icon color="gray" icon="write" /> Modifica disponibilità
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                dispatch(ContentActions.willListOrders());
                dispatch(
                  ContentActions.willListPlantsAvailabilities(
                    _.map(selectedFlatRows, "original.id")
                  )
                );
                // console.log('selectedFlatRows', _.map(selectedFlatRows, "original.id"))
                setPlantData(selectedFlatRows);
                setMaintenanceGroup(true);
                setModalType("maintenance");
                dispatch(UiActions.switchCloseAddNewPlant());
              }}
            >
              <Icon color="gray" icon="write" /> Pianifica manutenzione
            </DropdownItem>
            <DropdownItem
              data-cy="pricing"
              onClick={() => {
                console.log("selected flat", selectedFlatRows);
                dispatch(
                  ContentActions.didGetBillboardPricing({ pricing: {} })
                );
                dispatch(
                  UiActions.switchPricing({
                    items: _.map(
                      selectedFlatRows.map((i) => i.original),
                      function (el: any) {
                        return {
                          id: el.id,
                          city_code: el.city_code,
                          city: el.municipality,
                          address: el.address,
                        };
                      }
                    ),
                  })
                );
              }}
            >
              <Icon color="gray" icon="administration" /> Impostazioni pricing
            </DropdownItem>
            {/* <DropdownItem disabled onClick={() => { }}><Icon color="gray" icon="trash" /> Rimuovi</DropdownItem> */}
          </DropdownMenu>
        </Dropdown>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          closeRow={closeRow}
        />
        <SortColumn headerGroups={headerGroups} closeRow={closeRow} />
        <ListFilter />
      </div>
      <Grid container item xs={10} justify="center">
        <div {...getTableProps()} className="table">
          <div className="thead">
            {headerGroups.map((headerGroup) => (
              <div
                {...headerGroup.getHeaderGroupProps()}
                className="tr hoverPointer"
              >
                {headerGroup.headers.map((column) => (
                  <div
                    {...column.getHeaderProps()}
                    className="th d-sm-flex align-items-center detailText"
                    data-cy={column.id}
                    onClick={() => {
                      // console.log('click header', sortOrderAsc)
                      if (
                        column.id === "selection" ||
                        column.id === "expander" ||
                        column.id === "icons" ||
                        column.id === "Opzioni"
                      ) {
                        console.log("not sortable to:", column.id);
                      } else {
                        dispatch(
                          ContentActions.didSetSortColumnValue({
                            sortColumnValue: column.id,
                            sortOrderAsc: !sortOrderAsc,
                          })
                        );
                      }
                    }}
                  >
                    {column.render("Header")}
                    <>
                      {sortColumnValue == column.id
                        ? sortOrderAsc
                          ? " 🔼"
                          : " 🔽"
                        : ""}
                    </>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div
            data-cy="tableBody"
            className="tbody"
            {...getTableBodyProps({
              style: {
                borderRadius: 15,
                backgroundColor: "white",
                // overflow: "hidden",
              },
            })}
          >
            {billboardList[0] ? (
              <AutoSizer onResize={rebuildTooltip}>
                {({ height, width }) => (
                  <InfiniteLoader
                    isItemLoaded={(index: any) =>
                      index < rows.length - 4 ? true : false
                    }
                    itemCount={rows.length}
                    loadMoreItems={fetchMoreData}
                  >
                    {({ onItemsRendered, ref }: any) => (
                      <VariableSizeList
                        style={{ overflowX: "hidden" }}
                        height={height}
                        itemCount={rows.length}
                        itemData={itemData}
                        // Hardcoded values only :/
                        itemSize={(i) =>
                          rows[i] && rows[i].isExpanded
                            ? rows[i].original["billboard_class"] &&
                              rows[i].original["billboard_class"] === "Outdoor"
                              ? 300
                              : 200
                            : 80
                        }
                        width={width}
                        onScroll={rebuildTooltip}
                        onItemsRendered={onItemsRendered}
                        ref={listRef}
                      >
                        {RenderRow}
                      </VariableSizeList>
                    )}
                  </InfiniteLoader>
                )}
              </AutoSizer>
            ) : (
              <span
                style={{ fontSize: 30 }}
                className="d-sm-flex justify-content-center mt-5 poppinsFont"
              >
                Nessun risultato trovato
              </span>
            )}
          </div>
        </div>
      </Grid>
      {/* </Grid> */}
    </>
  );
};

export const PlantList = (props: any) => {
  const {
    setExplore,
    setPlantData,
    plantData,
    setBillboardId,
    setMaintenanceGroup,
    setModalType,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UiActions.setSelectedPage("Impianti"));
  }, []);

  const renderRowSubComponent = ({ row }: any) => {
    return <PlantDetailsInList item={row.values} />;
  };

  return (
    <Styles>
      <Table
        renderRowSubComponent={renderRowSubComponent}
        setPlantData={setPlantData}
        setBillboardId={setBillboardId}
        setMaintenanceGroup={setMaintenanceGroup}
        setModalType={setModalType}
      />
    </Styles>
  );
};
