import { combineReducers } from 'redux'
import { reducer as authReducer } from './slices/auth'
import { reducer as notificationReducer } from './slices/notification'
import { reducer as uiReducer } from './slices/ui'
import { reducer as profileReducer } from './slices/profile'
import { reducer as contentReducer } from './slices/content'
import {reducer as paymentReducer} from './slices/payment'
import {reducer as exploreReducer} from './slices/explore'
import { connectRouter } from 'connected-react-router'

export const createRootReducer = (history: any) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  notification: notificationReducer,
  ui: uiReducer,
  profile: profileReducer,
  content: contentReducer,
  payment: paymentReducer,
  explore: exploreReducer
})