import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { CardBody, CardText, Row } from 'reactstrap';
import { Popover, Typography, Button, Paper, Grid, Divider } from '@material-ui/core';
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { billboardClassification, locations, billboardsTypesFacade, pressType } from '../Autocomplete.options';
import { useStyles } from "./PlantListCss";
import moment from "moment";
import _ from 'lodash';

const topHeaders: string[] = ['CLASSE', 'UBICAZIONE', 'FACCIATA', 'SUPERFICIE', 'ALTEZZA', 'ILLUMINAZIONE']
const bottomHeaders: string[] = ['TIPO CONTENUTO', 'MANUTENZIONE', 'ULTIMO INTERVENTO', 'TRIBUTI', 'ULTIMO PAGAMENTO', 'N.SCIA']

const currencyFormatter = (value: number) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
}

export const PlantDetailsInList = (props: any) => {
  console.log("in PlantDetailsInList with:", props.item)
  const classes = useStyles()
  const dispatch = useDispatch()

  /// da eliminare dopo backend
  const [billboardClass, setBillboardClass] = useState('')
  const [sciaNumber, setSciaNumber] = useState(0)
  const [randomLocation, setRandomLocation] = useState('')
  const [randomFacade, setRandomFacade] = useState('')
  const [randomPressType, setRandomPressType] = useState('')

  const availabilities: any[] = useSelector(ContentSelectors.listBillboardAvailabilities)

  /// da eliminare dopo backend
  // const arrayOfFakeClasses: string[] = billboardClassification.map((classification: any) => classification.classification)
  // const arrayOfLocations: string[] = locations[billboardClass]?.map((location: any) => location.location)
  // const arrayOfFacades: string[] = billboardsTypesFacade[billboardClass]?.map((facade: any) => facade.facade)
  // const arrayOfPressTypes: string[] = pressType[billboardClass]?.map((press: any) => press.press)

  const data = props.item
  const maintenanceFilter: any[] = availabilities.filter((availability: any) => moment(availability.start_busy_date).isBefore(moment()))
  let lastMaintenance = maintenanceFilter.length ? moment(maintenanceFilter[maintenanceFilter.length - 1].start_busy_date).format('DD-MM-YYYY') : '-'

  const itemAlign: string = 'd-sm-flex align-items-center justify-content-center'

  useEffect(() => {
    if (!availabilities.length) {
      dispatch(ContentActions.willListBillboardAvailabilities({ billboard_id: data.id }))
    }

    /// da eliminare dopo backend
    // setBillboardClass(arrayOfFakeClasses[Math.floor(Math.random() * arrayOfFakeClasses.length)])
    // setSciaNumber(Math.floor(Math.random() * 9999999))

    return () => {
      dispatch(ContentActions.didListBillboardAvailabilities({ availabilities: undefined }))
      /// da eliminare dopo backend
      // setBillboardClass('')
      // setSciaNumber(0)
      // setRandomLocation('')
    }
  }, [])

  // useEffect(() => {  /// da eliminare dopo backend
  //   if (arrayOfLocations?.length && !randomLocation) {
  //     setRandomLocation(arrayOfLocations[Math.floor(Math.random() * arrayOfLocations.length)])
  //   }
  //   if (arrayOfFacades?.length && !randomFacade) {
  //     setRandomFacade(arrayOfFacades[Math.floor(Math.random() * arrayOfFacades.length)])
  //   }
  //   if (arrayOfPressTypes?.length && !randomPressType) {
  //     setRandomPressType(arrayOfPressTypes[Math.floor(Math.random() * arrayOfPressTypes.length)])
  //   }

  // }, [arrayOfLocations, arrayOfFacades])

  return (
    <Grid container item xs={12}>
      <Grid className={classes.detailsInListHeader} container item xs={12} alignItems="center">
        {topHeaders.map((header: string) => <Grid className='d-sm-flex justify-content-center' item xs={2}>{header}</Grid>)}
      </Grid>
      <Grid className={classes.detailsInListBody} container item xs={12}>
        <Grid className={`${itemAlign} ${data.billboard_class === 'Outdoor' ? classes.outdoorClass : classes.indoorClass}`} item xs={2}><span id="billboardClass">{data.billboard_class}</span></Grid>
        <Grid className={itemAlign} item xs={2}>{data.location ? data.location : '-'}</Grid>
        <Grid className={itemAlign} item xs={2}>{data.facade ? data.facade : '-'}</Grid>
        <Grid className={itemAlign} item xs={2}>{_.multiply(data.base, data.height)}cmq</Grid>
        <Grid className={itemAlign} item xs={2}>{data.height_ground ? data.height_ground : '-'}</Grid>
        <Grid className={itemAlign} item xs={2}>{data.lighting ? data.lighting : '-'}</Grid>
      </Grid>

      {data.billboard_class === 'Outdoor' &&
        <>
          <Grid className={classes.detailsInListHeader} container item xs={12} alignItems="center">
            {bottomHeaders.map((header: string) => <Grid className='d-sm-flex justify-content-center' item xs={2}>{header}</Grid>)}
          </Grid>
          <Grid className={classes.detailsInListBody} container item xs={12}>
            <Grid className={itemAlign} item xs={2}>{data.press_type ? data.press_type : '-'}</Grid>
            <Grid className={itemAlign} item xs={2}>{lastMaintenance}</Grid>
            <Grid className={itemAlign} item xs={2}>{data.last_maintenance ? moment(data.last_maintenance).format("DD-MM-YYYY") : "-"}</Grid>
            <Grid className={itemAlign} item xs={2}>{data['taxes.municipal_tax'] ? currencyFormatter(data['taxes.municipal_tax']) : '0 €'}</Grid>
            <Grid className={itemAlign} item xs={2}>{data['taxes.last_payment'] ? moment(data['taxes.last_payment']).format("DD-MM-YYYY") : "-"}</Grid>
            <Grid className={itemAlign} item xs={2}>{data.n_authorization ? data.n_authorization : '-'}</Grid>
          </Grid>
        </>}
    </Grid>
  )
}