import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Button, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText } from 'reactstrap'
import * as AuthApi from './api/auth'
import { actions as UiActions, selectors as UiSelectors } from './store/slices/ui';
import { actions as ContentActions, selectors as ContentSelectors } from './store/slices/content';
import { selectors as AuthSelectors } from './store/slices/auth'
import { actions as AuthActions } from './store/slices/auth'
import { selectors as ProfileSelectors } from './store/slices/profile'
import Icon from '../src/components/IconsComponent';
import placeholder_profile from './assets/placeholder_profile.png';
import billalo_logo from './assets/logo.png'
import { useStyles } from "./components/plant-list/PlantListCss";

export const Header = ({ className }: any) => {
  const classes = useStyles()

  const [isOpen, setIsOpen] = React.useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated);
  const userAttributes = useSelector(ProfileSelectors.getProfile)
  const user = useSelector(AuthSelectors.getUser)
  const selectedPage = useSelector(UiSelectors.getSelectedPage)
  let noGroup = useSelector(UiSelectors.getNoGroup)
  let notificationsPage = useSelector(UiSelectors.getNotificationsPage)
  const orders: any[] = useSelector(ContentSelectors.listOrders);
  const archivedOrders: any[] = useSelector(ContentSelectors.listArchivedOrders);

  const dispatch = useDispatch()
  const [page, setPage] = useState("")

  // console.log("userAttributes",user)
  // console.log("userAttributes",userAttributes)

  return (
    <Navbar style={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 4 }} className={className} light expand="md">
      {noGroup ? null : <NavbarBrand className="mr-10" href="/">
        <img src={billalo_logo} width="190" height="30" /></NavbarBrand>}
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        {noGroup ? null : <Nav className="d-sm-flex w-100 align-self-center justify-content-center mt-2" navbar>
          {isAuthenticated &&
            <>
              {/* <NavItem>
                <NavLink onClick={() => setPage("Dashboard")} disabled style={{ cursor: "pointer" }} className="mr-2 text-dark poppinsFont" href="">Dashboard</NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink data-cy="/dashboard" style={{ cursor: "pointer" }} onClick={() => {
                  setPage("Dashboard");
                  dispatch(UiActions.willSetNotificationsPage({ open: false, orders: orders, archivedOrders: archivedOrders }))
                  dispatch(UiActions.goTo("/"))
                  // dispatch(UiActions.goTo({ page: "/orders/orderdetails" }));
                }} className="mr-2 text-dark poppinsFont" href="/">
                  <div className={`${selectedPage === "Dashboard" ? "selectedPage" : ""}`}>Dashboard</div></NavLink>
              </NavItem>
              <NavItem>
                <NavLink data-cy="/billboards" style={{ cursor: "pointer" }} onClick={() => {
                  setPage("Impianti");
                  dispatch(UiActions.willSetNotificationsPage({ open: false, orders: orders, archivedOrders: archivedOrders }))
                  dispatch(UiActions.goTo("/billboards"))
                }} className="mr-2 text-dark poppinsFont" href="/billboards">
                  <div className={`${selectedPage === "Impianti" ? "selectedPage" : ""}`}>Impianti</div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink data-cy="/ordini" style={{ cursor: "pointer" }} onClick={() => {
                  setPage("Ordini");
                  dispatch(UiActions.willSetNotificationsPage({ open: false, orders: orders, archivedOrders: archivedOrders }))
                  dispatch(UiActions.goTo("/orders"))
                }} className="mr-2 text-dark poppinsFont">
                  <div className={`${selectedPage === "Ordini" ? "selectedPage" : ""}`}>Ordini</div></NavLink>
              </NavItem>
            </>
          }
        </Nav>}
        <Nav navbar>

          {isAuthenticated ? (
            <div className="d-sm-flex align-items-center">
              <button className="bg-transparent border-0 mr-2" onClick={() => {
                if (notificationsPage) {
                  dispatch(UiActions.willSetNotificationsPage({ open: false, orders: orders, archivedOrders: archivedOrders }))
                } else {
                  dispatch(UiActions.willSetNotificationsPage({ open: true, orders: orders, archivedOrders: archivedOrders }))
                }
                // dispatch(UiActions.willSetNotificationsPage(!notificationsPage))
              }}><Icon size={25} icon="notification" /></button>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle data-cy="toAtavar" className="text-dark" nav caret>
                  <img data-cy="avatar" className="loggedAvatar" src={placeholder_profile} />
                  {userAttributes.given_name} {userAttributes.family_name}
                </DropdownToggle>
                <DropdownMenu className={classes.drowdownMenu} right style={{ zIndex: 3 }}>
                  <DropdownItem onClick={() => {
                    dispatch(UiActions.willSetNotificationsPage({ open: false, orders: orders, archivedOrders: archivedOrders }))
                    dispatch(UiActions.goTo("/profile"))
                  }}>Profilo</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => {
                    dispatch(UiActions.willSetNotificationsPage({ open: false, orders: orders, archivedOrders: archivedOrders }))
                    dispatch(AuthActions.willLogoutUser());
                  }}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>) : (
            <NavItem>
              <NavLink className="text-dark" href="/login/">Login</NavLink>
            </NavItem>
          )
          }
        </Nav>

      </Collapse>
    </Navbar>
  )
}