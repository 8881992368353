import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ExploreActions, selectors as ExploreSelectors } from '../../store/slices/explore';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { useStyles, cancelButton, confirmButton, changeOnSelect, iconColorSelect } from './Explore.css';
import { Grid, InputAdornment, TextField, Chip, Popover, Modal } from '@material-ui/core';
import Icon from '../IconsComponent';
import { Period } from './filters/Period';
import { Municipality } from './filters/Municipality';
import { Typology } from './filters/Typology';
import { PlantPrice } from './filters/PlantPrice';
import { Audience } from './filters/Audience';
import { Format } from './filters/FormatFilter';
import { IVMunicipality } from './filters/IVMunicipality';
import { PlantDetailOverview } from '../PlantDetailOverview';
import { FilterListComponent } from './FilterListComponent';
import { ClassAndTypology } from './filters/ClassAndTypoligy';
import _ from 'lodash';

interface FilterListType {
  planningType: string;
  name: string;
  chip: string;
  icon: string;
  filterValue: string;
  filterCondition: boolean;
  multiChip?: boolean;
}

export const ExplorePlants = (props: any) => {
  const { anchorEl, setAnchorEl, setPlantProps, plantProps } = props

  const classes = useStyles();
  const dispatch = useDispatch()

  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [planningType, setPlanningType] = useState("")

  let filters = useSelector(ExploreSelectors.didGetExploreFilter)

  const setPopoverAnchor = () => {
    const anchorElement: any = document.getElementById('mapComponent')
    setAnchor(anchorElement)
  }

  const filtersList: FilterListType[] = [
    {
      planningType: 'Period',
      name: 'Periodo',
      chip: `Da ${filters.period?.date.from} a ${filters.period?.date.to}`,
      icon: 'calendar',
      filterCondition: !_.isEmpty(filters.period),
      filterValue: 'period'
    },
    {
      planningType: 'Municipality',
      name: 'Località',
      chip: `${filters.city && filters.city.length !== 0 ? filters.city : ''}`,
      icon: 'place',
      filterCondition: filters.city && filters.city.length !== 0,
      filterValue: 'city',
      multiChip: true
    },
    {
      planningType: 'ClassAndTypology',
      name: 'Classe e tipologia',
      chip: `${filters.media_type ? filters.media_type : ''}`,
      icon: 'spreadSheet',
      filterCondition: filters.media_type && filters.media_type.length !== 0,
      filterValue: 'media_type',
      multiChip: true
    },
    {
      planningType: 'PlantPrice',
      name: 'Prezzo Impianti',
      chip: `Da ${filters.price_from ? filters.price_from : ""}€ a ${filters.price_to ? filters.price_to : ""}€`,
      icon: 'administration',
      filterCondition: filters.price_from || filters.price_to,
      filterValue: 'price'
    },
    {
      planningType: 'Format',
      name: 'Formato',
      chip: `Formati: ${filters.format}`,
      icon: 'full',
      filterCondition: filters.format,
      filterValue: 'format',
      multiChip: true
    },
    {
      planningType: 'IVMunicipality',
      name: 'Indice Visibilità',
      chip: `Da ${filters.iV_municipality_from ? filters.iV_municipality_from : ""} a ${filters.iV_municipality_to ? filters.iV_municipality_to : ""}`,
      icon: 'chat',
      filterCondition: filters.iV_municipality_from && filters.iV_municipality_to,
      filterValue: 'iV_municipality_from'
    },
  ]

  const open = Boolean(anchor);
  const id = open ? 'simple-popover' : undefined;

  const open2 = Boolean(anchorEl);
  const id2 = open ? 'simple-popover' : undefined;

  return (
    <div className="poppinsFont">
      <Grid data-cy="campain" style={{ marginTop: 0 }} container spacing={4}>
        <Grid className="text-secondary" item xs={12}>Filtri Impianti</Grid>
        {filtersList.map((filter: FilterListType, index: number) => {

          return (
            <React.Fragment key={index}>
              <FilterListComponent
                planningType={filter.planningType}
                planningTypeSelected={planningType}
                setAnchor={setPopoverAnchor}
                setPlanningType={setPlanningType}
                filterValue={filter.filterValue}
                filterCondition={filter.filterCondition}
                chipText={filter.filterValue ? filter.chip : ''}
                name={filter.name}
                icon={filter.icon}
                multiChip={filter.multiChip}
              />
            </React.Fragment>
          )
        })}

        <Grid>
          <Popover
            className={classes.popoverInFilters}
            id={id}
            open={open}
            anchorEl={anchor}
            onClose={() => {
              setAnchor(null);
              setPlanningType("");
              dispatch(UiActions.setSelectedBillboardId(''));
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }} >
            {planningType === "Period" && <Period setAnchor={setAnchor} setPlanningType={setPlanningType} />}
            {planningType === "Municipality" && <Municipality setAnchor={setAnchor} setPlanningType={setPlanningType} />}
            {planningType === "Typology" && <Typology setAnchor={setAnchor} setPlanningType={setPlanningType} />}
            {planningType === "PlantPrice" && <PlantPrice setAnchor={setAnchor} setPlanningType={setPlanningType} />}
            {planningType === "Audience" && <Audience setAnchor={setAnchor} setPlanningType={setPlanningType} />}
            {planningType === "Format" && <Format setAnchor={setAnchor} setPlanningType={setPlanningType} />}
            {planningType === "IVMunicipality" && <IVMunicipality setAnchor={setAnchor} setPlanningType={setPlanningType} />}
            {planningType === "ClassAndTypology" && <ClassAndTypology setAnchor={setAnchor} setPlanningType={setPlanningType} />}
          </Popover>

          <Popover
            className={classes.popoverContainer}
            id={id2}
            open={open2}
            anchorEl={anchorEl}
            onClose={() => { setAnchorEl(null) }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <PlantDetailOverview item={plantProps} setAnchorEl={setAnchorEl} setPlantProps={setPlantProps} hideIcons />
          </Popover>
        </Grid>
      </Grid>
    </div>
  )
}