import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Paper, Grid, Modal, Popover, Stepper, Step, StepLabel } from '@material-ui/core';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { useStyles, ColorlibConnector } from './AddNewBillboard.css';
import { ReactComponent as LockedIcon } from '../../assets/icons/ic_secure_outline.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/ic_Check.svg';
import { ReactComponent as CardIcon } from '../../assets/icons/ic_Card_View.svg';
import { newBillboardValue } from './newBillboardValue';
import { Parameters } from './Parameters';
import { Location } from './Location';
import { DataInsertion } from './DataInsertion';

const steps: string[] = ["Inserisci parametri", "Localizzazione", "Dati"]
const initialStepArray: string[] = ["Inserisci parametri"]

export const AddNewBillboardContainer = ({ setModalType, item }: any) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [stepsDone, setStepsDone] = useState(initialStepArray)
  const [selectedFile, setSelectedFile] = useState({} as any)
  const [previewImage, setPreviewImage] = useState('') as any
  const [disabled, setDisabled] = useState(false)

  const actualStep = useSelector(UiSelectors.getStep)

  const handleStepsArray = (step: string) => {
    let newArray: string[] = [...stepsDone]

    if (newArray.includes(step) && newArray[steps.indexOf(step)]) {
      // newArray.length = steps.indexOf(step) - 1
      newArray.splice(steps.indexOf(step) + 1)
    } else {
      if (newArray.includes(step)) {
        newArray.filter((oldStep: string) => step !== oldStep)
      } else {
        newArray.push(step)
      }
    }
    setStepsDone(newArray)
  }

  useEffect(() => {
    if (item?.id) {
      dispatch(ContentActions.setBillboard({ setBillboard: item }))
      setStepsDone(steps)
      dispatch(UiActions.didSetStep('Dati'))
      setDisabled(true)
    } else {
      dispatch(ContentActions.setBillboard({ setBillboard: newBillboardValue }))
      dispatch(ContentActions.setBillboard({ key: 'id', value: uuidv4() }))
    }

    return () => {
      dispatch(ContentActions.setBillboard({ setBillboard: newBillboardValue }))
      dispatch(UiActions.didSetStep(steps[0]))
    }
  }, [])

  const selectedStep = (select: any) => {
    if (actualStep === select) {
      return classes.stepSelected
    }
    else {
      return classes.stepDone
    }
  }

  const stepIconSwitch = (step: string, index: number) => {

    if (!stepsDone.includes(step)) {
      return CardIcon
    } else if (stepsDone.includes(step) && stepsDone.includes(stepsDone[index + 1])) {
      return CheckIcon
    } else if (stepsDone.includes(step) && step === actualStep) {
      return LockedIcon
    }
  }

  return (
    <Grid container>
      <Grid className='border-bottom p-2' container item xs={12}>
        <Grid className='text-muted' item xs={11}>Carica nuovo impianto</Grid>
        <Grid style={{ cursor: 'pointer' }} className='d-sm-flex justify-content-center' item xs={1} onClick={() => {
          dispatch(UiActions.switchCloseAddNewPlant())
          setModalType("")
        }}>X</Grid>
      </Grid>
      <Grid className={classes.stepContainer} item xs={12}>
        <Stepper style={{ backgroundColor: '#FAFAFB' }} alternativeLabel activeStep={steps.indexOf(actualStep)} connector={<ColorlibConnector />}>
          {steps.map((stepname: string, index: number) => (
            <Step key={index}>
              <StepLabel className={stepsDone.includes(stepname) ? selectedStep(stepname) : classes.stepDisabled} StepIconComponent={stepIconSwitch(stepname, index)} onClick={() => {
                // console.log("stepsDone.includes(stepname)", stepsDone.includes(stepname))
                if (stepsDone.includes(stepname) && actualStep !== stepname) {
                  handleStepsArray(stepname)
                  dispatch(UiActions.didSetStep(stepname))
                }
              }}>{stepname}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={12}>
        {actualStep === 'Inserisci parametri' &&
          <Parameters
            handleStepsArray={handleStepsArray}
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            previewImage={previewImage}
            setPreviewImage={setPreviewImage}
            disabled={disabled} />}
        {actualStep === 'Localizzazione' &&
          <Location
            handleStepsArray={handleStepsArray}
            disabled={disabled} />}
        {actualStep === 'Dati' &&
          <DataInsertion
            handleStepsArray={handleStepsArray}
            selectedFile={selectedFile}
            disabled={disabled} />}
      </Grid>
    </Grid>
  )
}