import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory
} from "react-router-dom";
import { useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from './store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from './store/slices/ui';
import { actions as ProfileActions, selectors as ProfileSelectors } from './store/slices/profile';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { Styles, useStyles } from "./components/plant-list/PlantListCss";
import { Header } from "./header";
import { Container } from "reactstrap";
import { history } from './store'
import { ConnectedRouter } from 'connected-react-router'
import { LoginPage } from './pages/login'
import { SignupPage } from './pages/signup'
import { SignupConfirmPage } from './pages/signupConfirm'
import { ResetPassword } from './pages/resetpassword'
import { Billboards } from "./pages/billboards";
import { PricingPage } from "./pages/pricing";
import { Orders } from "./pages/orders";
import { OrderDetails } from './pages/orderdetails';
import { Profile } from './pages/profile';
import { Dashboard } from './pages/dashboard';
import { SomeCSVUpload } from './pages/csvupload';
// import { Signup } from './pages/newsignup';
import { Signup } from '../src/components/SignUp';
import { NotificationsPage } from '../src/components/NotificationsPage';
import { NoGroupAdvise } from "../src/components/NoGroupAdvise";

import * as AuthApi from './api/auth'
import { selectors as AuthSelectors } from './store/slices/auth'
import _ from "lodash";

const PrivateRoute = ({ children, ...rest }: any) => {

  let history = useHistory();
  const isLogged = useSelector(AuthSelectors.isLogged)
  const isChecked = useSelector(AuthSelectors.isChecked)

  return (
    <Route
      {...rest}
      render={
        props => {
          console.log("isLogged: ", isLogged)
          return (
            <>
              {isChecked ? (
                <>
                  {isLogged ? (
                    children

                  ) : (
                    <Redirect to="/login" />
                  )}
                </>
              ) : (
                <p>waiting</p>
              )
              }

            </>
          )
        }
      }
    />
  )
}

export const AppRouter = () => {
  const classes = useStyles()

  let order = useSelector(ContentSelectors.getSelectOrder)
  let notificationsPage = useSelector(UiSelectors.getNotificationsPage)
  let actionDone = useSelector(UiSelectors.getActionDone)
  let noGroup = useSelector(UiSelectors.getNoGroup)
  let noProfile = useSelector(UiSelectors.getNoProfile)

  return (
    <>
      <Backdrop className={classes.backdrop} open={actionDone.action === "orderContract" ? true : false} >
        <CircularProgress color="inherit" /> <br />
        <span className="ml-2">Download contratto in corso..</span>
      </Backdrop>
      <ConnectedRouter history={history}>
        <Header className="text-white border-bottom" />
        {noGroup ? <NoGroupAdvise /> : <>
          {notificationsPage
            ? <NotificationsPage />
            : <Container className="switchContainer" fluid>
              <Switch>
                <Route path="/resetpassword">
                  <div className="bg-white">
                    <ResetPassword />
                  </div>
                </Route>
                <Route path="/signup/confirm/:code">
                  <div className="bg-white">
                    <SignupConfirmPage />
                  </div>
                </Route>
                <Route path="/signup/confirm/">
                  <div className="bg-white">
                    <SignupConfirmPage />
                  </div>
                </Route>
                <Route path="/signup">
                  <div className="bg-white">
                    <SignupPage />
                  </div>
                </Route>
                <Route path="/newsignup">
                  <div className="bg-white">
                    <Signup />
                  </div>
                </Route>
                <Route path="/login">
                  <div className="bg-white">
                    <LoginPage />
                  </div>
                </Route>
                <Route path="/pricing">
                  <PricingPage />
                </Route>
                <Route path="/orders/orderdetails">
                  {order.id ? <OrderDetails /> : <Redirect from="/orders/orderdetails" to="/orders" />}
                </Route>
                <Route path="/orders">
                  <Orders />
                </Route>
                <Route path="/profile">
                  <Profile />
                </Route>
                <Route path="/billboards">
                  <Billboards />
                </Route>
                <Route path="/upload/some/csv/:id">
                  <SomeCSVUpload />
                </Route>
                <Route path="/">
                  {noGroup === false && noProfile ? <Redirect from="/" to="/profile" /> : <Dashboard />}
                </Route>
              </Switch>
            </Container>}
        </>}

      </ConnectedRouter>
    </>
  )
}
