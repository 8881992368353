import { Matcher } from "react-day-picker";
import { Order } from "../../graphql/generated";

export enum AvailabilityIssuePeriod {
  WEEKLY = 7,
  BIWEEKLY = 14,
}

export enum AvailabilityStatus {
  FREE = "FREE",
  BOOKED = "BOOKED",
  UNAVAILABLE = "UNAVAILABLE",
  MAINTENANCE = "MAINTENANCE",
}

export enum AvailabilityStatusCustom {
  HOVER = "HOVER",
}

export enum AvailabilityStatusRange {
  BOOKED = "BOOKED",
  UNAVAILABLE = "UNAVAILABLE",
  MAINTENANCE = "MAINTENANCE",
  FREE = "FREE",
  BOOKED_SINGLE = "BOOKED_SINGLE",
  BOOKED_START = "BOOKED_START",
  BOOKED_MIDDLE = "BOOKED_MIDDLE",
  BOOKED_END = "BOOKED_END",
  UNAVAILABLE_SINGLE = "UNAVAILABLE_SINGLE",
  UNAVAILABLE_START = "UNAVAILABLE_START",
  UNAVAILABLE_MIDDLE = "UNAVAILABLE_MIDDLE",
  UNAVAILABLE_END = "UNAVAILABLE_END",
  MAINTENANCE_SINGLE = "MAINTENANCE_SINGLE",
  MAINTENANCE_START = "MAINTENANCE_START",
  MAINTENANCE_MIDDLE = "MAINTENANCE_MIDDLE",
  MAINTENANCE_END = "MAINTENANCE_END",
  HOVER_SINGLE = "HOVER_SINGLE",
  HOVER_START = "HOVER_START",
  HOVER_MIDDLE = "HOVER_MIDDLE",
  HOVER_END = "HOVER_END",
}

export type AvailabilityModifiersMap<T = Matcher | Matcher[]> = Partial<
  Record<AvailabilityStatusRange, T>
>;
export interface Availability {
  id?: string;
  from: Date;
  to: Date;
  status: AvailabilityStatus;
  order?: Order | null;
}
