import React, { useState, useMemo, useCallback, useEffect, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useTable, useSortBy, useFlexLayout, useGlobalFilter, useAsyncDebounce, useRowSelect, useGroupBy } from "react-table";
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Form, Modal, ModalBody } from "reactstrap";
import { VariableSizeList, FixedSizeList } from "react-window";
import { Popover } from '@material-ui/core';
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from 'react-window-infinite-loader';
import ReactTooltip from "react-tooltip";
import debounce from "lodash/debounce";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { TextField, InputAdornment, Badge, Paper, Button, Grid } from '@material-ui/core';
import { PlantDetailsMap } from '../PlantDetailsMap';
import { PlantDetailOverview } from "../PlantDetailOverview";
import { Maintenance } from '../Maintenance';
import { AddNewPlant } from "../AddNewPlant";
import { AddNewBillboardContainer } from "../add-new-billboard/AddNewBillboardContainer";
import { CSVUpload } from '../CSVUpload';
import { ListFilter } from "./ListFilter";
import { Styles, useStyles, actionButton, cancelButton, addButton, sizeTip } from "./PlantListCss";
import { customBillboardIcon } from '../CustomBillboardIcon';
// import billboards_placeholder from '../assets/images/billboards_placeholder.svg'
import { ReactComponent as BillboardPlaceholder } from '../../assets/images/billboards_placeholder.svg'
import Icon from "../IconsComponent";
import _ from 'lodash';
import moment from 'moment';


const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
  leading: false,
  trailing: true
});

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = useRef() as any
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <div>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </div>
    )
  }
)

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: any) => {
  const classes = useStyles()
  const count = preGlobalFilteredRows.length
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <TextField
      data-cy="inputList"
      className={classes.noBordersAll}
      fullWidth
      variant="outlined"
      id="searchInput"
      size="small"
      value={value || ""}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon color="gray" icon="search" />
          </InputAdornment>
        ),
        classes: { notchedOutline: classes.noBorders }
      }}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Cerca per impianti`}
    />
  )
}

const Table = ({ setPlantData, setBillboardId, setMaintenanceGroup, explore, setModalType }: any) => {
  const classes = useStyles();
  const listRef = useRef(null);

  const [mapDetails, setMapDetails] = useState([] as any)
  const [orderColumn, setOrderColumn] = useState([] as any)
  const [actionOpen, setActionOpen] = useState(false);
  const toggleAction = () => setActionOpen(prevState => !prevState);
  const [anchorEl, setAnchorEl] = React.useState(null as any);
  const [plantProps, setPlantProps] = useState({}) as any
  const [selectedRowOverview, setSelectedRowOverview] = useState("")
  const [selectedInMap, setSelectedInMap] = useState(false)
  const [plantSelected, setPlantSelected] = useState(null)

  const billboardList = useSelector(ContentSelectors.listMyBillboards)
  let selectedBillboardId = useSelector(UiSelectors.getSelectedBillboardId)

  const defaultColumn = useMemo(
    () => ({
      // width: 200,
      expanded: false
    }),
    []
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const columns = useMemo(
    () => [
      {
        Header: "Indirizzo",
        accessor: (props: any) => {

          const address = props
            ? props.location_name
              ? props.location_name
              : props.address
            : ''

          return (
            <div className="d-sm-flex align-items-center mt-1" onClick={() => {
              dispatch(UiActions.setSelectedBillboardId(props.id));
              setAnchorEl(document.getElementById('mappa'))
              setPlantProps(props)
            }}>
              
              {props.imageUrl
                  ? <img
                    id={"image-" + props.imageUrl}
                    data-cy="avatar2"
                    src={props.imageUrl}
                    className="mr-3 plantAvatar"
                    loading="lazy"
                    onError={(e: any) => {
                      // console.log("image error. Reloading..");
                      // let imageElement = (document.getElementById("image-" + props.imageUrl) as HTMLImageElement)
                      // imageElement.src = props.imageUrl;
                      setTimeout(() => {
                        let imageElement = document.getElementById("image-" + props.imageUrl) as HTMLImageElement;

                        if(imageElement && imageElement.src){
                          imageElement.src = props.imageUrl;
                        }
                      }, 500);
                    }}
                  />
                  : <span className='mr-3'>{customBillboardIcon(props.media_type)}</span>
                }
              <Grid className="ml-2" container item xs={12}>
                <Grid item xs={8}>
                  {address && address.length < 25 ? address : `${address.substring(0, 25)}..`}
                </Grid>
                <Grid className="d-sm-flex flex-row-reverse" item xs={4}>
                  <span style={{ ...sizeTip, flexWrap: 'wrap' }} className="text-muted">{props.base} x {props.height}</span>
                </Grid>

                <Grid className='mt-2' item xs={12}>{props.municipality}</Grid>
              </Grid>
            </div>
          )
        }
      },
      {
        accessor: "municipality"
      },
      {
        accessor: "address"
      },
      {
        accessor: "media_type"
      },
      {
        accessor: "cod_cimasa",
        setGlobalFilter: false
      },
      {
        accessor: "base",
        setGlobalFilter: false
      },
      {
        accessor: "lighting",
        setGlobalFilter: false
      },
      {
        accessor: "id",
        setGlobalFilter: false
      },
      {
        accessor: "city_code",
        setGlobalFilter: false
      },
      {
        accessor: "billalo_code",
        setGlobalFilter: false
      },
      {
        accessor: "authorization_expiration",
        setGlobalFilter: false
      },
      {
        accessor: "available",
        setGlobalFilter: false
      },
      {
        accessor: "creationDate",
        setGlobalFilter: false
      },
      {
        accessor: "height",
        setGlobalFilter: false
      },
      {
        accessor: "height_ground",
        setGlobalFilter: false
      },
      {
        accessor: "last_maintenance",
        setGlobalFilter: false
      },
      {
        accessor: "latitude",
        setGlobalFilter: false
      },
      {
        accessor: "longitude",
        setGlobalFilter: false
      },
      {
        accessor: "media_owner",
        setGlobalFilter: false
      },
      {
        accessor: "n_authorization",
        setGlobalFilter: false
      },
      {
        accessor: "n_sheet",
        setGlobalFilter: false
      },
      {
        accessor: "orientation",
        setGlobalFilter: false
      },
      {
        accessor: "property_type",
        setGlobalFilter: false
      },
      {
        accessor: "updateDate",
        setGlobalFilter: false
      },
      {
        accessor: "taxes.last_payment",
        setGlobalFilter: false
      },
      {
        accessor: "taxes.municipal_tax",
        setGlobalFilter: false
      },
      {
        accessor: "billboard_class",
        setGlobalFilter: false
      },
      {
        accessor: "media_owner_type",
        setGlobalFilter: false
      },
      {
        accessor: "visible",
        setGlobalFilter: false
      },
      {
        accessor: "min_period",
        setGlobalFilter: false
      },
      {
        accessor: "location",
        setGlobalFilter: false
      },
      {
        accessor: "location_name",
        setGlobalFilter: false
      },
      {
        accessor: "press_type",
        setGlobalFilter: false
      },
      {
        accessor: "facade",
        setGlobalFilter: false
      }
    ],
    [billboardList]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    preGlobalFilteredRows,
    setGlobalFilter,
    toggleRowSelected,
    state
  } = useTable(
    {
      columns,
      data: billboardList,
      defaultColumn,
      initialState: {
        sortBy: orderColumn,
        hiddenColumns: ["facade","municipality", "address", "available", "taxes.last_payment", "taxes.municipal_tax", "city_code", "billalo_code", "authorization_expiration", "creationDate", "height", "height_ground", "id", "last_maintenance", "latitude", "longitude", "media_owner", "n_authorization", "n_sheet", "orientation", "property_type", "updateDate", "media_type", "size", "cod_cimasa", "base", "lighting", "billboard_class", "media_owner_type", "visible", "min_period", "location", "location_name", "press_type"]
      },
      autoResetSelectedRows: false,
    } as any,
    useFlexLayout,
    useGroupBy,
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          width: 10,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div className="d-sm-flex align-items-center">
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div className="d-sm-flex align-items-center">
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        <div className={`${row.getToggleRowSelectedProps().checked ? "borderSelected" : row.isSelected ? "overviewDetailRow" : 'border-bottom'} tr-group p-2`} style={style} onClick={() => {
          setSelectedRowOverview(row.id)
          toggleRowSelected(row.id, true)
          // console.log(row.getToggleRowSelectedProps().checked, row.isSelected)
        }}>
          <div className="tr d-sm-flex align-items-center hoverPointer">
            {row.cells.map((cell: any) => {
              return (
                <div {...cell.getCellProps()} className="td text-dark poppinsFont">
                  {cell.render("Cell")}
                </div>
              );
            })}
          </div>
        </div>
      );
    },
    [prepareRow, rows]
  );

  const dispatch = useDispatch()
  const fetchMoreData = () => {
    console.log('fetch More data...1')
    dispatch(ContentActions.willFetchMoreFirstBillboards({ filters: {}, fetchMore: true }))
  };
  const itemData = {
    rows,
    prepareRow,
  }

  const [dataFetch, setData] = useState(useMemo(() => [], []))

  useEffect(() => {
    setData(billboardList)
  }, [billboardList, explore, selectedInMap])

  return (
    <>
      {explore
        ? null
        : <div className='w-100'>
          <Grid container>
            <Grid item xs={3}>
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter} />
              <div {...getTableProps()} className="table mt-2">
                <Grid container >
                  <Grid item xs={8}>
                    <div id='rowInMap' className="thead">
                      {headerGroups.map((headerGroup) => (
                        <div {...headerGroup.getHeaderGroupProps()} className="tr">
                          {headerGroup.headers.map((column) => (
                            <div
                              {...column.getHeaderProps()}    //ordinamento
                              className="th text-muted hoverPointer"
                            // onClick={() => { console.log('columns on sort in map view', column) }}
                            >
                              {column.render("Header")}
                              <span>
                                {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                              </span>
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </Grid>
                  <Grid className="mt-1" item xs={2}>
                    <Dropdown className='ml-2' disabled={selectedFlatRows[0] ? false : true} isOpen={actionOpen} toggle={toggleAction}>
                      <DropdownToggle style={actionButton} data-cy="actionButton" className={`${selectedFlatRows[0] ? "backgroundSelected text-white" : "bg-transparent text-muted"}`} caret />
                      <DropdownMenu className={classes.drowdownMenu}>
                        <DropdownItem onClick={() => {
                          setPlantData(selectedFlatRows)
                          setModalType("maintenance")
                          dispatch(UiActions.switchCloseAddNewPlant())
                        }}><Icon color="gray" icon="course_outline" /> Modifica disponibilità</DropdownItem>
                        <DropdownItem data-cy="pricing" onClick={() => {
                          // console.log("selected flat", selectedFlatRows);
                          dispatch(ContentActions.didGetBillboardPricing({ pricing: {} }))
                          dispatch(UiActions.switchPricing({ items: _.map(selectedFlatRows.map(i => i.original), function (el: any) { return { 'id': el.id, 'city_code': el.city_code, "city": el.municipality, "address": el.address } }) }))
                        }}><Icon color="gray" icon="administration" /> Impostazioni pricing</DropdownItem>
                        {/* <DropdownItem disabled onClick={() => dispatch(UiActions.switchPricing())}><Icon color="gray" icon="trash" /> Rimuovi</DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>
                  </Grid>
                  <Grid className="mt-1" item xs={2}>
                    <ListFilter />
                  </Grid>
                </Grid>
                <div data-cy="tableBody" className="tbodyMap" {...getTableBodyProps({ style: { borderRadius: 15, backgroundColor: "white" } })}>
                  {billboardList[0] ? <AutoSizer onResize={rebuildTooltip}>
                    {({ height, width }) => (
                      <InfiniteLoader
                        isItemLoaded={(index: any) => index < rows.length - 4 ? true : false}
                        itemCount={rows.length}
                        loadMoreItems={fetchMoreData}
                      >
                        {({ onItemsRendered, ref }: any) => (
                          <VariableSizeList
                            height={height}
                            itemCount={rows.length}
                            itemData={itemData}
                            // Hardcoded values only :/
                            itemSize={(i) => (rows[i] && rows[i].isExpanded ? 250 : 80)}
                            width={width}
                            onScroll={rebuildTooltip}
                            onItemsRendered={onItemsRendered}
                            ref={listRef}
                          >
                            {RenderRow}
                          </VariableSizeList>
                        )}
                      </InfiniteLoader>
                    )}
                  </AutoSizer> : <span style={{ fontSize: 30 }} className="d-sm-flex justify-content-center mt-5 poppinsFont">Nessun risultato trovato</span>}
                </div>
              </div>
            </Grid>
            <Grid item xs={9}>
              {/* <div style={{ width: "69%" }}> */}
              <PlantDetailsMap item={mapDetails} markers={dataFetch} explore={explore} setModalType={setModalType} setAnchorEl={setAnchorEl} setPlantProps={setPlantProps} setSelectedInMap={setSelectedInMap} plantSelected={plantSelected} setPlantSelected={setPlantSelected} />
              {/* </div> */}
            </Grid>
          </Grid>
        </div>}
      <Popover
        className={classes.popoverContainer}
        id={id}
        open={open}
        anchorEl={anchorEl}
        //  anchorReference="anchorPosition"
        //  anchorPosition={{ top: 100, left: 560 }}
        onClose={() => {
          setAnchorEl(null);
          selectedInMap ? setSelectedInMap(false) : toggleRowSelected(selectedRowOverview, false);
          setPlantSelected(null);
          dispatch(ContentActions.didListBillboardAvailabilities({ availabilities: undefined }));
          dispatch(UiActions.setSelectedBillboardId(''));
          setPlantProps({})
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
        transformOrigin={{ vertical: 'top', horizontal: 'left', }}
      >
        <PlantDetailOverview item={plantProps} />
      </Popover>
    </>);
}

export const PlantListMap = (props: any) => {
  const { setPlantData, setBillboardId, setMaintenanceGroup, explore, setModalType } = props

  return (
    <Styles>
      <Table setPlantData={setPlantData} setBillboardId={setBillboardId} setMaintenanceGroup={setMaintenanceGroup} explore={explore} setModalType={setModalType} />
    </Styles>
  );
}