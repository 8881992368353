export const billboardsTypes = [ /// per il filtro in lista impianti
  { typology: "Affissione" },
  { typology: "Cartellonistica" },
  { typology: "Pensilina" },
  { typology: "Mediapole" },
  { typology: "Display Digitale" },
  { typology: "Facciata" },
  { typology: "Banner" },
  { typology: "Transenne" },
  { typology: "Altro" },
  { typology: "Impianto illuminato" },
  { typology: "Impianto opaco" },
  { typology: "Striscione" },
  { typology: "Vetrina" },
  { typology: "Display digitale" },
  { typology: "Altro" }
]

export const mediaOwnerType = [
  { type: "Pubblica" },
  { type: "Privata" }
]
export const billboardClassification = [
  { classification: "Outdoor" },
  { classification: "Indoor" },
  // { classification: "Dinamico" }
]

export const locations = {
  Outdoor: [
    { location: "Comune" },
    { location: "Zona Pedonale" },
    { location: "Autostrada" },
    { location: "Strada Statale" },
    { location: "Strada Provinciale" },
    { location: "Strada rurale" },
    { location: "Parcheggio" },
    { location: "Centro Commerciale" },
    { location: "Stazione" },
    { location: "Aereoporto" },
    { location: "Stadio" }
  ],
  Indoor: [
    { location: "Centro Commerciale" },
    { location: "Stazione" },
    { location: "Aereoporto" },
    { location: "Altro" }
  ]
}

export const billboardsTypesFacade = {
  Outdoor: [
    { facade: "Affissione" },
    { facade: "Cartellonistica" },
    { facade: "Pensilina" },
    { facade: "Mediapole" },
    { facade: "Display Digitale" },
    { facade: "Facciata" },
    { facade: "Banner" },
    { facade: "Transenne" },
    { facade: "Altro" }
  ],
  Indoor: [
    { facade: "Impianto illuminato" },
    { facade: "Impianto opaco" },
    { facade: "Striscione" },
    { facade: "Vetrina" },
    { facade: "Display digitale" },
    { facade: "Altro" }
  ]
}

export const pressType = {
  Outdoor: [
    { press: "Carta affissione" },
    { press: "PVC" },
    { press: "Microforato" },
    { press: "Telo" },
    { press: "Altro" }
  ],
  Indoor: [
    { press: "Carta" },
    { press: "Banner PVC" },
    { press: "Microforato" },
    { press: "Altro" }
  ]
}

/// OUTDOOR
// export const locationsOutdoor = [
//   { location: "Comune" },
//   { location: "Zona Pedonale" },
//   { location: "Autostrada" },
//   { location: "Strada Statale" },
//   { location: "Strada Provinciale" },
//   { location: "Strada rurale" },
//   { location: "Parcheggio" },
//   { location: "Centro Commerciale" },
//   { location: "Stazione" },
//   { location: "Aereoporto" },
//   { location: "Stadio" }
// ]
// export const billboardsTypesOutdoor = [
//   { typology: "Affissione" },
//   { typology: "Cartellonistica" },
//   { typology: "Pensilina" },
//   { typology: "Mediapole" },
//   { typology: "Display Digitale" },
//   { typology: "Facciata" },
//   { typology: "Banner" },
//   { typology: "Transenne" },
//   { typology: "Altro" }
// ]
export const minPeriod = [
  { period: "14 giorni" },
  { period: "30 giorni" },
  { period: "60 giorni" },
  { period: "90 giorni" },
  { period: "1 anno" },
]
// export const pressTypeOutdoor = [
//   { press: "Carta affissione" },
//   { press: "PVC" },
//   { press: "Microforato" },
//   { press: "Telo" },
//   { press: "Altro" }
// ]

/// INDOOR
// export const locationsIndoor = [
//   { location: "Comune" },
//   { location: "Stazione" },
//   { location: "Aereoporto" },
//   { location: "Stadio" }
// ]
// export const billboardsTypesIndoor = [
//   { typology: "Impianto illuminato" },
//   { typology: "Impianto opaco" },
//   { typology: "Striscione" },
//   { typology: "Vetrina" },
//   { typology: "Display digitale" },
//   { typology: "Altro" }
// ]
// export const pressTypeIndoor = [
//   { press: "Carta" },
//   { press: "Banner PVC" },
//   { press: "Microforato" },
//   { press: "Altro" }
// ]

/// INDOOR & OUTDOOR
export const facade = [
  { facade: "Monofacciale" },
  { facade: "Bifacciale" }
]
export const groungHeight = [
  { height_ground: "0-1mt" },
  { height_ground: "1-2mt" },
  { height_ground: "2-4mt" },
  { height_ground: "4-7mt" },
  { height_ground: "7-10 mt" },
  { height_ground: "Oltre 10mt" },
]
export const billboardsFormat = [
  { format: "70x100" },
  { format: "100x140" },
  { format: "140x200" },
  { format: "300x200" },
  { format: "400x300" },
  { format: "600x300" },
  { format: "1200x600" },
  { format: "Altro" },
]
export const lightingType = [
  { lighting: "Attigua" },
  { lighting: "Nulla" },
  { lighting: "Propria" }
]
export const orientationType = [
  { orientation: "Parallelo" },
  { orientation: "Perpendicolare" },
  { orientation: "In Rotonda" },
  { orientation: "Incrocio" }
]

/// PRICING
export const conditionsTotalPrice = [
  { condition1: "Maggiore" },
  { condition1: "Minore" },
]
export const amountTotalPrice = [
  { amount_1: "10" },
  { amount_1: "20" },
  { amount_1: "30" },
  { amount_1: "40" },
  { amount_1: "50" },
  { amount_1: "60" },
  { amount_1: "70" },
  { amount_1: "80" },
  { amount_1: "90" },
  { amount_1: "100" }
]
export const pricingPercentage = [
  { percentage: "10" },
  { percentage: "20" },
  { percentage: "30" },
  { percentage: "40" },
  { percentage: "50" },
  { percentage: "60" },
  { percentage: "70" },
  { percentage: "80" },
  { percentage: "90" },
  { percentage: "100" }
]

/// STATUS
export const statusOptions = [
  // { status: "Pagato" },
  { status: "Rifiutato" },
  { status: "Confermato" },
  // { status: "", disabled: true },
]

export const statusChange = (status: any) => {

  switch (status) {
    case "Draft":
      return ["Confermato"]
      break;
    case "draft":
      return ["Confermato"]
      break;
    case "accepted":
      return ["Rifiutato"]
      break;
    case "rejected":
      return ["Accettato"]
      break;
    case "scheduled":
      return ["Materiali Consegnati"]
      break;
    case "posted":
      return ["In uscita"]
      break;
    default:
      return ["Stato attuale non modificabile"]
  }
}