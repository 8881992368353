import React, { useState, useEffect } from 'react';
import { Popover, Checkbox, Typography, Grid, Slider, Button, Paper, InputAdornment, TextField, LinearProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Icon from "./IconsComponent";
import { statusOptions } from "./Autocomplete.options";
import moment from 'moment';
import { useStyles, BorderLinearProgress } from './OrdersFilter.css';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { useSuggestion } from "./Suggestion.hooks";
import Autosuggest, { BlurEvent, ChangeEvent } from 'react-autosuggest';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import _ from 'lodash';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" className="lightBlueColor" />;
const checkedIcon = <CheckBoxIcon fontSize="small" className="lightBlueColor" />;

const valuetext = (value: number) => {
  return `${value}%`;
}

export const OrdersFilter = (props: any) => {
  const { orders } = props
  const classes = useStyles();
  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [statusVisual, setStatusVisual] = useState([])
  const [status, setStatus] = useState([])
  const [advertiser, setAdvertiser] = useState("")
  const [campaignName, setCampaignName] = useState("")
  const [orderDate, setOrderDate] = useState("");
  const [orderDateVisual, setOrderDateVisual] = useState(null);
  const [dateFrom, setDateFrom] = useState("")
  const [dateTo, setDateTo] = useState("")
  const [dateFromVisual, setDateFromVisual] = useState(null)
  const [dateToVisual, setDateToVisual] = useState(null)
  const [printer, setPrinter] = useState("");
  const [amount, setAmount] = useState("") as any
  //// questi due sotto servono per AutoSuggest, non eliminateli!
  const [data1, setData] = useState("")
  const [fieldValue, setFieldValue] = useState("")

  const ordersFilters = useSelector(ContentSelectors.getOrdersFilters)
  let filteredOrders = useSelector(ContentSelectors.getFilteredOrders)
  // console.log("ordersFilters", ordersFilters)

  const {
    getSuggestions,
    getSuggestionValue,
    renderSuggestion,
    renderSectionTitle,
    getSectionSuggestions,
    onChange,
    value,
    setValue,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
  }: any = useSuggestion({
    setFieldValue,
    setData,
    // setSuggestions,
    // setCity,
  })

  const resetFilter = () => {
    setStatus([])
    setStatusVisual([])
    setAdvertiser("")
    setCampaignName("")
    setOrderDate("")
    setOrderDateVisual(null)
    setDateFrom("")
    setDateTo("")
    setDateFromVisual(null)
    setDateToVisual(null)
    setPrinter("")
    setAmount("")
  }

  const inputProps: any = {
    placeholder: "Comune",
    value,
    onChange: onChange,
    className: "autosuggestFilter",
    id: "comune"
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const data = {
    status: { value: status },
    advertiser: { value: advertiser },
    campaign_name: { value: campaignName },
    order_date: { value: orderDate },
    printer: { value: printer },
    order_period: { from: dateFrom, to: dateTo },
    amount: { value: amount },
  }

  useEffect(() => {
    console.log("filteredOrders updated")
  }, [filteredOrders])

  return (
    <div>
      {_.isEmpty(ordersFilters) ?
        <Button data-cy="filterButton" aria-describedby={id} className={`bg-white border-0 mr-3`} onClick={handleClick} >
          <Icon color="#0062cc" size={25} icon="filter" /></Button>
        :
        <Button data-cy="filterButton" aria-describedby={id} className={`bg-primary border-0 mr-3`} onClick={handleClick} >
          <Icon color="#FFFFFf" size={25} icon="filter" /></Button>
      }
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div style={{ overflow: "hidden" }} className={`${classes.root} robotoFont`}>
          <h2 className="mt-4 ml-3">Filtra ordini</h2>
          <Grid className="p-2" container spacing={3}>
            {/* <Grid item xs={12}>
              <Autosuggest
                multiSection={true}
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                renderSectionTitle={renderSectionTitle}
                getSectionSuggestions={getSectionSuggestions}
                inputProps={inputProps}
                theme={{
                  input: classes.autosuggest,
                  inputOpen: classes.autosuggest,
                  inputFocused: classes.inputFocused,
                  suggestionsContainer: classes.absolute,
                  suggestionsContainerOpen: classes.absolute,
                }}
              />
            </Grid> */}
            {/* <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  className={classes.borderColor}
                  data-cy="dateFrom"
                  disableToolbar
                  autoOk={true}
                  format="DD/MM/YYYY"
                  variant="inline"
                  id="start-date"
                  label="Data inizio"
                  keyboardIcon={<Icon color="grey" size={25} icon="schedule" />}
                  value={startDate}
                  onChange={(e: any) => {
                    setDateFrom(moment(e._d).format("YYYY-MM-DD"))
                    setStartDate((e._d))
                  }}
                  InputAdornmentProps={{ position: "start" }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  className={classes.borderColor}
                  data-cy="dateTo"
                  disableToolbar
                  autoOk={true}
                  format="DD/MM/YYYY"
                  variant="inline"
                  id="end-date"
                  label="Data fine"
                  keyboardIcon={<Icon color="grey" size={25} icon="schedule" />}
                  value={endDate}
                  onChange={(e: any) => {
                    setDateTo(moment(e._d).format("YYYY-MM-DD"))
                    setEndDate((e._d))
                  }}
                  InputAdornmentProps={{ position: "start" }}
                />
              </MuiPickersUtilsProvider>
            </Grid> */}
            {/* <Grid item xs={12}>
              <span><input data-cy="available" type="checkbox" color="#007bff" checked={available} onChange={(e: any) => {
                setAvailable(!available)
              }} /> Disponibili</span>
            </Grid> */}
            {/* <span className="ml-3 mb-4 text-black-50 detailText" data-cy="indiceBillalo">Indice Visibilità</span>
            <Grid item xs={12} alignItems="center">
              <Slider
                data-cy="billaloIndex"
                className={classes.billaloIndexSlide}
                value={billaloIndexSelector}
                onChange={billaloIndexChange}
                valueLabelDisplay="on"
                aria-labelledby="range-slider"
                valueLabelFormat={valuetext}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Autocomplete
                className={classes.borderColor}
                id="status"
                value={statusVisual}
                options={statusOptions}
                getOptionLabel={(option) => option.status}
                renderInput={(params) => <TextField data-cy="trovami" {...params} label="Status" />}
                onChange={(e: any, value: any) => {
                  let result = [] as any
                  value.map((i: any) => {
                    if (i.status == "Confermato") {
                      result.push("confirmed")
                    }
                    else if (i.status == "Rifiutato") {
                      result.push("rejected")
                    }
                  })
                  setStatusVisual(value)
                  setStatus(result)
                }}
                disableCloseOnSelect
                multiple
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.status}
                  </React.Fragment>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={`${classes.borderColor}`}
                fullWidth
                id="advertiser"
                label="Cliente"
                variant="outlined"
                size="small"
                value={advertiser}
                onChange={(e: any) => setAdvertiser(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={`${classes.borderColor}`}
                fullWidth
                id="campaign_name"
                label="Nome Campagna"
                variant="outlined"
                size="small"
                value={campaignName}
                onChange={(e: any) => setCampaignName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  className={classes.borderColor}
                  data-cy="dateFrom"
                  disableToolbar
                  autoOk={true}
                  format="DD/MM/YYYY"
                  variant="inline"
                  id="start-date"
                  label="Data inizio"
                  keyboardIcon={<Icon color="grey" size={25} icon="schedule" />}
                  value={dateFromVisual}
                  onChange={(e: any) => {
                    setDateFrom(moment(e._d).format("YYYY-MM-DD"))
                    setDateFromVisual((e._d))
                  }}
                  InputAdornmentProps={{ position: "start" }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  className={classes.borderColor}
                  data-cy="dateTo"
                  disableToolbar
                  autoOk={true}
                  format="DD/MM/YYYY"
                  variant="inline"
                  id="end-date"
                  label="Data fine"
                  keyboardIcon={<Icon color="grey" size={25} icon="schedule" />}
                  value={dateToVisual}
                  onChange={(e: any) => {
                    setDateTo(moment(e._d).format("YYYY-MM-DD"))
                    setDateToVisual((e._d))
                  }}
                  InputAdornmentProps={{ position: "start" }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  className={classes.borderColor}
                  data-cy="orderDate"
                  disableToolbar
                  autoOk={true}
                  format="DD/MM/YYYY"
                  variant="inline"
                  id="orderDate"
                  label="Data Ordine"
                  keyboardIcon={<Icon color="grey" size={25} icon="schedule" />}
                  value={orderDateVisual}
                  onChange={(e: any) => {
                    setOrderDate(moment(e._d).format("YYYY-MM-DD"))
                    setOrderDateVisual((e._d))
                  }}
                  InputAdornmentProps={{ position: "start" }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <TextField
                className={`${classes.borderColor} mt-2`}
                fullWidth
                id="amount"
                type="number"
                label="Spesa totale"
                variant="outlined"
                size="small"
                value={amount}
                onChange={(e: any) => setAmount(e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                className={`${classes.borderColor}`}
                fullWidth
                id="orderDate"
                label="Data Ordine"
                variant="outlined"
                size="small"
                value={orderDate}
                onChange={(e: any) => setOrderDate(e.targert.value)}
              />
            </Grid> */}

            {/* <Grid item xs={12}>
              <Autocomplete
                className={classes.borderColor}
                id="format"
                value={visualFormat}
                options={billboardsFormat}
                getOptionLabel={(option) => option.format}
                renderInput={(params) => <TextField {...params} label="Formato" />}
                onChange={(e: any, value: any) => {
                  let result = [] as any
                  value.map((i: any) => {
                    if (i.format == "1200x600") {
                      result.push({ base: 1200, height: 600 })
                    }
                    else if (i.format == "600x300") {
                      result.push({ base: 600, height: 300 })
                    }
                  })
                  setFormat(result)
                  setVisualFormat(value)
                }}
                disableCloseOnSelect
                multiple
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.format}
                  </React.Fragment>
                )}
              />
            </Grid> */}
            {/* <Grid item xs={12}>
              <Autocomplete
                className={classes.borderColor}
                id="lighting"
                value={lighting}
                options={lightingType}
                getOptionLabel={(option) => option.lighting}
                renderInput={(params) => <TextField {...params} label="Illuminazione" />}
                onChange={(e: any, value: any) => { setLighting(value) }}
                disableCloseOnSelect
                multiple
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.lighting}
                  </React.Fragment>
                )}
              />
            </Grid> */}
            {/* <Grid item xs={6}>
              <Autocomplete
                className={classes.borderColor}
                id="orientation"
                value={orientation}
                options={orientationType}
                getOptionLabel={(option) => option.orientation}
                renderInput={(params) => <TextField {...params} label="Orientamento" />}
                onChange={(e: any, value: any) => { setOrientation(value) }}
                disableCloseOnSelect
                multiple
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.orientation}
                  </React.Fragment>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                className={classes.borderColor}
                id="height_ground"
                value={height_ground}
                options={groungHeight}
                getOptionLabel={(option) => option.height_ground}
                renderInput={(params) => <TextField {...params} label="Altezza da Terra" />}
                onChange={(e: any, value: any) => { setHeightGround(value) }}
                disableCloseOnSelect
                multiple
                renderOption={(option, { selected }) => {
                  //console.log(option)
                  return (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.height_ground}
                    </React.Fragment>
                  )
                }}
              />
            </Grid> */}
            <Grid className="mt-3" container item xs={12} direction="row-reverse">
              <button data-cy="confirm" className={classes.confirmButton} onClick={() => {
                dispatch(ContentActions.willFilterOrders({ filters: data, items: orders }))
                setAnchorEl(null)
                console.log("Selected filters:", data)
              }} >Conferma</button>
              <button className={classes.cancelButton} onClick={handleClose}>Annulla</button>
              <button className={classes.cancelButton} onClick={() => {
                dispatch(ContentActions.willFilterOrders({ filters: {}, items: [] }))
                resetFilter()
                console.log("Selected filters:", data)
                setAnchorEl(null)
              }} >Azzera Filtro</button>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </div>
  );
}