import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createSelector } from 'reselect'

export const currentSlice = createSlice({
    name: 'payment',
    initialState: {
        userListCondition: [] as any
    },
    reducers: {
        willListConditionUsers: (state, action: PayloadAction<any>) => {
            console.log("in willListConditionUsers slice")
        },
        didListConditionUsers: (state, action: PayloadAction<any>) => {
            //console.log("action!!!", action)
            state.userListCondition = action.payload
        }
    }
})
export const { actions, reducer }: any = currentSlice
export const {
    willListConditionUsers,
    didListConditionUsers
} = actions

export const selectors = {
    listConditionUsers: (state: any) => state.payment.userListCondition
}