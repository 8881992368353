import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import moment from 'moment';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: "100%",
      height: "100%",
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      position: 'relative'
    },
    top: {
      color: '#0062CC',
      //animationDuration: '550ms',
      position: 'absolute',
      //left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
    billaloIcon: {
      color: '#0062CC',
      alignItems: "center",
      justifyContent: "center",
      // position: 'absolute',
    },
    visibilityProgress: {
      width: 80,
      height: 80,
    },
    municipalityGraphic: {
      width: 200,
      height: 200,
    },
    paperNotification: {
      height: "100%",
      width: 250,
      fontSize: 13
    },
    tooltip: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "white",
      borderWidth: 1,
      borderColor: "#f1f1f5",
      borderStyle: "solid",
      borderRadius: 5,
      padding: 15,
      fontSize: 15
    },
    popoverContainer: {
      width: 340,
      '& .MuiPaper-root.MuiPopover-paper.MuiPaper-rounded': {
        top: 'unset !important',
        bottom: 0
      },
      '& .MuiPaper-root': {
        height: '82vh',
        border: '1px solid #0062FF',
        borderRadius: 10
      }
    },
    drowdownMenu: {
      backgroundColor: '#44444F',
      borderRadius: 10,
      '& .dropdown-item': {
        color: 'white !important'
      },
      '& .dropdown-item:hover': {
        backgroundColor: 'transparent',
        color: '#FC5A5A !important',
        '& >svg>path': {
          fill: '#FC5A5A'
        }
      },
      '& .dropdown-item:focus': {
        backgroundColor: 'transparent',
        color: '#FC5A5A !important',
        '& >svg>path': {
          fill: '#FC5A5A'
        }
      },
      '& .dropdown-item>svg>path': {
        fill: 'white'
      }
    }
  }),
);

export const backButton = {
  display: "flex",
  backgroundColor: "white",
  width: 186,
  alignItems: "center",
  borderRadius: 10,
  borderWidth: 0,
  fontSize: 18,
  padding: "8px 11px 8px 11px",
  color: "gray"
}

export const confirmButton = {
  borderRadius: 10,
  backgroundColor: "#007bff",
  color: "white",
  border: "none",
  // height: 50,
  // width: "100%"
}

export const photoShape = {
  borderWidth: 1,
  borderColor: "#bfbfbf",
  borderStyle: "solid",
  borderRadius: 12,
  cursor: "pointer",
}

export const tooltip = {
  // '& .MuiTooltip-popper': {
  //   '& .MuiTooltip-tooltip': {
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
  borderWidth: 1,
  borderColor: "#f1f1f5",
  borderStyle: "solid",
  borderRadius: 5,
  padding: 15,
  fontSize: 15
  //   }
  // }
}

export const Divider = () => <Grid className="border-bottom" container item xs={12} alignItems="center"></Grid>

export const orderStatusSwitch = (status: string, campaignStart: any, campaignEnd: any) => {

  switch (status) {
    case "Draft":
      return "Draft"
      break;
    case "rejected":
      return "Rifiutato"
      break;
    case "reject":
      return "Rifiutato"
      break;
    case "accepted":
      return "Confermato"
      break;
    case "scheduled":
      return "Programmato"
      break;
    case "confirmed":
      return "Confermato"
      break;
    case "payed":
      return "Pagato"
      break;
    case "confirm":
      return "Confermato"
      break;
    // case "payed":
    //   return "Programmato"
    //   break;
    case "delivered_materials": {
      let now = moment()
      if (moment(now).isBetween(moment(campaignStart), moment(campaignStart).subtract(7, 'days'))) {
        return "Prossima Uscita"
      } else {
        return "Materiali Consegnati"
      }
    }
      break;
    case "posted": {
      let now = moment()
      if (moment(now).isBetween(moment(campaignStart), moment(campaignStart).add(3, 'days'))) {
        return "In corso"
      } else {
        return "In Uscita"
      }
    }
      break;
    case "completed":
      return "Completato"
      break;
    case "canceled":
      return "Cancellato"
      break;
    case "pass":
      return "Errore"
      break;
  }
}

export const notificationAlertTextChange = (notification: string) => {
  switch (notification) {
    case "Alert Materiali Leggero":
      return "Alcuni ordini sono in attesa dei materiali"
      break;
    case "Alert Materiali Pesante":
      return "Alcuni ordini sono in attesa dei materiali"
      break;
    case "Alert Foto Leggero":
      return "La campagna non han le foto minime richieste"
      break;
    default:
      return ""
  }
}

export const notificationTextChange = (notification: string) => {
  switch (notification) {
    case "Alert Materiali Leggero":
      return "Attesa materiali"
      break;
    case "Alert Materiali Pesante":
      return "Attesa materiali"
      break;
    case "Alert Foto Leggero":
      return "Foto richieste"
      break;
    default:
      return ""
  }
}

export const setBadge = (order_date: any, status: string, imgLength: number, plantListLength: number, campaignStart: any, campaignEnd: any) => {
  let now = moment().format("YYYY-MM-DD")
  let before15 = moment(campaignStart).subtract(15, 'days').format("YYYY-MM-DD")
  let before30 = moment(campaignStart).subtract(30, 'days').format("YYYY-MM-DD")
  let before7 = moment(campaignEnd).subtract(7, 'days').format("YYYY-MM-DD")

  // console.log("setBadge test", moment(now).isBefore(before30),now, moment(campaignStart).format("YYYY-MM-DD"), before30)
  // console.log("setBadge test", moment(now).isBetween(before30, moment(campaignStart).format("YYYY-MM-DD")), now, moment(campaignStart).format("YYYY-MM-DD"), before30)

  if (moment(now).isBetween(before15, moment(campaignStart).format("YYYY-MM-DD")) && status !== "delivered_materials") {
    return { invisible: false, notification: "Alert Materiali Pesante", style: { marginLeft: 10, width: 6, height: 6, borderRadius: "100%", backgroundColor: "#ffc542" } }  /// 15gg prima l'inizio della campagna && status !== delivered_materials Alert Materiali Pesante
  }
  else if (moment(now).isBetween(before30, moment(campaignStart).format("YYYY-MM-DD")) && status !== "delivered_materials") {
    return { invisible: false, notification: "Alert Materiali Leggero", style: { marginLeft: 10, width: 6, height: 6, borderRadius: "100%", backgroundColor: "#ffc542" } }  /// 30gg prima l'inizio della campagna && status !== delivered_materials Alert Materiali Leggero
  }
  else if (imgLength < (plantListLength / 2) && moment(now).isBetween(before7, moment(campaignStart).format("YYYY-MM-DD"))) {
    return { invisible: false, notification: "Alert Foto Leggero", style: { marginLeft: 10, width: 6, height: 6, borderRadius: "100%", backgroundColor: "#fc5a5a" } }  /// le foto caricate sono uguali o maggiorni alla metà degli impianti && mancano 7 gg alla fine della campagna Alert Foto Leggero
  } else {
    return { invisible: true, notification: "", style: {} }  /// nasconde il bedge
  }
}

export const statusColor = (status: string) => {
  switch (status) {
    case "Draft":
      return "confirmedOverview"
      break;
    case "rejected":
      return "negativeOverview"
      break;
    case "reject":
      return "negativeOverview"
      break;
    case "accepted":
      return "confirmedOverview"
      break;
    case "scheduled":
      return "pendingOverview"
      break;
    case "confirmed":
      return "confirmedOverview"
      break;
    case "payed":
      return "confirmedOverview"
      break;
    case "confirm":
      return "confirmedOverview"
      break;
    case "delivered_materials":
      return "confirmedOverview"
      break;
    case "posted":
      return "exitOverview"
      break;
    case "completed":
      return "confirmedOverview"
      break;
    case "canceled":
      return "negativeOverview"
      break;
    case "pass":
      return "negativeOverview"
      break;
  }
}