import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { AvailabilityStatus } from "./PlantAvailability.types";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItem: {
      marginBottom: theme.spacing(1),
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      borderBottomColor: theme.palette.divider,
      cursor: 'default'
    },
    pointer: {
      cursor: 'pointer'
    },
    bold: {
      fontWeight: "bold",
    },
    listItemDate: {
      fontSize: 14,
    },
    listItemSubtitle: {
      color: theme.palette.grey[500],
      whiteSpace: 'pre-wrap',
      overflow: 'hidden'
    },
    badge: {
      width: "1em",
      height: "1em",
      borderRadius: "1em",
      marginRight: "0.5em",
      display: "inline-block",
      verticalAlign: "middle",
    },
    [`badge_${AvailabilityStatus.BOOKED}`]: {
      backgroundColor: "rgba(80, 181, 255, 1)",
    },
    [`badge_${AvailabilityStatus.MAINTENANCE}`]: {
      backgroundColor: "rgba(255, 197, 65, 1)",
    },
    [`badge_${AvailabilityStatus.UNAVAILABLE}`]: {
      backgroundColor: "rgba(259, 90, 90, 1)",
    },
  })
);
