import React from "react"
import { StepConnector } from '@material-ui/core';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    cancelButton: {
      fontSize: 12,
      width: 100,
      height: 38,
      border: '1px solid #E2E2EA',
      backgroundColor: "#FAFAFB",
      color: "#696974",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "#FAFAFB",
      }
    },
    confirmButton: {
      fontSize: 12,
      width: 107,
      height: 38,
      borderColor: "transparent",
      backgroundColor: "#0062FF",
      color: "white",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "#0062FF",
      }
    },
    confirmButtonDisabled: {
      backgroundColor: "#e6e6e6",
      color: "white",
      borderRadius: 10,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      "&:hover": {
        backgroundColor: "#e6e6e6",
      }
    },
    paper: {
      padding: theme.spacing(2),
      //textAlign: 'center',
      alignItems: "center",
      justifyContent: "space-around",
    },
    margin: {
      margin: theme.spacing(1),
      borderColor: "#0099e6",
      borderRadius: 10,
    },
    autosuggest: {
      border: "none"
    },
    inputFocused: {
      outlineStyle: "none"
    },
    absolute: {
      position: "absolute",
      zIndex: 4,
      backgroundColor: "white",
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderStyle: "solid",
      borderColor: "#e6e6e6",
      //border: "1 solid #bfbfbf",
      overflow: "auto",
      width: 230,
      maxHeight: 225,
    },
    borderColor: {
      '& label.Mui-focused': {
        color: '#00bfff',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#00bfff',
      },
      // '& .MuiInputBase-input': {
      //   color: "black",
      //   opacity: 1,
      // },
      '& .MuiOutlinedInput-root': {
        // opacity: 0.4,
        '& fieldset': {
          borderRadius: 10,
          borderColor: '#e6e6e6'
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00bfff',
        },
      },
    },
    stepContainer: {
      backgroundColor: '#FAFAFB'
    },
    stepSelected: {
      '& > span > svg': {
        outline: '5px solid rgba(255, 197, 66, 0.1)',
        borderRadius: 20,
        backgroundColor: 'rgba(255, 197, 66, 0.1)',
      },
      '& > span > svg > g': {
        stroke: '#FFC542'
      }
    },
    stepDone: {
      '& > span': {
        cursor: 'pointer'
      },
      '& > span > svg': {
        outline: '5px solid rgba(130, 196, 60, 0.1)',
        borderRadius: 20,
        backgroundColor: 'rgba(130, 196, 60, 0.1)',
      },
      '& > span > svg > g': {
        stroke: '#82C43C'
      }
    },
    stepDisabled: {
      '& > span > svg': {
        outlineOffset: 5,
        outline: '1px solid #979797',
        borderRadius: 20,
        // backgroundColor:'transparent',
      }
    },
    buttonContainer: {
      backgroundColor: '#FAFAFB',
      borderBottomLeftRadius: '15px',
      borderBottomRightRadius: '15px',
      marginTop: 15,
      padding: '20px 0px 20px 10px'
    }
  }),
);

export const borderColorDatePicker = {
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "#e6e6e6",
  borderRadius: 10,
  justifyContent: "center",
  height: 39,
  '& .MuiInput-input': {
    marginLeft: 15
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: 'none'
  }
}

export const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundColor: 'rgba(238, 245, 232, 1)',
    },
  },
  completed: {
    '& $line': {
      backgroundColor: 'rgba(238, 245, 232, 1)',
    }
  },
  line: {
    height: 4,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);