import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ProfileActions, selectors as ProfileSelectors } from '../../store/slices/profile';
import { actions as UiActions } from '../../store/slices/ui'
import { Grid, Paper, Chip } from '@material-ui/core';
import { useStyles } from '../plant-list/ListFilter.css';
import Icon from '../IconsComponent';
import { ActivityButton } from '../ActivityButton';
import { mutableTextFieldAnagrafica } from './Profile.css';
import placeholder from '../../assets/placeholder_profile.png';
import moment from 'moment';
import _ from 'lodash';

// {userAttributes.given_name} {userAttributes.family_name}
export const PersonalProfile = ({setModalType}: any) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const userAttributes = useSelector(ProfileSelectors.getProfile)

  const [editProfilo, setEditProfilo] = useState(false)
  const [editProfilo2, setEditProfilo2] = useState(false)

  const [profilo, setProfilo] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }), {
    // mediaowner: _.replace(profileName, ' ', '_'),
    given_name: '',
    family_name: '',
    email: '',
    password: '----',
    phone: '0000000',
    creation_date: moment().format('DD-MM-YYYY')
  })


  useEffect(() => {
    if (userAttributes) {
      setProfilo(userAttributes)
    }
  }, [userAttributes])

  return (
    <Grid style={{ overflow: 'hidden',  height: '70vh' }} className="d-sm-flex flex-column mt-3" item xs={11}>
      
      <Grid className="d-sm-flex">
        <Grid container item xs={3} direction="column" alignItems="center" justify="center" />
        <Grid container item xs={9} justify="flex-end">
          <ActivityButton data-cy="confirm" style={{ height: 40 }} className={classes.confirmButton} name="willAddMediaOwner" color="primary" onClick={() => {
            // dispatch(ProfileActions.willAddMediaOwner(profilo))
          }}>Salva modifiche</ActivityButton>
          {/* <button data-cy="confirm" className={classes.confirmButton} onClick={() => {
          dispatch(ProfileActions.willAddMediaOwner(profilo))
        }} >Salva modifiche</button> */}
        </Grid>
      </Grid>

      <Grid className="d-sm-flex mt-4" container item xs={12}>
        <Grid style={{ borderRadius: 13, fontSize: 14 }} className="d-sm-flex bg-white p-2" container item xs={12} >
          <Grid item xs={2} container justify="center" alignItems="center">
            <img style={{ borderRadius: 14 }} src={placeholder} width="150" height="150" />
          </Grid>
          <Grid className="d-sm-flex ml-3" container item xs={10} spacing={4}>
            <Grid className="d-sm-flex align-items-center" item xs={12}>
              {/* <Grid className="d-sm-flex justify-content-center p-2" item xs={2}><img style={{ borderRadius: 14 }} src={placeholder} width="150" height="150" /></Grid> */}
              <Grid /* style={{ fontSize: 50 }} */ item xs={11}>
                {mutableTextFieldAnagrafica("given_name", "Nome", editProfilo ? profilo.given_name : <span style={{ fontSize: 50 }}>{profilo.given_name}</span>, editProfilo, profilo, "", 8, setProfilo)}
              </Grid>
              <Grid item xs={1}>
                <button className="border-0 bg-transparent" onClick={() => setEditProfilo(true)}><Icon size={25} icon="edit" /></button>
              </Grid>
            </Grid>
            <Grid className="d-sm-flex align-items-center" item xs={6}>
              <Grid style={{ opacity: 0.4 }} item xs={3}>Ruolo</Grid>
              <Grid className="d-sm-flex flex-row-reverse" item xs={3}>Admin</Grid>
            </Grid>
            <Grid className="d-sm-flex align-items-center" item xs={6}></Grid>
            <Grid className="d-sm-flex align-items-center" item xs={6}>
            </Grid>
            {editProfilo ? <Grid className="d-sm-flex flex-row-reverse" item xs={12}><button className="border rounded bg-transparent" onClick={() => setEditProfilo(false)}><Icon size={25} icon="check" />Applica</button></Grid> : null}
          </Grid>
        </Grid>

        <Grid style={{ borderRadius: 13, fontSize: 14 }} className="d-sm-flex bg-white p-2 mt-5" container item xs={12} >
          <Grid className="d-sm-flex align-items-center ml-3" container item xs={12} spacing={4}>
            <Grid className="d-sm-flex align-items-center mt-4" item xs={12}>
              <Grid style={{ fontSize: 20 }} item xs={11}>Anagrafica</Grid>
              <Grid /* className="d-sm-flex flex-row-reverse" */ item xs={1}><button className="border-0 bg-transparent" onClick={() => setEditProfilo2(true)}><Icon size={25} icon="edit" /></button></Grid>
            </Grid>
            <Grid className="d-sm-flex align-items-center" item xs={12}>
              {mutableTextFieldAnagrafica("email", "Email", profilo.email, editProfilo2, profilo, "Email", 3, setProfilo)}
              <Grid item xs={3}>
                  <button className="border rounded bg-transparent" onClick={() => {
                    setModalType('reset-password')
                    dispatch(UiActions.switchCloseAddNewPlant())
                  }}>Modifica password</button>
                </Grid>
              {mutableTextFieldAnagrafica("phone", "Telefono", profilo.phone, editProfilo2, profilo, "Telefono", 3, setProfilo)}
              {mutableTextFieldAnagrafica("creation_date", "Inscritto dal", profilo.creation_date, editProfilo2, profilo, "Inscritto dal", 3, setProfilo)}
              {/* <Grid item xs={3}><span style={{ opacity: 0.4 }}>Email</span><br />{userAttributes.email}</Grid> */}
            </Grid>
          </Grid>
          {editProfilo2
            ? <Grid className="d-sm-flex flex-row-reverse mt-3" item xs={12}>
              <button className="border rounded bg-transparent" onClick={() => setEditProfilo2(false)}><Icon size={25} icon="check" />
                Applica
              </button>
            </Grid>
            : null}

        </Grid>
      </Grid>

    </Grid>
  )
}