export const clients = [
  {
    name:'Alberto',
    type: 'Privato',
    orders_number: 49,
    region: 'Sardegna',
    sector: 'ADV',
    lifetime_value: 50000
  },
  {
    name:'Asmodee',
    type: 'Azienda',
    orders_number: 6,
    region: 'Piemonte',
    sector: 'CON',
    lifetime_value: 1000000
  },
  {
    name:'Luxor',
    type: 'Azienda',
    orders_number: 436,
    region: 'Sardegna',
    sector: 'ADV',
    lifetime_value: 345636
  },
  {
    name:'Luca',
    type: 'Privato',
    orders_number: 90,
    region: 'Marche',
    sector: 'CON',
    lifetime_value: 42662
  },
  {
    name:'Finnegan',
    type: 'Azienda',
    orders_number: 2,
    region: 'Sicilia',
    sector: 'CON',
    lifetime_value: 460093
  },
  {
    name:'Anna',
    type: 'Privato',
    orders_number: 15,
    region: 'Puglia',
    sector: 'ADV',
    lifetime_value: 22352
  }
]