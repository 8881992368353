import React, { useState, useRef, useReducer, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content'
import { v4 as uuidv4 } from 'uuid';
import { Formik, } from 'formik';
import { useStyles } from "./add-new-billboard/AddNewBillboard.css";
import { addNewPlantValidation } from "./Validations";
import { AddNewPlantForm } from "./AddNewPlantForm";
import moment from 'moment'


export const AddNewPlant = (props: any) => {
  // console.log("if modifing or add witn_ ", props)
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState({} as any)
  const [addBillboardId, setAddBillboardId] = useState(uuidv4())
  const plant = props.item
  const [value, setValue] = useState(plant ? plant.municipality : '')
  const [city, setCity] = useState('')
  const [cityCode, setCityCode] = useState('')
  const [suggestions, setSuggestions] = useState([] as any)
  const [taxesMunicipal, setTaxesMunicipal] = useState(plant ? plant.taxes.municipal_tax : "")
  const [taxesPayment, setTaxesPayment] = useState(plant ? moment(plant.taxes.last_payment).format("DD-MM-YYYY") : "")

  useEffect(() => {
    if (plant) {
      setCity(plant.municipality)
      setCityCode(plant.city_code)
      setAddBillboardId(plant.id)
      // console.log("plant in useEffect", plant)
    }
    setData({
      ["taxes"]: {
        ["last_payment"]: taxesPayment,
        ["municipal_tax"]: taxesMunicipal,
      }
    })

    // console.log("TAXES", taxesMunicipal, taxesPayment)
  }, [taxesPayment, plant])

  const [data, setData] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      municipality: value,
      address: plant ? plant.address : "",
      latitude: plant ? plant.latitude : "",
      longitude: plant ? plant.longitude : "",
      n_sheet: plant ? plant.n_sheet : "",
      city: "", // Questo serve vuoto
      city_code: '', // Questo serve vuoto
      base: plant ? plant.base : "",
      height: plant ? plant.height : "",
      media_type: plant ? plant.media_type : "",
      surface: plant ? plant.surface : "",
      size: plant ? plant.size : "",  //Supercifie
      height_ground: plant ? plant.height_ground : "",
      lighting: plant ? plant.lighting : "",
      orientation: plant ? plant.orientation : "",
      cimasa: plant ? plant.cod_cimasa : "", //Cimasa o Cimasa-code?
      price: plant ? plant.price : "", //Prezzo
      status: plant ? plant.status : "", //Status?
      last_maintenance: plant ? moment(plant.last_maintenance).format("DD-MM-YYYY") : "",
      n_authorization: plant ? plant.n_authorization : "",
      notes: plant ? plant.notes : "",
      billalo_index: plant ? plant.billalo_index : "",
      authorization_expiration: plant ? moment(plant.authorization_expiration).format("DD-MM-YYYY") : "",
      taxes: {
        last_payment: plant ? plant.taxes.last_payment : "",
        municipal_tax: plant ? plant.taxes.municipal_tax : "",
      },
      creation_date: plant ? plant.creation_date : moment()
    }
  );
  // console.log('process env', process.env, window.location.href)
  let url = window.location.origin
  if (url.includes("8080") || url.includes("3000")) {
    url = "https://ssp-dev.billalo.it"
  }
  // let imageUrl = `${url}resources/${addBillboardId}`
  let imageUrl = `${url}/resources/`
  // console.log('process env', window, url, imageUrl)
  return (
    <Formik
      initialValues={data}
      validationSchema={addNewPlantValidation}
      onSubmit={values => {
        // console.log("Formik Values", values, taxesMunicipal, taxesPayment, data);
        values.taxes.last_payment = taxesPayment
        values.taxes.municipal_tax = taxesMunicipal
        values.last_maintenance = data.last_maintenance
        values.authorization_expiration = data.authorization_expiration
        // console.log("Formik Values2", values, taxesMunicipal, taxesPayment, data);

        if (plant) {
          if (selectedFile.name) {
            imageUrl = imageUrl + addBillboardId + "_" + selectedFile.name
            dispatch(ContentActions.willUploadImage({ image: selectedFile, id: addBillboardId + "_" + selectedFile.name }))
            dispatch(ContentActions.willAddBillboard({ id: addBillboardId, data: values, city_code: cityCode, city: city, imageUrl: imageUrl }))
          } else {
            dispatch(ContentActions.willAddBillboard({ id: addBillboardId, data: values, city_code: cityCode, city: city, imageUrl: plant.imageUrl }))
          }
        }
        else if (selectedFile.name) {
          imageUrl = imageUrl + addBillboardId + "_" + selectedFile.name
          dispatch(ContentActions.willAddBillboard({ id: addBillboardId, data: values, city_code: values.city_code, city: values.city, imageUrl: imageUrl }))
          dispatch(ContentActions.willUploadImage({ image: selectedFile, id: addBillboardId + "_" + selectedFile.name }))

        } else {
          dispatch(ContentActions.willAddBillboard({ id: addBillboardId, data: values, city_code: values.city_code, city: values.city }))
        }
      }}>
      {({ errors, touched, values, handleChange, setFieldValue }) => {
        //console.log("ERRORS", errors, values, touched)
        if (plant) {
          delete errors.municipality
        }
        //console.log(city)
        return (
          <AddNewPlantForm plant={props.item} data={data} setData={setData} setSelectedFile={setSelectedFile} setTaxesMunicipal={setTaxesMunicipal} setTaxesPayment={setTaxesPayment} taxesMunicipal={taxesMunicipal} />
        )
      }}
    </Formik>
  )
}