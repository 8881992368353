import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { loader } from 'graphql.macro';
import { configuration } from '../config';
import _ from 'lodash';

var Velocity = require('velocityjs');
const util = require('util');
require('util.promisify').shim();
const fs = require('fs');

export const configure = () => {
  const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"
  Amplify.configure(configuration[stage]);
}

export const uploadFilter = async (filters: any) => {
  console.log("uploadFilter api", filters)

  try {
    localStorage.setItem('filters', JSON.stringify(filters));
    return
  } catch (error) {
    console.log("errore api uploadFilter")
    throw error
  }
}

export const listFilteredBillboards = async (filters: any) => {
  const query = loader('../graphql/listFilteredBillboards.gql');
  console.log('with query listFilteredBillboards: ', filters)

  try {
    const result: any = await API.graphql({ query: query, variables: { input: filters } });
    return result
  } catch (error) {
    console.log("errore api listFilteredBillboards")
    throw error
  }
}