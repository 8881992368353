import { useEffect } from "react";
import cn from "classnames";
import moment from "moment";
import { useDispatch } from "react-redux";

import {
  Button,
  Popover,
  PopoverProps,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { actions as UiActions } from "../../store/slices/ui";
import { actions as ContentActions } from "../../store/slices/content";

import { Availability, AvailabilityStatus } from "./PlantAvailability.types";

import { getTitle } from "./PlantAvailability.utils";

import {
  Billboard,
  useBatchAddBillboardAvailabilityMutation,
  useBatchDeleteBillboardAvailabilitiesMutation,
} from "../../graphql/generated";

import { useStyles } from "./PlantAvalabilityPopup.styles";

export interface PlantAvailabilityPopupParams {
  anchorEl?: PopoverProps["anchorEl"];
  availability?: Availability;
  showInfo?: boolean;
  showActions?: boolean;
}

export interface PlantAvailabilityPopupProps
  extends PlantAvailabilityPopupParams {
  onClose: () => void;
  plant: Billboard;
}

export type AvailabilityAction = {
  status?: AvailabilityStatus;
  title: string;
};

const actions: Record<AvailabilityStatus, AvailabilityAction[]> = {
  [AvailabilityStatus.FREE]: [
    {
      status: AvailabilityStatus.MAINTENANCE,
      title: "Pianifica manutenzione",
    },
    {
      status: AvailabilityStatus.UNAVAILABLE,
      title: "Imposta come non disponibile",
    },
  ],
  [AvailabilityStatus.BOOKED]: [
    {
      status: AvailabilityStatus.BOOKED,
      title: "Vai all'ordine",
    },
  ],
  [AvailabilityStatus.MAINTENANCE]: [
    {
      status: AvailabilityStatus.FREE,
      title: "Imposta come disponibile",
    },
  ],
  [AvailabilityStatus.UNAVAILABLE]: [
    {
      status: AvailabilityStatus.FREE,
      title: "Imposta come disponibile",
    },
  ],
};

export const PlantAvailabilityPopup: React.FC<PlantAvailabilityPopupProps> = ({
  anchorEl,
  onClose,
  plant,
  availability,
  showInfo = true,
  showActions = true,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [{ fetching: addLoading }, addAvailability] =
    useBatchAddBillboardAvailabilityMutation();
  const [{ fetching: deleteLoading }, deleteAvailability] =
    useBatchDeleteBillboardAvailabilitiesMutation();

  const loading = addLoading || deleteLoading;

  useEffect(() => {
    if (!loading) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!showInfo && !showActions) {
      onClose();
    }
  }, [showInfo, showActions, onClose]);

  if (!availability) return null;
  if (!showInfo && !showActions) return null;

  const availableActions = showActions && actions[availability.status];
  const handleAction = (action: AvailabilityAction) => {
    // Get range start and end for selected date
    const { from, status: availabilityStatus } = availability;
    const { status: nextStatus } = action;

    const dates = from.toISOString();
    const input = { id: plant.id!, city_code: plant.city_code! };

    if (nextStatus === AvailabilityStatus.FREE) {
      // Delete availability
      deleteAvailability({ dates, input });
    } else if (nextStatus === AvailabilityStatus.UNAVAILABLE) {
      // Set unavailable
      addAvailability({ dates, input, is_maintenance: false });
    } else if (nextStatus === AvailabilityStatus.MAINTENANCE) {
      // Set maintenance
      addAvailability({ dates, input, is_maintenance: true });
    } else if (availabilityStatus === AvailabilityStatus.BOOKED) {
      // Go to order detail
      const { order } = availability;
      if (order) {
        dispatch(ContentActions.willSelectOrder(order));
        dispatch(ContentActions.willListOrderImages({ order_id: order.id }));
        dispatch(UiActions.switchCloseAddNewPlant());
        dispatch(UiActions.goTo({ page: "/orders/orderdetails" }));
      }
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      open={!!anchorEl && !!availability}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      classes={{ paper: classes.container }}
    >
      {showInfo ? (
        <>
          <Typography gutterBottom variant="body1" className="font-weight-bold">
            {getTitle(availability)}
          </Typography>
          <Typography variant="body2">
            {[
              moment(availability.from).format("L"),
              moment(availability.to).format("L"),
            ].join(" - ")}
          </Typography>
        </>
      ) : null}
      {availableActions
        ? availableActions.map((action, index) => (
            <Button
              className={cn(
                classes.button,
                action.status && classes[`button_${action.status}`]
              )}
              key={index}
              onClick={() => handleAction(action)}
            >
              {action.title}
            </Button>
          ))
        : null}
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress size={44} color="primary" />
        </div>
      ) : null}
    </Popover>
  );
};

export default PlantAvailabilityPopup;
