import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Grid } from '@material-ui/core';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { useStyles, borderColorDatePicker } from './AddNewBillboard.css';
import Icon from '../IconsComponent';
import { stepTwoValidations, emptyValidations } from "../Validations";
import { InputNewBillboard } from './InputNewBillboard';
import { ActivityButton } from '../ActivityButton';
import moment from 'moment';

export const DataInsertion = ({ handleStepsArray, selectedFile, fieldValueAltraUbicazione, disabled }: any) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const billboard = useSelector(ContentSelectors.getNewBillboard)
  console.log("DataInsertion billboard", billboard?.authorization_expiration)

  const setValues = (key: string, value: any, setFieldValue: any) => {
    setFieldValue(key, value)
    dispatch(ContentActions.setBillboard({ key: key, value: value }))
  }

  return (
    <Grid container>
      <Grid container item xs={12} alignItems="center" justify="center">
        <Formik
          initialValues={billboard}
          /// lascio lo stesso validation dello step prima per convalidare automaticamente
          validationSchema={billboard.billboard_class
            ? stepTwoValidations[billboard.billboard_class]
            : emptyValidations /// senza di questo crasha
          }
          onSubmit={values => {
            dispatch(ContentActions.willAddBillboard({ id: billboard.id, data: billboard, city_code: billboard.city_code, city: billboard.municipality, imageUrl: billboard.imageUrl }))
            if (selectedFile?.name) {
              dispatch(ContentActions.willUploadImage({ image: selectedFile, id: billboard.id + "_" + selectedFile.name }))
            }
          }}>
          {({ errors, touched, values, handleChange, setFieldValue }) => {
            const propsToPass = {
              errors: errors,
              touched: touched,
              values: values,
              setFieldValue: setFieldValue
            }

            return (
              <Form className="poppinsFont detailText w-100">

                {billboard.billboard_class &&
                  billboard.billboard_class === "Outdoor" &&
                  <>
                    <>
                      <h5 className="ml-4" >Manutenzione</h5>
                      <Grid className='mt-3 mb-4' container item xs={12} alignItems="center" justify="space-around">
                        <InputNewBillboard data={billboard} name={'status'} label={'Stato'} type={'text'} {...propsToPass} />
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            style={{ ...borderColorDatePicker, width: document.getElementById('taxes.municipal_tax')?.offsetWidth }}
                            data-cy="last_maintenance"
                            disableToolbar
                            disableFuture
                            autoOk={true}
                            format="DD-MM-YYYY"
                            // rifmFormatter={val=> val.replace(/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi, '')}
                            // refuse={/[^\.\ \,\[a-zA-Z0-9_]*$]+/gi}  
                            id="last_maintenance"
                            placeholder="Ultimo intervento"
                            label=""
                            keyboardIcon={<Icon color="grey" size={20} icon="calendar" />}
                            value={billboard ? billboard.last_maintenance : ''}
                            helperText=""
                            inputValue={billboard.last_maintenance === "Invalid date" ? null : billboard.last_maintenance}
                            onChange={(e: any, value: any) => {
                              setValues('last_maintenance', value, setFieldValue)
                            }}
                            InputAdornmentProps={{ position: "end" }}
                            InputProps={{ disableUnderline: true, className: "ml-3" }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </>
                    <>
                      <h5 className="ml-4" >Tributi</h5>
                      <Grid className='mt-3 mb-4' container item xs={12} alignItems="center" justify="space-around">
                        <InputNewBillboard data={billboard} name={'taxes.municipal_tax'} label={'Importo'} type={'text'} {...propsToPass} />
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            style={{ ...borderColorDatePicker, width: document.getElementById('taxes.municipal_tax')?.offsetWidth }}
                            data-cy="last_payment"
                            disableToolbar
                            disableFuture
                            autoOk={true}
                            format="DD-MM-YYYY"
                            id="last_payment"
                            placeholder="Ultimo pagamento"
                            label=""
                            keyboardIcon={<Icon color="grey" size={20} icon="calendar" />}
                            value={billboard ? billboard.taxes.last_payment : ''}
                            helperText=""
                            inputValue={billboard.taxes.last_payment === "Invalid date" ? null : billboard.taxes.last_payment}
                            onChange={(e: any, value: any) => {
                              setValues('taxes.last_payment', value, setFieldValue)
                            }}
                            InputAdornmentProps={{ position: "end" }}
                            InputProps={{ disableUnderline: true, className: "ml-3" }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </>
                    <>
                      <h5 className="ml-4" >Autorizzazioni</h5>
                      <Grid className='mt-3 mb-4' container item xs={12} alignItems="center" justify="space-around">
                        <InputNewBillboard data={billboard} name={'n_authorization'} label={'Num.SCIA'} type={'text'} {...propsToPass} />
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            style={{ ...borderColorDatePicker, width: document.getElementById('taxes.municipal_tax')?.offsetWidth }}
                            data-cy="authorization_expiration"
                            disableToolbar
                            disableFuture
                            autoOk={true}
                            format="DD-MM-YYYY"
                            id="authorization_expiration"
                            placeholder="Data rilasio SCIA"
                            label=""
                            keyboardIcon={<Icon color="grey" size={20} icon="calendar" />}
                            value={billboard ? billboard.authorization_expiration : ''}
                            helperText=""
                            inputValue={billboard.authorization_expiration === "Invalid date" ? null : billboard.authorization_expiration}
                            onChange={(e: any, value: any) => {
                              setValues('authorization_expiration', value, setFieldValue)
                            }}
                            InputAdornmentProps={{ position: "end" }}
                            InputProps={{ disableUnderline: true, className: "ml-3" }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <h5 className="ml-4" >Descrizione</h5>
                      <Grid className='mt-3' container item xs={12} alignItems="center" justify="center">
                        <InputNewBillboard data={billboard} name={'notes'} label={'Note'} type={'text'} placeholder={'Scrivi qui una descrizione/nota...'} fullWidth {...propsToPass} />
                      </Grid>
                    </>
                  </>

                  ||

                  billboard.billboard_class === "Indoor" &&
                  <>
                    <h5 className="ml-4" >Descrizione</h5>
                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="center">
                      <InputNewBillboard data={billboard} name={'notes'} label={'Note'} type={'text'} placeholder={'Scrivi qui una descrizione/nota...'} fullWidth {...propsToPass} />
                    </Grid>
                  </>}

                <Grid className={classes.buttonContainer} container item xs={12} alignItems="center" justify="space-between">
                  <Grid item xs={5}></Grid>
                  <Grid className='mr-2' container item xs={5} alignItems="center">
                    <Grid item xs={6}>
                      <button data-cy="go-back" className={classes.cancelButton} onClick={() => {
                        dispatch(UiActions.didSetStep('Localizzazione'))
                        handleStepsArray('Localizzazione')
                      }}>Indietro</button>
                    </Grid>
                    <Grid item xs={6}>
                      <ActivityButton
                        data-cy="confirm"
                        className={classes.confirmButton}
                        name="conferma"
                        color="primary"
                        type="submit"
                        disabled
                        block
                      >
                        Conferma
                      </ActivityButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}