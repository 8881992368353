import React, { useState, useMemo, useCallback, useEffect, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { UncontrolledDropdown, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Button, Form, Modal, ModalBody } from "reactstrap";
import { useTable, useSortBy, useFlexLayout, useExpanded, useRowSelect, useGroupBy, useGlobalFilter, useAsyncDebounce } from "react-table";
import { VariableSizeList, FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from 'react-window-infinite-loader';
import ReactTooltip from "react-tooltip";
import debounce from "lodash/debounce";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { selectors as ProfileSelectors } from '../../store/slices/profile';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { TextField, InputAdornment, Grid, Badge, Tooltip, Zoom } from '@material-ui/core';
import Icon from "../IconsComponent";
import { useStyles } from "../plant-list/PlantListCss";
import { Styles } from './Profile.css';
import Popover from 'material-ui-popup-state/HoverPopover'
import { usePopupState, bindHover, bindPopover, } from 'material-ui-popup-state/hooks';
import { ListFilter } from "../plant-list/ListFilter";
// import { clients } from './client_list';
import _ from 'lodash';
import moment from 'moment';

const CSVTooltip = <Grid style={{ borderRadius: 10, width: 310 }} className="bg-white text-dark p-3 robotoFont" container>
  <Grid item xs={12}><b>Caricamento massivo</b></Grid>
  <Grid style={{ opacity: 0.5, marginTop: 10 }} item xs={12}>Clicca qui per caricare più impianti attraverso la compilazione di un foglio Excel.</Grid>
  {/* <Grid item xs={12} className="d-sm-flex flex-row-reverse mt-1"><button style={buttonOrder} >Ho capito</button></Grid> */}
</Grid>

const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
  leading: false,
  trailing: true
});

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  closeRow
}: any) => {
  const classes = useStyles()
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
    closeRow()
  }, 200)

  return (
    <TextField
      data-cy="inputList"
      style={{ width: '100%' }}
      className={classes.noBordersAll}
      variant="outlined"
      id="searchInput"
      size="small"
      value={value || ""}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon color="gray" icon="search" size={20} />
          </InputAdornment>
        ),
        classes: { notchedOutline: classes.noBorders }
      }}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Cerca per clienti per Nome, Tipologia...`}
    />
  )
}

export const Team = ({setModalType}: any) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [orderColumn, setOrderColumn] = useState([] as any)
  const [dropdownMenu, setDropdownOpen] = useState(false);
  const toggleMenu = () => setDropdownOpen(!dropdownMenu);

  const listRef = useRef(null);
  const mediaOwnerUsers = useSelector(ProfileSelectors.getMediaOwnerUsers)
  const sortColumnValue = useSelector(ContentSelectors.getSortColumnValue)
  const sortOrderAsc = useSelector(ContentSelectors.getSortOrderAsc)
  console.log("Clients userList", mediaOwnerUsers)

  useEffect(() => {
    console.log("client list updated!")
  }, [mediaOwnerUsers, sortOrderAsc, sortColumnValue])

  const defaultColumn = useMemo(
    () => ({
      width: 50,
      //expanded: false
    }),
    []
  );

  const closeRow = () => {
    listRef.current && (listRef.current! as any).resetAfterIndex(0, true);
  }

  const columns = useMemo(
    () => [
      {
        id: "Nome",
        Header: "Nome",
        width: 60,
        accessor: (props: any) => {
          let plantImage = (props.imageUrl ? props.imageUrl : `https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg`)
          return (
            <div className="d-sm-flex align-items-center text-dark poppinsFont">
              <img data-cy="avatar2" src={plantImage} className="mr-3 plantAvatar" loading='lazy' onError={() => { console.log("error", plantImage) }} />
              <div>{props.given_name} {props.family_name}</div>
            </div>)
        }
      },
      {
        accessor: "family_name",
      },
      {
        accessor: "given_name"
      },
      {
        id: "Ruolo",
        Header: "Ruolo",
        width: 40,
        accessor: (props: any) => <>Admin</>,
      },
      {
        id: "Iscritto dal",
        Header: "Iscritto dal",
        width: 40,
        accessor: (props: any) => <>{moment(props.creation_date).format('DD-MM-YYYY')}</>,
      },
      {
        accessor: "creation_date"
      },
      {
        id: "Email",
        Header: "Email",
        width: 60,
        accessor: 'email',
      },
      {
        id: "Opzioni",
        width: 30,
        accessor: (props: any) => {
          return (
            <UncontrolledDropdown disabled>
              <DropdownToggle data-cy="plantMenu" className="bg-transparent border-0">
                <button className="bg-transparent border-0 right"><Icon /* className="backgroundIconColor" */ color="gray" size={25} icon="more" /></button>
              </DropdownToggle>
              <DropdownMenu className={classes.drowdownMenu}>
                <DropdownItem className="text-secondary" data-cy="disponibilityButton" onClick={() => {
                  // setBillboardId(props.id)
                  // setModalType("disponibility")
                  // dispatch(UiActions.switchCloseAddNewPlant())
                }} ><Icon data-cy="availability" color="gray" size={15} icon="reply" /> Verifica disponibilità</DropdownItem>
                <DropdownItem className="text-secondary" data-cy="modifyButton" onClick={() => {
                  // setPlantData(props)
                  // setModalType("addNewPlant")
                  // dispatch(UiActions.switchCloseAddNewPlant())
                }} ><Icon color="gray" size={15} icon="write" /> Modifica impianto</DropdownItem>
                {/* <DropdownItem className="text-secondary" disabled onClick={() => { }} ><Icon color="gray" size={15} icon="trash" /> Rimuovi</DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
      },
    ],
    [mediaOwnerUsers]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    toggleSortBy,
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
    selectedFlatRows,
    state
  } = useTable(
    {
      columns,
      data: mediaOwnerUsers,
      // data: userList,
      defaultColumn,
      initialState: {
        expanded: {},
        sortBy: orderColumn,
        hiddenColumns: ['family_name', 'given_name', 'creation_date']
      },
      autoResetSelectedRows: false,
    } as any,
    useFlexLayout,
    useGroupBy,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    useRowSelect
  )

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      // let invisible: boolean = false
      return (
        <div className={`${row.isSelected ? "borderSelected" : ""} tr-group`}
          // onMouseOver={() => invisible = true} 
          // onMouseLeave={() => invisible = false} 
          {...row.getRowProps()} style={style}>
          <div className="d-flex align-items-center tr">
            {row.cells.map((cell) => {
              return (
                <div {...cell.getCellProps()} className="td">
                  {cell.render("Cell")}
                </div>
              );
            })}
          </div>
        </div>
      );
    },
    []
  );

  const fetchMoreData = () => {
    console.log('fetch More data... plantlist')
  };

  const itemData = {
    rows,
    prepareRow,
  }

  const SortColumn = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [sorted, setSorted] = useState("")
    const sortColumnValue = useSelector(ContentSelectors.getSortColumnValue)
    useEffect(() => {
      setSorted(sortColumnValue)
    }, [sortColumnValue])

    return (
      <Dropdown data-cy="orderBy" className="mr-2 ml-2" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle data-cy="sortButton" className="bg-white text-dark border" caret>Ordina per: <b>{sorted}</b>  </DropdownToggle>
        <DropdownMenu>
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps({ style: { width: 200 } })} >
              <div className="column">
                {headerGroup.headers.filter((item) => { return item.id !== "selection" && item.id !== "expander" && item.id !== "Opzioni" && item.id !== "icons" }).map((column) => {
                  // console.log("COLUMS", column)
                  // let sortableColumns = ["Comune / Indirizzo","Tipologia","Formato","Cimasa","Prezzo","Indice Visibilità","I più recenti",]
                  return (
                    <DropdownItem
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      onClick={() => {
                        dispatch(ContentActions.didSetSortColumnValueProfileClients({ sortColumnValue: column.id, sortOrderAsc: true }))
                        // toggleSortBy(column.id, true, false)                                        //ordinamento
                        // closeRow()
                        console.log("column.render", column)
                      }}
                      style={{ width: 200 }}
                      data-cy={`choice${column.id}`}
                    >
                      <input className="mr-2" type="checkbox" checked={sorted == column.id ? true : false} readOnly />
                      {column.render("id")}
                    </DropdownItem>
                  )
                })}
              </div>
            </div>
          ))}
        </DropdownMenu>
      </Dropdown>
    )
  }

  return (
    <Grid style={{ borderRadius: 13, fontSize: 14, overflow: 'hidden', height: '70vh' }} className="d-flex bg-white p-2 mt-7 mb-6" container item xs={11} >
      <Grid className="d-sm-flex ml-3 mt-2" container item xs={12} >
        <Styles>
          <div className="d-sm-flex w-100 align-items-center justify-content-around mb-3">
            <h5>Clienti</h5>
            <Form className="w-75 bd-highlight ml-3">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                closeRow={closeRow} />
            </Form>
            <div className="d-sm-flex align-items-center">
              <SortColumn />
              {/* <ListFilter /> */}
              <Button disabled style={{ borderRadius: 10 }} data-cy="filterButton" className={`bg-white border mr-3`} onClick={() => { }} ><Icon color="#0062cc" size={25} icon="filter" /></Button>
            </div>
          </div>
          <div {...getTableProps()} className="table">
            <div className="thead">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps({ style: { backgroundColor: '#f8f9fa', borderRadius: 10, height: 40 } })} className="tr align-items-center">
                  {headerGroup.headers.map((column) => (
                    <div
                      {...column.getHeaderProps()}
                      className="th  d-sm-flex align-items-center detailText"
                    // onClick={() => {
                    //   // console.log('click header', sortOrderAsc)
                    //   if (column.id === "selection" || column.id === "expander" || column.id === "icons" || column.id === "Opzioni") {
                    //     console.log("not sortable to:", column.id)
                    //   } else {
                    //     dispatch(ContentActions.didSetSortColumnValue({ sortColumnValue: column.id, sortOrderAsc: !sortOrderAsc }))
                    //   }
                    // }}
                    >
                      {column.render("Header")}
                      {/* <span>
                    {sortColumnValue == column.id ? (sortOrderAsc ? " 🔼" : " 🔽") : ""}
                  </span> */}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div data-cy="tableBody" className="tbody" {...getTableBodyProps({ style: { borderRadius: 15, backgroundColor: "white", overflow: "hidden" } })}>
              {mediaOwnerUsers[0] ? <AutoSizer onResize={rebuildTooltip}>
                {({ height, width }) => (
                  <InfiniteLoader
                    isItemLoaded={(index: any) => index < rows.length - 4 ? true : false}
                    itemCount={rows.length}
                    loadMoreItems={fetchMoreData}
                  >
                    {({ onItemsRendered, ref }: any) => (
                      <VariableSizeList
                        style={{ overflowX: "hidden" }}
                        height={height}
                        itemCount={rows.length}
                        itemData={itemData}
                        // Hardcoded values only :/
                        itemSize={(i) => (rows[i] && rows[i].isExpanded ? 250 : 80)}
                        width={width}
                        onScroll={rebuildTooltip}
                        onItemsRendered={onItemsRendered}
                        ref={listRef}
                      >
                        {RenderRow}
                      </VariableSizeList>
                    )}
                  </InfiniteLoader>
                )}
              </AutoSizer> : <span style={{ fontSize: 30 }} className="d-sm-flex justify-content-center mt-5 poppinsFont">Nessun componente del team trovato</span>}
            </div>
          </div>
        </Styles>
      </Grid>
    </Grid>
  );
}
