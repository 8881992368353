import React from 'react';

/// INDOOR
import { ReactComponent as AltroIndoor } from "../assets/map-pins/indoor/altro.svg";
import { ReactComponent as DigitalIndoor } from "../assets/map-pins/indoor/digital.svg";
import { ReactComponent as IlluminatoIndoor } from "../assets/map-pins/indoor/illuminato.svg";
import { ReactComponent as OpacoIndoor } from "../assets/map-pins/indoor/opaco.svg";
import { ReactComponent as StriscioneIndoor } from "../assets/map-pins/indoor/striscione.svg";
import { ReactComponent as VetrinaIndoor } from "../assets/map-pins/indoor/vetrina.svg";

/// OUTDOOR
import { ReactComponent as AffissioneOutdoor } from "../assets/map-pins/outdoor/affissione.svg";
import { ReactComponent as AltroOutdoor } from "../assets/map-pins/outdoor/altro.svg";
import { ReactComponent as BannerOutdoor } from "../assets/map-pins/outdoor/banner.svg";
import { ReactComponent as CartellonisticaOutdoor } from "../assets/map-pins/outdoor/cartellonistica.svg";
import { ReactComponent as DisplayOutdoor } from "../assets/map-pins/outdoor/display-digital.svg";
import { ReactComponent as FacciataOutdoor } from "../assets/map-pins/outdoor/facciata.svg";
import { ReactComponent as MediapoleOutdoor } from "../assets/map-pins/outdoor/mediapole.svg";
import { ReactComponent as PensilinaOutdoor } from "../assets/map-pins/outdoor/pensilina.svg";
import { ReactComponent as TransenneOutdoor } from "../assets/map-pins/outdoor/transenne.svg";

/// DINAMICO
import { ReactComponent as Dinamico } from "../assets/map-pins/dinamico_tdb.svg";

// const Marker = ({ children }: any) => children;
{/* <Marker
key={`cluster-${cluster.id}`}
lat={latitude}
lng={longitude}
>
<div
  className="super-cluster"
  style={{
    width: 20 + (pointCount / points.length) * 30,
    height: 20 + (pointCount / points.length) * 30,
  }}
  onClick={() => { }}
>
  {pointCount}
</div>
</Marker> */}
// { billboardProps, key, lat, lng, pointCount, points, randomFacade }

export const customMarker = (billboardProps: any, plantSelected: string) => {

  const selectedBillboard = plantSelected === billboardProps?.id ? "#0062FF" : "none"

  if (billboardProps?.billboard_class === 'Indoor') {
    switch (billboardProps.media_type) {
      case 'Altro':
        return <AltroIndoor />
      case 'Display digitale':
        return <DigitalIndoor />
      case 'Impianto illuminato':
        return <IlluminatoIndoor />
      case 'Impianto opaco':
        return <OpacoIndoor />
      case 'Striscione':
        return <StriscioneIndoor />
      case 'Vetrina':
        return <VetrinaIndoor />
    }
  } else if (billboardProps?.billboard_class === 'Outdoor') {
    switch (billboardProps.media_type) {
      case 'Affissione':
        return <AffissioneOutdoor />
      case 'Cartellonistica':
        return <CartellonisticaOutdoor />
      case 'Pensilina':
        return <PensilinaOutdoor />
      case 'Mediapole':
        return <MediapoleOutdoor />
      case 'Display Digitale':
        return <DisplayOutdoor />
      case 'Facciata':
        return <FacciataOutdoor />
      case 'Banner':
        return <BannerOutdoor />
      case 'Transenne':
        return <TransenneOutdoor />
      case 'Altro':
        return <AltroOutdoor />
    }
  } else if (billboardProps?.billboard_class === 'Dinamico') {
    return <Dinamico />
  }

}

export const customIcon = (billboardClass: string, type: string, size: number) => {

  if (billboardClass === 'Indoor') {
    switch (type) {
      case 'Altro':
        return <AltroIndoor height={size} width={size} />
      case 'Display digitale':
        return <DigitalIndoor height={size} width={size} />
      case 'Impianto illuminato':
        return <IlluminatoIndoor height={size} width={size} />
      case 'Impianto opaco':
        return <OpacoIndoor height={size} width={size} />
      case 'Striscione':
        return <StriscioneIndoor height={size} width={size} />
      case 'Vetrina':
        return <VetrinaIndoor height={size} width={size} />
    }
  } else if (billboardClass === 'Outdoor') {
    switch (type) {
      case 'Affissione':
        return <AffissioneOutdoor height={size} width={size} />
      case 'Cartellonistica':
        return <CartellonisticaOutdoor height={size} width={size} />
      case 'Pensilina':
        return <PensilinaOutdoor height={size} width={size} />
      case 'Mediapole':
        return <MediapoleOutdoor height={size} width={size} />
      case 'Display Digitale':
        return <DisplayOutdoor height={size} width={size} />
      case 'Facciata':
        return <FacciataOutdoor height={size} width={size} />
      case 'Banner':
        return <BannerOutdoor height={size} width={size} />
      case 'Transenne':
        return <TransenneOutdoor height={size} width={size} />
      case 'Altro':
        return <AltroOutdoor height={size} width={size} />
    }
  } else if (billboardClass === 'Dinamico') {
    return <Dinamico height={size} width={size} />
  }

}