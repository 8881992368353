import { gql } from 'graphql.macro';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `AWSDate` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 Date](https://en.wikipedia.org/wiki/ISO_8601#Calendar_dates) string. In other words, this scalar type accepts date strings of the form `YYYY-MM-DD`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-05-01**" and "**-9999-01-01**" are both valid dates.  This scalar type can also accept an optional [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators). For example, "**1970-01-01**", "**1970-01-01Z**", "**1970-01-01-07:00**" and "**1970-01-01+05:30**" are all valid dates. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDate: any;
  /** The `AWSDateTime` scalar type provided by AWS AppSync, represents a valid ***extended*** [ISO 8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) string. In other words, this scalar type accepts datetime strings of the form `YYYY-MM-DDThh:mm:ss.SSSZ`.  The scalar can also accept "negative years" of the form `-YYYY` which correspond to years before `0000`. For example, "**-2017-01-01T00:00Z**" and "**-9999-01-01T00:00Z**" are both valid datetime strings.  The field after the two digit seconds field is a nanoseconds field. It can accept between 1 and 9 digits. So, for example, "**1970-01-01T12:00:00.2Z**", "**1970-01-01T12:00:00.277Z**" and "**1970-01-01T12:00:00.123456789Z**" are all valid datetime strings.  The seconds and nanoseconds fields are optional (the seconds field must be specified if the nanoseconds field is to be used).  The [time zone offset](https://en.wikipedia.org/wiki/ISO_8601#Time_zone_designators) is compulsory for this scalar. The time zone offset must either be `Z` (representing the UTC time zone) or be in the format `±hh:mm:ss`. The seconds field in the timezone offset will be considered valid even though it is not part of the ISO 8601 standard. */
  AWSDateTime: any;
};

export type Advertiser = {
  __typename?: 'Advertiser';
  advertiser?: Maybe<Scalars['String']>;
  bank_account?: Maybe<Scalars['String']>;
  company_form?: Maybe<Scalars['String']>;
  company_type?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstJob?: Maybe<Scalars['Boolean']>;
  fiscal_code?: Maybe<Scalars['String']>;
  founded_by?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  lastYearCampaign?: Maybe<Scalars['Boolean']>;
  legal_address?: Maybe<Scalars['String']>;
  legal_cap?: Maybe<Scalars['String']>;
  legal_email?: Maybe<Scalars['String']>;
  legal_municipality?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  p_iva?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  recipient_code?: Maybe<Scalars['String']>;
  society_form?: Maybe<Scalars['String']>;
  subscription_date?: Maybe<Scalars['AWSDate']>;
  type?: Maybe<Scalars['String']>;
};

export type BatchBillboardInput = {
  city_code: Scalars['String'];
  id: Scalars['String'];
};

export type BatchBillboardInputType = {
  billboard_input?: InputMaybe<BillboardInput>;
  city_code?: InputMaybe<Scalars['String']>;
};

export type BatchPricingInput = {
  id?: InputMaybe<Scalars['ID']>;
  media_owner?: InputMaybe<Scalars['String']>;
};

export type BillaloIndex = {
  __typename?: 'BillaloIndex';
  indice_visibilita?: Maybe<Scalars['Float']>;
  iV_municipality?: Maybe<Scalars['Float']>;
  iV_normalized?: Maybe<Scalars['Float']>;
  panel_index?: Maybe<Scalars['Float']>;
};

export type BillaloIndexInput = {
  indice_visibilita?: InputMaybe<Scalars['Float']>;
  iV_municipality?: InputMaybe<Scalars['Float']>;
  iV_normalized?: InputMaybe<Scalars['Float']>;
  panel_index?: InputMaybe<Scalars['Float']>;
};

export type BillaloMapIndex = {
  __typename?: 'BillaloMapIndex';
  billboardId?: Maybe<Scalars['ID']>;
  city?: Maybe<Scalars['String']>;
  city_code?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
};

export type BillaloMapNumberIndex = {
  __typename?: 'BillaloMapNumberIndex';
  billboardCount?: Maybe<Scalars['Int']>;
  city_code?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
};

export type Billboard = {
  __typename?: 'Billboard';
  address?: Maybe<Scalars['String']>;
  authorization_expiration?: Maybe<Scalars['AWSDateTime']>;
  available?: Maybe<Scalars['Boolean']>;
  base?: Maybe<Scalars['Float']>;
  billalo_code?: Maybe<Scalars['String']>;
  billalo_index?: Maybe<BillaloIndex>;
  billboard_class?: Maybe<Scalars['String']>;
  calendar?: Maybe<Scalars['Boolean']>;
  city_code?: Maybe<Scalars['String']>;
  cod_cimasa?: Maybe<Scalars['String']>;
  creation_date?: Maybe<Scalars['AWSDateTime']>;
  creationDate?: Maybe<Scalars['AWSDateTime']>;
  facade?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  height_ground?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  indice_visibilita?: Maybe<Scalars['Float']>;
  is_draft?: Maybe<Scalars['Boolean']>;
  iV_municipality?: Maybe<Scalars['Float']>;
  iV_normalized?: Maybe<Scalars['Float']>;
  last_maintenance?: Maybe<Scalars['AWSDateTime']>;
  latitude?: Maybe<Scalars['Float']>;
  lighting?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  location_name?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  media_owner?: Maybe<Scalars['String']>;
  media_owner_type?: Maybe<Scalars['String']>;
  media_type?: Maybe<Scalars['String']>;
  min_period?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  n_authorization?: Maybe<Scalars['String']>;
  n_sheet?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  orientation?: Maybe<Scalars['String']>;
  other_location?: Maybe<Scalars['String']>;
  panel_index?: Maybe<Scalars['Float']>;
  press_type?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  pricing_id?: Maybe<Scalars['ID']>;
  property_type?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  taxes?: Maybe<Taxes>;
  updateDate?: Maybe<Scalars['AWSDateTime']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type BillboardAvailability = {
  __typename?: 'BillboardAvailability';
  advertiser?: Maybe<Scalars['String']>;
  billboard_id?: Maybe<Scalars['ID']>;
  campaign_id?: Maybe<Scalars['String']>;
  city_code?: Maybe<Scalars['String']>;
  is_maintenance?: Maybe<Scalars['Boolean']>;
  media_owner?: Maybe<Scalars['String']>;
  order_data?: Maybe<Order>;
  order_id?: Maybe<Scalars['String']>;
  start_busy_date?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Scalars['String']>;
};

export type BillboardInput = {
  address?: InputMaybe<Scalars['String']>;
  authorization_expiration?: InputMaybe<Scalars['AWSDateTime']>;
  base?: InputMaybe<Scalars['Float']>;
  billalo_code?: InputMaybe<Scalars['String']>;
  billalo_index?: InputMaybe<BillaloIndexInput>;
  billboard_class?: InputMaybe<Scalars['String']>;
  calendar?: InputMaybe<Scalars['Boolean']>;
  cod_cimasa?: InputMaybe<Scalars['String']>;
  creation_date?: InputMaybe<Scalars['AWSDateTime']>;
  facade?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  height_ground?: InputMaybe<Scalars['String']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  indice_visibilita?: InputMaybe<Scalars['Float']>;
  is_draft?: InputMaybe<Scalars['Boolean']>;
  iV_municipality?: InputMaybe<Scalars['Float']>;
  iV_normalized?: InputMaybe<Scalars['Float']>;
  last_maintenance?: InputMaybe<Scalars['AWSDateTime']>;
  latitude?: InputMaybe<Scalars['Float']>;
  lighting?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  location_name?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  media_owner?: InputMaybe<Scalars['String']>;
  media_owner_type?: InputMaybe<Scalars['String']>;
  media_type?: InputMaybe<Scalars['String']>;
  min_period?: InputMaybe<Scalars['String']>;
  municipality?: InputMaybe<Scalars['String']>;
  n_authorization?: InputMaybe<Scalars['String']>;
  n_sheet?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<Scalars['String']>;
  other_location?: InputMaybe<Scalars['String']>;
  panel_index?: InputMaybe<Scalars['Float']>;
  press_type?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  pricing_id?: InputMaybe<Scalars['ID']>;
  property_type?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  taxes?: InputMaybe<TaxesInput>;
  updateDate?: InputMaybe<Scalars['AWSDateTime']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type CampaignBillboard = {
  __typename?: 'CampaignBillboard';
  billboard_id?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Float']>;
};

export type CityCoefficient = {
  __typename?: 'CityCoefficient';
  airport?: Maybe<Scalars['Boolean']>;
  hospital?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  population?: Maybe<Scalars['Float']>;
  provincial_capital?: Maybe<Scalars['Boolean']>;
  regional_capital?: Maybe<Scalars['Boolean']>;
  university?: Maybe<Scalars['Boolean']>;
};

export type CustomersInput = {
  avatar?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  media_owner?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FilterInput = {
  billboard_class?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  city?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  format?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  from?: InputMaybe<Scalars['AWSDateTime']>;
  iV_municipality_from?: InputMaybe<Scalars['Float']>;
  iV_municipality_to?: InputMaybe<Scalars['Float']>;
  media_type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  price_from?: InputMaybe<Scalars['Float']>;
  price_to?: InputMaybe<Scalars['Float']>;
  to?: InputMaybe<Scalars['AWSDateTime']>;
};

export type Location = {
  __typename?: 'Location';
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
};

export type MediaOwner = {
  __typename?: 'MediaOwner';
  avatar?: Maybe<Scalars['String']>;
  bank_account?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fees?: Maybe<Scalars['Float']>;
  funds_transfer?: Maybe<Scalars['String']>;
  is_draft?: Maybe<Scalars['Boolean']>;
  mediaowner?: Maybe<Scalars['String']>;
  n_orders?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  offices?: Maybe<Array<Maybe<Office>>>;
  p_iva?: Maybe<Scalars['String']>;
  payment_mode?: Maybe<Scalars['String']>;
  payment_registered_office?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rea?: Maybe<Scalars['String']>;
  recipient_code?: Maybe<Scalars['String']>;
  registered_office?: Maybe<Scalars['String']>;
  registred_date?: Maybe<Scalars['AWSDate']>;
  society_form?: Maybe<Scalars['String']>;
  stripe_account?: Maybe<Scalars['String']>;
  stripe_actived?: Maybe<Scalars['Boolean']>;
  subscription_date?: Maybe<Scalars['AWSDate']>;
  website?: Maybe<Scalars['String']>;
};

export type MediaOwnerInput = {
  avatar?: InputMaybe<Scalars['String']>;
  bank_account?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  fees?: InputMaybe<Scalars['Float']>;
  funds_transfer?: InputMaybe<Scalars['String']>;
  n_orders?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offices?: InputMaybe<Array<InputMaybe<OfficeInput>>>;
  p_iva?: InputMaybe<Scalars['String']>;
  payment_mode?: InputMaybe<Scalars['String']>;
  payment_registered_office?: InputMaybe<Scalars['String']>;
  pec?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  rea?: InputMaybe<Scalars['String']>;
  recipient_code?: InputMaybe<Scalars['String']>;
  registered_office?: InputMaybe<Scalars['String']>;
  society_form?: InputMaybe<Scalars['String']>;
  stripe_account?: InputMaybe<Scalars['String']>;
  stripe_actived?: InputMaybe<Scalars['Boolean']>;
  subscription_date?: InputMaybe<Scalars['AWSDate']>;
  website?: InputMaybe<Scalars['String']>;
};

export type MediaOwnerUser = {
  __typename?: 'MediaOwnerUser';
  creation_date?: Maybe<Scalars['AWSDateTime']>;
  email?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  family_name?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  status?: Maybe<Scalars['String']>;
};

export type ModelBillboardsConnection = {
  __typename?: 'ModelBillboardsConnection';
  items?: Maybe<Array<Maybe<Billboard>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelOrderImagesConnection = {
  __typename?: 'ModelOrderImagesConnection';
  items?: Maybe<Array<Maybe<OrderImage>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelOrdersConnection = {
  __typename?: 'ModelOrdersConnection';
  items?: Maybe<Array<Maybe<Order>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addBillboard?: Maybe<Billboard>;
  addBillboardAvailability?: Maybe<BillboardAvailability>;
  addMediaOwner?: Maybe<MediaOwner>;
  addOrderImage?: Maybe<OrderImage>;
  batchAddBillboard?: Maybe<Array<Maybe<Billboard>>>;
  batchAddBillboardAvailability?: Maybe<Array<Maybe<BillboardAvailability>>>;
  batchDeleteBillboardAvailabilities?: Maybe<BillboardAvailability>;
  buildPdfOrderContract?: Maybe<Scalars['String']>;
  deleteBillboard?: Maybe<Billboard>;
  editBillboard?: Maybe<Billboard>;
  putPricing?: Maybe<Pricing>;
  registerStripeAccount?: Maybe<Scalars['String']>;
  setBillboardPricing?: Maybe<Billboard>;
  updateBillboardAuthorization?: Maybe<Billboard>;
  updateOrderState?: Maybe<Scalars['String']>;
  updateOrderStatus?: Maybe<Order>;
};


export type MutationAddBillboardArgs = {
  city_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  input?: InputMaybe<BillboardInput>;
};


export type MutationAddBillboardAvailabilityArgs = {
  advertiser?: InputMaybe<Scalars['String']>;
  city_code?: InputMaybe<Scalars['String']>;
  dates: Array<InputMaybe<Scalars['AWSDateTime']>>;
  id: Scalars['ID'];
  is_maintenance: Scalars['Boolean'];
  last_maintenance?: InputMaybe<Scalars['AWSDateTime']>;
};


export type MutationAddMediaOwnerArgs = {
  input?: InputMaybe<MediaOwnerInput>;
};


export type MutationAddOrderImageArgs = {
  campaign?: InputMaybe<Scalars['ID']>;
  caption?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  order_id?: InputMaybe<Scalars['ID']>;
  upload_date?: InputMaybe<Scalars['AWSDateTime']>;
};


export type MutationBatchAddBillboardArgs = {
  input?: InputMaybe<Array<InputMaybe<BatchBillboardInputType>>>;
};


export type MutationBatchAddBillboardAvailabilityArgs = {
  advertiser?: InputMaybe<Scalars['String']>;
  dates: Array<InputMaybe<Scalars['AWSDateTime']>>;
  input: Array<InputMaybe<BatchBillboardInput>>;
  is_maintenance: Scalars['Boolean'];
  last_maintenance?: InputMaybe<Scalars['AWSDateTime']>;
};


export type MutationBatchDeleteBillboardAvailabilitiesArgs = {
  dates?: InputMaybe<Array<InputMaybe<Scalars['AWSDateTime']>>>;
  input?: InputMaybe<Array<InputMaybe<BatchBillboardInput>>>;
};


export type MutationBuildPdfOrderContractArgs = {
  campaign: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationDeleteBillboardArgs = {
  city_code: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationEditBillboardArgs = {
  city_code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  input?: InputMaybe<BillboardInput>;
};


export type MutationPutPricingArgs = {
  id: Scalars['ID'];
  pricing_data?: InputMaybe<PricingInput>;
};


export type MutationSetBillboardPricingArgs = {
  city_code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  pricing_id?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateBillboardAuthorizationArgs = {
  authorization_expiration?: InputMaybe<Scalars['AWSDateTime']>;
  city_code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  n_authorization?: InputMaybe<Scalars['Int']>;
};


export type MutationUpdateOrderStateArgs = {
  action?: InputMaybe<Scalars['String']>;
  campaign: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateOrderStatusArgs = {
  campaign: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Scalars['String']>;
};

export type Office = {
  __typename?: 'Office';
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  municipalities?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type OfficeInput = {
  address?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  municipalities?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  advertiser?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  billboards?: Maybe<Array<Maybe<OrderBillboard>>>;
  campaign?: Maybe<Scalars['String']>;
  campaign_name?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['ID']>;
  media_owner?: Maybe<Scalars['String']>;
  order_date?: Maybe<Scalars['AWSDateTime']>;
  printer?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['AWSDateTime']>;
};

export type OrderBillboard = {
  __typename?: 'OrderBillboard';
  address?: Maybe<Scalars['String']>;
  available?: Maybe<Scalars['Boolean']>;
  base?: Maybe<Scalars['Float']>;
  billalo_code?: Maybe<Scalars['String']>;
  billboard_class?: Maybe<Scalars['String']>;
  calendar?: Maybe<Scalars['Boolean']>;
  city_code?: Maybe<Scalars['String']>;
  cod_cimasa?: Maybe<Scalars['String']>;
  facade?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  height_ground?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  indice_visibilita?: Maybe<Scalars['Float']>;
  is_draft?: Maybe<Scalars['Boolean']>;
  iV_municipality?: Maybe<Scalars['Float']>;
  iV_normalized?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  lighting?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  location_name?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  media_owner?: Maybe<Scalars['String']>;
  media_owner_type?: Maybe<Scalars['String']>;
  media_type?: Maybe<Scalars['String']>;
  min_period?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  n_authorization?: Maybe<Scalars['String']>;
  n_sheet?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  orientation?: Maybe<Scalars['String']>;
  other_location?: Maybe<Scalars['String']>;
  panel_index?: Maybe<Scalars['Float']>;
  press_type?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  pricing_id?: Maybe<Scalars['ID']>;
  property_type?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  visible?: Maybe<Scalars['Boolean']>;
};

export type OrderBillboardInput = {
  address?: InputMaybe<Scalars['String']>;
  available?: InputMaybe<Scalars['Boolean']>;
  base?: InputMaybe<Scalars['Float']>;
  billalo_code?: InputMaybe<Scalars['String']>;
  billboard_class?: InputMaybe<Scalars['String']>;
  calendar?: InputMaybe<Scalars['Boolean']>;
  city_code?: InputMaybe<Scalars['String']>;
  cod_cimasa?: InputMaybe<Scalars['String']>;
  facade?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  height_ground?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  imageUrl?: InputMaybe<Scalars['String']>;
  indice_visibilita?: InputMaybe<Scalars['Float']>;
  is_draft?: InputMaybe<Scalars['Boolean']>;
  iV_municipality?: InputMaybe<Scalars['Float']>;
  iV_normalized?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  lighting?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  location_name?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['Float']>;
  media_owner?: InputMaybe<Scalars['String']>;
  media_owner_type?: InputMaybe<Scalars['String']>;
  media_type?: InputMaybe<Scalars['String']>;
  min_period?: InputMaybe<Scalars['String']>;
  municipality?: InputMaybe<Scalars['String']>;
  n_authorization?: InputMaybe<Scalars['String']>;
  n_sheet?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  orientation?: InputMaybe<Scalars['String']>;
  other_location?: InputMaybe<Scalars['String']>;
  panel_index?: InputMaybe<Scalars['Float']>;
  press_type?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  pricing_id?: InputMaybe<Scalars['ID']>;
  property_type?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['String']>;
  visible?: InputMaybe<Scalars['Boolean']>;
};

export type OrderImage = {
  __typename?: 'OrderImage';
  campaign?: Maybe<Scalars['ID']>;
  caption?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  media_owner?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['ID']>;
  upload_date?: Maybe<Scalars['AWSDateTime']>;
};

export type Orders = {
  __typename?: 'Orders';
  calendar?: Maybe<Array<Maybe<Scalars['AWSDateTime']>>>;
  price?: Maybe<Scalars['Float']>;
};

export type OrdersInput = {
  calendar?: InputMaybe<Array<InputMaybe<Scalars['AWSDateTime']>>>;
  price?: InputMaybe<Scalars['Float']>;
};

export type Pricing = {
  __typename?: 'Pricing';
  amount_1?: Maybe<Scalars['Float']>;
  condition_1?: Maybe<Scalars['String']>;
  customers?: Maybe<Array<Maybe<Scalars['String']>>>;
  from?: Maybe<Scalars['AWSDateTime']>;
  id?: Maybe<Scalars['ID']>;
  media_owner?: Maybe<Scalars['String']>;
  percentage?: Maybe<Scalars['Float']>;
  rule_type?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['AWSDateTime']>;
};

export type PricingInput = {
  amount_1?: InputMaybe<Scalars['Float']>;
  condition_1?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  from?: InputMaybe<Scalars['AWSDateTime']>;
  percentage?: InputMaybe<Scalars['Float']>;
  rule_type?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['AWSDateTime']>;
};

export type Query = {
  __typename?: 'Query';
  batchGetBillboard?: Maybe<Array<Maybe<Billboard>>>;
  createStripeLoginLink?: Maybe<Scalars['String']>;
  getBillboard?: Maybe<Billboard>;
  getBillboardPricing?: Maybe<Pricing>;
  getBillboardsAvailabilities?: Maybe<Array<Maybe<BillboardAvailability>>>;
  getMediaOwner?: Maybe<MediaOwner>;
  getOrder?: Maybe<Order>;
  getOrderData?: Maybe<Order>;
  getResourceUrl?: Maybe<Scalars['String']>;
  getUploadCSVUrl?: Maybe<Scalars['String']>;
  getUploadOrderImageUrl?: Maybe<Scalars['String']>;
  getUploadUrl?: Maybe<Scalars['String']>;
  listAdvertisers?: Maybe<Array<Maybe<Advertiser>>>;
  listBillboardAvailability?: Maybe<Array<Maybe<BillboardAvailability>>>;
  listBillboardAvailabilityByRange?: Maybe<Array<Maybe<BillboardAvailability>>>;
  listBillboardsAvailablesByRange?: Maybe<Array<Maybe<BillboardAvailability>>>;
  listBillboardsByOwner?: Maybe<ModelBillboardsConnection>;
  listBillboardsInMap?: Maybe<Array<Maybe<BillaloMapIndex>>>;
  listBillboardsNumberInMap?: Maybe<Array<Maybe<BillaloMapNumberIndex>>>;
  listBillboardsTest?: Maybe<Array<Maybe<Billboard>>>;
  listFilteredBillboards?: Maybe<Array<Maybe<Billboard>>>;
  listMediaOwnerUsers?: Maybe<Array<Maybe<MediaOwnerUser>>>;
  listMyBillboards?: Maybe<ModelBillboardsConnection>;
  listMyOrders?: Maybe<ModelOrdersConnection>;
  listOrderImages?: Maybe<ModelOrderImagesConnection>;
};


export type QueryBatchGetBillboardArgs = {
  input?: InputMaybe<Array<InputMaybe<BatchBillboardInput>>>;
};


export type QueryCreateStripeLoginLinkArgs = {
  acct: Scalars['String'];
};


export type QueryGetBillboardArgs = {
  city_code?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type QueryGetBillboardPricingArgs = {
  id?: InputMaybe<Scalars['ID']>;
  media_owner?: InputMaybe<Scalars['String']>;
};


export type QueryGetBillboardsAvailabilitiesArgs = {
  billboard_id?: InputMaybe<Scalars['ID']>;
  start_busy_date?: InputMaybe<Scalars['AWSDateTime']>;
};


export type QueryGetOrderArgs = {
  campaign: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetOrderDataArgs = {
  campaign_id: Scalars['ID'];
  order_id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetResourceUrlArgs = {
  expires?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetUploadCsvUrlArgs = {
  expires?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetUploadOrderImageUrlArgs = {
  expires?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  order_id?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<Scalars['String']>;
};


export type QueryGetUploadUrlArgs = {
  billboardId?: InputMaybe<Scalars['ID']>;
  expires?: InputMaybe<Scalars['Int']>;
};


export type QueryListBillboardAvailabilityArgs = {
  billboard_id?: InputMaybe<Scalars['ID']>;
};


export type QueryListBillboardAvailabilityByRangeArgs = {
  billboard_id?: InputMaybe<Scalars['ID']>;
  start_date?: InputMaybe<Scalars['AWSDateTime']>;
  stop_date?: InputMaybe<Scalars['AWSDateTime']>;
};


export type QueryListBillboardsAvailablesByRangeArgs = {
  start_date?: InputMaybe<Scalars['AWSDateTime']>;
  stop_date?: InputMaybe<Scalars['AWSDateTime']>;
};


export type QueryListBillboardsByOwnerArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  media_owner?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListBillboardsInMapArgs = {
  lat_bot_rigth?: InputMaybe<Scalars['Float']>;
  lat_top_left?: InputMaybe<Scalars['Float']>;
  lon_bot_rigth?: InputMaybe<Scalars['Float']>;
  lon_top_left?: InputMaybe<Scalars['Float']>;
};


export type QueryListBillboardsNumberInMapArgs = {
  lat_bot_rigth?: InputMaybe<Scalars['Float']>;
  lat_top_left?: InputMaybe<Scalars['Float']>;
  lon_bot_rigth?: InputMaybe<Scalars['Float']>;
  lon_top_left?: InputMaybe<Scalars['Float']>;
};


export type QueryListBillboardsTestArgs = {
  city?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  media_type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryListFilteredBillboardsArgs = {
  input?: InputMaybe<FilterInput>;
};


export type QueryListMyBillboardsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListMyOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListOrderImagesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['ID']>;
};

export type Taxes = {
  __typename?: 'Taxes';
  last_payment?: Maybe<Scalars['AWSDateTime']>;
  municipal_tax?: Maybe<Scalars['Float']>;
};

export type TaxesInput = {
  last_payment?: InputMaybe<Scalars['AWSDateTime']>;
  municipal_tax?: InputMaybe<Scalars['Float']>;
};

export type AddBatchBillboardMutationVariables = Exact<{
  batchBillboard?: InputMaybe<Array<InputMaybe<BatchBillboardInputType>> | InputMaybe<BatchBillboardInputType>>;
}>;


export type AddBatchBillboardMutation = { __typename?: 'Mutation', batchAddBillboard?: Array<{ __typename?: 'Billboard', id: string, cod_cimasa?: string | null, media_owner?: string | null, city_code?: string | null, municipality?: string | null } | null> | null };

export type AddBillboardMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  city_code?: InputMaybe<Scalars['String']>;
  input?: InputMaybe<BillboardInput>;
}>;


export type AddBillboardMutation = { __typename?: 'Mutation', addBillboard?: { __typename?: 'Billboard', id: string, cod_cimasa?: string | null, media_owner?: string | null, city_code?: string | null, municipality?: string | null } | null };

export type AddBillboardAvailabilityMutationVariables = Exact<{
  id: Scalars['ID'];
  city_code?: InputMaybe<Scalars['String']>;
  dates: Array<InputMaybe<Scalars['AWSDateTime']>> | InputMaybe<Scalars['AWSDateTime']>;
  is_maintenance: Scalars['Boolean'];
  last_maintenance?: InputMaybe<Scalars['AWSDateTime']>;
  advertiser?: InputMaybe<Scalars['String']>;
}>;


export type AddBillboardAvailabilityMutation = { __typename?: 'Mutation', addBillboardAvailability?: { __typename?: 'BillboardAvailability', billboard_id?: string | null, media_owner?: string | null, start_busy_date?: any | null } | null };

export type AddMediaOwnerMutationVariables = Exact<{
  input?: InputMaybe<MediaOwnerInput>;
}>;


export type AddMediaOwnerMutation = { __typename?: 'Mutation', addMediaOwner?: { __typename?: 'MediaOwner', mediaowner?: string | null, avatar?: string | null, name?: string | null, subscription_date?: any | null, n_orders?: number | null, fees?: number | null, p_iva?: string | null, phone?: string | null, website?: string | null, registered_office?: string | null, rea?: string | null, pec?: string | null, society_form?: string | null, bank_account?: string | null, payment_mode?: string | null, payment_registered_office?: string | null, stripe_account?: string | null, recipient_code?: string | null, funds_transfer?: string | null, stripe_actived?: boolean | null, offices?: Array<{ __typename?: 'Office', name?: string | null, address?: string | null, phone?: string | null, email?: string | null, municipalities?: Array<string | null> | null } | null> | null } | null };

export type AddOrderImageMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  order_id?: InputMaybe<Scalars['ID']>;
  campaign?: InputMaybe<Scalars['ID']>;
  upload_date?: InputMaybe<Scalars['AWSDateTime']>;
  caption?: InputMaybe<Scalars['String']>;
}>;


export type AddOrderImageMutation = { __typename?: 'Mutation', addOrderImage?: { __typename?: 'OrderImage', id?: string | null } | null };

export type BatchAddBillboardAvailabilityMutationVariables = Exact<{
  input: Array<InputMaybe<BatchBillboardInput>> | InputMaybe<BatchBillboardInput>;
  dates: Array<InputMaybe<Scalars['AWSDateTime']>> | InputMaybe<Scalars['AWSDateTime']>;
  is_maintenance: Scalars['Boolean'];
  last_maintenance?: InputMaybe<Scalars['AWSDateTime']>;
  advertiser?: InputMaybe<Scalars['String']>;
}>;


export type BatchAddBillboardAvailabilityMutation = { __typename?: 'Mutation', batchAddBillboardAvailability?: Array<{ __typename?: 'BillboardAvailability', billboard_id?: string | null, media_owner?: string | null, start_busy_date?: any | null } | null> | null };

export type BatchDeleteBillboardAvailabilitiesMutationVariables = Exact<{
  dates?: InputMaybe<Array<InputMaybe<Scalars['AWSDateTime']>> | InputMaybe<Scalars['AWSDateTime']>>;
  input?: InputMaybe<Array<InputMaybe<BatchBillboardInput>> | InputMaybe<BatchBillboardInput>>;
}>;


export type BatchDeleteBillboardAvailabilitiesMutation = { __typename?: 'Mutation', batchDeleteBillboardAvailabilities?: { __typename?: 'BillboardAvailability', billboard_id?: string | null, media_owner?: string | null, start_busy_date?: any | null } | null };

export type BuildPdfOrderContractMutationVariables = Exact<{
  campaign: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type BuildPdfOrderContractMutation = { __typename?: 'Mutation', buildPdfOrderContract?: string | null };

export type CreateStripeLoginLinkQueryVariables = Exact<{
  acct: Scalars['String'];
}>;


export type CreateStripeLoginLinkQuery = { __typename?: 'Query', createStripeLoginLink?: string | null };

export type DeleteBillboardMutationVariables = Exact<{
  id: Scalars['ID'];
  city_code: Scalars['String'];
}>;


export type DeleteBillboardMutation = { __typename?: 'Mutation', deleteBillboard?: { __typename?: 'Billboard', address?: string | null, authorization_expiration?: any | null, available?: boolean | null, base?: number | null, city_code?: string | null, cod_cimasa?: string | null, creationDate?: any | null, height?: number | null, height_ground?: string | null, id: string, last_maintenance?: any | null, latitude?: number | null, lighting?: string | null, longitude?: number | null, media_owner?: string | null, media_type?: string | null, municipality?: string | null, n_authorization?: string | null, n_sheet?: number | null, orientation?: string | null, property_type?: string | null, price?: number | null, updateDate?: any | null, imageUrl?: string | null, pricing_id?: string | null, creation_date?: any | null, billboard_class?: string | null, media_owner_type?: string | null, visible?: boolean | null, min_period?: string | null, location?: string | null, location_name?: string | null, press_type?: string | null, facade?: string | null, is_draft?: boolean | null, other_location?: string | null, billalo_index?: { __typename?: 'BillaloIndex', iV_municipality?: number | null, iV_normalized?: number | null, panel_index?: number | null, indice_visibilita?: number | null } | null, taxes?: { __typename?: 'Taxes', last_payment?: any | null, municipal_tax?: number | null } | null } | null };

export type EditBillboardMutationVariables = Exact<{
  id: Scalars['ID'];
  city_code?: InputMaybe<Scalars['String']>;
}>;


export type EditBillboardMutation = { __typename?: 'Mutation', addBillboard?: { __typename?: 'Billboard', id: string, cod_cimasa?: string | null, media_owner?: string | null, city_code?: string | null, municipality?: string | null } | null };

export type GetBatchBillboardQueryVariables = Exact<{
  input?: InputMaybe<Array<InputMaybe<BatchBillboardInput>> | InputMaybe<BatchBillboardInput>>;
}>;


export type GetBatchBillboardQuery = { __typename?: 'Query', batchGetBillboard?: Array<{ __typename?: 'Billboard', address?: string | null, authorization_expiration?: any | null, available?: boolean | null, base?: number | null, billalo_code?: string | null, city_code?: string | null, cod_cimasa?: string | null, creationDate?: any | null, height?: number | null, height_ground?: string | null, id: string, last_maintenance?: any | null, latitude?: number | null, lighting?: string | null, longitude?: number | null, media_owner?: string | null, media_type?: string | null, municipality?: string | null, n_authorization?: string | null, n_sheet?: number | null, orientation?: string | null, property_type?: string | null, size?: string | null, updateDate?: any | null, creation_date?: any | null } | null> | null };

export type GetBillboardPricingQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type GetBillboardPricingQuery = { __typename?: 'Query', getBillboardPricing?: { __typename?: 'Pricing', amount_1?: number | null, condition_1?: string | null, customers?: Array<string | null> | null, from?: any | null, id?: string | null, media_owner?: string | null, percentage?: number | null, rule_type?: string | null, to?: any | null } | null };

export type GetMediaOwnerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMediaOwnerQuery = { __typename?: 'Query', getMediaOwner?: { __typename?: 'MediaOwner', avatar?: string | null, name?: string | null, subscription_date?: any | null, n_orders?: number | null, fees?: number | null, p_iva?: string | null, phone?: string | null, website?: string | null, registered_office?: string | null, rea?: string | null, pec?: string | null, society_form?: string | null, bank_account?: string | null, payment_mode?: string | null, payment_registered_office?: string | null, stripe_account?: string | null, recipient_code?: string | null, funds_transfer?: string | null, stripe_actived?: boolean | null, offices?: Array<{ __typename?: 'Office', name?: string | null, address?: string | null, phone?: string | null, email?: string | null, municipalities?: Array<string | null> | null } | null> | null } | null };

export type GetOrderQueryVariables = Exact<{
  campaign: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type GetOrderQuery = { __typename?: 'Query', getOrder?: { __typename?: 'Order', id?: string | null, media_owner?: string | null, advertiser?: string | null, status?: string | null, campaign_name?: string | null, from?: any | null, to?: any | null, order_date?: any | null, printer?: string | null, amount?: number | null, billboards?: Array<{ __typename?: 'OrderBillboard', id?: string | null, city_code?: string | null, billalo_code?: string | null, property_type?: string | null, media_owner?: string | null, municipality?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, media_type?: string | null, base?: number | null, height?: number | null, format?: string | null, size?: string | null, n_sheet?: number | null, height_ground?: string | null, lighting?: string | null, orientation?: string | null, cod_cimasa?: string | null, n_authorization?: string | null, available?: boolean | null, pricing_id?: string | null, price?: number | null, imageUrl?: string | null, iV_municipality?: number | null, iV_normalized?: number | null, panel_index?: number | null, indice_visibilita?: number | null, billboard_class?: string | null, media_owner_type?: string | null, visible?: boolean | null, min_period?: string | null, location?: string | null, location_name?: string | null, press_type?: string | null, facade?: string | null, is_draft?: boolean | null, other_location?: string | null } | null> | null } | null };

export type GetResourceUrlQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  expires?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
}>;


export type GetResourceUrlQuery = { __typename?: 'Query', getResourceUrl?: string | null };

export type GetUploadCsvUrlQueryVariables = Exact<{
  expires?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
}>;


export type GetUploadCsvUrlQuery = { __typename?: 'Query', getUploadCSVUrl?: string | null };

export type GetUploadOrderImageUrlQueryVariables = Exact<{
  expires?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  order_id?: InputMaybe<Scalars['ID']>;
}>;


export type GetUploadOrderImageUrlQuery = { __typename?: 'Query', getUploadOrderImageUrl?: string | null };

export type GetUploadUrlQueryVariables = Exact<{
  billboardId?: InputMaybe<Scalars['ID']>;
  expires?: InputMaybe<Scalars['Int']>;
}>;


export type GetUploadUrlQuery = { __typename?: 'Query', getUploadUrl?: string | null };

export type ListAdvertisersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAdvertisersQuery = { __typename?: 'Query', listAdvertisers?: Array<{ __typename?: 'Advertiser', advertiser?: string | null, name?: string | null, type?: string | null, p_iva?: string | null, company_form?: string | null, company_type?: string | null, pec?: string | null, email?: string | null, phone?: string | null, legal_municipality?: string | null, legal_address?: string | null, legal_cap?: string | null, legal_email?: string | null, firstJob?: boolean | null, lastYearCampaign?: boolean | null, founded_by?: string | null, image?: string | null, subscription_date?: any | null, fiscal_code?: string | null, bank_account?: string | null, society_form?: string | null, recipient_code?: string | null } | null> | null };

export type ListBillboardAvailabilityQueryVariables = Exact<{
  billboard_id?: InputMaybe<Scalars['ID']>;
}>;


export type ListBillboardAvailabilityQuery = { __typename?: 'Query', listBillboardAvailability?: Array<{ __typename?: 'BillboardAvailability', billboard_id?: string | null, media_owner?: string | null, start_busy_date?: any | null, is_maintenance?: boolean | null, order_id?: string | null, status?: string | null, campaign_id?: string | null } | null> | null };

export type ListBillboardAvailabilityByRangeQueryVariables = Exact<{
  billboard_id?: InputMaybe<Scalars['ID']>;
  start_date?: InputMaybe<Scalars['AWSDateTime']>;
  stop_date?: InputMaybe<Scalars['AWSDateTime']>;
}>;


export type ListBillboardAvailabilityByRangeQuery = { __typename?: 'Query', listBillboardAvailabilityByRange?: Array<{ __typename?: 'BillboardAvailability', billboard_id?: string | null, media_owner?: string | null, start_busy_date?: any | null, is_maintenance?: boolean | null, order_id?: string | null, status?: string | null, campaign_id?: string | null, order_data?: { __typename?: 'Order', id?: string | null, campaign?: string | null, media_owner?: string | null, advertiser?: string | null, status?: string | null, campaign_name?: string | null, from?: any | null, to?: any | null, order_date?: any | null, printer?: string | null, amount?: number | null, billboards?: Array<{ __typename?: 'OrderBillboard', id?: string | null, city_code?: string | null, billalo_code?: string | null, property_type?: string | null, media_owner?: string | null, municipality?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, media_type?: string | null, base?: number | null, height?: number | null, format?: string | null, size?: string | null, n_sheet?: number | null, height_ground?: string | null, lighting?: string | null, orientation?: string | null, cod_cimasa?: string | null, n_authorization?: string | null, available?: boolean | null, pricing_id?: string | null, price?: number | null, imageUrl?: string | null, iV_municipality?: number | null, iV_normalized?: number | null, panel_index?: number | null, indice_visibilita?: number | null, billboard_class?: string | null, media_owner_type?: string | null, visible?: boolean | null, min_period?: string | null, location?: string | null, location_name?: string | null, press_type?: string | null, facade?: string | null, is_draft?: boolean | null, other_location?: string | null } | null> | null } | null } | null> | null };

export type ListBillboardsAvailablesByRangeQueryVariables = Exact<{
  start_date?: InputMaybe<Scalars['AWSDateTime']>;
  stop_date?: InputMaybe<Scalars['AWSDateTime']>;
}>;


export type ListBillboardsAvailablesByRangeQuery = { __typename?: 'Query', listBillboardsAvailablesByRange?: Array<{ __typename?: 'BillboardAvailability', billboard_id?: string | null, media_owner?: string | null, start_busy_date?: any | null, is_maintenance?: boolean | null, order_id?: string | null, status?: string | null, campaign_id?: string | null } | null> | null };

export type ListBillboardsByOwnerQueryVariables = Exact<{
  media_owner?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type ListBillboardsByOwnerQuery = { __typename?: 'Query', listBillboardsByOwner?: { __typename?: 'ModelBillboardsConnection', nextToken?: string | null, items?: Array<{ __typename?: 'Billboard', address?: string | null, authorization_expiration?: any | null, available?: boolean | null, base?: number | null, billalo_code?: string | null, city_code?: string | null, cod_cimasa?: string | null, creationDate?: any | null, height?: number | null, height_ground?: string | null, id: string, last_maintenance?: any | null, latitude?: number | null, lighting?: string | null, longitude?: number | null, media_owner?: string | null, media_type?: string | null, municipality?: string | null, n_authorization?: string | null, n_sheet?: number | null, orientation?: string | null, property_type?: string | null, size?: string | null, updateDate?: any | null, price?: number | null, pricing_id?: string | null, creation_date?: any | null, billboard_class?: string | null, media_owner_type?: string | null, visible?: boolean | null, facade?: string | null, min_period?: string | null, location?: string | null, location_name?: string | null, press_type?: string | null, is_draft?: boolean | null, other_location?: string | null, billalo_index?: { __typename?: 'BillaloIndex', iV_municipality?: number | null, iV_normalized?: number | null, panel_index?: number | null, indice_visibilita?: number | null } | null } | null> | null } | null };

export type ListBillboardsInMapQueryVariables = Exact<{
  lat_bot_rigth?: InputMaybe<Scalars['Float']>;
  lat_top_left?: InputMaybe<Scalars['Float']>;
  lon_bot_rigth?: InputMaybe<Scalars['Float']>;
  lon_top_left?: InputMaybe<Scalars['Float']>;
}>;


export type ListBillboardsInMapQuery = { __typename?: 'Query', listBillboardsInMap?: Array<{ __typename?: 'BillaloMapIndex', billboardId?: string | null, city?: string | null, city_code?: string | null, location?: { __typename?: 'Location', lat?: number | null, lon?: number | null } | null } | null> | null };

export type ListBillboardsNumberInMapQueryVariables = Exact<{
  lat_bot_rigth?: InputMaybe<Scalars['Float']>;
  lat_top_left?: InputMaybe<Scalars['Float']>;
  lon_bot_rigth?: InputMaybe<Scalars['Float']>;
  lon_top_left?: InputMaybe<Scalars['Float']>;
}>;


export type ListBillboardsNumberInMapQuery = { __typename?: 'Query', listBillboardsNumberInMap?: Array<{ __typename?: 'BillaloMapNumberIndex', billboardCount?: number | null, city_code?: string | null, location?: { __typename?: 'Location', lat?: number | null, lon?: number | null } | null } | null> | null };

export type ListFilteredBillboardsQueryVariables = Exact<{
  input?: InputMaybe<FilterInput>;
}>;


export type ListFilteredBillboardsQuery = { __typename?: 'Query', listFilteredBillboards?: Array<{ __typename?: 'Billboard', address?: string | null, id: string, municipality?: string | null, media_type?: string | null, available?: boolean | null, city_code?: string | null, base?: number | null, height?: number | null, cod_cimasa?: string | null, latitude?: number | null, longitude?: number | null, media_owner?: string | null, orientation?: string | null, n_sheet?: number | null, price?: number | null, iV_municipality?: number | null, iV_normalized?: number | null, panel_index?: number | null, indice_visibilita?: number | null, pricing_id?: string | null, creation_date?: any | null, billboard_class?: string | null, media_owner_type?: string | null, visible?: boolean | null, min_period?: string | null, location?: string | null, location_name?: string | null, press_type?: string | null, facade?: string | null, is_draft?: boolean | null, other_location?: string | null } | null> | null };

export type ListMediaOwnerUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListMediaOwnerUsersQuery = { __typename?: 'Query', listMediaOwnerUsers?: Array<{ __typename?: 'MediaOwnerUser', id?: string | null, status?: string | null, enabled?: boolean | null, creation_date?: any | null, given_name?: string | null, family_name?: string | null, email?: string | null } | null> | null };

export type ListMyBillboardsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type ListMyBillboardsQuery = { __typename?: 'Query', listMyBillboards?: { __typename?: 'ModelBillboardsConnection', nextToken?: string | null, items?: Array<{ __typename?: 'Billboard', address?: string | null, authorization_expiration?: any | null, available?: boolean | null, base?: number | null, city_code?: string | null, cod_cimasa?: string | null, creationDate?: any | null, height?: number | null, height_ground?: string | null, id: string, last_maintenance?: any | null, latitude?: number | null, lighting?: string | null, longitude?: number | null, media_owner?: string | null, media_type?: string | null, municipality?: string | null, n_authorization?: string | null, n_sheet?: number | null, orientation?: string | null, property_type?: string | null, price?: number | null, updateDate?: any | null, imageUrl?: string | null, pricing_id?: string | null, creation_date?: any | null, billboard_class?: string | null, media_owner_type?: string | null, visible?: boolean | null, min_period?: string | null, location?: string | null, location_name?: string | null, press_type?: string | null, facade?: string | null, is_draft?: boolean | null, other_location?: string | null, billalo_index?: { __typename?: 'BillaloIndex', iV_municipality?: number | null, iV_normalized?: number | null, panel_index?: number | null, indice_visibilita?: number | null } | null, taxes?: { __typename?: 'Taxes', last_payment?: any | null, municipal_tax?: number | null } | null } | null> | null } | null };

export type ListMyOrdersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type ListMyOrdersQuery = { __typename?: 'Query', listMyOrders?: { __typename?: 'ModelOrdersConnection', nextToken?: string | null, items?: Array<{ __typename?: 'Order', id?: string | null, campaign?: string | null, media_owner?: string | null, advertiser?: string | null, status?: string | null, campaign_name?: string | null, from?: any | null, to?: any | null, order_date?: any | null, printer?: string | null, amount?: number | null, billboards?: Array<{ __typename?: 'OrderBillboard', id?: string | null, city_code?: string | null, billalo_code?: string | null, property_type?: string | null, media_owner?: string | null, municipality?: string | null, address?: string | null, latitude?: number | null, longitude?: number | null, media_type?: string | null, base?: number | null, height?: number | null, format?: string | null, size?: string | null, n_sheet?: number | null, height_ground?: string | null, lighting?: string | null, orientation?: string | null, cod_cimasa?: string | null, n_authorization?: string | null, available?: boolean | null, pricing_id?: string | null, price?: number | null, imageUrl?: string | null, iV_municipality?: number | null, iV_normalized?: number | null, panel_index?: number | null, indice_visibilita?: number | null, billboard_class?: string | null, media_owner_type?: string | null, visible?: boolean | null, min_period?: string | null, location?: string | null, location_name?: string | null, press_type?: string | null, facade?: string | null, is_draft?: boolean | null, other_location?: string | null } | null> | null } | null> | null } | null };

export type ListOrderImagesQueryVariables = Exact<{
  order_id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type ListOrderImagesQuery = { __typename?: 'Query', listOrderImages?: { __typename?: 'ModelOrderImagesConnection', nextToken?: string | null, items?: Array<{ __typename?: 'OrderImage', order?: string | null, id?: string | null, campaign?: string | null, upload_date?: any | null, caption?: string | null, media_owner?: string | null } | null> | null } | null };

export type RegisterStripeAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type RegisterStripeAccountMutation = { __typename?: 'Mutation', registerStripeAccount?: string | null };

export type UpdateOrderStateMutationVariables = Exact<{
  campaign: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  action?: InputMaybe<Scalars['String']>;
}>;


export type UpdateOrderStateMutation = { __typename?: 'Mutation', updateOrderState?: string | null };

export type UpdatePricingMutationVariables = Exact<{
  id: Scalars['ID'];
  pricing_data?: InputMaybe<PricingInput>;
}>;


export type UpdatePricingMutation = { __typename?: 'Mutation', putPricing?: { __typename?: 'Pricing', id?: string | null } | null };


export const AddBatchBillboardDocument = gql`
    mutation addBatchBillboard($batchBillboard: [BatchBillboardInputType]) {
  batchAddBillboard(input: $batchBillboard) {
    id
    cod_cimasa
    media_owner
    city_code
    municipality
  }
}
    `;

export function useAddBatchBillboardMutation() {
  return Urql.useMutation<AddBatchBillboardMutation, AddBatchBillboardMutationVariables>(AddBatchBillboardDocument);
};
export const AddBillboardDocument = gql`
    mutation addBillboard($id: ID, $city_code: String, $input: BillboardInput) {
  addBillboard(id: $id, city_code: $city_code, input: $input) {
    id
    cod_cimasa
    media_owner
    city_code
    municipality
  }
}
    `;

export function useAddBillboardMutation() {
  return Urql.useMutation<AddBillboardMutation, AddBillboardMutationVariables>(AddBillboardDocument);
};
export const AddBillboardAvailabilityDocument = gql`
    mutation addBillboardAvailability($id: ID!, $city_code: String, $dates: [AWSDateTime]!, $is_maintenance: Boolean!, $last_maintenance: AWSDateTime, $advertiser: String) {
  addBillboardAvailability(
    id: $id
    city_code: $city_code
    dates: $dates
    is_maintenance: $is_maintenance
    last_maintenance: $last_maintenance
    advertiser: $advertiser
  ) {
    billboard_id
    media_owner
    start_busy_date
  }
}
    `;

export function useAddBillboardAvailabilityMutation() {
  return Urql.useMutation<AddBillboardAvailabilityMutation, AddBillboardAvailabilityMutationVariables>(AddBillboardAvailabilityDocument);
};
export const AddMediaOwnerDocument = gql`
    mutation addMediaOwner($input: MediaOwnerInput) {
  addMediaOwner(input: $input) {
    mediaowner
    avatar
    name
    subscription_date
    n_orders
    fees
    p_iva
    phone
    website
    registered_office
    rea
    pec
    society_form
    bank_account
    payment_mode
    payment_registered_office
    offices {
      name
      address
      phone
      email
      municipalities
    }
    stripe_account
    recipient_code
    funds_transfer
    stripe_actived
  }
}
    `;

export function useAddMediaOwnerMutation() {
  return Urql.useMutation<AddMediaOwnerMutation, AddMediaOwnerMutationVariables>(AddMediaOwnerDocument);
};
export const AddOrderImageDocument = gql`
    mutation addOrderImage($id: ID, $order_id: ID, $campaign: ID, $upload_date: AWSDateTime, $caption: String) {
  addOrderImage(
    id: $id
    order_id: $order_id
    campaign: $campaign
    upload_date: $upload_date
    caption: $caption
  ) {
    id
  }
}
    `;

export function useAddOrderImageMutation() {
  return Urql.useMutation<AddOrderImageMutation, AddOrderImageMutationVariables>(AddOrderImageDocument);
};
export const BatchAddBillboardAvailabilityDocument = gql`
    mutation batchAddBillboardAvailability($input: [BatchBillboardInput]!, $dates: [AWSDateTime]!, $is_maintenance: Boolean!, $last_maintenance: AWSDateTime, $advertiser: String) {
  batchAddBillboardAvailability(
    input: $input
    dates: $dates
    is_maintenance: $is_maintenance
    last_maintenance: $last_maintenance
    advertiser: $advertiser
  ) {
    billboard_id
    media_owner
    start_busy_date
  }
}
    `;

export function useBatchAddBillboardAvailabilityMutation() {
  return Urql.useMutation<BatchAddBillboardAvailabilityMutation, BatchAddBillboardAvailabilityMutationVariables>(BatchAddBillboardAvailabilityDocument);
};
export const BatchDeleteBillboardAvailabilitiesDocument = gql`
    mutation batchDeleteBillboardAvailabilities($dates: [AWSDateTime], $input: [BatchBillboardInput]) {
  batchDeleteBillboardAvailabilities(dates: $dates, input: $input) {
    billboard_id
    media_owner
    start_busy_date
  }
}
    `;

export function useBatchDeleteBillboardAvailabilitiesMutation() {
  return Urql.useMutation<BatchDeleteBillboardAvailabilitiesMutation, BatchDeleteBillboardAvailabilitiesMutationVariables>(BatchDeleteBillboardAvailabilitiesDocument);
};
export const BuildPdfOrderContractDocument = gql`
    mutation buildPdfOrderContract($campaign: ID!, $id: ID!) {
  buildPdfOrderContract(campaign: $campaign, id: $id)
}
    `;

export function useBuildPdfOrderContractMutation() {
  return Urql.useMutation<BuildPdfOrderContractMutation, BuildPdfOrderContractMutationVariables>(BuildPdfOrderContractDocument);
};
export const CreateStripeLoginLinkDocument = gql`
    query createStripeLoginLink($acct: String!) {
  createStripeLoginLink(acct: $acct)
}
    `;

export function useCreateStripeLoginLinkQuery(options: Omit<Urql.UseQueryArgs<CreateStripeLoginLinkQueryVariables>, 'query'>) {
  return Urql.useQuery<CreateStripeLoginLinkQuery>({ query: CreateStripeLoginLinkDocument, ...options });
};
export const DeleteBillboardDocument = gql`
    mutation deleteBillboard($id: ID!, $city_code: String!) {
  deleteBillboard(id: $id, city_code: $city_code) {
    address
    authorization_expiration
    available
    base
    billalo_index {
      iV_municipality
      iV_normalized
      panel_index
      indice_visibilita
    }
    city_code
    cod_cimasa
    creationDate
    height
    height_ground
    id
    last_maintenance
    latitude
    lighting
    longitude
    media_owner
    media_type
    municipality
    n_authorization
    n_sheet
    orientation
    property_type
    price
    updateDate
    imageUrl
    pricing_id
    taxes {
      last_payment
      municipal_tax
    }
    creation_date
    billboard_class
    media_owner_type
    visible
    min_period
    location
    location_name
    press_type
    facade
    is_draft
    other_location
  }
}
    `;

export function useDeleteBillboardMutation() {
  return Urql.useMutation<DeleteBillboardMutation, DeleteBillboardMutationVariables>(DeleteBillboardDocument);
};
export const EditBillboardDocument = gql`
    mutation editBillboard($id: ID!, $city_code: String) {
  addBillboard(id: $id, city_code: $city_code) {
    id
    cod_cimasa
    media_owner
    city_code
    municipality
  }
}
    `;

export function useEditBillboardMutation() {
  return Urql.useMutation<EditBillboardMutation, EditBillboardMutationVariables>(EditBillboardDocument);
};
export const GetBatchBillboardDocument = gql`
    query getBatchBillboard($input: [BatchBillboardInput]) {
  batchGetBillboard(input: $input) {
    address
    authorization_expiration
    available
    base
    billalo_code
    city_code
    cod_cimasa
    creationDate
    height
    height_ground
    id
    last_maintenance
    latitude
    lighting
    longitude
    media_owner
    media_type
    municipality
    n_authorization
    n_sheet
    orientation
    property_type
    size
    updateDate
    creation_date
  }
}
    `;

export function useGetBatchBillboardQuery(options?: Omit<Urql.UseQueryArgs<GetBatchBillboardQueryVariables>, 'query'>) {
  return Urql.useQuery<GetBatchBillboardQuery>({ query: GetBatchBillboardDocument, ...options });
};
export const GetBillboardPricingDocument = gql`
    query getBillboardPricing($id: ID) {
  getBillboardPricing(id: $id) {
    amount_1
    condition_1
    customers
    from
    id
    media_owner
    percentage
    rule_type
    to
  }
}
    `;

export function useGetBillboardPricingQuery(options?: Omit<Urql.UseQueryArgs<GetBillboardPricingQueryVariables>, 'query'>) {
  return Urql.useQuery<GetBillboardPricingQuery>({ query: GetBillboardPricingDocument, ...options });
};
export const GetMediaOwnerDocument = gql`
    query getMediaOwner {
  getMediaOwner {
    avatar
    name
    subscription_date
    n_orders
    fees
    p_iva
    phone
    website
    registered_office
    rea
    pec
    society_form
    bank_account
    payment_mode
    payment_registered_office
    offices {
      name
      address
      phone
      email
      municipalities
    }
    stripe_account
    recipient_code
    funds_transfer
    stripe_actived
  }
}
    `;

export function useGetMediaOwnerQuery(options?: Omit<Urql.UseQueryArgs<GetMediaOwnerQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMediaOwnerQuery>({ query: GetMediaOwnerDocument, ...options });
};
export const GetOrderDocument = gql`
    query getOrder($campaign: ID!, $id: ID!) {
  getOrder(campaign: $campaign, id: $id) {
    id
    media_owner
    advertiser
    status
    campaign_name
    from
    to
    order_date
    printer
    amount
    billboards {
      id
      city_code
      billalo_code
      property_type
      media_owner
      municipality
      address
      latitude
      longitude
      media_type
      base
      height
      format
      size
      n_sheet
      height_ground
      lighting
      orientation
      cod_cimasa
      n_authorization
      available
      pricing_id
      price
      imageUrl
      iV_municipality
      iV_normalized
      panel_index
      indice_visibilita
      billboard_class
      media_owner_type
      visible
      min_period
      location
      location_name
      press_type
      facade
      is_draft
      other_location
    }
  }
}
    `;

export function useGetOrderQuery(options: Omit<Urql.UseQueryArgs<GetOrderQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrderQuery>({ query: GetOrderDocument, ...options });
};
export const GetResourceUrlDocument = gql`
    query getResourceUrl($id: ID, $expires: Int, $type: String) {
  getResourceUrl(id: $id, expires: $expires, type: $type)
}
    `;

export function useGetResourceUrlQuery(options?: Omit<Urql.UseQueryArgs<GetResourceUrlQueryVariables>, 'query'>) {
  return Urql.useQuery<GetResourceUrlQuery>({ query: GetResourceUrlDocument, ...options });
};
export const GetUploadCsvUrlDocument = gql`
    query getUploadCSVUrl($expires: Int, $type: String, $name: String) {
  getUploadCSVUrl(expires: $expires, type: $type, name: $name)
}
    `;

export function useGetUploadCsvUrlQuery(options?: Omit<Urql.UseQueryArgs<GetUploadCsvUrlQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUploadCsvUrlQuery>({ query: GetUploadCsvUrlDocument, ...options });
};
export const GetUploadOrderImageUrlDocument = gql`
    query getUploadOrderImageUrl($expires: Int, $type: String, $id: ID, $order_id: ID) {
  getUploadOrderImageUrl(
    expires: $expires
    type: $type
    id: $id
    order_id: $order_id
  )
}
    `;

export function useGetUploadOrderImageUrlQuery(options?: Omit<Urql.UseQueryArgs<GetUploadOrderImageUrlQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUploadOrderImageUrlQuery>({ query: GetUploadOrderImageUrlDocument, ...options });
};
export const GetUploadUrlDocument = gql`
    query getUploadUrl($billboardId: ID, $expires: Int) {
  getUploadUrl(billboardId: $billboardId, expires: $expires)
}
    `;

export function useGetUploadUrlQuery(options?: Omit<Urql.UseQueryArgs<GetUploadUrlQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUploadUrlQuery>({ query: GetUploadUrlDocument, ...options });
};
export const ListAdvertisersDocument = gql`
    query listAdvertisers {
  listAdvertisers {
    advertiser
    name
    type
    p_iva
    company_form
    company_type
    pec
    email
    phone
    legal_municipality
    legal_address
    legal_cap
    legal_email
    firstJob
    lastYearCampaign
    founded_by
    image
    subscription_date
    fiscal_code
    bank_account
    society_form
    recipient_code
  }
}
    `;

export function useListAdvertisersQuery(options?: Omit<Urql.UseQueryArgs<ListAdvertisersQueryVariables>, 'query'>) {
  return Urql.useQuery<ListAdvertisersQuery>({ query: ListAdvertisersDocument, ...options });
};
export const ListBillboardAvailabilityDocument = gql`
    query listBillboardAvailability($billboard_id: ID) {
  listBillboardAvailability(billboard_id: $billboard_id) {
    billboard_id
    media_owner
    start_busy_date
    is_maintenance
    order_id
    status
    campaign_id
  }
}
    `;

export function useListBillboardAvailabilityQuery(options?: Omit<Urql.UseQueryArgs<ListBillboardAvailabilityQueryVariables>, 'query'>) {
  return Urql.useQuery<ListBillboardAvailabilityQuery>({ query: ListBillboardAvailabilityDocument, ...options });
};
export const ListBillboardAvailabilityByRangeDocument = gql`
    query listBillboardAvailabilityByRange($billboard_id: ID, $start_date: AWSDateTime, $stop_date: AWSDateTime) {
  listBillboardAvailabilityByRange(
    billboard_id: $billboard_id
    start_date: $start_date
    stop_date: $stop_date
  ) {
    billboard_id
    media_owner
    start_busy_date
    is_maintenance
    order_id
    status
    campaign_id
    order_data {
      id
      campaign
      media_owner
      advertiser
      status
      campaign_name
      from
      to
      order_date
      printer
      amount
      billboards {
        id
        city_code
        billalo_code
        property_type
        media_owner
        municipality
        address
        latitude
        longitude
        media_type
        base
        height
        format
        size
        n_sheet
        height_ground
        lighting
        orientation
        cod_cimasa
        n_authorization
        available
        pricing_id
        price
        imageUrl
        iV_municipality
        iV_normalized
        panel_index
        indice_visibilita
        billboard_class
        media_owner_type
        visible
        min_period
        location
        location_name
        press_type
        facade
        is_draft
        other_location
      }
    }
  }
}
    `;

export function useListBillboardAvailabilityByRangeQuery(options?: Omit<Urql.UseQueryArgs<ListBillboardAvailabilityByRangeQueryVariables>, 'query'>) {
  return Urql.useQuery<ListBillboardAvailabilityByRangeQuery>({ query: ListBillboardAvailabilityByRangeDocument, ...options });
};
export const ListBillboardsAvailablesByRangeDocument = gql`
    query listBillboardsAvailablesByRange($start_date: AWSDateTime, $stop_date: AWSDateTime) {
  listBillboardsAvailablesByRange(start_date: $start_date, stop_date: $stop_date) {
    billboard_id
    media_owner
    start_busy_date
    is_maintenance
    order_id
    status
    campaign_id
  }
}
    `;

export function useListBillboardsAvailablesByRangeQuery(options?: Omit<Urql.UseQueryArgs<ListBillboardsAvailablesByRangeQueryVariables>, 'query'>) {
  return Urql.useQuery<ListBillboardsAvailablesByRangeQuery>({ query: ListBillboardsAvailablesByRangeDocument, ...options });
};
export const ListBillboardsByOwnerDocument = gql`
    query listBillboardsByOwner($media_owner: String, $limit: Int, $nextToken: String) {
  listBillboardsByOwner(
    media_owner: $media_owner
    limit: $limit
    nextToken: $nextToken
  ) {
    nextToken
    items {
      address
      authorization_expiration
      available
      base
      billalo_code
      billalo_index {
        iV_municipality
        iV_normalized
        panel_index
        indice_visibilita
      }
      city_code
      cod_cimasa
      creationDate
      height
      height_ground
      id
      last_maintenance
      latitude
      lighting
      longitude
      media_owner
      media_type
      municipality
      n_authorization
      n_sheet
      orientation
      property_type
      size
      updateDate
      price
      pricing_id
      creation_date
      billboard_class
      media_owner_type
      visible
      facade
      min_period
      location
      location_name
      press_type
      is_draft
      other_location
    }
  }
}
    `;

export function useListBillboardsByOwnerQuery(options?: Omit<Urql.UseQueryArgs<ListBillboardsByOwnerQueryVariables>, 'query'>) {
  return Urql.useQuery<ListBillboardsByOwnerQuery>({ query: ListBillboardsByOwnerDocument, ...options });
};
export const ListBillboardsInMapDocument = gql`
    query listBillboardsInMap($lat_bot_rigth: Float, $lat_top_left: Float, $lon_bot_rigth: Float, $lon_top_left: Float) {
  listBillboardsInMap(
    lat_bot_rigth: $lat_bot_rigth
    lat_top_left: $lat_top_left
    lon_bot_rigth: $lon_bot_rigth
    lon_top_left: $lon_top_left
  ) {
    billboardId
    city
    city_code
    location {
      lat
      lon
    }
  }
}
    `;

export function useListBillboardsInMapQuery(options?: Omit<Urql.UseQueryArgs<ListBillboardsInMapQueryVariables>, 'query'>) {
  return Urql.useQuery<ListBillboardsInMapQuery>({ query: ListBillboardsInMapDocument, ...options });
};
export const ListBillboardsNumberInMapDocument = gql`
    query listBillboardsNumberInMap($lat_bot_rigth: Float, $lat_top_left: Float, $lon_bot_rigth: Float, $lon_top_left: Float) {
  listBillboardsNumberInMap(
    lat_bot_rigth: $lat_bot_rigth
    lat_top_left: $lat_top_left
    lon_bot_rigth: $lon_bot_rigth
    lon_top_left: $lon_top_left
  ) {
    billboardCount
    city_code
    location {
      lat
      lon
    }
  }
}
    `;

export function useListBillboardsNumberInMapQuery(options?: Omit<Urql.UseQueryArgs<ListBillboardsNumberInMapQueryVariables>, 'query'>) {
  return Urql.useQuery<ListBillboardsNumberInMapQuery>({ query: ListBillboardsNumberInMapDocument, ...options });
};
export const ListFilteredBillboardsDocument = gql`
    query listFilteredBillboards($input: FilterInput) {
  listFilteredBillboards(input: $input) {
    address
    id
    municipality
    media_type
    available
    city_code
    base
    height
    cod_cimasa
    latitude
    longitude
    media_owner
    orientation
    n_sheet
    price
    iV_municipality
    iV_normalized
    panel_index
    indice_visibilita
    pricing_id
    creation_date
    billboard_class
    media_owner_type
    visible
    min_period
    location
    location_name
    press_type
    facade
    is_draft
    other_location
  }
}
    `;

export function useListFilteredBillboardsQuery(options?: Omit<Urql.UseQueryArgs<ListFilteredBillboardsQueryVariables>, 'query'>) {
  return Urql.useQuery<ListFilteredBillboardsQuery>({ query: ListFilteredBillboardsDocument, ...options });
};
export const ListMediaOwnerUsersDocument = gql`
    query listMediaOwnerUsers {
  listMediaOwnerUsers {
    id
    status
    enabled
    creation_date
    given_name
    family_name
    email
  }
}
    `;

export function useListMediaOwnerUsersQuery(options?: Omit<Urql.UseQueryArgs<ListMediaOwnerUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<ListMediaOwnerUsersQuery>({ query: ListMediaOwnerUsersDocument, ...options });
};
export const ListMyBillboardsDocument = gql`
    query listMyBillboards($limit: Int, $nextToken: String) {
  listMyBillboards(limit: $limit, nextToken: $nextToken) {
    nextToken
    items {
      address
      authorization_expiration
      available
      base
      billalo_index {
        iV_municipality
        iV_normalized
        panel_index
        indice_visibilita
      }
      city_code
      cod_cimasa
      creationDate
      height
      height_ground
      id
      last_maintenance
      latitude
      lighting
      longitude
      media_owner
      media_type
      municipality
      n_authorization
      n_sheet
      orientation
      property_type
      price
      updateDate
      imageUrl
      pricing_id
      taxes {
        last_payment
        municipal_tax
      }
      creation_date
      billboard_class
      media_owner_type
      visible
      min_period
      location
      location_name
      press_type
      facade
      is_draft
      other_location
    }
  }
}
    `;

export function useListMyBillboardsQuery(options?: Omit<Urql.UseQueryArgs<ListMyBillboardsQueryVariables>, 'query'>) {
  return Urql.useQuery<ListMyBillboardsQuery>({ query: ListMyBillboardsDocument, ...options });
};
export const ListMyOrdersDocument = gql`
    query listMyOrders($limit: Int, $nextToken: String) {
  listMyOrders(limit: $limit, nextToken: $nextToken) {
    nextToken
    items {
      id
      campaign
      media_owner
      advertiser
      status
      campaign_name
      from
      to
      order_date
      printer
      amount
      billboards {
        id
        city_code
        billalo_code
        property_type
        media_owner
        municipality
        address
        latitude
        longitude
        media_type
        base
        height
        format
        size
        n_sheet
        height_ground
        lighting
        orientation
        cod_cimasa
        n_authorization
        available
        pricing_id
        price
        imageUrl
        iV_municipality
        iV_normalized
        panel_index
        indice_visibilita
        billboard_class
        media_owner_type
        visible
        min_period
        location
        location_name
        press_type
        facade
        is_draft
        other_location
      }
    }
  }
}
    `;

export function useListMyOrdersQuery(options?: Omit<Urql.UseQueryArgs<ListMyOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<ListMyOrdersQuery>({ query: ListMyOrdersDocument, ...options });
};
export const ListOrderImagesDocument = gql`
    query listOrderImages($order_id: ID, $limit: Int, $nextToken: String) {
  listOrderImages(order_id: $order_id, limit: $limit, nextToken: $nextToken) {
    nextToken
    items {
      order
      id
      campaign
      upload_date
      caption
      media_owner
    }
  }
}
    `;

export function useListOrderImagesQuery(options?: Omit<Urql.UseQueryArgs<ListOrderImagesQueryVariables>, 'query'>) {
  return Urql.useQuery<ListOrderImagesQuery>({ query: ListOrderImagesDocument, ...options });
};
export const RegisterStripeAccountDocument = gql`
    mutation registerStripeAccount {
  registerStripeAccount
}
    `;

export function useRegisterStripeAccountMutation() {
  return Urql.useMutation<RegisterStripeAccountMutation, RegisterStripeAccountMutationVariables>(RegisterStripeAccountDocument);
};
export const UpdateOrderStateDocument = gql`
    mutation updateOrderState($campaign: ID!, $id: ID, $action: String) {
  updateOrderState(campaign: $campaign, id: $id, action: $action)
}
    `;

export function useUpdateOrderStateMutation() {
  return Urql.useMutation<UpdateOrderStateMutation, UpdateOrderStateMutationVariables>(UpdateOrderStateDocument);
};
export const UpdatePricingDocument = gql`
    mutation updatePricing($id: ID!, $pricing_data: PricingInput) {
  putPricing(id: $id, pricing_data: $pricing_data) {
    id
  }
}
    `;

export function useUpdatePricingMutation() {
  return Urql.useMutation<UpdatePricingMutation, UpdatePricingMutationVariables>(UpdatePricingDocument);
};