import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ExploreActions, selectors as ExploreSelectors } from '../../../store/slices/explore';
import { useStyles, cancelButtonFilters, confirmButtonFilters } from '../Explore.css';
import { Grid, TextField, InputAdornment, Slider } from '@material-ui/core';
import Icon from '../../IconsComponent';

const valuetext = (value: number) => {
  // console.log("valuetext", value)
  return `${value}`;
}

export const IVMunicipality = (props: any) => {
  const { setAnchor, setPlanningType } = props
  const classes = useStyles();
  const dispatch = useDispatch()

  const filters = useSelector(ExploreSelectors.didGetExploreFilter)
  
  const [rangeFrom, setPriceFrom] = useState(filters.iV_municipality_from ? (filters.iV_municipality_from * 10) : "")
  const [rangeTo, setPriceTo] = useState(filters.iV_municipality_to ? (filters.iV_municipality_to * 10) : "")
  const [billaloIndexSelector, seBillaloIndexSelector] = useState<number[]>(
    filters.iV_municipality_from && filters.iV_municipality_to
      ? [(filters.iV_municipality_from * 10), (filters.iV_municipality_to * 10)]
      : [0, 100]
  );
  const [billaloIndexStart, setBillaloIndexStart] = useState("");
  const [billaloIndexStop, setBillaloIndexStop] = useState("");

  const applyFunction = () => {
    // dispatch(ExploreActions.willExploreFilterBillboards({ exploreFilters: {} }))
    dispatch(ExploreActions.willExploreUploadFilter({ key: "iV_municipality", value: { from: (parseInt(billaloIndexStart) / 10), to: (parseInt(billaloIndexStop) / 10) } }))
    setAnchor(null)
    setPlanningType("")
  }

  const billaloIndexChange = (event: any, newValue: any) => {
    seBillaloIndexSelector(newValue as number[]);
    setBillaloIndexStart(newValue[0])
    setBillaloIndexStop(newValue[1])
  };

  return (
    <div className={`${classes.root} poppinsFont`}>
      <Grid container spacing={3}>
          <Grid item xs={12}><b>Prezzo Impianti</b></Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid className="mb-5" item xs={12}><span className="text-secondary detailText">SELEZIONA RANGE</span></Grid>
          <Grid item xs={12}>
            <Slider
              data-cy="visibilityIndex"
              className={classes.billaloIndexSlide}
              value={billaloIndexSelector}
              onChange={billaloIndexChange}
              scale={(x) => x / 10}
              valueLabelDisplay="on"
              aria-labelledby="range-slider"
              valueLabelFormat={valuetext}
            />
          </Grid>
        </Grid>
        <Grid className={classes.buttonsFilterContainer} container>
          <Grid container item xs={6} alignItems="center" justify="center">
            <button
              style={cancelButtonFilters}
              data-cy="goBack"
              onClick={() => { setAnchor(null); setPlanningType("") }}
            >Annulla</button>
          </Grid>
          <Grid container item xs={6} alignItems="center" justify="center">
            <button data-cy="apply" style={confirmButtonFilters} onClick={applyFunction}>Applica</button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}