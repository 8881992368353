import { call, put, takeEvery, takeLatest, delay, select } from 'redux-saga/effects'
import { Button } from 'reactstrap';
import * as PaymentApi from '../../api/payment'
import { actions as AuthActions } from '../slices/auth'
import { actions as NotificationActions } from '../slices/notification'
import { actions as PaymentActions, selectors as PaymentSelectors } from '../slices/payment'
import { push } from 'connected-react-router'
import moment from 'moment';
import _ from 'lodash';


export function* sagas() {
  console.log('in payment saga');
  yield takeLatest(PaymentActions.willListConditionUsers.type, willListConditionsUsers)
  // console.log('in content saga');
}

function* willListConditionsUsers(action: any) {
  console.log("in willListConditionsUsers saga")
  try {
    const result = yield call(PaymentApi.listAdvertisers);
    //console.log("In saga payment with: ", result)
    yield put(PaymentActions.didListConditionUsers(result.data.listAdvertisers))

  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
} 