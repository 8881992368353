import React from 'react';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    success: {
      backgroundColor: '#9BE2C6',
      height: 0,
      color: 'white',
      transition: 'height 0.5s'
    },
    danger: {
      backgroundColor: '#FC5A5A',
      height: 0,
      color: 'white',
      transition: 'height 0.5s'
    },
    warning: {
      backgroundColor: '#FFC542',
      height: 0,
      color: 'white',
      transition: 'height 0.5s'
    },
    info: {
      backgroundColor: '#50B5FF',
      height: 0,
      color: 'white',
      transition: 'height 0.5s'
    }
  }),
);