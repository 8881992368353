import { Switch } from '@material-ui/core';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';

export const SwitchNewBillboard = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 40,
      height: 20,
      padding: 0,
      display: 'flex',
    },
    switchBase: { /// pallino
      padding: 0,
      color: 'rgba(241, 241, 245, 1)',
      '&$checked': {
        transform: 'translateX(20px)',
        color: '#3DD598',
        '& + $track': {
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: '#F1F1F5',
          backgroundColor: '#FAFAFB',
          borderRadius: 10,
        },
      },
    },
    thumb: {
      width: 20,
      height: 20,
      boxShadow: 'none',
    },
    track: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#F1F1F5',
      backgroundColor: '#FAFAFB',
      borderRadius: 10,
    },
    checked: {
      backgroundColor: '#FAFAFB'
    },
  }),
)(Switch);