import moment from 'moment';

export const newBillboardValue = {
  /// nuovi parametri
  billboard_class: '',
  media_owner_type: '',
  visible: true,
  min_period: '',
  location: '',
  location_name: '',
  press_type: '',
  /////
  municipality: "",
  address: "",
  latitude: "",
  longitude: "",
  n_sheet: "",
  city: "", /// potrebbe non servire
  city_code: '',
  base: "",
  height: "",
  media_type: "",
  surface: "", /// potrebbe non servire
  size: "", /// potrebbe non servire
  height_ground: "",
  lighting: "",
  orientation: "",
  cod_cimasa: "",
  price: "",
  status: "",
  last_maintenance: "",
  n_authorization: "",
  notes: "",
  billalo_index: "",
  authorization_expiration: "",
  taxes: {
    last_payment: "",
    municipal_tax: "",
  },
  creation_date: moment(),
}