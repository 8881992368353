import React from 'react';
import { Grid } from '@material-ui/core';
import billalo_logo from '../assets/logo.png'

export const NoGroupAdvise = () => {

  return (
    <div className="d-flex justify-content-center align-items-center bg-white poppinsFont">
      <Grid container item xs={12} alignItems="center" justify="center" direction="column" spacing={4}>
        <Grid item xs={5}>
          <img src={billalo_logo} width="300" height="100%" />
        </Grid>
        <Grid item xs={5}>
          Questo utente non risulta associato a un concessionario <br />
          Per essere associato, contattare l'assistenza di Billalo tramite
           email su supporto@billalo.it.
        </Grid>
      </Grid>
    </div>
  )
}