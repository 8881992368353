import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { Styles, useStyles, actionButton, csvButton, addButton, } from "./PlantListCss";
import _ from 'lodash';

export const SortColumn = ({ headerGroups, closeRow }: any) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const [sorted, setSorted] = useState("")

  const sortColumnValue = useSelector(ContentSelectors.getSortColumnValue)

  useEffect(() => {
    setSorted(sortColumnValue)
  }, [sortColumnValue])

  const alignHeight = document.getElementById('searchInput')?.offsetHeight

  return (
    <Dropdown data-cy="orderBy" className="mr-2 ml-2" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle style={{ height: alignHeight }} data-cy="sortButton" className="bg-white text-dark border-0" caret>Ordina per: <b>{sorted}</b>  </DropdownToggle>
      <DropdownMenu className={classes.drowdownMenu}>
        {headerGroups.map((headerGroup) => (
          <div {...headerGroup.getHeaderGroupProps({ style: { width: 200 } })} >
            <div className="column">
              {headerGroup.headers.filter((item) => { return item.id !== "selection" && item.id !== "expander" && item.id !== "Opzioni" && item.id !== "icons" }).map((column) => {
                // console.log("COLUMS", column)
                // let sortableColumns = ["Comune / Indirizzo","Tipologia","Formato","Cimasa","Prezzo","Indice Visibilità","I più recenti",]
                return (
                  <DropdownItem
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    onClick={() => {
                      dispatch(ContentActions.didSetSortColumnValue({ sortColumnValue: column.id, sortOrderAsc: true }))
                      // toggleSortBy(column.id, true, false)                                        //ordinamento
                      closeRow()
                      console.log("column.render", column)
                    }}
                    style={{ width: 200 }}
                    data-cy={`choice${column.id}`}
                  >
                    <input className="mr-2" type="checkbox" checked={sorted == column.id ? true : false} readOnly />
                    {column.render("id")}
                  </DropdownItem>
                )
              })}
            </div>
          </div>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}