import { call, put, takeEvery, takeLatest, delay, select } from 'redux-saga/effects'
import { Button } from 'reactstrap';
import * as ContentApi from '../../api/content'
import { actions as AuthActions } from '../slices/auth'
import { actions as NotificationActions } from '../slices/notification'
import { actions as UiActions } from '../slices/ui'
import { actions as ContentActions, selectors as ContentSelectors } from '../slices/content'
import { push } from 'connected-react-router'
import moment from 'moment';
import _ from 'lodash';
import getDistance from 'geolib/es/getDistance';
import { v4 as uuidv4 } from 'uuid';
import { readFile } from 'fs';
import { any } from 'cypress/types/bluebird';
const axios = require('axios');
var Velocity = require('velocityjs');
const util = require('util');
const fs = require('fs');

export function* sagas() {
  console.log('in content saga1');
  // yield takeLatest(ContentActions.willListBillboardsbyOwner.type, willListBillboards)
  // yield takeLatest(ContentActions.getMoreBillboards.type, willGetMoreBillboards)
  yield takeLatest(ContentActions.willAddBatchBillboards.type, willAddBatchBillboards)
  yield takeLatest(ContentActions.willListMyBillboards.type, willListMyBillboards)
  yield takeLatest(ContentActions.willListMoreMyBillboards.type, willListMoreMyBillboards)
  yield takeLatest(ContentActions.willListBillboardsInMap.type, willListBillboardsInMap)
  yield takeLatest(ContentActions.willUploadImage.type, willUploadImage)
  yield takeLatest(ContentActions.willUploadCSV.type, willUploadCSV)
  yield takeLatest(ContentActions.willAddBillboard.type, willAddBillboard)
  yield takeLatest(ContentActions.willAddPricing.type, willAddPricing)
  yield takeLatest(ContentActions.willFetchMoreFirstBillboards.type, willFetchMoreFirstBillboards)
  yield takeLatest(ContentActions.willFilterBillboards.type, willFilterBillboards)
  yield takeLatest(ContentActions.willListBillboardAvailabilities.type, willListBillboardAvailabilities)
  yield takeLatest(ContentActions.willListBillboardAvailabilityByRange.type, willListBillboardAvailabilityByRange)
  yield takeLatest(ContentActions.willGetBillboardPricing.type, willGetBillboardPricing)
  yield takeLatest(ContentActions.willEditPricing.type, willEditPricing)
  yield takeLatest(ContentActions.willSelectOrder.type, willSelectOrder)
  yield takeLatest(ContentActions.willSetMaintenance.type, willSetMaintenance)
  yield takeLatest(ContentActions.willListOrders.type, willListOrders)
  yield takeLatest(ContentActions.willGetOrderBillboards.type, willGetOrderBillboards)
  yield takeLatest(ContentActions.willGetOrder.type, willGetOrder)
  yield takeLatest(ContentActions.willListPlantsAvailabilities.type, willListPlantsAvailabilities)
  yield takeLatest(ContentActions.willUpdateOrderStatus.type, willUpdateOrderStatus)
  yield takeLatest(ContentActions.willFilterOrders.type, willFilterOrders)
  yield takeLatest(ContentActions.willDeleteBillboardAvailabilities.type, willDeleteBillboardAvailabilities)
  yield takeLatest(ContentActions.willUploadOrderImage.type, willUploadOrderImage)
  // yield takeLatest(ContentActions.willGetOrderImagesUrl.type, willGetOrderImagesUrl)
  yield takeLatest(ContentActions.willListOrderImages.type, willListOrderImages)
  yield takeLatest(ContentActions.willGetOrderContract.type, willGetOrderContract)
  yield takeLatest(ContentActions.willDeleteBillboard.type, willDeleteBillboard)

}
// function* willListBillboards(action: any) {
//   // console.log("in saga : ", action)
//   try {
//     const result = yield call(ContentApi.listBillboards, action.payload.owner, "");
//     if (result.data.listBillboardsByOwner) {
//       const billboards = result.data.listBillboardsByOwner
//       yield put(ContentActions.didListBillboardsbyOwner({ "billboards": billboards, "media_owner": action.payload.owner }));
//     } else {
//       console.log("empty")
//     }
//   } catch (error: any) {
//     console.log('Error ', error);
//   }
// }
// function* willGetMoreBillboards(action: any) {
//   const nextToken = yield select(ContentSelectors.getMoreBillboardsByOwnerNextToken)
//   const media_owner = yield select(ContentSelectors.getMoreBillboardsMediaOwner)
//   if (nextToken) {
//     try {
//       const result = yield call(ContentApi.listBillboards, media_owner, nextToken);
//       // console.log("result", result)
//       if (result.data.listBillboardsByOwner) {
//         const billboards = result.data.listBillboardsByOwner
//         // console.log("in if", billboards)
//         yield put(ContentActions.didListMoreBillboardsbyOwner({ "billboards": billboards, "media_owner": media_owner }));
//       } else {
//         console.log("empty")
//       }
//     } catch (error: any) {
//       console.log('Error ', error);
//     }
//   }
// }

function* willListMyBillboards(action: any) {
  // console.log("in saga : ", action)
  console.log('in willListMyBillboard')
  let nextToken = "" as any
  try {
    do {

      const result = yield call(ContentApi.listMyBillboards, nextToken);
      // console.log("result", result)

      if (result.data.listMyBillboards) {

        const billboards = result.data.listMyBillboards
        if (nextToken === "") {
          yield put(ContentActions.didListFirstBillboards({ "billboards": billboards }));
        }
        yield put(ContentActions.didListMyBillboards({ "billboards": billboards }));
        if (nextToken === billboards.nextToken) {
          nextToken = null
        }
        nextToken = billboards.nextToken

      } else {
        console.log("empty")
      }


    } while (nextToken);

  } catch (error: any) {
    console.log('Error ', error);
  }
}

function* willListMoreMyBillboards(action: any) {
  const nextToken = yield select(ContentSelectors.getMoreMyBillboardsNextToken)
  console.log("fetch?")
  if (nextToken) {
    try {
      const result = yield call(ContentApi.listMyBillboards, nextToken);
      // console.log("result", result)
      if (result.data.listMyBillboards) {
        const billboards = result.data.listMyBillboards
        // console.log("in if", billboards)
        yield put(ContentActions.didListMoreMyBillboards({ "billboards": billboards }));
      } else {
        console.log("empty")
      }
    } catch (error: any) {
      console.log('Error ', error);
    }
  }
}

function* willAddBatchBillboards(action: any) {
  console.log('in willAddBatchBillboards 1', action.payload.batchBillboards)
  let batchBillboards = action.payload.batchBillboards

  yield put(UiActions.startActivityRunning("confermaAggiuntaBatch"));

  // let result = {} as any
  try {
    const result = yield call(ContentApi.addBatchBillboard, batchBillboards);

    if (!_.isEmpty(result.result_batch)) {
      yield put(ContentActions.didEmptyBillboards());
      yield put(ContentActions.willListMyBillboards())

    }
    yield put(UiActions.switchCloseAddNewPlant());

    if (result.result_batch.length > 0 && result.countErrors > 0) {
      yield put(NotificationActions.willShowNotification({ message: `Upload degli impianti completato. ${result.result_batch.length} impianti caricati`, type: "success" }));
    } else {
      yield put(NotificationActions.willShowNotification({ message: "Upload degli impianti completato", type: "success" }));
    }
    console.log("risultato addBatchBillboard saga", result.result_batch)
    // yield put(NotificationActions.willShowNotification({ message: "Caricamento CSV effettuato con successo.", type: "success" }));
  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: "Errore nell'upload degli impianti", type: "danger" }));
  }

  yield put(UiActions.stopActivityRunning("confermaAggiuntaBatch"));
}

function* willListBillboardsInMap(action: any) {
  let position = {
    top_left: {
      lat: action.payload.lat_top_left,
      lon: action.payload.lon_top_left
    },
    bot_rigth: {
      lat: action.payload.lat_bot_rigth,
      lon: action.payload.lon_bot_rigth
    }
  }
  console.log("in saga willListBillboardsInMap: ", position)
  const distance = getDistance(position.top_left, position.bot_rigth)
  const billboards = yield select(ContentSelectors.getBillboards)

  const result = {} as any
  try {
    if (distance < 15000) {

      const result = yield call(ContentApi.listBillboardsInMap, position)
      if (result.data.listBillboardsInMap) {
        console.log("errore?")
        // gli impianti della mappa che non sono presenti nella lista li inserisco in un array items
        let items = [] as any
        result.data.listBillboardsInMap.forEach((element: any) => {
          if (billboards[element] == undefined) {
            items.push(element)
          }
        });
        console.log("errore? 0")
        var dataCompact
        // mi sono ricavato gli items che non sono presenti in lista e chiamo get batch billboards
        //  per avere tutti i dati degli impianti mancanti, prima mi creo data che ha la struttura per l'input alla query gql
        if (items.length > 0) {
          var data = items.map((item: any) => {
            return { "id": item.billboardId, "city_code": item.city_code }
          })
          data = _.uniqBy(data, 'id'); //rimuovo possibili elementi doppi
          console.log("errore? 1", data)

          for (var i = 0; i < data.length; i += 99) { //chiamo la query batch ogni 100 elementi
            var tempArray = data.slice(i, i + 99)
            tempArray = _.remove(tempArray, (el: any) => { return el != undefined })
            try {
              const result = yield call(ContentApi.getBatchBillboard, data) // chiamo la query per il batch degli impianti
              if (result.data.batchGetBillboard) {
                // aggiungo il risultato della query mediante il reducer che chiamo sotto
                // var dataCompact = _.filter(_.compact(result.data.batchGetBillboard), ['address', "via Po"])
                dataCompact = _.chain(result.data.batchGetBillboard).compact().filter(['available', true]).value()
                console.log("errore? 2", result, dataCompact)
                yield put(ContentActions.didAddBillboards({ "billboards": dataCompact }))
              }
            } catch (error: any) {
              console.log('Error ', error);
            }
          }
        }
        //aggiungo il risultato della query geografica ad ES allo state di redux
        // yield put(ContentActions.didListBillboardsInMap({ "items": result.data.listBillboardsInMap }))
        console.log("errore? 3")
      }
    } else {
      const result = yield call(ContentApi.listBillboardsNumberInMap, position)
      if (result.data.listBillboardsNumberInMap) {
        console.log("in number billboards: ", result)
        // yield put(ContentActions.didListBillboardsInMap({ "items": result.data.listBillboardsNumberInMap }));
      }
      console.log("result in if ", result)
    }
  } catch (error: any) {
    console.log('Error', error);
  }


  // console.log("result", result)
  // if (result.data.listBillboardsByOwner) {
  //   const billboards = result.data.listBillboardsByOwner
  //   // console.log("in if", billboards)
  //   // yield put(ContentActions.didAddMediaOwner(action.payload.owner))
  //   yield put(ContentActions.didListBillboardsbyOwner({ "billboards": billboards, "media_owner": action.payload.owner }));
  // } else {
  //   console.log("empty")
  // }
}


function* willUploadImage(action: any) {
  console.log("action upload image", action)
  if (action.payload.image) {
    try {
      const result = yield call(ContentApi.getSignedUrl, action.payload.id);
      console.log("result upload ", result)
      if (result.data.getUploadUrl) {
        const url = result.data.getUploadUrl
        console.log("url s3", url)
        // aggiungere chiamata axios

        var options = {
          headers: {
            'Content-Type': "image/jpeg"
          }
        };
        axios.put(url, action.payload.image, options)
          .then(function (response: any) {
            console.log("response", response);
          })
          .catch(function (error: any) {
            console.log("errore", error);
          });
        yield put(NotificationActions.willShowNotification({ message: "Upload immagine completato", type: "success" }));

      } else {
        console.log("not url")
      }
    } catch (error: any) {
      console.log('Error ', error);
    }
  }
}

function* willUploadCSV(action: any) {
  console.log("action upload csv", action)
  if (action.payload.file) {
    try {
      yield put(UiActions.startActivityRunning("confermaAggiuntaBatch"));
      const result = yield call(ContentApi.getSignedCSVUrl, action.payload.file.type, action.payload.file.name);
      console.log("result upload ", result)
      if (result.data.getUploadCSVUrl) {
        const url = result.data.getUploadCSVUrl
        console.log("url s3", url)

        var options = {
          headers: {
            'Content-Type': action.payload.file.type
          }
        };
        axios.put(url, action.payload.file, options)
          .then(function (response: any) {
            console.log("response", response);
          })
          .catch(function (error: any) {
            console.log("errore", error);
          });
        yield put(NotificationActions.willShowNotification({ message: "Upload del file CSV completato", type: "success" }));
        yield put(UiActions.didSetActionDone({ done: true, action: "csvUpload" }));
      } else {
        console.log("not url")
      }
    } catch (error: any) {
      console.log('Error ', error);
    }
    yield put(UiActions.stopActivityRunning("confermaAggiuntaBatch"));
  }
}

function* willAddBillboard(action: any) {
  console.log("in willAddBillboard data", action.payload)
  yield put(UiActions.startActivityRunning("conferma"));
  let id = action.payload.id
  let data = action.payload.data
  let city_code = action.payload.city_code
  let city = action.payload.city
  let sheets_number = parseInt(data.n_sheet) ? data.n_sheet : 1

  let input: any = {
    property_type: data.property_type,
    facade: data.facade,
    address: data.address,
    base: parseFloat(data.base),
    cod_cimasa: data.cod_cimasa,
    authorization_expiration: moment(data.authorization_expiration, "DD-MM-YYYY"),
    height: parseFloat(data.height),
    height_ground: data.height_ground, //data.height_ground
    last_maintenance: moment(data.last_maintenance, "DD-MM-YYYY"),
    latitude: parseFloat(data.latitude),
    lighting: data.lighting,
    longitude: parseFloat(data.longitude),
    media_type: data.media_type,
    municipality: city,
    n_authorization: data.n_authorization,
    n_sheet: sheets_number,
    billalo_index: { ...data.billalo_index },
    orientation: data.orientation,
    price: parseFloat(data.price),
    size: data.size,
    taxes: {
      municipal_tax: parseFloat(data.taxes.municipal_tax),
      last_payment: moment(data.taxes.last_payment, "DD-MM-YYYY")
    },
    creation_date: moment(data.creation_date, "DD-MM-YYYY"),
    format: `${data.base}x${data.height}`,
    /// nuovi parametri
    billboard_class: data.billboard_class,
    media_owner_type: data.media_owner_type,
    visible: data.visible,
    min_period: data.min_period,
    location: data.location,
    location_name: data.location_name,
    press_type: data.press_type,
    other_location: data.location === 'Altro' ? data.other_location : '',
    notes: data.notes
  }
  if (action.payload.imageUrl) {
    input["imageUrl"] = action.payload.imageUrl
  }
  try {
    // const input = { address: "via", latitude: 40.782311, longitude: 14.309188, municipality: "Valencia" }
    const addBillboard = yield call(ContentApi.addBillboard, id, city_code, input);
    console.log("result addBillboard", addBillboard)
    yield put(NotificationActions.willShowNotification({ message: "Operazione completata con successo", type: "success" }));

  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }

  yield put(ContentActions.didEmptyBillboards());
  yield put(ContentActions.willListMyBillboards())
  yield put(UiActions.switchCloseAddNewPlant())
  yield put(UiActions.stopActivityRunning("conferma"))
  // yield put(push("/"))
}


function* willAddPricing(action: any) {
  console.log("data in saga add pricing", action)
  // const id_list = action.id_list
  // const city_code = action.city_code
  let bil_list = action.payload.bil_list

  //id e city code degli impianti [{id:id, city_code:city_code, pricing_id: pricing_id}]
  // let bil_list = [] as any //da sostituire con action.payload....
  bil_list.map((el: any) => {
    el.pricing_id = uuidv4()
  })
  // console.log("bil_list", bil_list)

  let price_rule = action.payload.price_rule
  price_rule.from = moment(price_rule.from)
  price_rule.to = moment(price_rule.to)
  if (price_rule.amount_1 == "") {
    price_rule.amount_1 = 0
  }
  // console.log("price rule", price_rule)
  try {
    const result = yield call(ContentApi.putPricing, bil_list, price_rule);
    console.log("result putpricing", result)
    yield put(NotificationActions.willShowNotification({ message: "Regola di pricing impostata correttamente", type: "success" }));
    yield put(push("/"))
  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
  yield put(ContentActions.didEmptyBillboards());
  yield put(ContentActions.willListMyBillboards())
  yield put(ContentActions.didGetBillboardPricing({ pricing: {} }))

}


function* willFetchMoreFirstBillboards(action: any) {
  console.log('in sagafetch')
  if (action.payload.fetchMore) {
    yield put(ContentActions.didFetchMoreFirstBillboards())

    yield put(ContentActions.didSetSortColumnValue())
    if (!_.isEmpty(action.payload.filters)) {
      console.log('in sagafetch filtro')

      yield put(ContentActions.willFilterBillboards({ filters: action.payload.filters }))
    }
  }
}


function* willFilterBillboards(action: any) {
  console.log('in saga filter', action)

  const conditions = action.payload.filters
  const billboards = action.payload.myBillboards
  let availabilities = [] as any
  let filteredBillboards = [] as any
  if (conditions.availabilities) {
    if (!_.isEmpty(conditions.availabilities.start) && !_.isEmpty(conditions.availabilities.stop)) {
      let range = {
        start: moment(conditions.availabilities.start).subtract(13, 'days'),
        stop: moment(conditions.availabilities.stop)
      }
      console.log("in saga filter 2:", range)
      try {
        availabilities = yield call(ContentApi.listBillboardsAvailablesByRange, range);
      } catch (error: any) {
        console.log('Errore in query disponibilita per filtro ', error);
      }
    }
  }

  // console.log('disponibilita post query?', availabilities)
  try {
    filteredBillboards = yield call(ContentApi.filterApi, conditions, billboards, availabilities);
  } catch (error: any) {
    console.log('Errore in filtro ', error);
  }

  if (_.isEmpty(conditions)) {
    console.log('svuota filtro e rimetti gli impianti precedenti')
    filteredBillboards = action.payload.myBillboards
  }

  // filter = filter.sortBy('typology')
  console.log('risultato filtro ', filteredBillboards)
  yield put(ContentActions.didFilterBillboards({ filteredBillboards: filteredBillboards, filters: conditions }))

}


function* willListBillboardAvailabilities(action: any) {
  console.log('in saga list availabilities', action)
  const billboard_id = action.payload.billboard_id

  try {
    const result = yield call(ContentApi.listBillboardAvailabilities, billboard_id);
    // console.log("result availabilities", result)
    yield put(ContentActions.didListBillboardAvailabilities({ availabilities: result.data.listBillboardAvailability }))

  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}


function* willListBillboardAvailabilityByRange(action: any) {
  console.log('in saga list availability by range', action)
  const billboard_id = action.payload.billboard_id
  const start_date = action.payload.start_date
  const stop_date = action.payload.stop_date
  // let start= "2021-04-24"     // 10 aprile
  // let stop= "2021-04-30"
  // console.log("in saga list availability by range 2", start_date, stop_date)

  let range = {
    start: moment(start_date).subtract(13, 'days'),
    stop: moment(stop_date)
  }
  // console.log("in saga list availability by range 3", range,billboard_id)

  try {
    const result = yield call(ContentApi.listBillboardAvailabilityByRange, billboard_id, range);
    console.log("result availability by range", result)
    yield put(ContentActions.didListBillboardAvailabilityByRange({ availabilitiesByRange: result.data.listBillboardAvailabilityByRange }))

  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}

function* willGetBillboardPricing(action: any) {
  console.log('in saga willGetBillboardPricing', action)
  const pricing_id = action.payload.pricing_id

  try {
    const result = yield call(ContentApi.getBillboardPricing, pricing_id);
    console.log("result willGetBillboardPricing", result)
    yield put(ContentActions.didGetBillboardPricing({ pricing: result }))

  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}

function* willEditPricing(action: any) {
  console.log('in saga willEditPricing', action)
  try {
    const result = yield call(ContentApi.updatePricing, action.payload.pricing_id, action.payload.pricing_data);
    console.log("result willEditPricing", result)
    // yield put(ContentActions.didGetBillboardPricing({ pricing: result }))
    yield put(NotificationActions.willShowNotification({ message: "Regola di pricing modificata correttamente", type: "success" }));
    //aggiungere redirect ad home FORSE
  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}

function* willSelectOrder(action: any) {
  console.log('in willSelectOrder saga')
  // yield put(ContentActions.didGetSelectOrder(action.payload))
}

function* willSetMaintenance(action: any) {
  console.log("in willSetMaintenance saga", action.payload)
  const billboards = action.payload.billboards
  const date = action.payload.date
  const is_maintenance = action.payload.is_maintenance
  // let ids = [] as any

  // if (billboards.length > 0) {
  //   ids = _.map(billboards, (o: any) => { return { id: o.id, city_code: o.city_code } })
  // } else { ids = billboards.id }
  //  else { ids = { id: billboards.id, city_code: billboards.city_code } }
  // console.log("in willSetMaintenance saga: ids", ids)

  let moments = _.map(date, (o: any) => { return moment(o) }),
    last_maintenance = moment.max(moments)

  if (billboards.length > 1) {
    try {
      yield put(UiActions.startActivityRunning("confirmMaintenance"));
      const result = yield call(ContentApi.batchAddBillboardAvailability, billboards, moment(last_maintenance).add(13, 'days'), moments, is_maintenance)
      console.log("result willSetMaintenance multy", result)
      yield put(NotificationActions.willShowNotification({ message: "Modifica impostata correttamente", type: "success" }));
    } catch (error: any) {
      console.log('Error ', error);
      yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
    }
  } else {
    /// qui arrivano ids e city_code undefined, ma billboards ha i dati. Se li passi diretti non arrivano
    let ids = billboards.id
    let city_code = billboards.city_code
    console.log("in willSetMaintenance saga 2", billboards)
    try {
      yield put(UiActions.startActivityRunning("confirmMaintenance"));
      const result = yield call(ContentApi.addBillboardAvailability, ids, moment(last_maintenance).add(13, 'days'), moments, city_code, is_maintenance)
      console.log("result willSetMaintenance mono", result)
      yield put(NotificationActions.willShowNotification({ message: "Modifica impostata correttamente", type: "success" }));
    } catch (error: any) {
      console.log('Error ', error);
      yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
    }
  }
  yield put(UiActions.stopActivityRunning("confirmMaintenance"));

}

function* willListOrders(action: any) {
  console.log('in saga willListOrders', action)

  if (action.payload === "empty") {
    action.payload = null
  }

  try {
    const result = yield call(ContentApi.listMyOrders, action.payload);
    console.log("result willListOrders", result.data)
    if (result.data.listMyOrders.nextToken) {
      yield put(ContentActions.didListOrders(result.data.listMyOrders))
      yield put(ContentActions.willListOrders(result.data.listMyOrders.nextToken))
    } else {
      yield put(ContentActions.didListOrders(result.data.listMyOrders))
    }
  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}

function* willGetOrderBillboards(action: any) {
  console.log('in saga willGetOrderBillboards')
  let billboards = action.payload.billboards
  let orderBillboards = action.payload.orderBillboards

  let billboardsFind = [] as any

  _.map(orderBillboards, (o: any) => {
    _.map(billboards, (i: any) => {
      if (o.billboard_id === i.id) {
        billboardsFind.push(i)
      }
    })
  })

  yield put(ContentActions.didSetOrderBillboards(billboardsFind))
  // console.log("in saga willGetOrderBillboards with billboardsFind", billboardsFind)
}

function* willGetOrder(action: any) {
  console.log('in saga willGetOrder', action)
  let order_id = action.payload.order_id
  let campaign_id = action.payload.campaign_id

  try {
    const result = yield call(ContentApi.getOrder, campaign_id, order_id);
    console.log("result willGetOrder", result)
    yield put(ContentActions.didGetOrder({ order: result }))
  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}

function* willListPlantsAvailabilities(action: any) {
  console.log('in saga willListPlantsAvailabilities', action)
  const ids = action.payload
  try {
    const result = yield call(ContentApi.listBillboardsAvailabilities, ids);
    console.log("result willListPlantsAvailabilities", result)
    if (_.isEmpty(result)) {
      yield put(ContentActions.didListBillboardAvailabilities({ availabilities: ["empty"] }))
    } else {
      yield put(ContentActions.didListBillboardAvailabilities({ availabilities: result }))
    }
  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}

function* willUpdateOrderStatus(action: any) {
  console.log('in saga willUpdateOrderStatus', action)

  let id = action.payload.id
  let campaign_id = action.payload.campaign_id
  let status = action.payload.action /// questo lo lascio status perchè action faceva conflitto con quello di redux

  yield put(UiActions.didSetActionDone({ done: null, action: "" }));

  try {
    const result = yield call(ContentApi.updateOrderStatus, campaign_id, id, status);
    console.log("result willUpdateOrderStatus", result)
    yield put(NotificationActions.willShowNotification({ message: "Stato dell'ordine aggiornato correttamente", type: "success" }));
    // yield put(ContentActions.willListOrders())
    yield put(UiActions.didSetActionDone({ done: true, action: "orderStatus" }));

  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
    yield put(UiActions.didSetActionDone({ done: null, action: "" }));
  }
}

function* willFilterOrders(action: any) {
  console.log('in saga filter orders', action)

  const conditions = action.payload.filters
  const orders = action.payload.orders
  let filteredOrders = [] as any

  try {
    filteredOrders = yield call(ContentApi.filterApiOrders, conditions, orders);
  } catch (error: any) {
    console.log('Errore in filtro ', error);
  }

  if (_.isEmpty(conditions)) {
    console.log('svuota filtro e rimetti gli ordini precedenti')
    filteredOrders = action.payload.orders
  }

  // filter = filter.sortBy('typology')
  console.log('risultato filtro ', filteredOrders)
  yield put(ContentActions.didFilterOrders({ filteredOrders: filteredOrders, filters: conditions }))

}

function* willDeleteBillboardAvailabilities(action: any) {
  console.log('in saga willDeleteBillboardAvailabilities', action)
  const dates = action.payload.date
  const input = action.payload.input

  try {
    yield call(ContentApi.batchDeleteBillboardAvailabilities, dates, input);
    yield put(NotificationActions.willShowNotification({ message: "Modifiche impostate correttamente", type: "success" }));
  } catch (error: any) {
    console.log('Errore in filtro ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }

}

function* willUploadOrderImage(action: any) {
  console.log('in saga willUploadOrderImage', action)

  let imageArray = action.payload.images
  let order_id = action.payload.order_id
  let campaign = action.payload.campaign

  yield put(UiActions.startActivityRunning("confermaAggiuntaFoto"));

  for (let i = 0; i < imageArray.length; i++) {
    let id = uuidv4()
    let upload_date = moment()
    const url = yield call(ContentApi.getUploadOrderImageUrl, id, order_id, imageArray[i].file.type)
    if (url) {
      const result = yield call(ContentApi.uploadOrderImage, url, imageArray[i])
      console.log("uploadOrderImage result", result)
      if (result.status === 200) {
        yield call(ContentApi.addOrderImage, id, order_id, campaign, upload_date, imageArray[i].caption)
      }
    }
  }
  yield put(UiActions.stopActivityRunning("confermaAggiuntaFoto"));
  yield put(ContentActions.willListOrderImages({ order_id: order_id }))
  yield put(NotificationActions.willShowNotification({ message: "Foto caricate correttamente", type: "success" }));

}

// function* willGetOrderImagesUrl(action: any) {
//   console.log('in saga willGetOrderImagesUrl', action)

//   try {
//     const result = yield call(ContentApi.getUploadOrderImageUrl, action.payload);
//     yield put(ContentActions.didGetOrderImagesUrl(result.data.getUploadOrderImageUrl))
//   } catch (error: any) {
//     console.log('Errore in willGetOrderImagesUrl ', error);
//   }

// }

function* willListOrderImages(action: any) {
  console.log('in saga willListOrderImages', action)

  try {
    const result = yield call(ContentApi.listOrderImages, action.payload.order_id, action.payload.nextToken);
    console.log("result willListOrderImages", result.data)
    if (result.data.listOrderImages.nextToken) {
      yield put(ContentActions.didListOrderImages(result.data.listOrderImages))
      yield put(ContentActions.willListOrderImages({ order_id: result.data.listOrderImages.items.order, nextToken: result.data.listOrderImages.nextToken }))
    } else {
      yield put(ContentActions.didListOrderImages(result.data.listOrderImages))
    }
  } catch (error: any) {
    console.log('Error ', error);
  }

}

function* willGetOrderContract(action: any) {
  console.log('in saga willGetOrderContract', action)

  yield put(UiActions.didSetActionDone({ done: true, action: "orderContract" }))

  try {
    // const result = yield call(ContentApi.buildPdfOrderContract, action.payload.campaign, action.payload.id);
    // console.log("result willGetOrderContract", result.data.buildPdfOrderContract)
    // if (result.data.buildPdfOrderContract) {
    const result = yield call(ContentApi.getResourceUrl, action.payload.id);
    console.log("result willGetOrderContract getting resource url", result.data.getResourceUrl)
    window.open(`${result.data.getResourceUrl}`)
    // }
    // yield put(ContentActions.didListOrderImages(result.data.listOrderImages))
  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }

  yield put(UiActions.didSetActionDone({ done: null, action: "" }))

  // window.open("")

}

function* willDeleteBillboard(action: any) {
  // console.log('in saga willDeleteBillboard', action)

  yield put(UiActions.startActivityRunning("willDeleteBillboard"));

  const id = action.payload.id
  const city_code = action.payload.city_code

  try {
    const result = yield call(ContentApi.deleteBillboard, id, city_code);
    console.log("result willDeleteBillboard", result.data.deleteBillboard)

    yield put(ContentActions.willListMyBillboards())
    yield put(UiActions.switchCloseAddNewPlant())
    yield put(NotificationActions.willShowNotification({ message: "Impianto eliminato correttamente", type: "success" }));
  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }

  yield put(UiActions.stopActivityRunning("willDeleteBillboard"));
}