import React, { useEffect, useRef, useState } from 'react';
import { Paper, Grid } from '@material-ui/core';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import moment from 'moment';
import 'moment/locale/it';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ExploreActions, selectors as ExploreSelectors } from '../../store/slices/explore';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { mapCssStyle, useStyles } from '../Map.css';
import Icon from "../IconsComponent";
import useSupercluster from 'use-supercluster'
import _ from 'lodash';
import { customMarker } from '../CustomMarker';

const Marker = ({ children }: any) => children;
const geolib = require('geolib');

export const ExploreMap = (props: any) => {
  const { step, setAnchorEl, setPlantProps } = props
  
  const dispatch = useDispatch()
  const classes = useStyles()

  let myBillboardsInMap = useSelector(ExploreSelectors.listMyBillboards)
  let coordinates = useSelector(ExploreSelectors.getExploreBillboardCoordinates)
  let selectedBillboards = useSelector(ExploreSelectors.listExploreAllBillboardsInMap)
  let selectedBillboardId = useSelector(UiSelectors.getSelectedBillboardId)

  if (_.isEmpty(coordinates)) {
    coordinates = [{ lat: 45, lng: 9 }, { lat: 39.4, lng: 9.09 }]
    // console.log('true', coordinates)
  }

  let bounds1 = geolib.getBounds(coordinates);
  let size = {
    width: 1200,
    height: 600
  }
  if (document.getElementById("mappa")) {
    size = {
      width: document.getElementById("mappa")!.offsetWidth,
      height: document.getElementById("mappa")!.offsetHeight,
    }
  }
  bounds1 = {
    ne: {
      lat: bounds1.maxLat,
      lng: bounds1.maxLng
    },
    sw: {
      lat: bounds1.minLat,
      lng: bounds1.minLng
    }
  };

  const { center, zoom } = fitBounds(bounds1, size);

  const objectPlants = _.keyBy(myBillboardsInMap, 'id');

  const [bounds, setBound] = useState([] as any)
  const [zoom1, setZoom] = useState(10 as any)

  let points = myBillboardsInMap.map((bill: any) => ({
    type: "Feature",
    properties: {
      cluster: false,
      "cluster_id": bill.id,
      "point_count": bill.id,
      "is_selected": bill.id in selectedBillboards //controllo se l impianto e presente tra gli impianti selezionati
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(bill.longitude),
        parseFloat(bill.latitude)
      ]
    }
  }));
  
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: zoom1,
    options: { radius: 75, maxZoom: 20 }
  });

  return (
    <>
      <Grid className={classes.foundedPlantsWidget} container>
        <Grid container item xs={9} alignItems="center" justify="center">{step === "Pianifica campagna" ? "Impianti selezionati" : "Impianti trovati"}</Grid>
        <Grid item xs={3}><b className="ml-3" >{step === "Pianifica campagna" ? `+ ${_.size(selectedBillboards)}` : points.length}</b></Grid>
      </Grid>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyAMhpfSL4SkLCu2kSu-8oXXfTRtxddzYcE",
          libraries: ['places', 'geometry', 'drawing', 'visualization']
        }}
        defaultCenter={center}
        center={center}
        defaultZoom={zoom}
        zoom={zoom}
        options={{ fullscreenControl: false, styles: mapCssStyle }}
        layerTypes={['TrafficLayer', 'TransitLayer']}
        onChange={({ zoom, bounds }) => {
          // setTimeout(function () { onChangeMarginBounds(bounds) }, 500)
          setZoom(zoom);
          setBound([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {

          map.streetViewControl = true;
          map.mapTypeControl = true;
        }}
      // onChange={({ bounds, marginBounds }) => {
      //     setTimeout(function () { onChangeMarginBounds(bounds) }, 500)
      // }}
      >
        {clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount
          } = cluster.properties;
          //console.log("in render:", pointCount, isCluster, cluster)
          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  className="super-cluster"
                  style={{
                    width: 20 + (pointCount / points.length) * 30,
                    height: 20 + (pointCount / points.length) * 30,
                  }}
                  onClick={() => { }}
                >
                  {pointCount}
                </div>
              </Marker>
            );
          }
          
          return (
            <Marker
              key={`crime-${cluster.properties.cluster_id}`}
              lat={latitude}
              lng={longitude}
            >
              <div
                id={`crime-${cluster.properties.cluster_id}`}
                style={{
                  width: 15,
                  height: 15,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  dispatch(UiActions.setSelectedBillboardId(cluster.properties.cluster_id))
                  setPlantProps(objectPlants[cluster.properties.cluster_id])
                  setAnchorEl(document.getElementById("listInMap"))
                }}
              >
                {customMarker(objectPlants[cluster.properties.cluster_id], selectedBillboardId)}
              </div>
            </Marker>
          );
        })}
      </GoogleMapReact>
    </>
  )
}