import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { loader } from 'graphql.macro';
import { configuration } from '../config';
import raw from "raw.macro";
import moment from 'moment';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
const axios = require('axios');
const comuni = require('../assets/comuni.json')

var Velocity = require('velocityjs');
const util = require('util');
require('util.promisify').shim();
const fs = require('fs');

export const configure = () => {
  const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"
  Amplify.configure(configuration[stage]);
}
export const listBillboards = async (media_owner: string, nextToken: string) => {
  // console.log("in api", media_owner, nextToken)
  const query = loader('../graphql/listBillboardsByOwner.gql');
  // console.log('with query: ', query)
  try {
    const result: any = await API.graphql({ query: query, variables: { media_owner: media_owner, limit: 20, nextToken: nextToken } });
    return result
  } catch (error) {
    console.log("errore api")
    throw error
  }
}

export const addBatchBillboard = async (batchBillboard: any) => {
  // console.log('with addBatchBillboard api: ', batchBillboard)

  const randomDate = (start: any, end: any) => {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  }

  const locationsArray: string[] = ["Centro Commerciale", "Stazione", "Aereoporto", "Altro"]

  let arrayToBatch = [] as any
  let countErrors = 0
  batchBillboard.splice(-1, 1)

  batchBillboard.map((element: any) => {

    const requiredToUploadOutdoor =
      element &&
      element['Tipologia Concessionaria *'] &&
      element['Classe *'] &&
      element['Comune *'] &&
      element['Indirizzo *'] &&
      element['Latitudine *'] &&
      element['Longitudine *'] &&
      element['Tipologia *'] &&
      element['Base (cm) *'] &&
      element['Altezza (cm) *'] &&
      element['Illuminazione *'] &&
      element['Orientamento *'] &&
      element['Prezzo *']

    const requiredToUploadIndoor =
      element &&
      element['Tipologia Concessionaria *'] &&
      element['Classe *'] &&
      element['Comune *'] &&
      element['Latitudine *'] &&
      element['Longitudine *'] &&
      element['Tipologia *'] &&
      element['Base (cm) *'] &&
      element['Altezza (cm) *'] &&
      element['Prezzo *']

    let requiredToUpload: any
    if (element['Classe *'] === 'Indoor') {
      requiredToUpload = requiredToUploadIndoor
    } else {
      requiredToUpload = requiredToUploadOutdoor
    }

    if (requiredToUpload) {
      element.billboard_input = {}

      const comuneImpianto: string = element['Comune *']

      let nomeComune = _.startCase(_.toLower(_.deburr(comuneImpianto.replace(/[^a-z0-9 ,.?!]/ig, ' '))))

      let obj = {
        city_code: "",
        billboard_input: {} as any
      }
      obj.city_code = _.find(comuni, { "name": nomeComune }).cod

      if (obj.city_code) {
        if (element['Tipologia Concessionaria *']) { obj.billboard_input.property_type = element['Tipologia Concessionaria *'] }
        if (element['Comune *']) { obj.billboard_input.municipality = _.startCase(_.toLower(element['Comune *'])) }
        if (element['Indirizzo *']) {
          obj.billboard_input.address = _.startCase(_.toLower(element['Indirizzo *']))
        } else {
          obj.billboard_input.address = ''
        }
        if (element['Latitudine *']) { obj.billboard_input.latitude = parseFloat((element['Latitudine *']).replace(/,/, '.')) }
        if (element['Longitudine *']) { obj.billboard_input.longitude = parseFloat((element['Longitudine *']).replace(/,/, '.')) }
        if (element['Tipologia *']) { obj.billboard_input.media_type = element['Tipologia *'] }
        if (element['Base (cm) *']) { obj.billboard_input.base = parseFloat(element['Base (cm) *']) }
        if (element['Altezza (cm) *']) { obj.billboard_input.height = parseFloat(element['Altezza (cm) *']) }
        if (element['Formato *']) { obj.billboard_input.format = element['Formato *'] } else {
          obj.billboard_input.format = `${element['Base (cm) *']}x${element['Altezza (cm) *']}`
        }
        if (element['Superficie (cmq)']) { obj.billboard_input.size = element['Superficie (cmq)'] }
        if (element['Numero fogli']) { obj.billboard_input.n_sheet = parseInt(element['Numero fogli']) }
        if (element['Altezza da terra *']) { obj.billboard_input.height_ground = element['Altezza da terra *'] }
        if (element['Illuminazione *']) { obj.billboard_input.lighting = element['Illuminazione *'] } else {
          obj.billboard_input.lighting = ''
        }
        if (element['Orientamento *']) { obj.billboard_input.orientation = element['Orientamento *'] } else {
          obj.billboard_input.orientation = ''
        }
        if (element['Codice Cimasa']) {
          obj.billboard_input.cod_cimasa = element['Codice Cimasa']
        } else {
          if (element['Codice']) {
            obj.billboard_input.cod_cimasa = element['Codice']
          } else {
            obj.billboard_input.cod_cimasa = ''
          }
        }
        // if (element['Codice']) { obj.billboard_input.cod_cimasa = element['Codice'] } else {
        //   obj.billboard_input.cod_cimasa = ''
        // }
        if (element['Ultima manutenzione']) {
          obj.billboard_input.last_maintenance = moment(element['Ultima manutenzione'], 'DD/MM/YYYY')
        } else {
          obj.billboard_input.last_maintenance = moment(randomDate(new Date(moment().year(), 0, 1), new Date()))
        }
        if (element['Facciate']) {
          obj.billboard_input.facade = element['Facciate']
        } else {
          obj.billboard_input.facade = 'Non selezionate'
        }
        if (element['Ultimo pagamento tributi']) {
          obj.billboard_input.taxes = { municipal_tax: _.random(20, 500), last_payment: moment(element['Ultimo pagamento tributi'], 'DD/MM/YYYY') }
        } else {
          obj.billboard_input.taxes = { municipal_tax: _.random(20, 500), last_payment: moment(randomDate(new Date(moment().year(), 0, 1), new Date())) }
        }
        // console.log('data non valida?', element.billboard_input.taxes)
        if (element['Prezzo *']) {
          obj.billboard_input.price = parseFloat((element['Prezzo *']).replace(/,/g, '.').replace(/€|\s/g, ''))
        } else {
          { obj.billboard_input.price = 0 }
        }
        //// aggiunti in seguito
        if (element['Numero autorizzazione SCIA']) {
          obj.billboard_input.n_authorization = element['Numero autorizzazione SCIA']
        } else {
          obj.billboard_input.n_authorization = /* _.random(20, 500) */ 0
        }
        if (element['Data rilascio SCIA']) {
          obj.billboard_input.authorization_expiration = moment(element['Data rilascio SCIA'], 'DD/MM/YYYY')
        } else {
          obj.billboard_input.authorization_expiration = moment(randomDate(new Date(moment().year(), 0, 1), new Date()))
        }
        if (element['Stato']) { obj.billboard_input.status = element['Stato'] }
        if (element['Classe *']) { obj.billboard_input.billboard_class = element['Classe *'] }
        if (element['Note']) { obj.billboard_input.notes = element['Note'] }
        if (element['NOTE']) { obj.billboard_input.notes = element['NOTE'] }
        if (element['Tipo Contenuto']) { obj.billboard_input.press_type = element['Tipo Contenuto'] }
        if (element['Uscita Minima']) { obj.billboard_input.min_period = element['Uscita Minima'] }
        if (element['Ubicazione']) {
          if (!locationsArray.includes(element['Ubicazione'])) {
            obj.billboard_input.location = 'Altro'
            obj.billboard_input.other_location = element['Ubicazione']
          } else {
            obj.billboard_input.location = element['Ubicazione']
          }
          if (element['Classe *'] === 'Indoor') {
            obj.billboard_input.location_name = element['Nome Ubicazione *']
          }

        }
        // if (element['Note']) { obj.billboard_input.notes = element['Note'] }
        console.log("obj.billboard_input", obj.billboard_input)


        obj.billboard_input.creation_date = moment()
        // if (element['Prezzo *']) { element.billboard_input.price = _.random(20, 500) } 
        arrayToBatch.push(obj)

        delete element['Tipologia Concessionaria *']
        delete element.Tipo
        delete element.Dimensione
        delete element['Prezzo *']
        delete element['Ultimo pagamento tributi']
        delete element['Ultima manutenzione']
        delete element['Codice Cimasa']
        delete element['Codice']
        delete element['Orientamento *']
        delete element['Superficie (cmq)']
        delete element['Altezza da terra *']
        delete element.Formato
        delete element['Tipologia *']
        delete element['Tipologia impianto']
        delete element['Latitudine *']
        delete element['Longitudine *']
        delete element['Indirizzo *']
        delete element['Comune *']
        delete element.Concessionario
        delete element['Altezza (cm) *']
        delete element['Base (cm) *']
        delete element['Illuminazione *']
        delete element['Numero fogli']
        delete element['Codice Comune']
        /// aggiunti in seguito
        delete element['Data rilascio SCIA']
        delete element['Numero autorizzazione SCIA']
        delete element['Stato']
        delete element['Classe *']
        delete element['Tipo Contenuto']
        delete element['Facciate']
        delete element['Uscita Minima']
        if (element['Ubicazione']) {
          delete element['Ubicazione']
          if (element['Classe *'] === 'Indoor') {
            delete element['Nome Ubicazione *']
          }
        }
        delete element['Note']
        delete element['NOTE']
        return element
      } else {
        countErrors++
      }
    } else {
      console.log('impianto non valido, lo elimino dalla lista')
      countErrors++
    }
  })

  const query = loader('../graphql/addBatchBillboard.gql');

  let tempArray = [] as any
  let result_batch = [] as any
  // console.log('in willAddBatchBillboards tempArray', tempArray, result_batch)

  for (var i = 0; i < arrayToBatch.length; i += 25) {
    tempArray = arrayToBatch.slice(i, i + 25)
    // tempArray = _.remove(tempArray, (el: any) => { return el != undefined })
    // console.log("in for temparray", tempArray)
    try {
      // console.log("in willAddBatchBillboards 3")
      const result: any = await API.graphql({ query: query, variables: { batchBillboard: tempArray } });
      _.map(result.data.batchAddBillboard, function (o: any) { result_batch.push(o) })
    } catch (error) {
      console.log("errore api", error)
      throw error
    }
  }
  return { result_batch, countErrors }
}


export const listMyBillboards = async (nextToken: string) => {
  // console.log("in api", media_owner, nextToken)
  const query = loader('../graphql/listMyBillboards.gql');
  // console.log('with query: ', query)
  try {
    const result: any = await API.graphql({ query: query, variables: { limit: 1001, nextToken: nextToken } });
    return result
  } catch (error) {
    console.log("errore api")
    throw error
  }
}

export const listBillboardsNumberInMap = async (position: any) => {
  // console.log("in api", media_owner, nextToken)
  const query = loader('../graphql/listBillboardsNumberInMap.gql');
  // console.log('with query: ', query)
  try {
    const result: any = await API.graphql({ query: query, variables: { lat_bot_rigth: position.bot_rigth.lat, lat_top_left: position.top_left.lat, lon_bot_rigth: position.bot_rigth.lon, lon_top_left: position.top_left.lon } });
    return result
  } catch (error) {
    console.log("errore api")
    throw error
  }
}

export const listBillboardsInMap = async (position: any) => {
  // console.log("in api", media_owner, nextToken)
  const query = loader('../graphql/listBillboardsInMap.gql');
  // console.log('with query: ', query)
  try {
    const result: any = await API.graphql({ query: query, variables: { lat_bot_rigth: position.bot_rigth.lat, lat_top_left: position.top_left.lat, lon_bot_rigth: position.bot_rigth.lon, lon_top_left: position.top_left.lon } });
    return result
  } catch (error) {
    console.log("errore api")
    throw error
  }
}

export const getBatchBillboard = async (data: any) => {
  // console.log("in api", media_owner, nextToken)
  const query = loader('../graphql/getBatchBillboard.gql');
  // console.log('with query: ', query)
  try {
    const result: any = await API.graphql({ query: query, variables: { input: data } });
    return result
  } catch (error) {
    console.log("errore api")
    throw error
  }
}


export const getSignedUrl = async (id: string) => {
  console.log("in api", id)
  const query = loader('../graphql/getUploadUrl.gql');
  // console.log('with query: ', query)
  try {
    const result: any = await API.graphql({ query: query, variables: { billboardId: id } });
    return result
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const getSignedCSVUrl = async (type: string, name: string) => {
  const query = loader('../graphql/getUploadCSVUrl.gql');
  // console.log('with query: ', query)
  try {
    const result: any = await API.graphql({ query: query, variables: { type: type, name: name } });
    return result
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const addBillboard = async (id: String, city_code: String, input: any) => {
  const query = loader('../graphql/addBillboard.gql');
  console.log('with query addBillboard: ', input)
  // const array: never[]= []
  try {
    const result: any = await API.graphql({ query: query, variables: { id: id, city_code: city_code, input: input } });
    return result
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const putPricing = async (bil_list: any, price_rule: any) => {
  // const query = loader('../graphql/addBillboard.gql');
  // console.log('with query in putPricing: ', query)
  // const array: never[]= []
  try {
    const vtl = raw("../graphql/vtl/setBillboardsPricing.vtl")
    const query = Velocity.render(vtl, {
      // context: { bil_list: bil_list, price_rule: price_rule },
      context: {
        bil_list: bil_list,
        amount_1: price_rule.amount_1,
        condition_1: price_rule.condition_1,
        customers: price_rule.customers,     //customers potrebbero dare errori nel vtl
        percentage: price_rule.percentage,
        rule_type: price_rule.rule_type,
        from: price_rule.from,
        to: price_rule.to
      },
      util: { stringify: (obj: any) => JSON.stringify(obj) }
    }, {});

    const id = raw("../graphql/vtl/bil_list.vtl")
    let data = Velocity.render(id, {
      // context: { bil_list: bil_list, price_rule: price_rule },
      context: { bil_list: bil_list },
      util: { stringify: (obj: any) => JSON.stringify(obj) }
    }, {});
    data = JSON.parse(data)
    console.log('result vtl', query, data)
    const result: any = await API.graphql({ query: query, variables: { ...data, ...price_rule } });
    console.log('result ', result)
    return result
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}



export const listBillboardAvailabilities = async (billboard_id: String) => {
  const query = loader('../graphql/listBillboardAvailability.gql');
  try {
    const result: any = await API.graphql({ query: query, variables: { billboard_id: billboard_id } });
    // console.log("listBillboardAvailabilities api:", result)
    return result
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const listBillboardAvailabilityByRange = async (billboard_id: String, range: any) => {
  const query = loader('../graphql/listBillboardAvailabilityByRange.gql');
  // console.log('with query: ', query, batchBillboard)
  // const array: never[]= []
  try {
    const result: any = await API.graphql({ query: query, variables: { billboard_id: billboard_id, start_date: range.start, stop_date: range.stop } });
    return result
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const listBillboardsAvailablesByRange = async (range: any) => {
  const query = loader('../graphql/listBillboardsAvailablesByRange.gql');
  console.log('with query: ', range)
  // const array: never[]= []
  try {
    const result: any = await API.graphql({ query: query, variables: { start_date: range.start, stop_date: range.stop } });
    return result.data.listBillboardsAvailablesByRange
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}


export const filterApi = async (conditions: any, billboards: any, availabilities: any) => {
  console.log('filter api', conditions)
  try {
    var filter: any = _.chain(billboards)
    if (!_.isEmpty(conditions)) {
      if (!_.isEmpty(conditions.media_type.value)) {
        console.log('filter with typology')
        filter = filter.filter(function (o: any) { return conditions.media_type.value.includes(o.media_type) })
      }
      if (!_.isEmpty(conditions.area.value)) {
        console.log('filter with area')
        filter = filter.filter(function (o: any) { return conditions.area.value.includes(o.municipality) }) //area é intesa come comune?
      }
      if (!_.isEmpty(conditions.lighting.value)) {
        console.log('filter with lighting')
        filter = filter.filter(function (o: any) { return conditions.lighting.value.includes(o.lighting) })
      }
      if (!_.isEmpty(conditions.orientation.value)) {
        console.log('filter with orientation')
        filter = filter.filter(function (o: any) { return conditions.orientation.value.includes(o.orientation) })
      }
      if (conditions.billalo_index.start || conditions.billalo_index.start === 0) { // da verificare
        console.log('filter with billalo index')
        filter = filter.filter(function (o: any) {
          if (o.billalo_index) {
            return _.inRange(o.billalo_index.iV_municipality * 10, conditions.billalo_index.start, conditions.billalo_index.stop)
          } //verificare
        })
      }
      if (!_.isEmpty(conditions.format.value)) {
        let condition = conditions.format.value
        console.log('filter with format', conditions.format.value)
        filter = filter.filter(function (o: any) {
          if (conditions.format.value.length > 1) {
            let cond = false
            _.forEach(conditions.format.value, (i: any) => {
              if (i === "Altro") {
                if (((o.height != 300 || o.base != 1200) && (o.height != 300 || o.base != 600) && (o.height != 300 || o.base != 400) && (o.height != 200 || o.base != 180) && (o.height != 140 || o.base != 200) && (o.height != 200 || o.base != 140) && (o.height != 140 || o.base != 100) && (o.height != 100 || o.base != 70)) === true) {
                  cond = true
                  return false
                }
              } else {
                if ((o.height == i.height && o.base == i.base) === true) {
                  cond = true
                  return false
                }
              }
            })
            return cond
          } else {
            if (condition[0] === "Altro") {
              return ((o.height != 300 || o.base != 1200) && (o.height != 300 || o.base != 600) && (o.height != 300 || o.base != 400) && (o.height != 200 || o.base != 180) && (o.height != 140 || o.base != 200) && (o.height != 200 || o.base != 140) && (o.height != 140 || o.base != 100) && (o.height != 100 || o.base != 70))
            } else {
              return (o.height == condition[0].height && o.base == condition[0].base)
            }
          }
        })
      }
      if (!_.isEmpty(conditions.height_ground.value)) {
        console.log('filter with height_ground')
        filter = filter.filter(function (o: any) { return conditions.height_ground.value.includes(o.height_ground) })
      }

      // filter=filter.find(function(o:any){return o.billalo_index==null})
      console.log('reduce:::::', filter.value())
      if (!_.isEmpty(conditions.availabilities.start) && !_.isEmpty(conditions.availabilities.stop)) {
        console.log('filter with availabilities', conditions.availabilities.start, conditions.availabilities.stop)
        let range = {
          start: moment(conditions.availabilities.start).subtract(13, 'days'),
          stop: moment(conditions.availabilities.stop)
        }
        console.log('filter with availabilities', range)
        //Get from graphql
        // const availabilities = yield call(ContentApi.listBillboardsAvailablesByRange, range);
        // const availabilities = [
        //   { id: 1, start_busy_date: "2021-01-05", stop_busy_date: "2021-01-18" },
        //   { id: 1, start_busy_date: "2021-01-19", stop_busy_date: "2021-02-02" }
        // ]
        console.log("disponibilita dopo query", availabilities)
        filter = filter[conditions.availabilities.available ? "differenceWith" : "intersectionWith"](availabilities, function (o: any, d: any) {
          // for(availability of d){
          const availability = d;
          if (availability.billboard_id === o.id) {
            // console.log('matching ', conditions.availabilities.start <= moment(availability.start_busy_date).format('YYYY-MM-DD'),
            //   availability.billboard_id,
            //   conditions.availabilities.stop > moment(availability.start_busy_date).add(13, 'days').format('YYYY-MM-DD'))
            if (conditions.availabilities.start <= moment(availability.start_busy_date).format('YYYY-MM-DD') &&
              conditions.availabilities.stop > moment(availability.start_busy_date).add(13, 'days').format('YYYY-MM-DD')) {
              return true
            }
          }
        })
      }
    }
    filter = filter.value()
    return filter
  } catch (error) {
    console.log("errore api filter", error)
    throw error
  }
}

export const getBillboardPricing = async (pricing_id: String) => {
  const query = loader('../graphql/getBillboardPricing.gql');
  console.log('with query: ', pricing_id)
  try {
    const result: any = await API.graphql({ query: query, variables: { id: pricing_id } });
    return result.data.getBillboardPricing
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const updatePricing = async (pricing_id: String, pricing_data: any) => {
  const query = loader('../graphql/updatePricing.gql');
  // console.log('with query: ', pricing_id)
  try {
    const result: any = await API.graphql({ query: query, variables: { id: pricing_id, pricing_data: pricing_data } });
    return result.data.updatePricing
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const addBillboardAvailability = async (id: any, last_maintenance: any, dates: any, city_code: any, is_maintenance: boolean) => {
  const query = loader('../graphql/addBillboardAvailability.gql');
  console.log('with query: ', id, last_maintenance, dates, city_code, is_maintenance)
  try {
    let result: any
    if (last_maintenance) {
      // console.log("with query 2 true:", id)
      result = await API.graphql({ query: query, variables: { id: id, last_maintenance: last_maintenance, is_maintenance: is_maintenance, dates: dates, city_code: city_code } });
    } else {
      // console.log("with query 2 false:", id)
      result = await API.graphql({ query: query, variables: { id: id, is_maintenance: is_maintenance, dates: dates, city_code: city_code } });
    }
    return result.data.addBillboardAvailability
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const batchAddBillboardAvailability = async (billboards: any, last_maintenance: any, dates: any, is_maintenance: boolean) => {
  const query = loader('../graphql/batchAddBillboardAvailability.gql');
  console.log('with query: ', billboards, last_maintenance, dates)
  console.log('in batchAddBillboardAvailability api: ', billboards, last_maintenance, dates, is_maintenance)

  let ids = _.map(billboards, (o: any) => { return { id: o.id, city_code: o.city_code } })

  try {
    const result: any = await API.graphql({ query: query, variables: { input: ids, last_maintenance: last_maintenance, is_maintenance: is_maintenance, dates: dates } });
    return result.data.batchAddBillboardAvailability
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}


export const listMyOrders = async (nextToken: any) => {
  const query = loader('../graphql/listMyOrders.gql');
  console.log('with query: ', nextToken)
  try {
    const result: any = await API.graphql({ query: query, variables: { limit: 500, nextToken: nextToken } });
    console.log("in listMyOrders with", result)
    return result
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const getOrder = async (campaign_id: string, order_id: string) => {
  const query = loader('../graphql/getOrder.gql');
  console.log('with query: ', campaign_id, order_id)
  try {
    const result: any = await API.graphql({ query: query, variables: { campaign: "114d4431-3e34-4eda-abbb-781fe2e4ec1d", id: "5553f97f-f0b1-4607-b17b-812ce363ec24" } });
    // console.log("in getOrder with", result.data.getOrder)
    return result.data.getOrder
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}


export const listBillboardsAvailabilities = async (billboards: any) => {
  const query = loader('../graphql/listBillboardAvailability.gql');

  let availabilities = [] as any
  let availabilities_filtered = [] as any
  for (let i = 0; i < billboards.length; i++) {

    try {
      const result: any = await API.graphql({ query: query, variables: { billboard_id: billboards[i] } });
      _.forEach(result.data.listBillboardAvailability, function (o: any) {
        // console.log("availabilies in api [0]", o, availabilities)
        availabilities.push(o)
      })
    } catch (error) {
      console.log("errore api", error)
      throw error
    }
  }

  availabilities_filtered = _.uniqBy(availabilities, 'start_busy_date');

  // console.log("availabilies in api [2]", availabilities, availabilities_filtered)
  return availabilities_filtered
}

export const updateOrderStatus = async (campaign_id: any, id: any, action: string) => {
  console.log("with query: ", campaign_id, id, action)
  const query = loader('../graphql/updateOrderState.gql');

  try {
    const result: any = await API.graphql({ query: query, variables: { campaign: campaign_id, id: id, action: action } });
    return result.data.updateOrderStatus
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const filterApiOrders = async (conditions: any, orders: any) => {
  console.log('filter api', conditions)
  try {
    var filter: any = _.chain(orders)
    if (!_.isEmpty(conditions)) {
      if (!_.isEmpty(conditions.status.value)) {
        console.log('filter with status')
        _.forEach(conditions.status, (i: any) => {
          filter = filter.filter(function (o: any) { return i.includes(o.status) })
        })
      }
      if (!_.isEmpty(conditions.advertiser.value)) {
        console.log('filter with advertiser', conditions.advertiser.value)
        filter = filter.filter(function (o: any) { console.log('filter with advertiser 1', conditions.advertiser.value); return conditions.advertiser.value.includes(o.advertiser) })
      }
      if (!_.isEmpty(conditions.campaign_name.value)) {
        console.log('filter with campaign_name')
        filter = filter.filter(function (o: any) { return conditions.campaign_name.value.includes(o.campaign_name) })
      }
      if (!_.isEmpty(conditions.order_date.value)) {
        console.log('filter with order_date')
        filter = filter.filter(function (o: any) { return conditions.order_date.value.includes(moment(o.order_date).format("YYYY-MM-DD")) })
      }
      if (!_.isEmpty(conditions.printer.value)) {
        console.log('filter with printer')
        filter = filter.filter(function (o: any) { return conditions.printer.value.includes(o.printer) })
      }
      if (!_.isEmpty(conditions.amount.value)) {
        console.log('filter with amount')
        filter = filter.filter(function (o: any) { return conditions.amount.value.includes(o.amount) })
      }
      if (!_.isEmpty(conditions.order_period.from) && !_.isEmpty(conditions.order_period.to)) {
        console.log('filter with order_period')
        filter = filter.filter(function (o: any) { console.log("order_period", o, conditions); return conditions.order_period.from === moment(o.from).format("YYYY-MM-DD") && conditions.order_period.to === moment(o.to).format("YYYY-MM-DD") })
      }

      console.log('reduce:::::', filter.value())
    }
    filter = filter.value()
    return filter
  } catch (error) {
    console.log("errore api filter", error)
    throw error
  }
}

export const batchDeleteBillboardAvailabilities = async (dates: any, input: any) => {
  console.log("with query: ", dates, input)
  const query = loader('../graphql/batchDeleteBillboardAvailabilities.gql');

  try {
    const result: any = await API.graphql({ query: query, variables: { dates: dates, input: input } });
    console.log("in batchDeleteBillboardAvailabilities api with: ", result.data.batchDeleteBillboardAvailabilities)
    return result.data.batchDeleteBillboardAvailabilities
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const addOrderImage = async (id: any, order_id: any, campaign: any, upload_date: any, caption: any) => {
  console.log("with query: ", id, order_id, campaign, upload_date, caption)
  const query = loader('../graphql/addOrderImage.gql');

  try {
    const result: any = await API.graphql({ query: query, variables: { id: id, order_id: order_id, campaign: campaign, upload_date: upload_date, caption: caption } });
    console.log("in addOrderImage api with: ", result.data.addOrderImage)
    return result.data.addOrderImage
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const getUploadOrderImageUrl = async (id: string, order_id: string, type: string) => {
  console.log("with query: ", id, order_id, type)
  const query = loader('../graphql/getUploadOrderImageUrl.gql');

  try {
    const result: any = await API.graphql({ query: query, variables: { id: id, order_id: order_id, type: type } });
    console.log("in getUploadOrderImageUrl api with: ", result)
    return result.data.getUploadOrderImageUrl
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const uploadOrderImage = async (url: string, imageArray: any) => {
  console.log("with query: ", url, imageArray)

  try {
    let resp = null
    var options = {
      headers: {
        'Content-Type': imageArray.file.type
      }
    };
    await axios.put(url, imageArray.file, options)
      .then(function (response: any) {
        console.log("response", response);
        resp = response
      })
      .catch(function (error: any) {
        console.log("errore", error);
      });
    return resp
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const listOrderImages = async (order_id: string, nextToken: string) => {
  console.log("with query: ", order_id, nextToken)
  const query = loader('../graphql/listOrderImages.gql');

  try {
    const result: any = await API.graphql({ query: query, variables: { order_id: order_id, limit: 100, nextToken: nextToken } });
    console.log("in listMyOrders with", result)
    return result
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const buildPdfOrderContract = async (campaign: string, id: string) => {
  console.log("with query: ", campaign, id)
  const query = loader('../graphql/buildPdfOrderContract.gql');

  try {
    const result: any = await API.graphql({ query: query, variables: { campaign: campaign, id: id } });
    console.log("in buildPdfOrderContract with", result)
    return result
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const getResourceUrl = async (id: string) => {
  console.log("with query: ", id)
  const query = loader('../graphql/getResourceUrl.gql');

  try {
    const result: any = await API.graphql({ query: query, variables: { id: id } });
    console.log("in getResourceUrl with", result)
    return result
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}

export const deleteBillboard = async (id: string, city_code: string) => {
  console.log("with query deleteBillboard: ", id, city_code)
  const mutation = loader('../graphql/deleteBillboard.gql');

  try {
    const result: any = await API.graphql({ query: mutation, variables: { id: id, city_code: city_code } });
    console.log("in deleteBillboard with", result)
    return result
  } catch (error) {
    console.log("errore api", error)
    throw error
  }
}