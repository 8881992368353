import { call, put, takeEvery, takeLatest, delay, fork } from 'redux-saga/effects'
import { Button } from 'reactstrap';

import * as AuthApi from '../../api/auth'
import { actions as AuthActions } from '../slices/auth'
import { actions as NotificationActions } from '../slices/notification'
import { actions as UIActions } from '../slices/ui'
import { actions as ContentActions } from '../slices/content'
import { push } from 'connected-react-router'
import React from 'react';

export function* sagas() {
  yield takeLatest(AuthActions.willLoginUser.type, willLoginUser)
  yield takeLatest(AuthActions.willLogoutUser.type, willLogoutUser)
  yield takeLatest(AuthActions.willSignupUser.type, willSignupUser)
  yield takeLatest(AuthActions.willConfirmUser.type, willConfirmUser)
  yield takeLatest(AuthActions.willForgotPasswordRequest.type, willForgotPasswordRequest)
  yield takeLatest(AuthActions.willForgotPasswordConfirm.type, willForgotPasswordConfirm)
  yield takeLatest(AuthActions.willResendSignup.type, willResendSignup)
  yield takeLatest(AuthActions.willResetPassword.type, willResetPassword)
  console.log('before auth saga');

  yield fork(checkAuthentication);
  console.log('in auth saga');
}

function* checkAuthentication() {
  console.log('checkauth')
  yield call(AuthApi.configure);
  const result = yield call(AuthApi.isAuthenticated);

  // console.log('in check auth onLoad: ', result);
  if (result) {
    const user = yield call(AuthApi.getAuthenticatedUser);
    yield put(AuthActions.didLoginUserSuccess(user));
    if (user.signInUserSession.accessToken.payload["cognito:groups"]) {
      yield put(ContentActions.willListOrders())
      yield put(ContentActions.willListMyBillboards())
    }
  }
  else {
    if (!window.location.href.includes('signup/confirm')) {
      yield put(AuthActions.didLoginUserFails({}));
      yield put(push("/login"))
    }
  }
}
function* willConfirmUser(action: any) {
  // console.log("in willConfirmUser with ", action)
  yield put(NotificationActions.willShowNotification({ message: "Confirming username " + action.payload.username, type: "info" }))

  try {
    yield put(UIActions.startActivityRunning("confirm"));
    localStorage.removeItem('username')
    localStorage.removeItem('emailConfirm')

    const result = yield call(AuthApi.confirm, action.payload.username, action.payload.code)
    console.log("willConfirmUser success result ", result)
    yield put(AuthActions.didConfirmUserSuccess(result));

    yield put(NotificationActions.willShowNotification({ message: result, type: "success" }));
    action.payload.history.push('/login')
    yield put(UIActions.stopActivityRunning("confirm"));
  } catch (error: any) {
    yield put(AuthActions.didConfirmUserFails(error));
    console.log("willConfirmUser fails error ", error)
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
    yield put(UIActions.stopActivityRunning("confirm"));
  }
}

function* willLoginUser(action: any) {
  // console.log('in willLoginUser with ', action)
  yield put(UIActions.startActivityRunning("login"));
  try {
    const result = yield call(AuthApi.login, action.payload.email, action.payload.password)
    console.log("result: willLoginUser", result)
    yield put(AuthActions.didLoginUserSuccess({ result: result, history: action.payload.history }));
    // action.payload.history.push("/")
    yield put(push("/"))
    yield put(ContentActions.willListOrders())
    yield put(ContentActions.willListMyBillboards())
  } catch (error: any) {
    yield put(AuthActions.didLoginUserFails(error));

    if (error.code == "UserNotConfirmedException") {
      console.log('in UserNotConfirmedException');
      const message = <>Utente non confermato - <Button color="link" href="/signup/confirm">Invia email di conferma</Button></>
      localStorage.setItem('username', action.payload.email)
      localStorage.setItem('emailConfirm', "RESEND_SIGNUP_USER")
      yield put(NotificationActions.willShowNotification({ message: message, type: "danger", delay: 10000 }));
    } else if (error.code == "PasswordResetRequiredException") {
      const message = <>Richiesto l'inserimento di una nuova password - <Button color="link" href="/signup/confirm">Invia email di conferma</Button></>
      localStorage.setItem('username', action.payload.email)
      localStorage.setItem('emailConfirm', "PASSWORD_RESET")
      yield put(push("/"))
      yield put(NotificationActions.willShowNotification({ message: message, type: "danger" }));
    } else {
      yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
    }
  }
  yield put(UIActions.stopActivityRunning("login"));
}

function* willLogoutUser(action: any) {
  try {
    const result = yield call(AuthApi.logout)
    yield put(AuthActions.didLogoutUser(result));
    // action.payload.history.push("/")
    yield put(UIActions.didSetNoGroup(false))
    yield put(push("/login"))
  } catch (error: any) {
    yield put(AuthActions.didLoginUserFails(error));
  }
}

function* willSignupUser(action: any) {
  // console.log('in willSignupUser with ', action)
  try {
    yield put(UIActions.startActivityRunning("signup"));
    localStorage.setItem('username', action.payload.email)
    localStorage.setItem('emailConfirm', "SIGNUP_USER")
    const result = yield call(AuthApi.signup, action.payload.email, action.payload.password, action.payload.given_name, action.payload.family_name)
    yield put(AuthActions.didSignupUserSuccess(result));
    //Redirect to Confirm
    action.payload.history.push('/signup/confirm')
    yield put(UIActions.stopActivityRunning("signup"));
  } catch (error: any) {
    yield put(AuthActions.didSignupUserFails(error));
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
    yield delay(1000);
    yield put(UIActions.stopActivityRunning("signup"));
  }
}

function* willForgotPasswordRequest(action: any) {
  // console.log("in willForgotPasswordRequest with ", action)
  yield put(UIActions.startActivityRunning("requestNewPassword"));
  try {
    localStorage.setItem('username', action.payload.email)
    localStorage.setItem('emailConfirm', "PASSWORD_RESET")
    const result = yield call(AuthApi.forgotPasswordRequest, action.payload.email)
    yield put(AuthActions.didForgotPasswordRequestSuccess(result))
    yield put(NotificationActions.willShowNotification({ message: "New password requested", type: "success" }));
    action.payload.history.push("/signup/confirm/")
  } catch (error: any) {
    yield put(AuthActions.didForgotPasswordRequestFails(error));
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
  yield put(UIActions.stopActivityRunning("requestNewPassword"));
}

function* willForgotPasswordConfirm(action: any) {
  // console.log("in willForgotPasswordConfirm with ", action)
  yield put(UIActions.startActivityRunning("conferma"));
  try {
    localStorage.removeItem('username')
    localStorage.removeItem('emailConfirm')
    const result = yield call(AuthApi.forgotPasswordConfirm, action.payload.email, action.payload.code, action.payload.password)
    yield put(AuthActions.didForgotPasswordConfirmSuccess(result))
    yield put(NotificationActions.willShowNotification({ message: "New password confirmed", type: "success" }));
    action.payload.history.push('/login')
  } catch (error: any) {
    yield put(AuthActions.didForgotPasswordConfirmFails(error));
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
  yield put(UIActions.stopActivityRunning("conferma"));
}

function* willResendSignup(action: any) {
  console.log("in willResendSignupConfirm with ", action)
  try {
    yield put(UIActions.startActivityRunning("resendSignupConfirm"));
    localStorage.setItem('username', action.payload.email)
    localStorage.setItem('emailConfirm', "SIGNUP_USER")
    const result = yield call(AuthApi.resendSignuUpCode, action.payload.email)
    //Redirect to Confirm
    action.payload.history.push('/signup/confirm')
  } catch (error: any) {
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
  yield put(UIActions.stopActivityRunning("resendSignupConfirm"));
}

function* willResetPassword(action: any) {
  // console.log("in willResetPasswordConfirm with ", action)
  yield put(UIActions.startActivityRunning("willResetPassword"));
  // user: any, oldPassword: string, newPassword: string
  const oldPassword: string = action.payload.oldPassword
  const newPassword: string = action.payload.newPassword
  const history: any = action.payload.history

  try {
    const user = yield call(AuthApi.getAuthenticatedUser);
    if (user) {
      const resultResetPassword = yield call(AuthApi.resetPassword, user, oldPassword, newPassword);
      console.log('resultResetPassword', resultResetPassword)
      yield put(UIActions.switchCloseAddNewPlant())
      // yield put(AuthActions.willLogoutUser())
      //Redirect to Login
      // history.push('/login')
    } else {
      yield put(NotificationActions.willShowNotification({ message: 'Errore nella modifica della password. Riprovare', type: "danger" }));
    }
  } catch (error: any) {
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
  yield put(UIActions.stopActivityRunning("willResetPassword"));
  yield put(NotificationActions.willShowNotification({ message: 'Password modificata correttamente.', type: "success" }));
}