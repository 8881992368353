import React, { useState, useRef, useReducer, useEffect } from "react";

const comuni = require('../assets/comuniFinal.json')

export const useSuggestion = ({ setFieldValue, setData, setCity, setSuggestions, pushCity, setValues }: any) => {

  const [value, setValue] = useState('')
  // const [city, setCity] = useState('')
  const [cityCode, setCityCode] = useState('')
  // const [suggestions, setSuggestions] = useState([] as any)

  const escapeRegexCharacters = (str: any) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  const getSuggestions: any = (value: any) => {

    // console.log('in getSuggestions')
    const escapedValue = escapeRegexCharacters(value.trim());
    if (escapedValue === '') {
      return [];
    }
    const regex = new RegExp('^' + escapedValue, 'i');
    return comuni
      .map((section: any) => {
        return {
          title: section.region,
          municipalities: section.municipalities.filter((municipality: any) => regex.test(municipality.name))
        };
      })
      .filter((section: any) => section.municipalities.length > 0);
  }

  const getSuggestionValue = (suggestion: any) => {
    // console.log('in getSuggestionValue', suggestion)
    setCityCode(suggestion.cod)
    setCity(suggestion.name)
    setFieldValue("city", suggestion.name)
    setFieldValue("city_code", suggestion.cod)
    setValues && setValues('city_code', suggestion.cod, setFieldValue)
    setValues && setValues('municipality', suggestion.name, setFieldValue)
    return suggestion.name;
  }

  const getSuggestionValueFilter = (suggestion: any) => {
    // console.log('in getSuggestionValueFilter', suggestion)
    pushCity(suggestion)
    return suggestion.name;
  }

  const renderSuggestion = (suggestion: any) => {
    return (
      <span data-cy="rendered-succestion-city" className="hoverPointer">{suggestion.name}</span>
    );
  }

  const renderSectionTitle = (section: any) => {
    return (
      <strong className="p-2">{section.title}</strong>
    );
  }

  const getSectionSuggestions = (section: any) => {
    // console.log('in getSectionSuggestions', section)
    return section.municipalities;
  }

  const onChange = (event: any, newValue: any, method: any) => {
    setValue(newValue.newValue)
    setData({ ["municipality"]: newValue.newValue })
    setCityCode(newValue.newValue)
    setCity(newValue.newValue)
    setFieldValue("city", newValue.newValue)
  };

  const onSuggestionsFetchRequested = (value: any) => {
    setSuggestions(getSuggestions(value.value))
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  };

  return {
    getSuggestions,
    getSuggestionValue,
    getSuggestionValueFilter,
    renderSuggestion,
    renderSectionTitle,
    getSectionSuggestions,
    onChange,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    value,
    cityCode,
    setValue
    //inputProps
  }
}