import {
  createClient,
  makeResult,
  makeErrorResult,
  Exchange,
  ExecutionResult,
  cacheExchange,
  dedupExchange,
} from "urql";

import {
  Source,
  pipe,
  share,
  filter,
  takeUntil,
  mergeMap,
  merge,
  make,
} from "wonka";

import { Operation, OperationResult } from "@urql/core";
import { API } from "aws-amplify";

const makeAmplifySource = (operation: Operation): Source<OperationResult> => {
  return make<OperationResult>((observer) => {
    let ended = false;

    Promise.resolve()
      //@ts-ignore
      .then(() => {
        if (ended) return;
        return API.graphql({
          query: operation.query,
          variables: operation.variables,
        });
      })
      //@ts-ignore
      .then((result: ExecutionResult | AsyncIterable<ExecutionResult>) => {
        if (ended || !result) {
          return;
        } else {
          observer.next(makeResult(operation, result as ExecutionResult));
          return;
        }
      })
      .then(() => {
        observer.complete();
      })
      .catch((error) => {
        observer.next(makeErrorResult(operation, error));
        observer.complete();
      });

    return () => {
      ended = true;
    };
  });
};

const amplifyFetchExchange: Exchange = ({ forward }) => {
  return (ops$) => {
    const sharedOps$ = share(ops$);

    const executedOps$ = pipe(
      sharedOps$,
      filter((operation: Operation) => {
        return operation.kind === "query" || operation.kind === "mutation";
      }),
      mergeMap((operation: Operation) => {
        const { key } = operation;
        const teardown$ = pipe(
          sharedOps$,
          filter((op) => op.kind === "teardown" && op.key === key)
        );

        return pipe(makeAmplifySource(operation), takeUntil(teardown$));
      })
    );

    const forwardedOps$ = pipe(
      sharedOps$,
      filter((operation) => operation.kind === "teardown"),
      forward
    );

    return merge([executedOps$, forwardedOps$]);
  };
};

const exchanges = [dedupExchange, cacheExchange, amplifyFetchExchange];

if (process.env.NODE_ENV === "development") {
  exchanges.unshift(require("@urql/devtools").devtoolsExchange);
}

// `url` is irrelevant, as we bypass fetch calls and call AWS Amplify
export default createClient({ url: "/", exchanges });
