import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import Icon from "./IconsComponent";
import { TextField, Button, IconButton, ButtonGroup, Grid, Paper, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui'
import { Formik, Form, Field, useFormikContext } from 'formik';
import Autosuggest, { BlurEvent, ChangeEvent } from 'react-autosuggest';
import { useStyles, borderColorDatePicker } from "./add-new-billboard/AddNewBillboard.css";
import moment from "moment";
import { useSuggestion } from "./Suggestion.hooks";
import { billboardsTypes, groungHeight, lightingType, orientationType } from "./Autocomplete.options";
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, } from '@material-ui/pickers';
import { ActivityButton } from './ActivityButton';
import MomentUtils from '@date-io/moment';
const comuni = require('../assets/comuniFinal.json')

export const AddNewPlantForm = (props: any) => {
  const { data, setData, plant, setSelectedFile, setTaxesMunicipal, setTaxesPayment, taxesMunicipal } = props;
  const classes = useStyles();
  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();

  //const [selectedFile, setSelectedFile] = useState({} as any)
  const [previewImage, setPreviewImage] = useState(plant ? plant.imageUrl ? `${plant.imageUrl}` : "https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg" : '')
  // const [value, setValue] = useState('')
  const [city, setCity] = useState(plant ? plant.municipality : "")
  // const [cityCode, setCityCode] = useState('')
  const [suggestions, setSuggestions] = useState([] as any)
  const [firstPolicy, setFirstPolicy] = useState(false)
  const [secondPolicy, setSecondPolicy] = useState(false)

  const { setFieldValue, values, errors, touched, handleChange }: any = useFormikContext()

  const {
    getSuggestions,
    getSuggestionValue,
    renderSuggestion,
    renderSectionTitle,
    getSectionSuggestions,
    onChange,
    value,
    cityCode,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    //inputProps
  }: any = useSuggestion({ setFieldValue, setData, setSuggestions, setCity })

  const escapeRegexCharacters = (str: any) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  const handleClick = (event: any) => {
    (hiddenFileInput.current as any).click();
    // console.log("FILE CONTENT: ", previewImage)
  };

  const handleChangeInput = (evt: any) => {
    const key = evt.target.id;
    const value = evt.target.value;
    setData({ [key]: value });
    // console.log("in hangleChangeInput", key, value)
  }

  const inputProps: any = {
    placeholder: "Comune",
    value,
    onChange: onChange,
    className: "autosuggest"
  };
  useEffect(() => {
    // console.log('in useeffect plant', plant)
    // console.log("data", data)
    setTaxesMunicipal(data.taxes.municipal_tax)
  }, [plant, data])

  return (
    <Form className="poppinsFont detailText">
      <div className="d-sm-flex justify-content-between align-items-center border-bottom">
        {plant ? <b className="ml-3">Modifica impianto</b> : <b className="ml-3">Aggiungi nuovo impianto</b>}
        <div>
          {/* <IconButton className="bg-transparent border-0 mr-1 right">
            <Icon color="#bfbfbf" size={20} icon="more" />
          </IconButton> */}
          <IconButton className="bg-transparent border-0 mr-1" onClick={() => dispatch(UiActions.switchCloseAddNewPlant())}>
            <Icon color="#bfbfbf" size={20} icon="close" />
          </IconButton>
        </div>
      </div>
      <div className="p-2">
        <Button data-cy="buttonUploadImage" className={`${classes.margin} border`} startIcon={<Icon size={20} color="#bfbfbf" icon="add" />} size="small" onClick={handleClick}>{plant ? "Modifica foto" : "Aggiungi Foto"}</Button>
        <input type="file"
          ref={hiddenFileInput}
          style={{ display: 'none' }}
          data-cy="inputUploadImage"
          onChange={(e: any) => {
            // console.log("FILE EVENT IMAGE", e.target.files[0])
            setSelectedFile(e.target.files[0])
            setPreviewImage(URL.createObjectURL(e.target.files[0]))
          }}
        />
      </div>
      {previewImage != "" ? <Grid className="mb-4" item sm={11}><span className="p-3"><img src={previewImage} width="100%" height="100%" /></span></Grid> : null}
      <Grid container spacing={3}>
        <Grid className="ml-3" item sm={12}>POSIZIONE</Grid>
        <Grid item xs={6}>
          {plant ? (
            <TextField
              className={`${classes.borderColor}`}
              id="municipality"
              label="Comune"
              variant="outlined"
              size="small"
              defaultValue={plant ? plant.municipality : ""}
              InputProps={{ disabled: true }}
            />
          ) : (
            <div>
              <div data-cy="searchMunicipality" >
                <Autosuggest
                  multiSection={true}
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  renderSectionTitle={renderSectionTitle}
                  getSectionSuggestions={getSectionSuggestions}
                  inputProps={inputProps}
                  theme={{
                    input: classes.autosuggest,
                    inputOpen: classes.autosuggest,
                    inputFocused: classes.inputFocused,
                    suggestionsContainer: classes.absolute,
                    suggestionsContainerOpen: classes.absolute,
                  }}
                />
                <TextField hidden type="hidden" name="city_code" value={cityCode} onChange={handleChange} />
                <TextField hidden type="hidden" name="city" value={city} onLoad={() => plant ? plant.municipality : city} onChange={handleChange} />
              </div>
              {errors.municipality && touched.municipality ? (
                <span style={{ fontSize: 12 }} className="ml-3 text-danger">{errors.municipality}</span>
              ) : null}
            </div>)}
        </Grid>
        <Grid item xs={6}>
          <TextField
            className={`${classes.borderColor}`}
            id="address"
            name="address"
            label="Indirizzo"
            variant="outlined"
            size="small"
            defaultValue={plant ? plant.address : ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid className={classes.paper} item xs={6}>
          <Field as={TextField}
            className={classes.borderColor}
            id="latitude"
            name="latitude"
            label="Latitudine *"
            type="number"
            variant="outlined"
            size="small"
            value={values.latitude}
            onChange={handleChange}
            helperText={touched.latitude ? errors.latitude : ""}
            error={touched.latitude && Boolean(errors.latitude)}
          />
        </Grid>
        <Grid item xs={6}>
          <Field as={TextField}
            className={`${classes.borderColor}`}
            id="longitude"
            label="Longitudine *"
            type="number"
            variant="outlined"
            size="small"
            value={values.longitude}
            onChange={handleChange}
            helperText={touched.longitude ? errors.longitude : ""}
            error={touched.longitude && Boolean(errors.longitude)}
          />
        </Grid>
        <Grid className="ml-3" item sm={12}>PARAMETRI</Grid>
        <Grid item xs={6}>
          <Autocomplete
            className={`${classes.borderColor}`}
            size="small"
            id="media_type"
            disableClearable
            value={values.media_type}
            options={billboardsTypes.map(option => option.typology)}
            getOptionDisabled={(option) => option === "Dinamico" || option === "Facciata" || option === "Evento"}
            onChange={(e: any) => setFieldValue("media_type", e.target.textContent)}
            renderInput={(params) => <Grid item xs={11}> <TextField {...params} name="media_type" label="Tipologia" variant="outlined" /></Grid>}
          />
        </Grid>
        <Grid className="d-sm-flex align-items-center" item xs={6}>
          <Grid item xs={5}>
            <span className="text-black-50">Numero Fogli *</span>
          </Grid>
          <Grid item xs={4}>
            <Field as={TextField}
              className={`${classes.borderColor}`}
              id="n_sheet"
              name="n_sheet"
              type="number"
              size="small"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              value={values.n_sheet}
              onChange={handleChange}
              helperText={touched.n_sheet ? errors.n_sheet : ""}
              error={touched.n_sheet && Boolean(errors.n_sheet)}
            />
          </Grid>
        </Grid>
        <Grid container item alignItems="center" xs={12} spacing={3}>
          <Grid item xs={3}>
            <Field as={TextField}
              className={`${classes.borderColor}`}
              id="base"
              label="Base *"
              type="number"
              name="base"
              variant="outlined"
              size="small"
              value={values.base}
              onChange={handleChange}
              helperText={touched.base ? errors.base : ""}
              error={touched.base && Boolean(errors.base)}
            />
          </Grid>
          <Grid item xs={3}>
            <Field as={TextField}
              className={`${classes.borderColor}`}
              id="height"
              label="Altezza *"
              name="height"
              type="number"
              variant="outlined"
              size="small"
              value={values.height}
              onChange={handleChange}
              helperText={touched.height ? errors.height : ""}
              error={touched.height && Boolean(errors.height)}
            />
          </Grid>
          <Grid item xs={2}>
            <Field as={TextField}
              className={`${classes.borderColor}`}
              id="surface"
              name="surface"
              label="Superfice"
              size="small"
              value={values.base * values.height}
              onChange={(e: any) => setFieldValue("surface", e.target.value)}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={3}>
            <Field as={TextField}
              className={`${classes.borderColor}`}
              id="size"
              name="size"
              label="Formato"
              type="size"
              size="small"
              value={`${values.base}X${values.height}`}
              onChange={(e: any) => { setFieldValue("size", e.target.value) }}
              InputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>
        <Grid container item alignItems="center" xs={11}>
          <Grid item xs={4}>
            <Autocomplete
              className={`${classes.borderColor}`}
              size="small"
              id="height_ground"
              value={values.height_ground}
              onChange={(e: any) => setFieldValue("height_ground", e.target.textContent)}
              options={groungHeight.map(option => option.height_ground)}
              renderInput={(params) => <Grid item xs={11}> <TextField name="height_ground" {...params} label="Alt. da terra" variant="outlined" /></Grid>}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              className={`${classes.borderColor}`}
              size="small"
              id="lighting"
              value={values.lighting}
              onChange={(e: any) => setFieldValue("lighting", e.target.textContent)}
              options={lightingType.map(option => option.lighting)}
              renderInput={(params) => <Grid item xs={11}> <TextField name="lighting" {...params} label="Illuminazione" variant="outlined" /></Grid>}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              className={`${classes.borderColor}`}
              size="small"
              id="orientation"
              value={values.orientation}
              onChange={(e: any) => setFieldValue("orientation", e.target.textContent)}
              options={orientationType.map(option => option.orientation)}
              renderInput={(params) => <Grid item xs={11}> <TextField name="orientation" {...params} label="Orientamento" variant="outlined" /></Grid>}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Field as={TextField}
            className={`${classes.borderColor}`}
            id="cimasa"
            label="Cimasa *"
            variant="outlined"
            name="cimasa"
            size="small"
            value={values.cimasa}
            onChange={handleChange}
            helperText={touched.cimasa ? errors.cimasa : ""}
            error={touched.cimasa && Boolean(errors.cimasa)}
          />
        </Grid>
        <Grid item xs={6}>
          <Field as={TextField}
            className={`${classes.borderColor}`}
            id="price"
            label="Prezzo Base *"
            type="number"
            variant="outlined"
            name="price"
            size="small"
            value={values.price}
            onChange={handleChange}
            helperText={touched.price ? errors.price : ""}
            error={touched.price && Boolean(errors.price)}
          />
        </Grid>
        <Grid className="ml-3" item sm={12}>MANUTENZIONE</Grid>
        <Grid item xs={6}>
          <TextField
            className={classes.borderColor}
            id="status"
            label="Stato"
            name="status"
            variant="outlined"
            size="small"
            value={values.status}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              style={{...borderColorDatePicker, width: document.getElementById('municipal_tax')?.offsetWidth}}
              data-cy="last_maintenance"
              disableToolbar
              disableFuture
              autoOk={true}
              format="DD-MM-YYYY"
              id="last_maintenance"
              placeholder="Ultima manutenzione"
              label=""
              keyboardIcon={<Icon color="grey" size={20} icon="calendar" />}
              value={data.last_maintenance}
              helperText=""
              inputValue={data.last_maintenance === "Invalid date" ? null : data.last_maintenance}
              onChange={(e: any, value: any) => setData({ ["last_maintenance"]: value })}
              InputAdornmentProps={{ position: "end" }}
              InputProps={{ disableUnderline: true, className: "ml-3" }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid className="ml-3" item sm={12}>TRIBUTI</Grid>
        <Grid item xs={6}>
          <TextField
            className={classes.borderColor}
            id="municipal_tax"
            label="Comunale"
            name="municipal_tax"
            type="number"
            variant="outlined"
            size="small"
            value={taxesMunicipal}
            defaultValue={plant ? plant.taxes.municipal_tax : ""}
            onChange={(e: any) => setTaxesMunicipal(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              style={{...borderColorDatePicker, width: document.getElementById('municipal_tax')?.offsetWidth}}
              data-cy="last_payment"
              disableToolbar
              disableFuture
              autoOk={true}
              format="DD-MM-YYYY"
              id="last_payment"
              placeholder="Ultimo pagamento"
              label=""
              keyboardIcon={<Icon color="grey" size={20} icon="calendar" />}
              value={plant ? plant.taxes.last_payment : data.taxes.last_payment}
              helperText=""
              inputValue={data.taxes.last_payment === "Invalid date" ? null : data.taxes.last_payment}
              onChange={(e: any, value: any) => {
                // console.log("last_payment", e,value)
                setTaxesPayment(value)
              }}
              InputAdornmentProps={{ position: "end" }}
              InputProps={{ disableUnderline: true, className: "ml-3" }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid className="ml-3" item sm={12}>AUTORIZZAZIONI</Grid>
        <Grid item xs={6}>
          <TextField
            className={classes.borderColor}
            id="n_authorization"
            label="Numero Autorizzazione"
            variant="outlined"
            size="small"
            defaultValue={plant ? plant.n_authorization : ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              style={{...borderColorDatePicker, width: document.getElementById('municipal_tax')?.offsetWidth}}
              data-cy="authorization_expiration"
              disableToolbar
              // disableFuture
              disablePast
              autoOk={true}
              format="DD-MM-YYYY"
              id="authorization_expiration"
              placeholder="Scadenza"
              label=""
              keyboardIcon={<Icon data-cy="authorization_expiration_button" color="grey" size={20} icon="calendar" />}
              value={data.authorization_expiration}
              helperText=""
              inputValue={data.authorization_expiration === "Invalid date" ? null : data.authorization_expiration}
              onChange={(e: any, value: any) => setData({ ["authorization_expiration"]: value })}
              InputAdornmentProps={{ position: "end" }}
              InputProps={{ disableUnderline: true, className: "ml-3" }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        {/* <Grid className="ml-3" item sm={12}>Note</Grid>
        <Grid item xs={12}>
            <TextField
              className={`${classes.borderColor}`}
              id="notes"
              multiline
              rows={4}
              placeholder="Hello my name..."
              variant="outlined"
              size="small"
              defaultValue={plant ? plant.notes : ""}
              onChange={handleChange}
            />
          </Grid> */}
        <span className="p-3"><input data-cy="policy1" type="checkbox" onClick={() => setFirstPolicy(!firstPolicy)} /> Confermo di aver letto i <a style={{ color: "#66b0ff" }} >Termini e Condizioni del Servizio</a> e la <a style={{ color: "#66b0ff" }} >Privacy Policy</a>.</span>
        <span className="p-3"><input data-cy="policy2" type="checkbox" onClick={() => setSecondPolicy(!secondPolicy)} /> Confermo di essere esclusivo titolare dell’impianto pubblicitario</span>
        <div className="d-sm-flex w-100">
          <ButtonGroup data-cy="finishButtons" className="m-3 w-100">
            <button data-cy="cancel" className={`${classes.cancelButton} p-2 w-25`} onClick={() => dispatch(UiActions.switchCloseAddNewPlant())}>Annulla</button>
            {firstPolicy === true && secondPolicy === true ?
              <ActivityButton data-cy="confirm" className={`${classes.confirmButton} p-2 flex-grow-1 w-75`} name="conferma" color="primary" disabled block>Conferma</ActivityButton> :
              <Tooltip title={<span style={{ fontSize: 17, color:'black' }}>Accettazione delle policy richiesta</span>}>
                <span style={{ opacity: 0.5 }} className={`${classes.confirmButton} d-sm-flex p-2 flex-grow-1 w-75 justify-content-center`}>
                  Conferma
                </span>
              </Tooltip>}
          </ButtonGroup>
        </div>
      </Grid>
    </Form>
  )
}