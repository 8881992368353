import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Icon from './IconsComponent';

import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content'
import { actions as UiActions } from '../store/slices/ui'
import { useStyles } from './profile/Profile.css';
import { ActivityButtonModal } from './profile/ActivityButtonModal';
import billboards_placeholder from "../assets/images/billboards_placeholder.svg";

export const DeleteBillboardModal = ({ item, setModalType }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [image, setImage] = useState()

  useEffect(() => {
    if (item) {
      if (item.imageUrl) {
        setImage(item.imageUrl)
      } else {
        setImage(billboards_placeholder)
      }
    }
  }, [])

  const address = item
    ? item.location_name
      ? item.location_name
      : item.address
    : ''

  return (
    <Grid className="poppinsFont" container alignItems="center" justify="center">
      <Grid className={classes.modalInProfileHeader} container>
        <Grid item xs={11}>Rimuovi impianto</Grid>
        <Grid className="d-sm-flex flex-row-reverse hoverPointer" item xs={1} onClick={() => {
          dispatch(UiActions.switchCloseAddNewPlant())
          setModalType("")
        }} >
          <Icon size={24} color="#92929D" icon="close" />
        </Grid>
      </Grid>
      {/* <Grid className='border-bottom pb-4' container item xs={12}>
        <Grid container alignItems="center" className='text-muted' item xs={11}>Rimuovi impianto</Grid>
        <Grid container alignItems="center" style={{ cursor: 'pointer', color: '#92929D' }} className='d-sm-flex justify-content-center' item xs={1} onClick={() => {
          dispatch(UiActions.switchCloseAddNewPlant())
          setModalType("")
        }}>X</Grid>
      </Grid> */}
      {item
        ? <>
          <Grid className="mt-4" item xs={11}>Sei sicuro di voler eliminare questo impianto?</Grid>
          <Grid className="mt-4 detailText text-muted" item xs={11}>
            <Grid container item xs={7}>
              <Grid container alignItems="center" justify="center" item xs={1}>
                {image && <img
                  id={"image-" + item.imageUrl}
                  data-cy="avatar2"
                  src={image}
                  className="mr-3 plantAvatar"
                  loading="lazy"
                  onError={(e: any) => {
                    console.log("image error. Reloading..");

                    setTimeout(() => {
                      let imageElement = document.getElementById(
                        "image-" + item.imageUrl
                      ) as HTMLImageElement;
                      imageElement.src = item.imageUrl;
                    }, 500);
                  }}
                />}
              </Grid>
              <Grid container alignItems="center" item xs={11}>{address} - {item.municipality}</Grid>
            </Grid>
            <Grid className={`${classes.modalInProfileFooter} border-0`} item xs={12}>
              <Grid className="d-sm-flex">
                <Grid item xs={7} />
                <Grid item xs={2}>
                  <button className={classes.cancelButtonModal} onClick={() => {
                    dispatch(UiActions.switchCloseAddNewPlant())
                    setModalType("")
                  }}>Annulla</button>
                </Grid>
                <Grid className="ml-4" item xs={2}>
                  <ActivityButtonModal data-cy="confirm" className={classes.confirmButtonModal} name="willDeleteBillboard"
                    onClick={() => { dispatch(ContentActions.willDeleteBillboard(item)) }}>
                    Conferma
                  </ActivityButtonModal>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
        : <h3>Caricamento in corso...</h3>}
    </Grid>
  )
}