import React, { useState, useMemo, useCallback, useEffect, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TextField, InputAdornment, Badge, Paper, Button, Grid } from '@material-ui/core';
import { useTable, useSortBy, useFlexLayout, useExpanded, useRowSelect, useGroupBy } from "react-table";
import { VariableSizeList, FixedSizeList } from "react-window";
import { Popover } from '@material-ui/core';
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from 'react-window-infinite-loader';
import ReactTooltip from "react-tooltip";
import debounce from "lodash/debounce";
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { backButton, useStyles, setBadge, notificationTextChange, confirmButton } from '../components/OrderDetails.css';
import { ListInOrderDetail, sizeTip } from "./plant-list/PlantListCss";
import Papa from 'papaparse';
import { PlantDetailOverview } from "./PlantDetailOverview";
import { customBillboardIcon } from './CustomBillboardIcon';
import _ from 'lodash';

const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
  leading: false,
  trailing: true
});

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = useRef() as any
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <div>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </div>
    )
  }
)

export const OrderDetailsRight = () => {
  const listRef = useRef(null);
  const classes = useStyles()

  const [orderColumn, setOrderColumn] = useState([] as any)
  const [actionOpen, setActionOpen] = useState(false);
  const toggleAction = () => setActionOpen(prevState => !prevState);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [plantProps, setPlantProps] = useState({})
  const [selectedRowOverview, setSelectedRowOverview] = useState("")
  const [hideIcons, setHideIcons] = useState(false)

  let order = useSelector(ContentSelectors.getSelectOrder)

  const defaultColumn = useMemo(
    () => ({
      width: 270,
      expanded: false
    }), []
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const columns = useMemo(
    () => [
      {
        Header: "Comune / Indirizzo",
        accessor: (props: any) => {

          const address = props
            ? props.location_name
              ? props.location_name
              : props.address
            : ''

          return (
            <div className="d-sm-flex align-items-center mt-1" onClick={() => {
              // dispatch(UiActions.setSelectedBillboardId(props.id));
              // setAnchorEl(document.getElementById('mappa'))
              // setPlantProps(props)
              setHideIcons(true)
              setPlantProps(props)
              // setAnchorEl(event.currentTarget)
              setAnchorEl(document.getElementById('listInMap') as any)
            }}>

              {props.imageUrl
                ? <img
                  id={"image-" + props.imageUrl}
                  data-cy="avatar2"
                  src={props.imageUrl}
                  className="mr-3 plantAvatar"
                  loading="lazy"
                  onError={(e: any) => {
                    // console.log("image error. Reloading..");
                    // let imageElement = (document.getElementById("image-" + props.imageUrl) as HTMLImageElement)
                    // imageElement.src = props.imageUrl;
                    setTimeout(() => {
                      let imageElement = document.getElementById("image-" + props.imageUrl) as HTMLImageElement;

                      if (imageElement && imageElement.src) {
                        imageElement.src = props.imageUrl;
                      }
                    }, 500);
                  }}
                />
                : <span className='mr-3'>{customBillboardIcon(props.media_type)}</span>
              }
              <Grid className="ml-2" container item xs={12}>
                <Grid item xs={8}>
                  {address && address.length < 25 ? address : `${address.substring(0, 25)}..`}
                </Grid>
                <Grid className="d-sm-flex flex-row-reverse" item xs={4}>
                  <span style={{ ...sizeTip, flexWrap: 'wrap' }} className="text-muted">{props.base} x {props.height}</span>
                </Grid>

                <Grid className='mt-2' item xs={12}>{props.municipality}</Grid>
              </Grid>
            </div>
          )
        }
        // {
        //   const address = (props.location_name || props.address) ? props.location_name : props.address

        //   return (
        //     <div className={`${anchorEl !== null ? 'overviewDetailRow' : ''} row mt-3 ml-2`} onClick={(event: any) => {
        //       setHideIcons(true)
        //       setPlantProps(props)
        //       // setAnchorEl(event.currentTarget)
        //       setAnchorEl(document.getElementById('ordered-plants') as any)
        //     }}>
        //       {/* <img data-cy="avatar2" className="mr-3 plantAvatar" src={imageUrl} /> */}
        //       <span><b>Comune di {props.municipality.length > 15 ? `${props.municipality.substring(0, 15)}..` : props.municipality}</b><br /><span style={{ color: "#0062CC" }} className="detailText">{address.length > 15 ? `${address.substring(0, 15)}..` : address}</span> - <span className="text-muted detailText">{props.base} x {props.height}</span></span>
        //     </div>
        //     )
        // }
      },
      {
        Header: "Tipologia",
        accessor: "media_type"
      },
      // {
      //     Header: "Formato",
      //     accessor: "size"
      // },
      {
        Header: "Cimasa",
        accessor: "cod_cimasa"
      },
      {
        Header: "Prezzo",
        accessor: "base"
      },
      // {
      //     Header: "Indice Billalo",
      //     accessor: ""
      // },
      {
        Header: "Visibilità",
        accessor: "lighting"
      }, ,
      // {
      //     Header: "Traffico",
      //     accessor: ""
      // },
      {
        accessor: "id"
      },
      {
        accessor: "city_code"
      },
      {
        accessor: "billalo_code"
      },
      {
        accessor: "authorization_expiration"
      },
      {
        accessor: "available"
      },
      {
        accessor: "creationDate"
      },
      {
        accessor: "height"
      },
      {
        accessor: "height_ground"
      },
      {
        accessor: "last_maintenance"
      },
      {
        accessor: "latitude"
      },
      {
        accessor: "longitude"
      },
      {
        accessor: "media_owner"
      },
      {
        accessor: "n_authorization"
      },
      {
        accessor: "n_sheet"
      },
      {
        accessor: "orientation"
      },
      {
        accessor: "property_type"
      },
      {
        accessor: "updateDate"
      },
      {
        accessor: "taxes.last_payment",
      },
      {
        accessor: "taxes.municipal_tax",
      },
      {
        accessor: "billboard_class",
        setGlobalFilter: false
      },
      {
        accessor: "media_owner_type",
        setGlobalFilter: false
      },
      {
        accessor: "visible",
        setGlobalFilter: false
      },
      {
        accessor: "min_period",
        setGlobalFilter: false
      },
      {
        accessor: "location",
        setGlobalFilter: false
      },
      {
        accessor: "location_name",
        setGlobalFilter: false
      },
      {
        accessor: "press_type",
        setGlobalFilter: false
      },
      {
        accessor: "facade",
        setGlobalFilter: false
      }
    ], []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    toggleRowSelected,
    // state: { selectedRowIds, expanded },
  } = useTable(
    {
      columns,
      data: order.billboards,
      defaultColumn,
      initialState: {
        sortBy: orderColumn,
        hiddenColumns: ["billboard_class","media_owner_type","visible","min_period","location","location_name","press_type","facade","available", "city_code", "billalo_code", "authorization_expiration", "creationDate", "height", "height_ground", "id", "last_maintenance", "latitude", "longitude", "media_owner", "n_authorization", "n_sheet", "orientation", "property_type", "updateDate", "media_type", "size", "cod_cimasa", "base", "lighting", "taxes.last_payment", "taxes.municipal_tax"]
      },
      autoResetSelectedRows: false,
      // autoResetExpanded: false,
      // expandSubRows: false
    } as any,
    useFlexLayout,
    useGroupBy,
    useSortBy,
    useRowSelect,
  )

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);

      return (
        <div className={`${row.getToggleRowSelectedProps().checked ? "borderSelected" : row.isSelected ? "overviewDetailRow" : ''} tr-group`} style={style} onClick={() => {
          setSelectedRowOverview(row.id)
          toggleRowSelected(row.id, true)
          console.log(row.getToggleRowSelectedProps().checked, row.isSelected)
        }}>
          <div className="tr d-flex align-items-center hoverPointer">
            {row.cells.map((cell: any) => {
              return (
                <div {...cell.getCellProps()} className="td text-dark poppinsFont">
                  {cell.render("Cell")}
                </div>
              );
            })}
          </div>
        </div>
      );
    },
    [prepareRow, rows]
  );

  const fetchMoreData = () => {
    console.log('fetch More data...1')
  };
  const itemData = {
    rows,
    prepareRow,
  }

  return (
    <ListInOrderDetail>
      <div className="d-sm-flex align-items-center">
        <div {...getTableProps({ style: { backgroundColor: "white", borderRadius: 15, width: 350, height: "100%", overflow: "hidden" } })} className="col-12 table mt-3">
          <div data-cy="tableBody" className="tbody" {...getTableBodyProps({ style: { margin: 2, overflow: "hidden" } })}>
            <AutoSizer onResize={rebuildTooltip}>
              {({ height, width }) => (
                <InfiniteLoader
                  isItemLoaded={(index: any) => index < rows.length - 4 ? true : false}
                  itemCount={rows.length}
                  loadMoreItems={fetchMoreData}
                >
                  {({ onItemsRendered, ref }: any) => (
                    <VariableSizeList
                      style={{ overflowX: "hidden" }}
                      height={height}
                      itemCount={rows.length}
                      itemData={itemData}
                      // Hardcoded values only :/
                      itemSize={(i) => (rows[i] && rows[i].isExpanded ? 250 : 80)}
                      width={width}
                      onScroll={rebuildTooltip}
                      onItemsRendered={onItemsRendered}
                      ref={listRef}
                    >
                      {RenderRow}
                    </VariableSizeList>
                  )}
                </InfiniteLoader>
              )}
            </AutoSizer>
          </div>
        </div>
        <Popover
          className={classes.popoverContainer}
          id={id}
          open={open}
          anchorEl={anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 100, left: 712 }}
          onClose={() => { setAnchorEl(null); toggleRowSelected(selectedRowOverview, false); setHideIcons(false) }}
          anchorOrigin={{ vertical: 'center', horizontal: 'right', }}
          transformOrigin={{ vertical: 'center', horizontal: 'right', }}
        >
          <PlantDetailOverview item={plantProps} hideIcons={hideIcons} />
        </Popover>
      </div>
    </ListInOrderDetail>
  )
}