import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Grid, Tooltip, Switch } from '@material-ui/core';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import Icon from './IconsComponent';


export const ModalImage = (props: any) => {
  console.log("in modalImage with", props)
  const {imageProps} = props
  const dispatch = useDispatch()

  let url = window.location.origin
  if (window.location.origin.includes("8080") || window.location.origin.includes("3000")) {
    url = "https://ssp-dev.billalo.it"
  }
  console.log('url immagine', window)

  return (
    <div>
      <Grid item xs={12} className="d-sm-flex flex-row-reverse p-1">
        <button className="bg-transparent border-0" onClick={() => dispatch(UiActions.switchCloseAddNewPlant())}> <Icon size={25} icon="close" /></button>
      </Grid>
      <Grid item xs={12} className="d-sm-flex flex-column p-1 poppinsFont">
        <Grid item xs={12} className="d-sm-flex">
          <img style={{}} src={`${url}/resources/ordersImage/${imageProps.order}/${imageProps.id}`} width="100%" height="100%" />
        </Grid>
        {imageProps.caption
          ?
          <Grid style={{textAlign:"center", marginTop:10, fontSize:20}} item xs={12}>
            {imageProps.caption}
          </Grid>
          :
          null}
      </Grid>
    </div>
  )
}


// dispatch(UiActions.switchCloseAddNewPlant())