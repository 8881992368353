import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Paper, Grid, TextField, Popover, Button, FormControlLabel, Switch } from '@material-ui/core';
import Autosuggest, { BlurEvent, ChangeEvent } from 'react-autosuggest';
import { useSuggestion } from "../Suggestion.hooks";
import { Formik, Form, Field, useFormikContext } from 'formik';
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { useStyles } from './AddNewBillboard.css';
import { locations } from '../Autocomplete.options';
import { stepTwoValidations, emptyValidations } from "../Validations";
import { InputNewBillboard } from './InputNewBillboard';
import { AutocompleteNewBillboard } from './AutocompleteNewBillboard';

// const locationsArray: string[] = ["Centro Commerciale", "Stazione", "Aereoporto", "Altro"]

export const Location = ({ handleStepsArray, disabled }: any) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const hiddenFileInput = useRef(null);

  const [errorLocationName, setErrorLocationName] = useState(false)
  const [data, setData] = useState("")
  const [fieldValue, setFieldValue] = useState("")
  const [city, setCity] = useState("") as any
  const [cityError, setCityError] = useState(false)
  const [suggestions, setSuggestions] = useState([] as any)

  const billboard = useSelector(ContentSelectors.getNewBillboard)

  const setValues = (key: string, value: any, setFieldValue: any) => {
    setFieldValue(key, value)
    dispatch(ContentActions.setBillboard({ key: key, value: value }))
  }

  const {
    getSuggestions,
    getSuggestionValue,
    renderSuggestion,
    renderSectionTitle,
    getSectionSuggestions,
    onChange,
    value,
    cityCode,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
    //inputProps
  }: any = useSuggestion({ setFieldValue, setData, setCity, setSuggestions, setValues })

  const inputProps: any = {
    placeholder: "Comune",
    value: city,
    onChange: onChange,
    className: "autosuggestNewBillboard",
  };

  useEffect(() => {
    if (billboard.municipality) {
      setCity(billboard.municipality)
    }

  }, [])

  useEffect(() => {
    if (city) {
      setCityError(false)
    }
  }, [city])

  return (
    <Grid container>
      <Grid container item xs={12} alignItems="center" justify="center">
        <Formik
          initialValues={billboard}
          validationSchema={billboard.billboard_class
            ? stepTwoValidations[billboard.billboard_class]
            : emptyValidations /// senza di questo crasha
          }
          onSubmit={values => {
            if (!city || (billboard.location === 'Altro' && !billboard.other_location)) {
              if (!city) {
                setCityError(true)
              }
              if (!billboard.other_location) {
                setErrorLocationName(true)
              }

            } else {
              setCityError(false)
              setErrorLocationName(false)
              dispatch(UiActions.didSetStep('Dati'))
              handleStepsArray('Dati')
            }
          }}>
          {({ errors, touched, values, handleChange, setFieldValue }) => {
            const propsToPass = {
              errors: errors,
              touched: touched,
              values: values,
              setFieldValue: setFieldValue
            }

            if (billboard) {
              delete errors.municipality
            }

            return (
              <Form className="poppinsFont detailText w-100">
                <h5 className="ml-4" >Localizzazione impianto</h5>

                {billboard.billboard_class &&
                  <>
                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                      <AutocompleteNewBillboard data={billboard} name={'location'} label={'Ubicazione'} options={locations[billboard.billboard_class]} {...propsToPass} />
                      {billboard.location === 'Altro'
                        ? <InputNewBillboard data={billboard} name={'other_location'} label={'Altra ubicazione *'} type={'text'} errorControl={errorLocationName} setErrorControll={setErrorLocationName} {...propsToPass} />
                        : <Grid item xs={5} />}
                    </Grid>

                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                      {disabled
                        ? <InputNewBillboard data={billboard} name={'municipality'} label={''} type={'text'} disabled={disabled} {...propsToPass} />
                        : <div>
                          <div data-cy="searchMunicipality" >
                            <Autosuggest
                              multiSection={true}
                              suggestions={suggestions}
                              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                              onSuggestionsClearRequested={onSuggestionsClearRequested}
                              getSuggestionValue={getSuggestionValue}
                              renderSuggestion={renderSuggestion}
                              renderSectionTitle={renderSectionTitle}
                              getSectionSuggestions={getSectionSuggestions}
                              inputProps={inputProps}
                              theme={{
                                input: classes.autosuggest,
                                inputOpen: classes.autosuggest,
                                inputFocused: classes.inputFocused,
                                suggestionsContainer: classes.absolute,
                                suggestionsContainerOpen: classes.absolute,
                              }}
                            />
                            <TextField hidden type="hidden" name="city_code" value={cityCode} onChange={handleChange} />
                            <TextField hidden type="hidden" name="city" value={city} onLoad={() => billboard ? billboard.municipality : city} onChange={handleChange} />
                            <InputNewBillboard hidden data={billboard} name={'municipality'} label={''} type={'hidden'} {...propsToPass} />
                          </div>
                          {cityError ? (
                            <span style={{ fontSize: 12 }} className="ml-3 text-danger">Required</span>
                          ) : null}
                        </div>}
                      {billboard.billboard_class === 'Indoor'
                        ? <InputNewBillboard data={billboard} name={'location_name'} label={'Nome ubicazione'} type={'text'} {...propsToPass} />
                        : <InputNewBillboard data={billboard} name={'address'} label={'Indirizzo'} type={'text'} {...propsToPass} />}
                    </Grid>

                    <Grid className='mt-3' container item xs={12} alignItems="center" justify="space-around">
                      <InputNewBillboard data={billboard} name={'latitude'} label={'Latitudine'} type={'text'} {...propsToPass} />
                      <InputNewBillboard data={billboard} name={'longitude'} label={'Longitudine'} type={'text'} {...propsToPass} />
                    </Grid>
                  </>}

                <Grid className={classes.buttonContainer} container item xs={12} alignItems="center" justify="space-between">
                  <Grid item xs={5}></Grid>
                  <Grid className='mr-2' container item xs={5} alignItems="center">
                    <Grid item xs={6}>
                      <button data-cy="go-back" className={classes.cancelButton} onClick={() => {
                        dispatch(UiActions.didSetStep('Inserisci parametri'))
                        handleStepsArray('Inserisci parametri')
                      }}>Indietro</button>
                    </Grid>
                    <Grid item xs={6}>
                      <button data-cy="confirm" className={classes.confirmButton} type="submit" onClick={() => { }}>Conferma</button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      </Grid>
    </Grid>
  )
}