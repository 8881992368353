import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { Form, Input, ModalBody, ModalFooter, ModalHeader, Label } from "reactstrap";
import { Button, TextField, InputAdornment, Grid, IconButton, Tooltip, Zoom } from '@material-ui/core';
import { useStyles, cancelButton, confirmButton, csvButtonUpload } from './plant-list/PlantListCss';
import { ActivityButton } from './ActivityButton';
import Icon from './IconsComponent';
import ReactPlayer from 'react-player';
import video_non_disponibile from '../assets/video_non_disponibile.png';
import Papa from 'papaparse';
import _ from 'lodash';

const comuni = require('../assets/comuni.json')

const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const CSVUpload = () => {

  const dispatch = useDispatch()
  const classes = useStyles()

  const [fileCSV, setFileCSV] = useState({})
  const [uploadFile, setUploadFile] = useState(false)
  const [fileName, setFileName] = useState("")
  const [fileSize, setFileSize] = useState(0)

  let actionDone = useSelector(UiSelectors.getActionDone)
  // dispatch(UiActions.didSetActionDone(false))

  const handleChange = (e: any) => {
    e.preventDefault()
    console.log("file caricato", e.target.files[0])
    setFileCSV(e.target.files[0])
    setFileName(e.target.files[0].name)
    setFileSize(e.target.files[0].size)
    setUploadFile(true)
  }

  const preview = (e: any) => {
    e.preventDefault()
    // setModalUpload(true)
    let csvfile: any = {};
    csvfile = fileCSV
    dispatch(ContentActions.willUploadCSV({ file: fileCSV }))
    // console.log("file cvs: ", fileCSV)
    
    // Papa.parse(csvfile, {
    //   complete: viewFile,
    //   header: true
    // });
  }
  const viewFile = (result: any) => {
    var data = result.data;
    // console.log("risultato parse: ", data);
    // console.log("file csv parsato che mando al componente lista modale", data)
    dispatch(ContentActions.willAddBatchBillboards({ batchBillboards: data }));
    // dispatch(UiActions.switchCloseAddNewPlant())
  }

  // ///funzione download file da s3
  // const downloadS3 = () => {
  //   var AWS = require('aws-sdk');
  //   AWS.config.update(
  //     {
  //       accessKeyId: ".. your key ..",
  //       secretAccessKey: ".. your secret key ..",
  //     }
  //   );
  //   var s3 = new AWS.S3();
  //   s3.getObject(
  //     { Bucket: "my-bucket", Key: "my-picture.jpg" },
  //     (error: any, data: any) => {
  //       if (error != null) {
  //         alert("Errore nel download: " + error);
  //       }
  //       // do something with data.Body
  //     }
  //   );
  // }


  return (
    <div className="poppinsFont">
      {actionDone.action === "csvUpload" ?
        <Grid className={classes.csvUploaded} container alignItems="center" justify="center" spacing={2}>
          <Grid style={{fontSize:20}} item xs={12}>
            File caricato correttamente. Il Team di Billalo provvederà a lavorarlo e caricherà gli impianti nel minor tempo possibile.
          </Grid>
          <Grid item xs={12}>
            <button data-cy="confirm" style={confirmButton} onClick={() => {
              dispatch(UiActions.switchCloseAddNewPlant())
              dispatch(UiActions.didSetActionDone({done: null, action: ""}))
            }}>Torna alla lista degli impianti</button>
            {/* <button>Conferma</button> */}
          </Grid>
        </Grid>
        : <> <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="d-sm-flex justify-content-between align-items-center border-bottom">
                <b className="ml-3">Carica CSV impianti</b>
                <div>
                  {/* <IconButton className="bg-transparent border-0 mr-1 right">
                  <Icon color="#bfbfbf" size={20} icon="more" />
                </IconButton> */}
                  <IconButton className="bg-transparent border-0 mr-1" onClick={() => dispatch(UiActions.switchCloseAddNewPlant())}>
                    <Icon color="#bfbfbf" size={20} icon="close" />
                  </IconButton>
                </div>
              </div>
            </Grid>
            <Grid style={{ height: 250 }} item xs={12}>
              <img src={video_non_disponibile} width="100%" height="100%" alt="video_non_disponibile" />
              {/* <ReactPlayer
                width='100%'
                height='100%'
                url='http://www.youtube.com/embed/_K527O5B-qQ'
                controls /> */}
              {/* <iframe width="100%" height="250" src="http://www.youtube.com/embed/_K527O5B-qQ"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe> */}
            </Grid>
            <Grid container item xs={12}>
              <Grid container item xs={6} alignItems="center">
                <Grid className="hoverPointer" container alignItems="center" >
                  <Grid className={classes.csvButtonDownload} item xs={10}>
                    <Grid className={classes.csvButtonIcon} item xs={4}><Icon color="rgb(102, 176, 255)" size={30} icon="doc" /></Grid>
                    <Grid className="ml-3" container item xs={8}>
                      <Grid>Istruzioni PDF...<br /><span style={{ opacity: 0.5 }} >146.5 kb</span></Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={`${classes.csvButtonDownload} mt-3 hoverPointer`} item xs={10}>
                  <Grid className={classes.csvButtonIcon} item xs={4}><Icon color="rgb(102, 176, 255)" size={30} icon="doc" /></Grid>
                  <Grid className="ml-3" container item xs={8}>
                    <Grid>template...<br /><span style={{ opacity: 0.5 }} >146.5 kb</span></Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item xs={6} alignItems="center">
                {/* <a href="https://c.files.bbci.co.uk/16620/production/_91408619_55df76d5-2245-41c1-8031-07a4da3f313f.jpg" download >DOWNLOAD IMAGE</a> */}
                {uploadFile ? <>
                  <div style={{ height: 60, width: 150, }}><p>File caricato.</p></div>
                  <Grid className={`${classes.csvButtonUploaded} d-sm-flex align-items-center mt-3`} item xs={10}>
                    <Grid className={classes.csvButtonIconUploaded} item xs={4}><Icon color="#77b300" size={30} icon="attachment" /></Grid>
                    <Grid className="ml-2" container item xs={4}>
                      <Grid>{fileName.substring(0, 8)}..<br /><span style={{ opacity: 0.5 }} >{formatBytes(fileSize)}</span></Grid>
                    </Grid>
                    <Grid className="d-sm-flex align-items-center justify-content-center hoverPointer" onClick={() => {
                      setFileCSV({});
                      setUploadFile(false);
                    }} item xs={4}><Icon size={30} icon="trash" /></Grid>
                  </Grid> </> : <Grid container alignItems="center" justify="center" > <input hidden accept=".csv" id="contained-button-file" onChange={handleChange} multiple type="file" />
                  <label htmlFor="contained-button-file">
                    <Button style={csvButtonUpload} color="primary" component="span">
                      <b className="poppinsFont">Carica csv</b>
                    </Button>
                  </label> </Grid>}
              </Grid>
            </Grid>
          </Grid>
        </Form>
          {uploadFile ? <Grid className="mt-3" container item xs={12} spacing={1}>
            <Grid container item xs={3}>
              <button data-cy="cancel" style={cancelButton} onClick={() => {
                dispatch(UiActions.switchCloseAddNewPlant())
              }}>Annulla</button>
            </Grid>
            <Grid container item xs={9}>
              <ActivityButton data-cy="confirm" style={confirmButton} onClick={preview} name="confermaAggiuntaBatch" color="primary" disabled block>Conferma</ActivityButton>
              {/* <button>Conferma</button> */}
            </Grid>
          </Grid> : null}
        </>}

    </div>
  )
}