import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { sagas as authSagas } from './auth'
import { sagas as notificationSagas } from './notification'
import { sagas as profileSagas } from './profile'
import { sagas as contentSagas } from './content'
import { sagas as paymentSagas } from './payment'
import { sagas as exploreSagas } from './explore'
import { sagas as uiSagas } from './ui'


function* runAllSagas() {
  console.log('in root saga')
  yield all([
    profileSagas(),
    notificationSagas(),
    authSagas(),
    contentSagas(),
    paymentSagas(),
    uiSagas(),
    exploreSagas()
  ])
}

export default runAllSagas;