import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Grid, TextField } from '@material-ui/core';
import { Field } from 'formik';
import { actions as ContentActions } from '../../store/slices/content';
import { useStyles } from './AddNewBillboard.css';

export const InputNewBillboard = ({ data, values, setFieldValue, touched, errors, name, label, type, readOnly, fullWidth, placeholder, hidden, disabled, errorControl, setErrorControll }: any) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [inputValue, setInputValue] = useState('')

  const alternativeKey = name.split('.')

  const setValues = (key: string, value: any, setFieldValue: any) => {
    setFieldValue(key, value)
    dispatch(ContentActions.setBillboard({ key: key, value: value }))
  }

  return (
    <Grid item xs={fullWidth ? 11 : 5}>
      <Field as={TextField}
        className={`${classes.borderColor}`}
        data-cy={name}
        fullWidth
        id={name}
        hidden={hidden}
        disabled={disabled}
        label={label}
        type={type}
        name={name}
        placeholder={placeholder ? placeholder : ''}
        variant="outlined"
        size="small"
        value={readOnly
          ? data
          : name.includes('.')
            ? values[alternativeKey[0]][alternativeKey[1]]
            : values[name]}
        InputProps={{
          readOnly: readOnly,
        }}
        inputValue={inputValue}
        onInputChange={(_: any, newInputValue: any) => {
          setInputValue(newInputValue)
        }}
        onChange={(e: any) => { 
          setValues(name, e.target.value, setFieldValue); 
          setErrorControll && setErrorControll(false) }}
        helperText={
          errorControl
            ? 'Required'
            : touched[name]
              ? errors[name]
              : ""
        }
        error={errorControl
          ? Boolean('Required')
          : touched[name] && Boolean(errors[name])}
      />
    </Grid>
  )
}