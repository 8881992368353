import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { Grid } from '@material-ui/core';
import { confirmButton } from "./plant-list/PlantListCss";

export const BillaloSupport = () => {

  const dispatch = useDispatch()

  return (
    <>
      <div>
        <div className="d-sm-flex mt-4 mb-3 poppinsFont">
          <Grid container item xs={12} alignItems="center" justify="center">
            <Grid className="p-2" container item xs={12}>
              Per richiedere una modifica dell'ordine, contattare il team di Billalo su info@billalo.com
            </Grid>
            <Grid className="p-2" container item xs={12} alignItems="center" justify="center">
              <Grid container item xs={9}>
                <button data-cy="confirm" style={confirmButton} onClick={() => {
                  dispatch(UiActions.switchCloseAddNewPlant())
                }}>Torna alla lista degli ordini</button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  )
}