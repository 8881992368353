import React, { HTMLAttributes } from "react";
import { Grid, Typography } from "@material-ui/core";
import cn from "classnames";

import moment from "moment";
import Icon from "../IconsComponent";

import { Availability } from "./PlantAvailability.types";

import { useStyles } from "./PlantAvailabilityListItem.styles";
import { getTitle, getDescription } from "./PlantAvailability.utils";

interface PlatAvailabilityListItemProps extends HTMLAttributes<HTMLElement> {
  availability: Availability;
}

export const PlatAvailabilityListItem: React.FC<
  PlatAvailabilityListItemProps
> = ({ availability, ...props }) => {
  const classes = useStyles();
  return (
    <Grid
      key={availability.id}
      item
      container
      spacing={1}
      className={cn(classes.listItem, { [classes.pointer]: !!props.onClick })}
      wrap="nowrap"
      {...props}
    >
      <Grid item xs={false}>
        <Icon color="grey" size={24} icon="schedule" />
      </Grid>
      <Grid item xs={4}>
        <Typography
          noWrap
          variant="caption"
          className={cn(classes.listItemDate)}
        >
          {[
            moment(availability.from).format("D MMM"),
            moment(availability.to).format("D MMM"),
          ].join(" - ")}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography
          component={"div"}
          noWrap
          variant="body1"
          className={classes.bold}
        >
          <div
            className={cn(
              classes.badge,
              classes[`badge_${availability.status}`]
            )}
          />
          {getTitle(availability)}
        </Typography>
        <Typography variant="body2" className={classes.listItemSubtitle}>
          {getDescription(availability)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PlatAvailabilityListItem;
