import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { loader } from 'graphql.macro';
import { configuration } from '../config';


export const listMediaOwnerUsers = async () => {
  console.log("with query: ")
  const query = loader('../graphql/listMediaOwnerUsers.gql');

  try {
    const result: any = await API.graphql({ query: query });
    console.log("in listMediaOwnerUsers with", result)
    return result
  } catch (error) {
    console.log("errore api")
    throw error
  }
}

export const createStripeLoginLink = async (stripe_account: string) => {
  console.log("with query createStripeLoginLink", stripe_account)
  const query = loader('../graphql/createStripeLoginLink.gql');

  try {
    const result: any = await API.graphql({ query: query, variables: { acct: stripe_account } });
    console.log("in createStripeLoginLink with", result)
    return result.data.createStripeLoginLink
  } catch (error) {
    console.log("errore api")
    throw error
  }
}

export const registerStripeAccount = async () => {
  console.log("with query registerStripeAccount")
  const mutation = loader('../graphql/registerStripeAccount.gql');

  try {
    const result: any = await API.graphql({ query: mutation });
    console.log("in registerStripeAccount with", result)
    return result.data.registerStripeAccount
  } catch (error) {
    console.log("errore api")
    throw error
  }
}