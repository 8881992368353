import React, { useState } from 'react';
import { Popover, Checkbox, Typography, Grid, Slider, Button, Paper, InputAdornment, TextField, LinearProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Icon from "../IconsComponent";
import { billboardsTypes, groungHeight, lightingType, orientationType, billboardsFormat } from "../Autocomplete.options";
import moment from 'moment';
import { useStyles, BorderLinearProgress } from './ListFilter.css';
import { useDispatch, useSelector } from 'react-redux';
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { useSuggestion } from "../Suggestion.hooks";
import Autosuggest, { BlurEvent, ChangeEvent } from 'react-autosuggest';
import { result } from 'cypress/types/lodash';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import _ from 'lodash';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" className="lightBlueColor" />;
const checkedIcon = <CheckBoxIcon fontSize="small" className="lightBlueColor" />;

const valuetext = (value: number) => {
  return `${value}`;
}

export const ListFilter = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const filters = useSelector(ContentSelectors.getFilters)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [city, setCity] = useState("")
  const [dateFrom, setDateFrom] = useState({});
  const [dateTo, setDateTo] = useState({});
  const [available, setAvailable] = useState(false);
  const [billaloIndexStart, setBillaloIndexStart] = useState("");
  const [billaloIndexStop, setBillaloIndexStop] = useState("");
  const [media_type, setMediaType] = useState([]) as any
  const [format, setFormat] = useState([]) as any
  const [visualFormat, setVisualFormat] = useState([]) as any
  const [lighting, setLighting] = useState([]) as any
  const [orientation, setOrientation] = useState([]) as any
  const [height_ground, setHeightGround] = useState([]) as any
  const [suggestions, setSuggestions] = useState([] as any)
  const [billaloIndexSelector, seBillaloIndexSelector] = useState<number[]>([0, 100]);
  //// questi due sotto servono per AutoSuggest, non eliminateli!
  const [data1, setData] = useState("")
  const [fieldValue, setFieldValue] = useState("")

  const {
    getSuggestions,
    getSuggestionValue,
    renderSuggestion,
    renderSectionTitle,
    getSectionSuggestions,
    onChange,
    value,
    setValue,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
  }: any = useSuggestion({
    setFieldValue,
    setData,
    setSuggestions,
    setCity,
  })

  const resetFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setValue("")
    setDateFrom({})
    setDateTo({})
    setAvailable(false)
    setBillaloIndexStart("")
    setBillaloIndexStop("")
    setMediaType([])
    setFormat([])
    setVisualFormat([])
    setLighting([])
    setOrientation([])
    setHeightGround([])
    setSuggestions([])
    seBillaloIndexSelector([0, 100])
  }

  const inputProps: any = {
    placeholder: "Comune",
    value,
    onChange: onChange,
    className: "autosuggestFilter",
    id: "comune"
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const data = {
    area: { value: value },
    availabilities: { available: available, start: dateFrom, stop: dateTo }, //available sarebbe il checkbox "disponibili"
    billalo_index: { start: billaloIndexStart, stop: billaloIndexStop },
    media_type: { value: media_type.map((i: any) => i.typology) },
    format: { value: format },
    lighting: { value: lighting.map((i: any) => i.lighting) },
    orientation: { value: orientation.map((i: any) => i.orientation) },
    height_ground: { value: height_ground.map((i: any) => i.height_ground) },
  }

  const billaloIndexChange = (event: any, newValue: any) => {
    seBillaloIndexSelector(newValue as number[]);
    setBillaloIndexStart(newValue[0])
    setBillaloIndexStop(newValue[1])
  };

  return (
    <div>
      {filters.area ?
        <Button style={{borderRadius:10}} data-cy="filterButton" aria-describedby={id} className={`bg-primary border-0 mr-3 `} onClick={handleClick} ><Icon color="#FFFFFf" size={25} icon="filter" /></Button>
        :
        <Button style={{borderRadius:10}} data-cy="filterButton" aria-describedby={id} className={`bg-white border mr-3`} onClick={handleClick} ><Icon color="#0062cc" size={25} icon="filter" /></Button>
      }
      <Popover
        className={classes.popover}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <div className={`${classes.root} robotoFont`}>
          <h2 className="mt-4 ml-3">Filtra impianti</h2>
          <Grid className="p-2" container spacing={3}>
            <Grid item xs={12}>
              <Autosuggest
                multiSection={true}
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                renderSectionTitle={renderSectionTitle}
                getSectionSuggestions={getSectionSuggestions}
                inputProps={inputProps}
                theme={{
                  input: classes.autosuggest,
                  inputOpen: classes.autosuggest,
                  inputFocused: classes.inputFocused,
                  suggestionsContainer: classes.absolute,
                  suggestionsContainerOpen: classes.absolute,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  className={classes.borderColor}
                  data-cy="dateFrom"
                  disableToolbar
                  disablePast
                  autoOk={true}
                  format="DD/MM/YYYY"
                  variant="inline"
                  id="start-date"
                  label="Data inizio"
                  keyboardIcon={<Icon color="grey" size={25} icon="schedule" />}
                  value={startDate}
                  onChange={(e: any) => {
                    setDateFrom(moment(e._d).format("YYYY-MM-DD"))
                    setStartDate((e._d))
                  }}
                  InputAdornmentProps={{ position: "start" }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  className={classes.borderColor}
                  data-cy="dateTo"
                  disableToolbar
                  disablePast
                  autoOk={true}
                  format="DD/MM/YYYY"
                  variant="inline"
                  id="end-date"
                  label="Data fine"
                  keyboardIcon={<Icon color="grey" size={25} icon="schedule" />}
                  value={endDate}
                  onChange={(e: any) => {
                    setDateTo(moment(e._d).format("YYYY-MM-DD"))
                    setEndDate((e._d))
                  }}
                  InputAdornmentProps={{ position: "start" }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <span><input data-cy="available" type="checkbox" color="#007bff" checked={available} onChange={(e: any) => {
                setAvailable(!available)
              }} /> Disponibili</span>
            </Grid>
            <span className="ml-3 mb-4 text-black-50 detailText" data-cy="indiceBillalo">Indice Visibilità</span>
            <Grid item xs={12}>
              <Slider
                data-cy="visibilityIndex"
                className={classes.billaloIndexSlide}
                value={billaloIndexSelector}
                onChange={billaloIndexChange}
                scale={(x) => x / 10}
                valueLabelDisplay="on"
                aria-labelledby="range-slider"
                valueLabelFormat={valuetext}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                className={classes.borderColor}
                id="media_type"
                value={media_type}
                options={billboardsTypes}
                getOptionLabel={(option) => option.typology}
                renderInput={(params) => <TextField data-cy="trovami" {...params} label="Tipologia" />}
                onChange={(e: any, value: any) => { setMediaType(value) }}
                disableCloseOnSelect
                multiple
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.typology}
                  </React.Fragment>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                className={classes.borderColor}
                id="format"
                value={visualFormat}
                options={billboardsFormat}
                getOptionLabel={(option) => option.format}
                renderInput={(params) => <TextField {...params} label="Formato" />}
                onChange={(e: any, value: any) => {
                  let result = [] as any
                  value.map((i: any) => {
                    if (i.format == "1200x300") {
                      result.push({ base: 1200, height: 300 })
                    }
                    else if (i.format == "600x300") {
                      result.push({ base: 600, height: 300 })
                    }
                    else if (i.format == "400x300") {
                      result.push({ base: 400, height: 300 })
                    }
                    else if (i.format == "180x200") {
                      result.push({ base: 180, height: 200 })
                    }
                    else if (i.format == "200x140") {
                      result.push({ base: 200, height: 140 })
                    }
                    else if (i.format == "140x200") {
                      result.push({ base: 140, height: 200 })
                    }
                    else if (i.format == "100x140") {
                      result.push({ base: 100, height: 140 })
                    }
                    else if (i.format == "70x100") {
                      result.push({ base: 70, height: 100 })
                    }
                    else if (i.format == "Altro") {
                      result.push("Altro")
                    }
                  })
                  setFormat(result)
                  setVisualFormat(value)
                }}
                disableCloseOnSelect
                multiple
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.format}
                  </React.Fragment>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                className={classes.borderColor}
                id="lighting"
                value={lighting}
                options={lightingType}
                getOptionLabel={(option) => option.lighting}
                renderInput={(params) => <TextField {...params} label="Illuminazione" />}
                onChange={(e: any, value: any) => { setLighting(value) }}
                disableCloseOnSelect
                multiple
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.lighting}
                  </React.Fragment>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                className={classes.borderColor}
                id="orientation"
                value={orientation}
                options={orientationType}
                getOptionLabel={(option) => option.orientation}
                renderInput={(params) => <TextField {...params} label="Orientamento" />}
                onChange={(e: any, value: any) => { setOrientation(value) }}
                disableCloseOnSelect
                multiple
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.orientation}
                  </React.Fragment>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                className={classes.borderColor}
                id="height_ground"
                value={height_ground}
                options={groungHeight}
                getOptionLabel={(option) => option.height_ground}
                renderInput={(params) => <TextField {...params} label="Altezza da Terra" />}
                onChange={(e: any, value: any) => { setHeightGround(value) }}
                disableCloseOnSelect
                multiple
                renderOption={(option, { selected }) => {
                  //console.log(option)
                  return (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.height_ground}
                    </React.Fragment>
                  )
                }}
              />
            </Grid>
            <Grid className="mt-3" container item xs={12} direction="row-reverse">
              <button data-cy="confirm" className={classes.confirmButton} onClick={() => {
                dispatch(ContentActions.willFilterBillboards({ filters: data }))
                setAnchorEl(null)
                console.log("Selected filters:", data)
              }} >Conferma</button>
              <button className={classes.cancelButton} onClick={handleClose}>Annulla</button>
              <button className={classes.cancelButton} onClick={() => {
                dispatch(ContentActions.willFilterBillboards({ filters: {} }))
                resetFilter()
                console.log("Selected filters:", data)
                setAnchorEl(null)
              }} >Azzera Filtro</button>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </div>
  );
}