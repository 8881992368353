import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { Button, Backdrop, Popover, CircularProgress, Grid, Paper, Tooltip } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { useStyles, modifiersStyles, renderDay, quarterStatusMaintenance, quarterStatusAvailability } from './AvailabilityComponent.css';
import Icon from '../IconsComponent';
import moment from 'moment';
import 'moment/locale/it';
import _ from 'lodash';


export const AvailabilityComponent = (props: any) => {
  const { setDate, setFilter, filter, setMaintenanceDates, data, maintenanceGroup, maintenanceDates,
    maintenance, availabilities, modalType, addedMainten, setAddedMainten, deletedMainten, setDeletedMainten } = props
  const classes = useStyles();

  const [year, setYear] = useState(moment()) as any
  const [period, setPeriod] = useState({} as any)
  const [currentYear, setCurrentYear] = useState(moment().year())
  let occupatedDays1 = [] as any
  const [occupatedDays, setOccupatedDays] = useState(!_.isEmpty(filter) ? filter.uscite : occupatedDays1) as any
  const [selectedQuatt, setSelectedQuatt] = useState([]) as any
  const [maintenanceArray, setMaintenanceArray] = useState([]) as any
  const [maintenanceDays, setMaintenanceDays] = useState([]) as any
  const [firstMonday, setFirstMonday] = useState() as any
  const [oggetto, setOggetto] = useState({}) as any
  // console.log("filter", filter)

  const billboardAvailabilities = useSelector(ContentSelectors.listBillboardAvailabilities)
  // console.log("billboardAvailabilities", billboardAvailabilities)

  const select = (quattordicina: any) => {
    let startYear: any
    if (currentYear === 2023) {
      startYear = moment("2023-01-02")
    }
    else if (currentYear === 2034) {
      startYear = moment("2034-01-02")
    }
    else if (currentYear === 2040) {
      startYear = moment("2040-01-02")
    }
    else if (currentYear === 2045) {
      startYear = moment("2045-01-02")
    } else {
      startYear = moment().year(currentYear).startOf('year').day("Monday").add(8, 'days')
    }
    const inizioQuattordicina = startYear.add(_.multiply(7, quattordicina - 1), 'days')

    let quatt = [...selectedQuatt] /// questo è per il periodo
    let mainten = [...maintenanceArray] /// questo è per la manutenzione
    let addArray = [...addedMainten] as any /// questo è per la modifica della manutenzione
    let deleteArray = [...deletedMainten] as any /// questo è per la modifica della manutenzione

    if (billboardAvailabilities && billboardAvailabilities[0] !== "empty") {
      let day = inizioQuattordicina.format("YYYY-MM-DD")
      // console.log("true selected quattordicina", addArray, quattordicina, oggetto[quattordicina], day)

      if (oggetto[quattordicina] || addArray.includes(day)) { /// questo serve per farlo entrare in questa condizione
        // console.log("true addArray.includes(inizioQuattordicina)", addArray.includes(day))
        if (addArray.includes(day)) { /// se l'elemento è presente nell'array di aggiunta, viene eliminato
          addArray = _.remove(addArray, day)
        } else {
          // console.log("true deleteArray.includes(inizioQuattordicina)", deleteArray.includes(day))
          if (deleteArray.includes(day)) { /// se l'elemento è presente nell'array di eliminazione, viene eliminato
            deleteArray = _.filter(deleteArray, day)
          } else {
            deleteArray.push(day)
            // console.log("deleteArray.push", day)
          }
        }
      } else {
        // console.log("true deleteArray", deleteArray)
        addArray.push(day)
        // console.log("addArray.push", day)
      }
      setDeletedMainten(deleteArray)
      setAddedMainten(addArray)
    }

    if (quatt.includes(quattordicina) || mainten.includes(inizioQuattordicina)) {
      // console.log("true")
      setSelectedQuatt(quatt.filter((i: any) => {
        return i != quattordicina
      }))
      setMaintenanceArray(mainten.filter((i: any) => {
        let x = moment(i).format("DD-MM-YYYY")
        let y = moment(inizioQuattordicina).format("DD-MM-YYYY")
        // console.log("true 1", i, inizioQuattordicina, x, y, x != y)
        return x != y
      }))
      // console.log("true", mainten, maintenance, selectedQuatt)
    }
    else {
      quatt.push(quattordicina)
      mainten.push(inizioQuattordicina)
      setSelectedQuatt(quatt)
      setMaintenanceArray(mainten)
      setMaintenanceDates(mainten)
    }
  }
  // console.log("maintenanceDates", maintenanceDates)
  // console.log("addedMainten", addedMainten)
  // console.log("deleteMainten", deletedMainten)

  const selectGroup = (quattordicina: any) => {
    // const startYear = moment().year(currentYear).startOf('year').day("Monday").add(8, 'days')
    let startYear: any
    if (currentYear === 2023) {
      startYear = moment("2023-01-02")
    }
    else if (currentYear === 2034) {
      startYear = moment("2034-01-02")
    }
    else if (currentYear === 2040) {
      startYear = moment("2040-01-02")
    }
    else if (currentYear === 2045) {
      startYear = moment("2045-01-02")
    } else {
      startYear = moment().year(currentYear).startOf('year').day("Monday").add(8, 'days')
    }
    const inizioQuattordicina = startYear.add(_.multiply(7, quattordicina - 1), 'days')

    let quatt = [...selectedQuatt] /// questo è per il periodo
    let mainten = [...maintenanceArray] /// questo è per la manutenzione

    if (quatt.includes(quattordicina) || mainten.includes(inizioQuattordicina)) {
      // console.log("true")
      setSelectedQuatt(quatt.filter((i: any) => {
        return i != quattordicina
      }))
      setMaintenanceArray(mainten.filter((i: any) => {
        let x = moment(i).format("DD-MM-YYYY")
        let y = moment(inizioQuattordicina).format("DD-MM-YYYY")
        // console.log("true 1", i, inizioQuattordicina, x, y, x != y)
        return x != y
      }))
      // console.log("true", mainten, maintenance, selectedQuatt)
    }
    else {
      quatt.push(quattordicina)
      mainten.push(inizioQuattordicina)
      setSelectedQuatt(quatt)
      setMaintenanceArray(mainten)
    }
    // console.log("maintenanceArray", maintenanceArray)
    // console.log("maintenanceArray", occupatedDays)
  }

  const mapQuart = () => {
    let startYear = moment(year).startOf('year').day("Monday").add(8, 'days')
    if (startYear.month() !== 0) {
      if (currentYear === 2023) {
        startYear = moment("2023-01-02")
      }
      else if (currentYear === 2034) {
        startYear = moment("2034-01-02")
      }
      else if (currentYear === 2040) {
        startYear = moment("2040-01-02")
      }
      else if (currentYear === 2045) {
        startYear = moment("2045-01-02")
      } else {
        startYear = moment(year).startOf('year').day("Monday").add(8, 'days')
      }
    } else {
      setFirstMonday(startYear)
    }
    let object = {} as any

    if (billboardAvailabilities && billboardAvailabilities[0] !== "empty") {
      if (billboardAvailabilities.length > 0) {
        billboardAvailabilities.forEach((day: any) => {
          let year = moment(day.start_busy_date).year()
          // console.log("years", currentYear, year, startYear)
          if (currentYear === year) {
            // console.log("mapQuart 1", data)
            if (firstMonday === undefined) {
              // console.log("mapQuart 2", day.start_busy_date)
              let monday = startYear
              let daysBetween = monday.diff(day.start_busy_date, 'days')
              const quattordicina = _.floor((daysBetween - 7) / 7)
              const cosa = quattordicina
              const multiply = _.round(_.multiply(cosa, -1))
              const x = _.multiply(26, (currentYear - moment().year())) + multiply
              maintenanceDays.map((o: any) => {
                if (o === x) {
                  console.log(x, "already added")
                } else {
                  maintenanceDays.push(x)
                }
              })
              // maintenanceDays.push(x)
              object[multiply] = day
            } else {
              let daysBetween = startYear.diff(day.start_busy_date, 'days')
              const quattordicina = _.floor((daysBetween - 7) / 7)
              const cosa = quattordicina
              // console.log("mapQuart 3", cosa, quattordicina, daysBetween, firstMonday)
              const multiply = _.round(_.multiply(cosa, -1))
              const x = _.multiply(26, (currentYear - moment().year())) + multiply
              maintenanceDays.map((o: any) => {
                if (o === x) {
                  console.log(x, "already added")
                } else {
                  maintenanceDays.push(x)
                }
              })
              // maintenanceDays.push(x)
              object[multiply] = day
              // console.log("mapQuart 2false", startYear, firstMonday, day.start_busy_date, daysBetween, quattordicina, cosa)
            }
          }
        })
      } else {
        // console.log("mapQuart 4")
        setMaintenanceDays([])
      }
      setOggetto(object)
      // console.log("mapQuart 5", maintenanceDays, object)
    }
  }

  useEffect(() => {
    if (!_.isEmpty(data)) {
      mapQuart()
    }
  }, [currentYear, data, billboardAvailabilities])

  useEffect(() => {
    setOccupatedDays(!_.isEmpty(filter) ? filter.uscite : occupatedDays1)
    if (!_.isEmpty(maintenanceArray)) {
      setMaintenanceDates(maintenanceArray)
    }
    // console.log("maintenance", maintenance)
  }, [filter, maintenance])

  const VisualizedDate = (quattordicina: any, info: any) => {
    let startYear: any
    if (currentYear === 2023) {
      startYear = moment("2023-01-02")
    }
    else if (currentYear === 2034) {
      startYear = moment("2034-01-02")
    }
    else if (currentYear === 2040) {
      startYear = moment("2040-01-02")
    }
    else if (currentYear === 2045) {
      startYear = moment("2045-01-02")
    } else {
      startYear = moment(year).startOf('year').day("Monday").add(8, 'days')
    }
    // console.log("info",quattordicina, info)
    if (info) {
      if (info.is_maintenance) {
        return <span data-cy="is_maintenance_true" style={{ fontSize: 15 }} >{`${startYear.add(_.multiply(7, quattordicina - 1), 'days').format('DD-MM-YYYY')}\nIn manutenzione`}</span>
      } else {
        return <span data-cy="is_maintenance_false" style={{ fontSize: 15 }} >{`${startYear.add(_.multiply(7, quattordicina - 1), 'days').format('DD-MM-YYYY')}\nOccupato`}</span>
      }
    }
    return <span data-cy="buttonNotOccupated" style={{ fontSize: 15 }} >{startYear.add(_.multiply(7, quattordicina - 1), 'days').format('DD-MM-YYYY')}</span>
  }

  const addDateToReserve = (quattordicina: any) => {
    let annoAttuale = !_.isEmpty(filter) ? filter.year : moment().year()
    let numeroUscita = (currentYear - annoAttuale) * 26 + quattordicina
    // console.log("addDateToReserve", filter, numeroUscita, annoAttuale, quattordicina)
    // console.log('test uscite', occupatedDays)
    // occupatedDays.includes(numeroUscita) ? occupatedDays.splice(occupatedDays.indexOf(numeroUscita), 1) : (
    //   (occupatedDays.includes(numeroUscita - 1) || occupatedDays.includes(numeroUscita + 1)) ? occupatedDays.push(numeroUscita) : null)
    let array = [...occupatedDays]

    if (array.includes(numeroUscita)) {
      if (!array.includes(numeroUscita - 1) || !array.includes(numeroUscita + 1)) {
        // console.log('in true', occupatedDays, numeroUscita)
        array.splice(array.indexOf(numeroUscita), 1)
      }
    }
    else if (((array.includes(numeroUscita - 1) || array.includes(numeroUscita + 1)) || array.length === 0 || maintenance || maintenanceGroup) && (array.length !== 1000 || maintenance)) {
      array.push(numeroUscita)
      // console.log('else', occupatedDays)
    }

    // console.log("occupatedDays", occupatedDays)
    if (array.length > 0) {
      // console.log('occupatedDays array non vuoto')
      let max = _.max(array)
      let min = _.min(array)
      if (typeof (min) === "number" && typeof (max) === "number") {
        let anno_from: number = min <= 26 ? annoAttuale : annoAttuale + _.ceil(_.divide(min, 26)) - 1
        let anno_to: number = max <= 26 ? annoAttuale : annoAttuale + _.ceil(_.divide(max, 26)) - 1
        // console.log('data minimo massimo', min, max, anno_from, anno_to)
        let primoLunedi = moment().set('year', anno_from).set('month', 0).set('date', 1).isoWeekday(8)
        if (primoLunedi.date() > 7) {
          primoLunedi = primoLunedi.isoWeekday(-6)
        }
        let date = {
          from: moment(primoLunedi).add(7 * (min - 1), 'days').format("DD-MM-YYYY"),
          to: moment(primoLunedi).add(7 * (max - 1) + 13, 'days').format("DD-MM-YYYY")
        }
        setPeriod(date)
        // console.log("data", primoLunedi, date, min % 26 == 0 ? 52 : (min % 26 === 1 ? 1 : min + 1))
      }
    } else {
      setPeriod({})
    }
    setOccupatedDays(array)
  }

  useEffect(() => {
    setDate(period)
    // console.log('uscite: ', period, filter)
    // console.log("occupatedDays", occupatedDays)
    if (_.isEmpty(period)) {
      setFilter({})
    } else {
      setFilter({ year: moment().year(), uscite: occupatedDays, date: period })
    }
  }, [year, selectedQuatt])

  const array = _.range(1, 52)

  return (
    <>
      {_.isEmpty(billboardAvailabilities) && maintenanceGroup === true ?
        <div className="d-sm-flex align-items-center">
          <b className="mr-5">Caricamento in corso...</b>
          <CircularProgress color="inherit" /> </div> :
        <div className="d-sm-flex flex-column align-items-center mr-4 detailText">
          {/* <button onClick={() => {
            console.log("addedMainten", addedMainten)
            console.log("deletedMainten", deletedMainten)
          }}>TEST</button> */}
          <div className="d-sm-flex align-items-center ml-3">
            <Button disabled={currentYear === moment().year() ? true : false} data-cy="prevYear" style={{ fontSize: 12 }} className="border-0" onClick={() => { setCurrentYear(currentYear - 1); setYear(moment(year).subtract(1, 'year')); mapQuart(); }}><Icon size={20} color="#bfbfbf" icon="chevronLeft" /></Button>
            <b>{year.year()}</b>
            <Button data-cy="nextYEar" style={{ fontSize: 12 }} className="border-0" onClick={() => { setCurrentYear(currentYear + 1); setYear(moment(year).add(1, 'year')); mapQuart(); }}><Icon className="right" size={20} color="#bfbfbf" icon="chevronRight" /></Button>
          </div>
          <div className="d-sm-flex justify-content-around mt-2">
            <Grid container item xs={12} spacing={3}>
              {array.map((i: number) => {
                // let isDotOccupated = _.forEach(occupatedDays, function (number: number) { return number === (_.multiply(i, currentYear - moment().year())) ? true : false })
                let isOccupated = true
                _.forEach(occupatedDays, function (value: number) {
                  if (value === (_.multiply(26, (currentYear - moment().year())) + i)) {
                    isOccupated = false
                  }
                })
                let isAfter = false
                // const startYear = moment(year).startOf('year').day("Monday").add(8, 'days')
                let startYear: any
                if (currentYear === 2023) {
                  startYear = moment("2023-01-02")
                }
                else if (currentYear === 2034) {
                  startYear = moment("2034-01-02")
                }
                else if (currentYear === 2040) {
                  startYear = moment("2040-01-02")
                }
                else if (currentYear === 2045) {
                  startYear = moment("2045-01-02")
                } else {
                  startYear = moment(year).startOf('year').day("Monday").add(8, 'days')
                }
                let date = startYear.add(_.multiply(7, i - 1), 'days').format('YYYY-MM-DD')
                let today = moment().add(60, 'days').format('YYYY-MM-DD')
                let uscita = _.multiply(26, (currentYear - moment().year())) + i

                if (moment(today).isAfter(date)) {
                  isAfter = true
                }
                return (
                  <Grid key={`uscita-${i}`} item xs={2}>
                    <PopupState variant="popover" popupId="demo-popup-popover">
                      {(popupState) => (
                        <>
                          <Tooltip disableFocusListener disableTouchListener title={isAfter ? <span style={{ fontSize: 15, color:"black" }}>Non selezionabile</span> : <span style={{ color:"black"}}>{VisualizedDate(i, oggetto[i])}</span>}>
                            <button data-cy={`quattordicina-${i}`} style={availabilities === true || modalType === "disponibilityGroup" ? quarterStatusAvailability(isAfter, isOccupated, oggetto[i], deletedMainten, date) : quarterStatusMaintenance(isAfter, isOccupated, oggetto[i], deletedMainten, date)} color="primary" {...bindTrigger(popupState)}
                              onClick={() => {
                                if (!_.isEmpty(data)) {
                                  // console.log("uscita scelta:", _.multiply(26, (currentYear - moment().year())) + i)
                                  // console.log("oggetto", oggetto)
                                  if (modalType === "disponibilityGroup" || maintenanceGroup) {
                                    if (isAfter || oggetto[i]) {
                                      console.log("non selezionabile")
                                    } else if (!isAfter) {
                                      selectGroup(i);
                                      addDateToReserve(i)
                                    } else {
                                      if (oggetto[i]) {
                                        console.log("non disponibile")
                                      } else {
                                        if (!isAfter) {
                                          selectGroup(i);
                                          addDateToReserve(i)
                                        } else {
                                          console.log("non selezionabile")
                                        }
                                      }
                                    }
                                  }
                                  else if (availabilities || maintenance) {
                                    if (availabilities) {
                                      if (oggetto[i] && oggetto[i].is_maintenance) {
                                        console.log("non selezionabile")
                                      } else {
                                        if (!isAfter) {
                                          select(i);
                                          addDateToReserve(i)
                                        } else {
                                          console.log("non selezionabile")
                                        }
                                      }
                                    }
                                    else if (maintenance) {
                                      if (oggetto[i] && oggetto[i].is_maintenance === false) {
                                        console.log("non selezionabile")
                                      } else {
                                        if (!isAfter) {
                                          select(i);
                                          addDateToReserve(i)
                                        } else {
                                          console.log("non selezionabile")
                                        }
                                      }
                                    }
                                  } else {
                                    if (oggetto[i]) {
                                      console.log("non disponibile")
                                    } else {
                                      if (!isAfter) {
                                        select(i);
                                        addDateToReserve(i)
                                      } else {
                                        console.log("non selezionabile")
                                      }
                                    }
                                  }
                                }
                                else {
                                  if (!isAfter) {
                                    selectGroup(i);
                                    addDateToReserve(i)
                                  }
                                }
                              }}><span className="d-sm-flex flex-column align-items-center"><a>{i}</a><div className={isOccupated ? classes.dotNotOccupated : classes.dotOccupated} /></span></button>
                          </Tooltip>
                          <Popover data-cy={`popoverOccupated-${i}`} {...bindPopover(popupState)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
                            transformOrigin={{ vertical: 'top', horizontal: 'center', }}
                          >
                            {/* {isOccupated} */}
                            {/* {occupatedDays.includes(i) ? occupatedPopover(i) : NotOccupatedPopover(i)} */}
                          </Popover>
                        </>
                      )}
                    </PopupState>
                  </Grid>
                )
              })
              }
            </Grid>
          </div>
        </div>
      }
    </>
  );
}