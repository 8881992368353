import { call, put, takeEvery, takeLatest, delay } from 'redux-saga/effects'

import { actions as ProfileActions } from '../slices/profile'
import { actions as UiActions } from '../slices/ui'
import { actions as ContentActions } from '../slices/content'
import { actions as NotificationActions } from '../slices/notification'
import * as AuthApi from '../../api/auth'
import { push } from 'connected-react-router'
import _ from 'lodash';

export function* sagas() {
  console.log('inside ui saga')
  yield takeLatest(UiActions.switchPricing.type, switchPricing)
  yield takeLatest(UiActions.goTo.type, goTo)
  yield takeLatest(UiActions.willSetNotificationsPage.type, willSetNotificationsPage)

}

function* switchPricing(action: any) {
  console.log('in switchPricing saga', action);
  if (action.payload == "home") {
    yield put(push("/"))
  } else {
    yield put(push("/pricing"))
  }
}

function* goTo(action: any) {
  if (action.payload.page === '/')
    yield put(push("/"))
  if (action.payload.page === '/orders/orderdetails')
    yield put(push("/orders/orderdetails"))
  if (action.payload === '/orders') {
    // console.log("in goTo saga ui", action)
    yield put(push("/orders"))
  }
  if (action.payload.page === 'backToOrders') {
    yield put(push("/orders"))
  }
  if (action.payload === '/profile') {
    // console.log("in goTo saga ui", action)
    yield put(push("/profile"))
  }
}

function* willSetNotificationsPage(action: any) {
  console.log('inside ui saga')

  const open = action.payload.open
  const orders: any[] = action.payload.orders
  const archivedOrders: any[] = action.payload.archivedOrders

  if (!_.isEmpty(orders) || !_.isEmpty(archivedOrders)) {
    console.log("orders already fetched")
  } else {
    yield put(ContentActions.willListOrders("empty"))
  }
  yield put(UiActions.didSetNotificationsPage(open))

}