import { Auth } from 'aws-amplify';

const getIdToken = async () => ({
  Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
});

export const configuration: any = {
  dev: {
    Auth: {

      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_VjG99WG0m',      //dev

      userPoolWebClientId: '54g3o40bjnm9vkj2cdb2cssmb0',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

    },

    // OPTION 1
    graphql_headers: getIdToken,

    'aws_appsync_graphqlEndpoint': 'https://mv5es7tnxzgdlmtcdz4jd4li4e.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  },
  stage: {
    Auth: {

      // REQUIRED - Amazon Cognito Region
      region: 'eu-west-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'eu-west-1_gHCqJJCQe',        //stage

      userPoolWebClientId: '3njj9uddctiblonmb3s8dfks9s',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false

    },

    // OPTION 1
    graphql_headers: getIdToken,
    
    'aws_appsync_graphqlEndpoint': 'https://vpvlbrtmorbnxc7rg3mxykqzua.appsync-api.eu-west-1.amazonaws.com/graphql',
    'aws_appsync_region': 'eu-west-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
  }
}

