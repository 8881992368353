import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Grid, TextField } from '@material-ui/core';
import { Field } from 'formik';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { actions as ContentActions } from '../../store/slices/content';
import { useStyles } from './AddNewBillboard.css';


export const AutocompleteNewBillboard = ({ data, values, setFieldValue, touched, errors, name, label, options, disabled }: any) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  // console.log("AutocompleteNewBillboard",setFieldValue, touched, errors)

  const setValues = (key: string, value: any, setFieldValue: any) => {
    setFieldValue(key, value)
    dispatch(ContentActions.setBillboard({ key: key, value: value }))
  }

  /// prendo il primo oggetto e ne estraggo la chiave da usare poi nell'Autocomplete
  const optionValue: string = Object.keys(options[0])[0]

  return (
    <Grid item xs={5}>
      <Autocomplete
        className={`${classes.borderColor}`}
        data-cy={name}
        size="small"
        id={name}
        disabled={disabled}
        value={values[name]}
        onChange={(e: any) => { setValues(name, e.target.textContent, setFieldValue) }}
        options={options.map((option: any) => option[optionValue])}
        getOptionSelected={(option, value) => option.id === value.id}
        renderInput={(params) =>
          <Field as={TextField}
            name={name}
            {...params}
            label={label}
            variant="outlined"
            helperText={touched[name] ? errors[name] : ""}
            error={touched[name] && Boolean(errors[name])}
          />
        }
        renderOption={(props: any, option: any) => {
          return (
            <span {...props} style={{ backgroundColor: 'transparent' }}>
              {props}
            </span>
          );
        }}
      />
    </Grid>
  )
}