import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { loader } from 'graphql.macro';
import { configuration } from '../config';
export const configure = () => {
  const stage: string = process.env.REACT_APP_STAGE != undefined ? process.env.REACT_APP_STAGE : "dev"
  Amplify.configure(configuration[stage]);
}

const usersCondition = [
  {
    id: "1",
    name: "Giancarlo",
    media_owner: "Gruppo",
    avatar: "https://biografieonline.it/img/bio/g/Giancarlo_Magalli.jpg"
  },
  {
    id: "2",
    name: "Loris",
    media_owner: "Gruppo",
    avatar: "https://biografieonline.it/img/bio/l/Lory_Del_Santo.jpg"
  },
  {
    id: "3",
    name: "Elon",
    media_owner: "Gruppo",
    avatar: "https://www.biography.com/.image/ar_4:3%2Cc_fill%2Ccs_srgb%2Cfl_progressive%2Cq_auto:good%2Cw_1200/MTY2MzU3Nzk2OTM2MjMwNTkx/elon_musk_royal_society.jpg"
  },
  {
    id: "4",
    name: "Mike",
    media_owner: "Gruppo",
    avatar: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Mike_Tyson_2019_by_Glenn_Francis.jpg/240px-Mike_Tyson_2019_by_Glenn_Francis.jpg"
  },
  {
    id: "5",
    name: "Tiger",
    media_owner: "Gruppo",
    avatar: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3e/Tiger_Woods_in_May_2019.jpg/220px-Tiger_Woods_in_May_2019.jpg"
  },
  {
    id: "6",
    name: "Demo",
    media_owner: "Gruppo",
    avatar: "http://pinkitalia.it/wp-content/uploads/2015/06/foto-Demo-Manifesto.jpg"
  },
]


export const listAdvertisers = async () => {
  console.log("with query: ")
  const query = loader('../graphql/listAdvertisers.gql');

  try {
    const result: any = await API.graphql({ query: query });
    console.log("in listAdvertisers with", result)
    return result
  } catch (error) {
    console.log("errore api")
    throw error
  }
}