import React from 'react';
import { useAsyncDebounce } from "react-table";
import { TextField, InputAdornment } from '@material-ui/core';
import Icon from "../IconsComponent";
import { useStyles } from "./PlantListCss";
import _ from 'lodash';


export const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  closeRow
}: any) => {
  const classes = useStyles()
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
    closeRow()
  }, 200)

  return (
    <TextField
      data-cy="inputList"
      fullWidth
      className={`${classes.noBordersAll} ml-2`}
      variant="outlined"
      id="searchInput"
      size="small"
      value={value || ""}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon color="gray" icon="search" size={20} />
          </InputAdornment>
        ),
        classes: { notchedOutline: classes.noBorders }
      }}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Cerca per Comune, indirizzo...`}
    />
  )
}