import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ExploreActions, selectors as ExploreSelectors } from '../store/slices/explore';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { ButtonDropdown, UncontrolledDropdown, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Button } from "reactstrap";
import { Grid } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { orderStatusSwitch, setBadge, statusColor, notificationTextChange, tooltip } from './OrderDetails.css';
import Icon from './IconsComponent';
import placeholder_profile from '../assets/placeholder_profile.png';
import moment from 'moment';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: "#fafafb",
    },
    notificationsContainer: {
      backgroundColor: "white",
      borderRadius: 15,
      marginTop: 10,
      marginBottom: 10,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#e6f2ff",
      }
    },
    drowdownMenu: {
      backgroundColor: '#44444F',
      borderRadius: 10,
      '& .dropdown-item': {
        color: 'white !important'
      },
      '& .dropdown-item:hover': {
        backgroundColor: 'transparent',
        color: '#FC5A5A !important',
        '& >svg>path': {
          fill: '#FC5A5A'
        }
      },
      '& .dropdown-item:focus': {
        backgroundColor: 'transparent',
        color: '#FC5A5A !important',
        '& >svg>path': {
          fill: '#FC5A5A'
        }
      },
      '& .dropdown-item>svg>path': {
        fill: 'white'
      }
    }
  }),
);

// const notificationsArray: any[] = [
//   {
//     avatar: placeholder_profile,
//     name: "Radix",
//     text: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit…",
//     time: moment().subtract(13, 'minutes')
//   },
//   {
//     avatar: placeholder_profile,
//     name: "Giulio",
//     text: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit…",
//     time: moment().subtract(63, 'minutes')
//   },
//   {
//     avatar: placeholder_profile,
//     name: "Media Owner a caso",
//     text: "Lorem ipsum Lorem ipsum dolor sit amet, consectetur adipiscing elit…",
//     time: moment().subtract(8, 'minutes')
//   }
// ]

const DropdownMenuNotifications = (order: any) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const orders: any[] = useSelector(ContentSelectors.listOrders);
  const archivedOrders: any[] = useSelector(ContentSelectors.listArchivedOrders);

  const [actionOpenShow, setActionOpenShow] = useState(false);
  const toggleActionShow = () => setActionOpenShow(prevState => !prevState);

  const goToDetails = ({ order }: any) => {
    dispatch(ContentActions.willSelectOrder(order));
    dispatch(ContentActions.willListOrderImages({ order_id: order.id }))
    // dispatch(ContentActions.willGetOrderBillboards({ orderBillboards: [] }));
    dispatch(UiActions.goTo({ page: "/orders/orderdetails" }));
  }

  return (
    <Dropdown isOpen={actionOpenShow} toggle={toggleActionShow}>
      <DropdownToggle data-cy="sortButton" className="d-sm-flex align-items-center bg-transparent border-0"><Icon color="gray" size={25} icon="more" /></DropdownToggle>
      <DropdownMenu className={classes.drowdownMenu}>
        <DropdownItem onClick={() => {
          goToDetails(order)
          dispatch(UiActions.willSetNotificationsPage({open:false, orders: orders, archivedOrders:archivedOrders}))
        }}><Icon color="gray" icon="reply" /> Vai all'ordine</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

export const NotificationsPage = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch()

  // const [list, setList] = useState("in corso")
  // const [actionOpen, setActionOpen] = useState(false);
  // const [actionOpenShow, setActionOpenShow] = useState(false);
  // const toggleAction = () => setActionOpen(prevState => !prevState);
  // const toggleActionShow = () => setActionOpenShow(prevState => !prevState);
  // const [dropdownMenu, setDropdownOpen] = useState(false);

  // const notificationsPage = useSelector(UiSelectors.getNotificationsPage)
  const orders: any[] = useSelector(ContentSelectors.listOrders);
  const archivedOrders: any[] = useSelector(ContentSelectors.listArchivedOrders);

  let now = moment()

  const allOrders = [...orders, ...archivedOrders]
  console.log("allOrders in notifications", allOrders)

  useEffect(() => {
      if (!_.isEmpty(orders) || !_.isEmpty(archivedOrders)) {
        console.log("orders already fetched")
      } else {
        dispatch(ContentActions.willListOrders("empty"))
      }

  }, [])

  // const goToDetails = (props: any) => {
  //   dispatch(ContentActions.willSelectOrder(props));
  //   dispatch(ContentActions.willListOrderImages({ order_id: props.id }))
  //   // dispatch(ContentActions.willGetOrderBillboards({ orderBillboards: [] }));
  //   dispatch(UiActions.goTo({ page: "/orders/orderdetails" }));
  // }

  return (
    <div style={{ backgroundColor: "#fafafb", height: "95vh" }}>
      {(allOrders.length && allOrders[0] !== 'empty')
        ? <>
          <Grid className={`${classes.container} d-sm-flex poppinsFont`} container alignItems="center" direction="column">
            <Grid className="mt-6" container item xs={8}>
              <Grid item xs={2}>
                <span style={{ fontSize: 30, fontWeight: 600 }}>Notifiche</span>
              </Grid>
              <Grid item xs={4}>
                {/* <Dropdown isOpen={actionOpenShow} toggle={toggleActionShow}>
                  <DropdownToggle disabled data-cy="sortButton" className="d-sm-flex align-items-center bg-transparent text-dark border-0 ml-4" caret><span style={{ fontSize: 23, opacity: 0.5, marginRight: 7 }}>Mostra:</span> <b style={{ fontSize: 20 }}>{"Recenti"}</b>  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem disabled onClick={() => { }}><Icon color="gray" icon="refund" /> Aggiorna Stato</DropdownItem>
                  </DropdownMenu>
                </Dropdown> */}
              </Grid>
              <Grid item xs={6}>

              </Grid>
            </Grid>
            <Grid container item xs={12} justify="center">
              {allOrders.length && _.map(allOrders, (o: any, index: number) => {
                if (index <= 4) {
                  return (
                    <Grid key={index} className={`${classes.notificationsContainer} p-3`} container alignItems="center" item xs={8}>
                      <Grid item xs={1}><img data-cy="avatar" src={placeholder_profile} className="mr-3 plantAvatar" /></Grid>
                      {/* <Grid item xs={9}>{`${o.name} ${o.text}`}</Grid> */}
                      <Grid item xs={10}>Lo stato attuale della campagna <b>{o.campaign_name}</b> creata da <b>{o.advertiser}</b> è <span className={statusColor(o.status)}>{orderStatusSwitch(o.status, o.from, o.to)}</span></Grid>
                      <Grid item xs={1}>
                        {/* <button className="bg-transparent border-0 right" onClick={() => {
                              goToDetails(o)
                              dispatch(UiActions.didSetNotificationsPage(false))
                            }}>
                          <Icon color="gray" size={25} icon="more" />
                        </button> */}
                        <DropdownMenuNotifications order={o} />
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={11}>{moment(o.order_date).from(now)}</Grid>
                    </Grid>
                  )
                }
              })}
            </Grid>

          </Grid>
        </>
        : <h3 className='d-flex align-items-center justify-content-center'>Nessuna notifica</h3>}
    </div>
  )
}