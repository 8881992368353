import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { createSelector } from 'reselect'
import { clients } from '../../components/profile/client_list';
import { newBillboardValue } from '../../components/add-new-billboard/newBillboardValue';
import moment from 'moment';

export const currentSlice = createSlice({
  name: 'content',
  initialState: {
    // billboardsFilteredSorted: [] as any,
    myBillboards: [] as any,
    firstBillboards: [] as any,
    billboardsInMap: [] as any,
    indexFetchBillboards: 50,
    billboards: {} as any,
    nextToken: "",
    myNextToken: "",
    media_owner: "",
    batchBillboard: [] as any, //forse non serve
    user: {} as {} | null,
    forgotPasswordRequested: false,
    error: undefined,
    billboardsCoordinates: [] as any,
    sortColumnValue: "",
    sortOrderAsc: true,
    filters: {} as any,
    isFiltered: false,
    billboardAvailabilities: [] as any,
    billboardsAvailabilities: [] as any,      // usato per mostrare indisponibilitá in caso di manutenzione multipla
    billboardAvailabilityByRange: null as any,
    pricing: {} as any,
    selectedOrder: {} as any,
    orders: [] as any,
    archivedOrders: [] as any,
    orderBillboards: [] as any,
    sortOrdersColumnValue: "",
    sortOrdersOrderAsc: true,
    order: [] as any,
    ordersFilters: {} as any,
    filteredOrders: [] as any,
    orderImages: [] as any,
    clientsInProfile: [...clients],
    newBillboard: {} as any
  },
  reducers: {
    willAddBatchBillboards: (state, action: PayloadAction<any>) => state,
    willListMyBillboards: (state, action: PayloadAction<any>) => state,
    didEmptyBillboards: (state, action: PayloadAction<any>) => {
      state.myBillboards = []
      state.myNextToken = ""
      state.billboardsCoordinates = []
    },
    didListMyBillboards: (state, action: PayloadAction<any>) => {
      // console.log('in list my billboards 1', state.myBillboards)
      state.myBillboards = state.myBillboards.concat(action.payload.billboards.items)   //mybillboards sará la var che conterrá tutti gli impianti   \
      state.billboardsInMap = state.myBillboards
      state.myNextToken = action.payload.billboards.nextToken
      state.billboards = _.keyBy(action.payload.billboards.items, 'id')
      state.billboardsCoordinates = _.compact(state.billboardsCoordinates.concat(_.map(action.payload.billboards.items, function (o: any) {
        if (o.latitude && o.longitude) {
          return { "lat": o.latitude, "lng": o.longitude }
        }
      })))
      // console.log('in list my billboards 2', state.myBillboards)

      // console.log("reducer list my billboards: ", state.myBillboards)
    },
    didListFirstBillboards: (state, action: PayloadAction<any>) => {
      state.billboardsInMap = action.payload.billboards.items
      state.firstBillboards = action.payload.billboards.items.slice(0, state.indexFetchBillboards)  //inserisco qui i primi N impianti che carico
    },
    didFetchMoreFirstBillboards: (state, action: PayloadAction<any>) => {
      console.log("in fetch", state.indexFetchBillboards)
      state.indexFetchBillboards = state.indexFetchBillboards + 50
      state.firstBillboards = _.slice(state.myBillboards, 0, state.indexFetchBillboards)  //inserisco qui i primi N impianti che carico
      console.log("in fetch1", state.indexFetchBillboards)
    },
    didListMoreMyBillboards: (state, action: PayloadAction<any>) => {
      state.myBillboards = state.myBillboards.concat(action.payload.billboards.items)
      state.billboards = _.assignIn(state.billboards, _.keyBy(state.myBillboards, 'id'));
      state.myNextToken = action.payload.billboards.nextToken
    },
    willListMoreMyBillboards: (state, action: PayloadAction<any>) => state,
    willListBillboardsInMap: (state, action: PayloadAction<any>) => state,
    didListBillboardsInMap: (state, action: PayloadAction<any>) => {
      state.billboardsInMap = action.payload.items
    },
    didAddBillboards: (state, action: PayloadAction<any>) => {
      state.myBillboards = _.uniqBy(state.myBillboards.concat(action.payload.billboards), 'id')
      state.billboards = _.assignIn(state.billboards, _.keyBy(action.payload.billboards, 'id'))
      console.log("in reducer", current(state.billboards))
    },
    didSetSortColumnValue: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        console.log('did set sort value', action.payload, current(state.firstBillboards))
        state.sortColumnValue = action.payload.sortColumnValue
        state.sortOrderAsc = action.payload.sortOrderAsc
      }
      switch (state.sortColumnValue) {
        case "Indirizzo": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.firstBillboards = _.slice(_.orderBy(state.billboardsInMap, ['address', 'location_name'], 'asc'), 0, state.indexFetchBillboards)
          } else {
            state.firstBillboards = _.slice(_.orderBy(state.billboardsInMap, 'address', 'desc'), 0, state.indexFetchBillboards)
          }
          break;
        }
        case "Tipologia": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.firstBillboards = _.slice(_.orderBy(state.billboardsInMap, 'media_type', 'asc'), 0, state.indexFetchBillboards)
          } else {
            state.firstBillboards = _.slice(_.orderBy(state.billboardsInMap, 'media_type', 'desc'), 0, state.indexFetchBillboards)
          }
          break;
        }
        case "Formato": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.firstBillboards = _.slice(_.orderBy(state.billboardsInMap, ['base', 'height'], 'asc'), 0, state.indexFetchBillboards)
          } else {
            state.firstBillboards = _.slice(_.orderBy(state.billboardsInMap, ['base', 'height'], 'desc'), 0, state.indexFetchBillboards)
          }
          break;
        }
        case "Prezzo": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.firstBillboards = _.slice(_.orderBy(state.billboardsInMap, 'price', 'asc'), 0, state.indexFetchBillboards)
          } else {
            state.firstBillboards = _.slice(_.orderBy(state.billboardsInMap, 'price', 'desc'), 0, state.indexFetchBillboards)
          }
          break;
        }
        case "Cimasa": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.firstBillboards = _.slice(_.orderBy(state.billboardsInMap, 'cod_cimasa', 'asc'), 0, state.indexFetchBillboards)
          } else {
            state.firstBillboards = _.slice(_.orderBy(state.billboardsInMap, 'cod_cimasa', 'desc'), 0, state.indexFetchBillboards)
          }
          break;
        }
        case "Visibilità": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.firstBillboards = _.slice(_.orderBy(state.billboardsInMap, 'billalo_index.iV_municipality', 'asc'), 0, state.indexFetchBillboards)
          } else {
            state.firstBillboards = _.slice(_.orderBy(state.billboardsInMap, 'billalo_index.iV_municipality', 'desc'), 0, state.indexFetchBillboards)
          }
          break;
        }
        case "I più recenti": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.firstBillboards = _.slice(_.orderBy(state.billboardsInMap, 'creation_date', 'asc'), 0, state.indexFetchBillboards)
          } else {
            state.firstBillboards = _.slice(_.orderBy(state.billboardsInMap, 'creation_date', 'desc'), 0, state.indexFetchBillboards)
          }
          break;
        }
        default:
          //Comandi eseguiti quando nessuno dei valori coincide col valore dell'epressione
          break;
      }
    },
    willAddBillboard: (state, action: PayloadAction<any>) => state,
    willUploadImage: (state, action: PayloadAction<any>) => state,
    willUploadCSV: (state, action: PayloadAction<any>) => state,
    willAddPricing: (state, action: PayloadAction<any>) => state,
    willEditPricing: (state, action: PayloadAction<any>) => state,
    willFetchMoreFirstBillboards: (state, action: PayloadAction<any>) => {
      console.log('filtri? ', action)
      if (state.firstBillboards.length < 50) {
        action.payload.fetchMore = false
      }
      if (!_.isEmpty(state.filters)) {    // se c'é un filtro attivo
        console.log('in willFetchMoreFirstBillboards true', action)
        action.payload.filters = current(state.filters)
      }
    },
    willFilterBillboards: (state, action: PayloadAction<any>) => {
      action.payload.myBillboards = current(state.myBillboards)
    },
    didFilterBillboards: (state, action: PayloadAction<any>) => {
      state.firstBillboards = _.slice(action.payload.filteredBillboards, 0, state.indexFetchBillboards)
      state.isFiltered = !state.isFiltered
      state.filters = action.payload.filters
      state.billboardsInMap = action.payload.filteredBillboards
      //coordinate
      state.billboardsCoordinates = _.compact(_.map(action.payload.filteredBillboards, function (o: any) {
        if (o.latitude && o.longitude) {
          return { "lat": o.latitude, "lng": o.longitude }
        }
      }))
      console.log("in reducer did filter", state.firstBillboards, action.payload.filteredBillboards)

    },
    didChangeFilter: (state, action: PayloadAction<any>) => {
      state.isFiltered = action.payload
    },
    willListBillboardAvailabilities: (state, action: PayloadAction<any>) => state,
    didListBillboardAvailabilities: (state, action: PayloadAction<any>) => {
      // console.log("didListBillboardAvailabilities", action.payload.availabilities)
      if (action.payload.availabilities !== undefined) {
        state.billboardAvailabilities = action.payload.availabilities
        console.log("didListBillboardAvailabilities 2 true", state.billboardAvailabilities)
      } else {
        state.billboardAvailabilities = []
        console.log("didListBillboardAvailabilities 2 false", state.billboardAvailabilities)
      }
    },
    willListBillboardAvailabilityByRange: (state, action: PayloadAction<any>) => state,
    didListBillboardAvailabilityByRange: (state, action: PayloadAction<any>) => {
      state.billboardAvailabilityByRange = action.payload.availabilitiesByRange
    },
    willGetBillboardPricing: (state, action: PayloadAction<any>) => state,
    didGetBillboardPricing: (state, action: PayloadAction<any>) => {
      state.pricing = action.payload.pricing
    },
    willSelectOrder: (state, action: PayloadAction<any>) => {
      //console.log('in willSelectOrder action',action)
      state.selectedOrder = action.payload
    }
    ,
    didSetOrdersSortColumnValue: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        console.log('did set sort value', action.payload, current(state.orders))
        state.sortOrdersColumnValue = action.payload.sortOrdersColumnValue
        state.sortOrdersOrderAsc = action.payload.sortOrdersOrderAsc
      }
      switch (state.sortOrdersColumnValue) {
        case "Stato": {
          console.log("ordina per", state.sortOrdersColumnValue)
          if (state.sortOrdersOrderAsc) {
            state.orders = _.orderBy(state.orders, 'status', 'asc')
          } else {
            state.orders = _.orderBy(state.orders, 'status', 'desc')
          }
          break;
        }
        case "Cliente": {
          console.log("ordina per", state.sortOrdersColumnValue, state.sortOrdersOrderAsc)
          if (state.sortOrdersOrderAsc) {
            state.orders = _.orderBy(state.orders, 'advertiser', 'asc')
          } else {
            state.orders = _.orderBy(state.orders, 'advertiser', 'desc')
          }
          break;
        }
        case "Nome Campagna": {
          console.log("ordina per", state.sortOrdersColumnValue)
          if (state.sortOrdersOrderAsc) {
            state.orders = _.orderBy(state.orders, 'campaign_name', 'asc')
          } else {
            state.orders = _.orderBy(state.orders, 'campaign_name', 'desc')
          }
          break;
        }
        case "Inizio/Fine": {
          console.log("ordina per", state.sortOrdersColumnValue)
          if (state.sortOrdersOrderAsc) {
            state.orders = _.orderBy(state.orders, 'from', 'asc')
          } else {
            state.orders = _.orderBy(state.orders, 'from', 'desc')
          }
          break;
        }
        case "Data Ordine": {
          console.log("ordina per", state.sortOrdersColumnValue)
          if (state.sortOrdersOrderAsc) {
            state.orders = _.orderBy(state.orders, 'order_date', 'asc')
          } else {
            state.orders = _.orderBy(state.orders, 'order_date', 'desc')
          }
          break;
        }
        case "Spesa Tot": {
          console.log("ordina per", state.sortOrdersColumnValue)
          if (state.sortOrdersOrderAsc) {
            state.orders = _.orderBy(state.orders, 'amount', 'asc')
          } else {
            state.orders = _.orderBy(state.orders, 'amount', 'desc')
          }
          break;
        }

        default:
          break;
      }
    },
    willSetMaintenance: (state, action: PayloadAction<any>) => state,
    didListOrders: (state, action: PayloadAction<any>) => {
      if (_.isEmpty(action.payload.items) && _.isEmpty(state.orders)) {
        state.orders = ['empty']
      } else {
        let now = moment()
        // console.log("in didListOrders", action)
        if (action.payload.nextToken) {
          // console.log("in didListOrders concat")
          let array = action.payload.items
          let archivedArray = [] as any
          let ordersArray = [] as any
          _.map(array, (o: any) => {
            // console.log("didListOrders map", o)
            if (o.status === "rejected" || o.status === "completed" || o.status === "cancelled" || o.status === "accepted" || o.status === "pass") {
              if (o.status === "reject" || o.status === "rejected" && moment(now).isBefore(moment(o.order_date).add(1, 'weeks'))) {
                ordersArray.push(o)
              } else {
                archivedArray.push(o)
              }
            } else {
              // console.log("in didListOrders draft", moment(now).isAfter(moment(o.order_date).add(30, 'minutes')))
              if (moment(now).isAfter(moment(now).add(30, 'minutes')) && o.status === "Draft" || o.status === "draft") {
                return
              } else {
                ordersArray.push(o)
              }
            }
          })
          state.orders = _.orderBy(_.concat(state.orders, ordersArray), 'order_date', 'desc')
          state.archivedOrders = _.orderBy(_.concat(state.archivedOrders, archivedArray), 'order_date', 'desc')
          // state.archivedOrders = _.concat(state.archivedOrders, archivedArray)
          // console.log("in didListOrders with", state.orders, state.archivedOrders)
        } else {
          if (_.isEmpty(action.payload.items)) {
            console.log("orders fetched")
          } else {
            // state.orders = action.payload.items
            let array = action.payload.items
            let archivedArray = [] as any
            let ordersArray = [] as any
            _.map(array, (o: any) => {
              // console.log("didListOrders map", o)
              if (o.status === "reject" || o.status === "rejected" || o.status === "completed" || o.status === "cancelled" || o.status === "accepted" || o.status === "pass") {
                if (o.status === "reject" || o.status === "rejected" && moment(now).isBefore(moment(o.order_date).add(1, 'weeks'))) {
                  ordersArray.push(o)
                } else {
                  archivedArray.push(o)
                }
              } else {
                // console.log("in didListOrders draft", moment(now).isAfter(moment(o.order_date).add(30, 'minutes')))
                if (moment(now).isAfter(moment(now).add(30, 'minutes')) && o.status === "Draft" || o.status === "draft") {
                  return
                } else {
                  ordersArray.push(o)
                }
              }
            })
            state.orders = _.orderBy(_.concat(state.orders, ordersArray), 'order_date', 'desc')
            state.archivedOrders = _.orderBy(_.concat(state.archivedOrders, archivedArray), 'order_date', 'desc')
            // state.orders = ordersArray
            // state.archivedOrders = archivedArray
          }
          // console.log("in didListOrders end", action.payload.items)
          // console.log("in didListOrders end with", state.orders, state.archivedOrders)
        }
      }
    },
    willListOrders: (state, action: PayloadAction<any>) => {
      if (action.payload === "empty") {
        state.orderBillboards = [] as any
      }
    },
    willGetOrderBillboards: (state, action: PayloadAction<any>) => {
      // console.log("willGetOrderBillboards", action.payload)
      action.payload["billboards"] = current(state.billboards)
      action.payload.orderBillboards = current(state.selectedOrder.billboards)
    },
    didSetOrderBillboards: (state, action: PayloadAction<any>) => {
      // console.log("didSetOrderBillboards", action.payload)
      state.orderBillboards = action.payload
    },
    willGetOrder: (state, action: PayloadAction<any>) => state,
    didGetOrder: (state, action: PayloadAction<any>) => {
      state.order = action.payload.order
    },
    willGetOrderBillbiards: (state, action: PayloadAction<any>) => {
      let billboards = current(state.billboards)
      let order = state.order.billboards
      _.map(order, (o: any) => {
        _.map(billboards, (i: any) => {
          if (o.billboard_id === i.id) {
            state.orderBillboards.push(i)
          }
        })
      })
    },
    willListPlantsAvailabilities: (state, action: PayloadAction<any>) => state,
    didListPlantsAvailabilities: (state, action: PayloadAction<any>) => {
      console.log("did list", action)
      state.billboardsAvailabilities = action.payload.availabilities
    },
    willUpdateOrderStatus: (state, action: PayloadAction<any>) => {
      console.log("in willUpdateOrderStatus", action)
    },
    willFilterOrders: (state, action: PayloadAction<any>) => {
      action.payload["orders"] = action.payload.items
    },
    didFilterOrders: (state, action: PayloadAction<any>) => {
      console.log("in reducer did filter orders", state.orders, action.payload.filteredOrders)
      state.ordersFilters = action.payload.filters
      state.filteredOrders = action.payload.filteredOrders
    },
    willDeleteBillboardAvailabilities: (state, action: PayloadAction<any>) => {
      console.log("in willDeleteBillboardAvailabilities")
    },
    willUploadOrderImage: (state, action: PayloadAction<any>) => {
      console.log("in willUploadOrderImage")
    },
    willGetOrderImagesUrl: (state, action: PayloadAction<any>) => {
      console.log("in willGetOrderImagesUrl")
    },
    didGetOrderImagesUrl: (state, action: PayloadAction<any>) => {
      console.log("in didGetOrderImagesUrl")
      state.orderImages = action.payload
    },
    willListOrderImages: (state, action: PayloadAction<any>) => {
      console.log("in willListOrderImages")
    },
    didListOrderImages: (state, action: PayloadAction<any>) => {
      console.log("in didListOrderImages")
      if (action.payload === "empty") {
        state.orderImages = []
      }
      else if (action.payload.nextToken) {
        state.orderImages = _.concat(state.orderImages, action.payload.items)
      } else {
        if (_.isEmpty(state.orderImages)) {
          state.orderImages = action.payload.items
        } else {
          console.log("all images fetched!")
        }
      }
    },
    willGetOrderContract: (state, action: PayloadAction<any>) => {
      console.log("in willGetOrderContract")
    },
    didSetSortColumnValueProfileClients: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        console.log('did set sort value', action.payload, current(state.clientsInProfile))
        state.sortColumnValue = action.payload.sortColumnValue
        state.sortOrderAsc = action.payload.sortOrderAsc
      }
      switch (state.sortColumnValue) {
        case "Nome azienda": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.clientsInProfile = _.orderBy(state.clientsInProfile, 'name', 'asc')
          } else {
            state.clientsInProfile = _.orderBy(state.clientsInProfile, 'name', 'desc')
          }
          break;
        }
        case "Tipologia": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.clientsInProfile = _.orderBy(state.clientsInProfile, 'type', 'asc')
          } else {
            state.clientsInProfile = _.orderBy(state.clientsInProfile, 'type', 'desc')
          }
          break;
        }
        // case "Formato": {
        //   console.log("ordina per", state.sortColumnValue)
        //   if (state.sortOrderAsc) {
        //     state.clientsInProfile = _.orderBy(state.clientsInProfile, ['base', 'height'], 'asc')
        //   } else {
        //     state.clientsInProfile = _.orderBy(state.clientsInProfile, ['base', 'height'], 'desc')
        //   }
        //   break;
        // }
        case "N.Ordini": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.clientsInProfile = _.orderBy(state.clientsInProfile, 'orders_number', 'asc')
          } else {
            state.clientsInProfile = _.orderBy(state.clientsInProfile, 'orders_number', 'desc')
          }
          break;
        }
        case "Regione": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.clientsInProfile = _.orderBy(state.clientsInProfile, 'region', 'asc')
          } else {
            state.clientsInProfile = _.orderBy(state.clientsInProfile, 'region', 'desc')
          }
          break;
        }
        case "Settore": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.clientsInProfile = _.orderBy(state.clientsInProfile, 'sector', 'asc')
          } else {
            state.clientsInProfile = _.orderBy(state.clientsInProfile, 'sector', 'desc')
          }
          break;
        }
        case "Lifetime Value": {
          console.log("ordina per", state.sortColumnValue)
          if (state.sortOrderAsc) {
            state.clientsInProfile = _.orderBy(state.clientsInProfile, 'lifetime_value', 'asc')
          } else {
            state.clientsInProfile = _.orderBy(state.clientsInProfile, 'lifetime_value', 'desc')
          }
          break;
        }
        default:
          //Comandi eseguiti quando nessuno dei valori coincide col valore dell'epressione
          break;
      }

      console.log("ordina per clientsInProfile array", state.clientsInProfile)
    },
    setBillboard: (state, action: PayloadAction<any>) => {
      // console.log("in setBillboard")
      if (action.payload.setBillboard) {
        state.newBillboard = action.payload.setBillboard
      } else {
        const key: string = action.payload.key
        const value: any = action.payload.value
        if (key.includes('.')) {
          const splittedKey: string[] = key.split('.')

          state.newBillboard[splittedKey[0]][splittedKey[1]] = value
        } else {
          state.newBillboard[key] = value
        }

      }
    },
    willDeleteBillboard: (state, action: PayloadAction<any>) => state
  }
})
export const { actions, reducer }: any = currentSlice
export const {
  didAddMediaOwner,
  willAddBatchBillboards,
  willListMyBillboards,
  didListMyBillboards,
  didListMoreMyBillboards,
  willListMoreMyBillboards,
  willListBillboardsInMap,
  didListBillboardsInMap,
  didAddBillboards,
  didSetSortColumnValue,
  willAddBillboard,
  willUploadImage,
  willUploadCSV,
  willAddPricing,
  didListFirstBillboards,
  willFetchMoreFirstBillboards,
  willFilterBillboards,
  didFilterBillboards,
  willListBillboardAvailabilities,
  didListBillboardAvailabilities,
  willListBillboardAvailabilityByRange,
  didListBillboardAvailabilityByRange,
  willGetBillboardPricing,
  didGetBillboardPricing,
  willSelectOrder,
  didSetOrdersSortColumnValue,
  willSetMaintenance,
  willListOrders,
  didListOrders,
  willGetOrderBillboards,
  didSetOrderBillboards,
  willGetOrder,
  didGetOrder,
  willListPlantsAvailabilities,
  didListPlantsAvailabilities,
  willFilterOrders,
  didFilterOrders,
  willUpdateOrderStatus,
  willDeleteBillboardAvailabilities,
  willUploadOrderImage,
  willGetOrderImagesUrl,
  didGetOrderImagesUrl,
  didListOrderImages,
  willListOrderImages,
  willGetOrderContract,
  didSetSortColumnValueProfileClients,
  setBillboard,
  willDeleteBillboard

} = actions
// const listBillboardsByOwner = (state: any) => state.content.billboardsByOwner
// const getMediaOwner = (state: any) => state.content.media_owner
const listMyBillboards = (state: any) => state.content.firstBillboards
export const selectors = {
  // listBillboardsByOwner: listBillboardsByOwner,
  // listBillboardsByOwner2: createSelector([listBillboardsByOwner, getMediaOwner], (billboards, owner) => billboards),
  listMyBillboards: listMyBillboards,
  getMoreMyBillboardsNextToken: (state: any) => state.content.myNextToken,
  getBillboardsInMap: (state: any) => state.content.billboardsInMap,
  getBillboards: (state: any) => state.content.billboards,
  getBillboardCoordinates: (state: any) => state.content.billboardsCoordinates,
  getSortColumnValue: (state: any) => state.content.sortColumnValue,
  listAllMyBillboards: (state: any) => state.content.myBillboards,
  getSortOrderAsc: (state: any) => state.content.sortOrderAsc,
  isFiltered: (state: any) => state.content.isFiltered,
  getFilters: (state: any) => state.content.filters,
  listAllBillboardsInMap: (state: any) => state.content.billboardsInMap,
  listBillboardAvailabilities: (state: any) => state.content.billboardAvailabilities,
  listBillboardAvailabilitiesByRange: (state: any) => state.content.billboardAvailabilityByRange,
  getBillboardPricing: (state: any) => state.content.pricing,
  getSelectOrder: (state: any) => state.content.selectedOrder,
  listOrders: (state: any) => state.content.orders,
  listArchivedOrders: (state: any) => state.content.archivedOrders,
  getSortOrdersColumnValue: (state: any) => state.content.sortOrdersColumnValue,
  getSortOrdersOrderAsc: (state: any) => state.content.sortOrdersOrderAsc,
  listOrderBillboards: (state: any) => state.content.orderBillboards,
  getOrder: (state: any) => state.content.order,
  getOrdersFilters: (state: any) => state.content.ordersFilters,
  getFilteredOrders: (state: any) => state.content.filteredOrders,
  listOrderImages: (state: any) => state.content.orderImages,
  listClientsInProfile: (state: any) => state.content.clientsInProfile,
  getNewBillboard: (state: any) => state.content.newBillboard,
}