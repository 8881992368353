import { call, put, takeEvery, takeLatest, delay, select } from 'redux-saga/effects'
import * as ContentApi from '../../api/explore'
import { actions as NotificationActions } from '../slices/notification'
import { actions as ExploreActions, selectors as ExploreSelectors } from '../slices/explore'
import { push } from 'connected-react-router'
import moment from 'moment';
import _ from 'lodash';
import getDistance from 'geolib/es/getDistance';
import { v4 as uuidv4 } from 'uuid';
import { readFile } from 'fs';
import { any } from 'cypress/types/bluebird';
const axios = require('axios');
var Velocity = require('velocityjs');
const util = require('util');
const fs = require('fs');

export function* sagas() {
  console.log('in explore saga1');
  yield takeLatest(ExploreActions.willExploreFilterBillboards.type, willExploreFilterBillboards)
  yield takeLatest(ExploreActions.willExploreUploadFilter.type, willExploreUploadFilter)
}

// function* willExploreFilterBillboards(action: any) {
//   // console.log('in saga willExploreFilterBillboards', action)
//   // let _filters = action.payload.filters
//   // let _filters1, price_from
//   // console.log('in saga willExploreFilterBillboards 2', action.payload.filters)

//   const campaign_id = action.payload.campaign_id

//   let { price_from, price_to, period, ..._exploreFilters } = action.payload.exploreFilters
//   // console.log('filtro 1', _exploreFilters)

//   let start_busy_dates = action.payload.start_busy_dates

//   if (!_.isEmpty(action.payload.exploreFilters)) {
//     if (price_from !== "" && price_from) {
//       // delete action.payload.exploreFilters.price_from
//       _exploreFilters = { price_from, ..._exploreFilters }
//       // console.log("in if ", _exploreFilters)
//     }
//     if (action.payload.exploreFilters.price_to !== "" && price_to) {
//       _exploreFilters = { price_to, ..._exploreFilters }
//     }
//     if (!_.isEmpty(action.payload.exploreFilters.period)) {
//       // console.log("periodo1", _exploreFilters)

//       _exploreFilters.from = moment(action.payload.exploreFilters.period.date.from, "DD-MM-YYYY")
//       _exploreFilters.to = moment(action.payload.exploreFilters.period.date.to, "DD-MM-YYYY")
//       // console.log("periodo2", _exploreFilters)
//       //   period.from = moment(_exploreFilters.period.from).subtract(13, 'days')
//       //   // _exploreFilters.period.from= moment(_exploreFilters.period.from).subtract(13, 'days')
//       //   _exploreFilters = { period, ..._exploreFilters }
//     }
//     delete _exploreFilters.period

//     if (_exploreFilters.interest) {
//       delete _exploreFilters.interest
//     }
//     // console.log('filtro', _exploreFilters)
//     try {
//       // const result = yield call(ContentApi.listFilteredBillboards, _exploreFilters, start_busy_dates, campaign_id);
//       const result = yield call(ContentApi.listFilteredBillboards, _exploreFilters)
//       // console.log("result willExploreFilterBillboards", result)
//       let is_confirm = action.payload.is_confirm
//       let array = action.payload.selectedPlants
//       let selectedFilteredBillboards = [] as any

//       if (!_.isEmpty(array) && action.payload.is_confirm) {
//         //in caso di conferma creazione campagna devo controllare che gli impianti siano tutti disponibili
//         // controllo impianti  e setto is confirm a true se devo chiamare will create campaign
//         // let count = 0
//         // console.log('in if list billboards check', array)
//         let x = _.keyBy(result.data.listFilteredBillboards, 'id')
//         // _.remove(array, function (i: any) {     // da controllare
//         //   if (!x[i.id]) {
//         //     console.log('in remove if true', count, array)
//         //     count++
//         //     return true
//         //   }
//         // })
//         array.map((o: any) => {
//           result.data.listFilteredBillboards.map((i: any) => {
//             if (o.id === i.id) {
//               selectedFilteredBillboards.push(o)
//             }
//           })
//         })

//         let count = array.length - selectedFilteredBillboards.length
//         // console.log('array??', array, count)

//         if (count > 0) {
//           is_confirm = false
//           // yield put(UIActions.switchDraftCampainModal(''))
//           yield put(ExploreActions.didSelectBillboards({ ids: _.mapValues(_.keyBy(selectedFilteredBillboards, function (o) { return o.id }), function (o) { return o.price }) }));
//           yield put(NotificationActions.willShowNotification({ message: count > 1 ? `${count} impianti non sono più disponibili` : "Un impianto non è più disponibile", type: "danger" }));
//         }
//       }
      
//       yield put(ExploreActions.didExploreFilterBillboards({ billboards: result.data.listFilteredBillboards }))

//     } catch (error: any) {
//       console.log('Error ', error);
//       yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
//     }
//   } else {
//     yield put(ExploreActions.didExploreSetFilter({}))
//     // yield fork(checkFilters)
//   }

// }

function* willExploreFilterBillboards(action: any) {
  let { price_from, price_to, period, ..._exploreFilters } = action.payload.exploreFilters

  if (!_.isEmpty(action.payload.exploreFilters)) {
    if (price_from !== "" && price_from) {
      _exploreFilters = { price_from, ..._exploreFilters }
    }
    if (action.payload.exploreFilters.price_to !== "" && price_to) {
      _exploreFilters = { price_to, ..._exploreFilters }
    }
    if (!_.isEmpty(action.payload.exploreFilters.period)) {
      _exploreFilters.from = moment(action.payload.exploreFilters.period.date.from, "DD-MM-YYYY").subtract(13, 'days')
      _exploreFilters.to = moment(action.payload.exploreFilters.period.date.to, "DD-MM-YYYY")
    }
    if (!_exploreFilters.media_type) {
      delete _exploreFilters.media_type
      delete _exploreFilters.billboard_class
    }
    delete _exploreFilters.period
    
    try {
      const result = yield call(ContentApi.listFilteredBillboards, _exploreFilters);
      yield put(ExploreActions.didExploreFilterBillboards({ billboards: result.data.listFilteredBillboards }))
    } catch (error: any) {
      console.log('Error ', error);
      yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
    }
  } else {
    yield put(ExploreActions.didExploreSetFilter({}))
  }

}

function* willExploreUploadFilter(action: any) {
  // console.log('in saga willExploreUploadFilter', action)
  let exploreFilters = action.payload.exploreFilters

  if (!exploreFilters.media_type) {
    delete exploreFilters.media_type
    delete exploreFilters.billboard_class
  }

  try {
    yield call(ContentApi.uploadFilter, exploreFilters);
    // console.log("result uploadFilter saga", result)
    yield put(ExploreActions.didExploreUploadFilter({ exploreFilters: exploreFilters }))
    yield put(ExploreActions.willExploreFilterBillboards({ exploreFilters: {} }))
  } catch (error: any) {
    console.log('Error ', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
}