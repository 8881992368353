import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  actions as ContentActions,
  selectors as ContentSelectors,
} from "../store/slices/content";
import { actions as UiActions } from "../store/slices/ui";
import { Grid } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  useStyles,
  cancelButton,
  confirmButton,
} from "./plant-list/PlantListCss";
import { PlantAvailability } from "./PlantAvailability";

import Icon from "./IconsComponent";
import moment from "moment";

export const Disponibility = (props: any) => {
  const { billboardId, item } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [dateRangeFrom, setDateRangeFrom] = useState(null as any);
  const [dateRangeTo, setDateRangeTo] = useState(null as any);
  const [billboardAvailabilitiesByRange, setBillboardAvailabilitiesByRange] =
    useState(null as any);

  const [availabilityInitialDate, setAvailabilityInitialDate] =
    useState<Date>();
  const billboardAvailabilitiesByRange1 = useSelector(
    ContentSelectors.listBillboardAvailabilitiesByRange
  );

  useEffect(() => {
    setBillboardAvailabilitiesByRange(billboardAvailabilitiesByRange1);
  }, [billboardAvailabilitiesByRange1]);
  // console.log('disponibilitá nel frontend:billboardAvailabilitiesByRange ', billboardAvailabilitiesByRange1)

  const didCheckAvailabilities = !!billboardAvailabilitiesByRange;
  const selectedRangeAvailable =
    didCheckAvailabilities && billboardAvailabilitiesByRange?.length === 0;

  return (
    <div className="p-4">
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <span className="disponibility">
            Inserisci le date e verifica se un impianto è disponibile
          </span>
        </Grid>
        <Grid container item xs={12} spacing={4}>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                className={classes.borderColor}
                data-cy="dateFrom"
                disableToolbar
                disablePast
                autoOk={true}
                fullWidth
                // maxDate={dateRangeTo ? dateRangeTo : undefined}
                format="DD/MM/YYYY"
                variant="inline"
                id="start-date"
                label="Data inizio"
                keyboardIcon={<Icon color="grey" size={25} icon="schedule" />}
                value={dateRangeFrom}
                onChange={(e: any) => {
                  setDateRangeFrom(moment(e._d).format("YYYY-MM-DD"));
                }}
                InputAdornmentProps={{ position: "start" }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                className={classes.borderColor}
                data-cy="dateTo"
                disableToolbar
                disablePast
                autoOk={true}
                fullWidth
                minDate={dateRangeFrom ? dateRangeFrom : undefined}
                format="DD/MM/YYYY"
                variant="inline"
                id="end-date"
                label="Data fine"
                keyboardIcon={<Icon color="grey" size={25} icon="schedule" />}
                value={dateRangeTo}
                onChange={(e: any) => {
                  setDateRangeTo(moment(e._d).format("YYYY-MM-DD"));
                }}
                InputAdornmentProps={{ position: "start" }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        {didCheckAvailabilities ? (
          <Grid item>
            {selectedRangeAvailable ? (
              <div className="d-sm-flex align-items-center">
                <Icon size={20} color="#00e68a" icon="taskList" />
                <span style={{ color: "#00e68a" }} className="ml-3 robotoFont">
                  L'impianto è disponibile nelle date selezionate
                </span>
              </div>
            ) : (
              <div className="d-sm-flex align-items-center">
                <Icon size={20} color="red" icon="error" />
                <span className="ml-3 notDisponibility">
                  L'impianto non è disponibile nelle date selezionate
                </span>
              </div>
            )}
          </Grid>
        ) : null}
        {didCheckAvailabilities && !selectedRangeAvailable ? (
          <Grid item>
            <PlantAvailability
              className={"pt-2"}
              plant={item}
              showList={false}
              editable={false}
              calendarHorizontal
              initialDate={availabilityInitialDate}
              key={availabilityInitialDate?.toString()}
            />
          </Grid>
        ) : null}
        <Grid item container xs={12} spacing={1}>
          <Grid container item xs={3}>
            <button
              data-cy="cancel"
              style={cancelButton}
              onClick={() => {
                dispatch(UiActions.switchCloseAddNewPlant());
                dispatch(
                  ContentActions.didListBillboardAvailabilityByRange({
                    billboardAvailabilityByRange: null,
                  })
                );
              }}
            >
              Chiudi
            </button>
          </Grid>
          <Grid container item xs={9}>
            <button
              data-cy="confirm"
              style={confirmButton}
              disabled={!dateRangeFrom || !dateRangeTo}
              onClick={() => {
                setAvailabilityInitialDate(new Date(dateRangeFrom));
                dispatch(
                  ContentActions.willListBillboardAvailabilityByRange({
                    billboard_id: billboardId,
                    start_date: dateRangeFrom,
                    stop_date: dateRangeTo,
                  })
                );
              }}
            >
              Conferma
            </button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
