import { createSlice, PayloadAction, createDraftSafeSelector, createSelector } from "@reduxjs/toolkit";

export const currentSlice = createSlice({
  name: 'ui',
  initialState: {
    bilListView: false,
    activitiesRunning: {} as any,
    addNewPlant: false,
    pricing: false,
    pricingProps: {} as any,
    selectedPage: "", //setta la pagina corrente
    exploreBillboards: false,
    actionDone: { done: null, action: "" } as any,
    noGroup: false,
    notificationsPage: false,
    noProfile: false,
    selectedBillboardId: '',
    step: 'Inserisci parametri'
  },
  reducers: {
    switchMapListView: (state, action: PayloadAction<string>) => { state.bilListView = !state.bilListView },
    startActivityRunning: (state, action: PayloadAction<string>) => void (state.activitiesRunning[action.payload] = true),
    stopActivityRunning: (state, action: PayloadAction<string>) => void (delete state.activitiesRunning[action.payload]),
    switchCloseAddNewPlant: (state, action: PayloadAction<string>) => { state.addNewPlant = !state.addNewPlant },
    switchPricing: (state, action: PayloadAction<any>) => { state.pricingProps = action.payload.items },
    goTo: (state, action: PayloadAction<string>) => state,
    setSelectedPage: (state, action: PayloadAction<any>) => { state.selectedPage = action.payload },
    didSetExploreBillboards: (state, action: PayloadAction<any>) => { state.exploreBillboards = !state.exploreBillboards },
    didSetActionDone: (state, action: PayloadAction<any>) => { state.actionDone = action.payload },
    didSetNoGroup: (state, action: PayloadAction<any>) => { state.noGroup = action.payload },
    willSetNotificationsPage: (state, action: PayloadAction<any>) => state,
    didSetNotificationsPage: (state, action: PayloadAction<any>) => { state.notificationsPage = action.payload },
    didSetNoProfile: (state, action: PayloadAction<any>) => { state.noProfile = action.payload },
    setSelectedBillboardId: (state, action: PayloadAction<any>) => {
      console.log("setSelectedBillboardId", action.payload)
      /// cambia il colore di diversi elementi in base a che impianto è stato selezionato
      state.selectedBillboardId = action.payload
    },
    didSetStep: (state, action: PayloadAction<any>) => { state.step = action.payload }
  }
})

// Extract the action creators object and the reducer
export const { actions, reducer }: any = currentSlice
export const {
  switchMapListView,
  startActivityRunning,
  stopActivityRunning,
  switchCloseAddNewPlant,
  switchPricing,
  goTo,
  setSelectedPage,
  didSetExploreBillboards,
  didSetActionDone,
  didSetNoGroup,
  willSetNotificationsPage,
  didSetNotificationsPage,
  didSetNoProfile,
  setSelectedBillboardId,
  didSetStep
} = actions

const activitiesRunningSelector = (state: any) => state.ui.activitiesRunning;
export const selectors = {
  getMapListView: (state: any) => state.ui.bilListView,
  activityRunningSelector: createDraftSafeSelector(
    (state: any) => state.ui.activitiesRunning,
    (_: any, currentActivity: string) => currentActivity,
    (activitiesRunning: any, currentActivity: any) => activitiesRunning[currentActivity] != undefined ? activitiesRunning[currentActivity] : false
  ),
  switchCloseAddNewPlant: (state: any) => state.ui.addNewPlant,
  switchPricing: (state: any) => state.ui.pricingProps,
  getSelectedPage: (state: any) => state.ui.selectedPage,
  getExploreBillboards: (state: any) => state.ui.exploreBillboards,
  getActionDone: (state: any) => state.ui.actionDone,
  getNoGroup: (state: any) => state.ui.noGroup,
  getNotificationsPage: (state: any) => state.ui.notificationsPage,
  getNoProfile: (state: any) => state.ui.noProfile,
  getSelectedBillboardId: (state: any) => state.ui.selectedBillboardId,
  getStep: (state: any) => state.ui.step
}
