import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TextField } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Icon from '../../IconsComponent';

import { actions as AuthActions, selectors as AuthSelectors } from '../../../store/slices/auth'
import { actions as UiActions } from '../../../store/slices/ui'
import { useStyles } from '../Profile.css';
import { ActivityButtonModal } from '../ActivityButtonModal';

const ResetPasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  newPassword: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  confirmNewPassword: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
});

export const ResetPassword = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [error, setError] = useState(false)

  return (
    <Grid container alignItems="center" justify="center">
      <Grid className="mb-4 mr-4 poppinsFont" item xs={9}><h4>Reset password</h4></Grid>
      <Grid item xs={12}>
        <Formik
          initialValues={{
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
          }}
          validationSchema={ResetPasswordSchema}
          validateOnBlur={true}
          onSubmit={values => {
            if (values.newPassword !== values.confirmNewPassword) {
              setError(true)
            } else {
              dispatch(AuthActions.willResetPassword({ oldPassword: values.oldPassword, newPassword: values.newPassword }));
            }
          }}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form>

              <Grid container justify="center" item xs={6}>
                <TextField
                  className={classes.borderColor}
                  size="small"
                  id="oldPassword"
                  variant='outlined'
                  label="vecchia password"
                  onChange={(e: any) => setFieldValue('oldPassword', e.target.value)}
                  value={values.oldPassword}
                  helperText={touched.oldPassword ? errors.oldPassword : ""}
                  error={touched.oldPassword && Boolean(errors.oldPassword)}
                />
              </Grid>

              <Grid className="mt-4" container alignItems="center" justify="space-between">
                <Grid container justify="center" item xs={6}>
                  <TextField
                    className={classes.borderColor}
                    size="small"
                    id="newPassword"
                    variant='outlined'
                    label="nuova password"
                    onChange={(e: any) => {
                      setError(false)
                      setFieldValue('newPassword', e.target.value)
                    }}
                    value={values.newPassword}
                    helperText={touched.newPassword ? errors.newPassword : ""}
                    error={touched.newPassword && Boolean(errors.newPassword)}
                  />
                </Grid>
                <Grid container justify="center" item xs={6}>
                  <TextField
                    className={classes.borderColor}
                    size="small"
                    id="confirmNewPassword"
                    variant='outlined'
                    label="conferma nuova password"
                    onChange={(e: any) => {
                      setError(false)
                      setFieldValue('confirmNewPassword', e.target.value)
                    }}
                    value={values.confirmNewPassword}
                    helperText={touched.confirmNewPassword ? errors.confirmNewPassword : ""}
                    error={touched.confirmNewPassword && Boolean(errors.confirmNewPassword)}
                  />
                </Grid>
              </Grid>

              {error && <Grid style={{color:'red', marginTop:30}} container justify="center" item xs={12}>Nuova e vecchia password devono coincidere</Grid>}
              
              <Grid className={`${classes.modalInProfileFooter} poppinsFont`} item xs={12}>
                <Grid className="d-sm-flex">
                  <Grid item xs={7} />
                  <Grid item xs={2}>
                    <button className={classes.cancelButtonModal} onClick={() => { dispatch(UiActions.switchCloseAddNewPlant()) }}>Annulla</button>
                  </Grid>
                  <Grid item xs={2}>
                    <ActivityButtonModal data-cy="confirm" className={classes.confirmButtonModal} name="willResetPassword">
                      Salva
                    </ActivityButtonModal>
                  </Grid>
                </Grid>
              </Grid>

            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}