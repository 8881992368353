import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: 300,
      fontSize:14
    },
    typography: {
      padding: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    greenColor: {
      color: "#00e68a"
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
      color: '#0062CC',
      //animationDuration: '550ms',
      position: 'absolute',
      //left: 0,
    },
    circle: {
      strokeLinecap: 'round',
    },
    billaloIcon: {
      color: '#0062CC',
      alignItems: "center",
      justifyContent: "center",
      position: 'absolute',
    },
    visibilityProgress: {
      width: 80,
      height: 80,
    },
    outdoorClass: {
      fontSize: 14,
      color: 'white',
      "& > span": {
        backgroundColor: '#50B5FF',
        borderRadius: 5,
        padding: '5px 8px 5px 8px'
      }
    },
    indoorClass: {
      fontSize: 14,
      color: 'white',
      "& > span": {
        backgroundColor: '#ff9ad5',
        borderRadius: 5,
        padding: '5px 8px 5px 8px'
      }
    },
    circularProgressContainer: {
      backgroundColor: 'rgba(226, 226, 234, 0.2)',
      borderRadius: 20,
    }
  }),
);

export const sizeButton = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: "#E6E6E6",
  color: "#8C8C8C",
  border: "none",
  borderRadius: 4,
  width: "100%",
  fontSize: 14,
  padding: '2px 4px 2px 4px'
}