import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

export const currentSlice = createSlice({
  name: 'explore',
  initialState: {
    firstBillboards: [] as any,
    exploreFilters: {} as any,
    exploreBillboardsCoordinates: [] as any,
    exploreBillboardsInMap: [] as any
  },
  reducers: {
    willExploreFilterBillboards: (state, action: PayloadAction<any>) => {
      action.payload.exploreFilters = { ...current(state.exploreFilters) }
    },
    didExploreFilterBillboards: (state, action: PayloadAction<any>) => {
      // console.log("in didExploreFilterBillboards", action.payload)
      state.exploreBillboardsInMap = action.payload.billboards;
      state.exploreBillboardsCoordinates = _.compact(_.map(action.payload.billboards, function (o: any) {
        if (o.latitude && o.longitude) {
          return { "lat": o.latitude, "lng": o.longitude }
        }
      }))
    },
    didExploreSetFilter: (state, action: PayloadAction<any>) => {
      state.exploreFilters = action.payload
      state.exploreBillboardsCoordinates = []
      state.exploreBillboardsInMap = []
      localStorage.removeItem('exploreFilters');
    },
    willExploreUploadFilter: (state, action: PayloadAction<any>) => {
      console.log('willExploreUploadFilter slice', action)

      let exploreFilters = { ...current(state.exploreFilters) }

      const field = action.payload.key
      if (field === "price") {
        if (!action.payload.value.from) {
          delete exploreFilters.price_from
        } else {
          exploreFilters['price_from'] = action.payload.value.from
        }
        if (!action.payload.value.to) {
          delete exploreFilters.price_to
        } else {
          exploreFilters['price_to'] = action.payload.value.to
        }
      } else if (field === "iV_municipality") {
        if (!action.payload.value.from) {
          delete exploreFilters.iV_municipality_from
        } else {
          exploreFilters['iV_municipality_from'] = action.payload.value.from
        }
        if (!action.payload.value.to) {
          delete exploreFilters.iV_municipality_to
        } else {
          exploreFilters['iV_municipality_to'] = action.payload.value.to
        }
      } else if (field === "media_type") {
        exploreFilters['media_type'] = action.payload.value.media_type
        exploreFilters['billboard_class'] = action.payload.value.billboard_class
      } else {
        if (_.isEmpty(action.payload.value)) {
          delete exploreFilters[field]
          // if (!exploreFilters.media_type) {
          //   delete exploreFilters.billboard_class
          // }
        } else {
          exploreFilters[field] = action.payload.value
        }
      }

      action.payload.exploreFilters = exploreFilters
    },
    didExploreUploadFilter: (state, action: PayloadAction<any>) => {
      state.exploreFilters = action.payload.exploreFilters
      if (_.isEmpty(state.exploreFilters)) {
        state.exploreBillboardsCoordinates = []
        state.exploreBillboardsInMap = []
      }
    },
  }
})
export const { actions, reducer }: any = currentSlice
export const {
  willExploreFilterBillboards,
  didExploreFilterBillboards,
  didExploreSetFilter,
  willExploreUploadFilter,
  didExploreUploadFilter,
} = actions


export const selectors = {
  listMyBillboards: (state: any) => state.explore.exploreBillboardsInMap,
  didGetExploreFilter: (state: any) => state.explore.exploreFilters,
  listExploreAllBillboardsInMap: (state: any) => state.explore.exploreBillboardsInMap,
  getExploreBillboardCoordinates: (state: any) => state.explore.exploreBillboardsCoordinates,
}