import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import styled from "styled-components";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ///////FILTER PART START///////
    root: {
      width: 300,
      height: "77vh",
      padding: theme.spacing(2),
      //backgroundColor: "#fafafb",
      overflowY: "auto",
      position:'relative'
    },
    popoverInFilters: {
      margin: 20,
      borderRadius: 10
    },
    buttonBackground: {
      // backgroundColor: "#0062cc",
      justifyContent: "space-evenly",
    },
    filterBody: {
      height: '50vh',
      overflow: 'auto'
    },
    filterBodyPeriod: {
      height: '38vh',
      overflow: 'auto'
    },
    buttonsFilterContainer: {
      position:'absolute',
      bottom:30,
      left:0,
      right:0
    },
    ///////FILTER PART END///////
    borderColor: {
      '& label.Mui-focused': {
        color: '#00bfff',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#00bfff',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: 10
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00bfff',
        },
      },
    },
    noBorders: {
      border: "none",
    },
    noBordersAll: {
      // width: 180,
      marginLeft: 30,
      borderRadius: 10,
      backgroundColor: "white",
    },
    datePickerText: {
      fontSize: 14
    },
    datePicker: {
      width: 120,
      '& .MuiIconButton-root': {
        padding: "2px !important"
      }
    },
    autosuggest: {
      border: "none"
    },
    inputFocused: {
      outlineStyle: "none"
    },
    absolute: {
      // position: "absolute",
      // zIndex: 4,
      backgroundColor: "white",
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 1,
      borderRightWidth: 1,
      borderStyle: "solid",
      borderColor: "#bfbfbf",
      //border: "1 solid #bfbfbf",
      overflow: "auto",
      width: 250,
      maxHeight: 150,
    },
    selectedCampain: {
      color: "#0062cc",
      borderBottomWidth: 1,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderBottomStyle: "solid",
      borderBottomColor: "#0062cc",
      backgroundColor: "transparent"
    },
    stepDone: {
      color: "#00e68a",
      backgroundColor: "transparent",
      borderWidth: 0
    },
    confirmStepBorders: {
      backgroundColor: "white",
      borderRadius: 14,
      marginTop: 15,
    },
    confirmStepBordersBlue: {
      backgroundColor: "#0066ff",
      borderRadius: 14,
      marginTop: 15,
      color: "white",
      fontSize: 18
    },
    visibilityProgress: {
      width: 100,
      height: 100,
    },
    percentageProgress: {
      color: 'black',
      alignItems: "center",
      justifyContent: "center",
      fontSize: 25
      // position: 'absolute',
    },
    tableConfirmStep: {
      minWidth: 650,
      '& MuiPaper-root MuiTableContainer-root MuiPaper-elevation1 MuiPaper-rounded': {
        borderWidth: 0
      },
      '& .MuiTableHead-root': {
        backgroundColor: "#fafafb",
        borderRadius: 14,
      }
    },
    checkboxLegalAcceptance: {
      '&$checked': {
        color: "blue",
      },
    },
    billaloIndexSlide: {
      color: '#00e68a',
    },
    popoverContainer: {
      width: 340,
      '& .MuiPaper-root.MuiPopover-paper.MuiPaper-rounded': {
        top: 'unset !important',
        bottom: 0
      },
      '& .MuiPaper-root': {
        height: '82vh',
        border: '1px solid #0062FF',
        borderRadius: 10
      }
    }
  }),
);

export const changeOnSelect = (planningType: any, planning: any) => {
  return planningType === planning ? "selectedPlanningType" : ""
}
export const iconColorSelect = (planningType: any, planning: any) => {
  return planningType === planning ? "black" : "#92929D"
}

export const cancelButtonFilters = {
  height: 40,
  fontSize: 15,
  fontFamily: "'Poppins', sans-serif",
  padding: "1px 20px 1px 20px",
  color: "#696974",
  backgroundColor: "transparent",
  border: '1px solid #E2E2EA',
  borderRadius: 10,
}

export const confirmButtonFilters = {
  height: 40,
  fontSize: 15,
  fontFamily: "'Poppins', sans-serif",
  padding: "1px 20px 1px 20px",
  color: "white",
  backgroundColor: "#0062FF",
  border: '1px solid #E2E2EA',
  borderRadius: 10,
}

export const cancelButton = {
  fontSize: 15,
  fontFamily: "'Poppins', sans-serif",
  padding: "1px 20px 1px 20px",
  color: "#595959",
  backgroundColor: "#e6e6e6",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
}

export const confirmButton = {
  fontSize: 15,
  fontFamily: "'Poppins', sans-serif",
  padding: "1px 20px 1px 20px",
  backgroundColor: "#0062cc",
  borderRadius: 10,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "#d9d9d9",
  color: "white",
}

export const buttonApply = {
  fontSize: 13,
  padding: "11px 35px 11px 35px",
  color: "white",
  backgroundColor: "#66b0ff",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
}

export const draftButtons = {
  fontSize: 15,
  fontFamily: "'Poppins', sans-serif",
  padding: "6px 15px 6px 15px",
  color: "#595959",
  backgroundColor: "#e6e6e6",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
}
export const draftButtonsSelected = {
  fontSize: 15,
  fontFamily: "'Poppins', sans-serif",
  padding: "6px 15px 6px 15px",
  color: "white",
  backgroundColor: "#0062cc",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
}

export const selectedCampain = {
  color: "#0062cc",
  borderBottomWidth: 1,
  borderBottomStyle: "solid",
  borderBottomColor: "#0062cc",
}

export const goToPaymentButton = {
  fontSize: 18,
  fontFamily: "'Poppins', sans-serif",
  padding: "12px 60px 12px 60px",
  color: "#0066ff",
  backgroundColor: "#fafafb",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
}

export const municipalityGraphic = {
  width: 320,
  height: 320,
}

export const headerTableConcessionarie = {
  backgroundColor: "#fafafb",
  borderRadius: 10,
  height: 40,
  alignItems: "center",
  paddingLeft: 15
}

export const legalAcceptanceButton = {
  backgroundColor: "transparent",
  borderWidth: 0,
}

export const rowTableConcessionarie = {
  minHeight: 150,
  maxHeight: 300,
  paddingLeft: 15,
}

export const acceptLegalAcceptance = {
  fontSize: 18,
  fontFamily: "'Poppins', sans-serif",
  padding: "12px 60px 12px 60px",
  color: "white",
  backgroundColor: "#66b0ff",
  borderWidth: 0,
  borderRadius: 10,
  borderStyle: "solid",
  borderColor: "white",
}

export const actionButton = {
  borderWidth: 2,
  borderStyle: "solid",
  borderColor: "#E6E6E6",
  borderRadius: 10,
  backgroundColor: "white",
}

export const Styles = styled.div`
padding: 0.2rem;

.table {
  align-items: center;

  .thead {
    font-family: 'Poppins', sans-serif;
  }

  .tbody {
    height: 43vh;
  }

  .tbodyConfirm {
    height: 54vh;
  }

  .tr-group {
    display: flex;
    flex-direction: column;
    // border: 1px solid #00e68a;
    // border-radius: 10px;
  }

  .tr {
    display: flex;
    :last-child {
      .td {
        font-size : 13px;
        border-bottom: 0;
        color: #6c757d;
      }
    }
  }

  .th,
  .td {
    margin: 0;
    padding: 0.5rem;
    color: #6c757d;

    :last-child {
      border-right: 0;
    }
  }

}
`;

export const PaymentStyles = styled.div`
* {
  box-sizing: border-box;
}

body,
html {
  background-color: #f6f9fc;
  font-size: 18px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

h1 {
  color: #32325d;
  font-weight: 400;
  line-height: 50px;
  font-size: 40px;
  margin: 20px 0;
  padding: 0;
}

.Checkout {
  margin-top: 100px;
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}

label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 60px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: white;
  background-color: #007bff;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  outline: 0;
  border-radius: 10px;
  background: white;
  width: 800px;
  border: 1px solid #00bfff;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}
`;

export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const createOptions = (fontSize: any) => {
  return {
    hidePostalCode: true,
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};