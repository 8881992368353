import React, { useState, useMemo, useCallback, useEffect, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ButtonDropdown, UncontrolledDropdown, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Button, Row, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader, Label } from "reactstrap";
import { useTable, useSortBy, useFlexLayout, useExpanded, useGlobalFilter, useRowSelect, useGroupBy } from "react-table";
import { VariableSizeList, FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from 'react-window-infinite-loader';
import ReactTooltip from "react-tooltip";
import debounce from "lodash/debounce";
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { selectors as ProfileSelectors } from '../../store/slices/profile';
import { actions as UiActions, selectors as UiSelectors } from '../../store/slices/ui';
import { TextField, InputAdornment, Tooltip, Zoom, Backdrop, CircularProgress, Grid } from '@material-ui/core';
import Icon from "../../components/IconsComponent";
import { useStyles } from "../plant-list/PlantListCss";
import { useStylesDashboards, Styles } from "./Dashboard.css";
import { orderStatusSwitch, setBadge, statusColor, notificationTextChange, tooltip } from '../../components/OrderDetails.css';
import _ from 'lodash';
import moment from 'moment';

const currencyFormatter = (value: number) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
}

const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
  leading: false,
  trailing: true
});

export const DashboardList = ({ orders }: any) => {
  const listRef = useRef(null);
  const dispatch = useDispatch()
  const classes = useStyles();

  const [orderColumn, setOrderColumn] = useState([] as any)

  // let orders = useSelector(ContentSelectors.listOrders)
  const billboardAvailabilitiesByRange1 = useSelector(ContentSelectors.listBillboardAvailabilitiesByRange)

  const defaultColumn = useMemo(
    () => ({
      width: 50,
      //expanded: false
    }),
    []
  );

  const goToDetails = (props: any) => {
    dispatch(ContentActions.willSelectOrder(props));
    dispatch(ContentActions.willListOrderImages({ order_id: props.id }))
    // dispatch(ContentActions.willGetOrderBillboards({ orderBillboards: [] }));
    dispatch(UiActions.goTo({ page: "/orders/orderdetails" }));
  }

  const columns = useMemo(
    () => [
      {
        id: "Cliente",
        Header: "Cliente",
        // width: 30,
        accessor: (props: any) => {
          //console.log(props)
          const plantImage = `https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg`
          return (
            <div className="row d-sm-flex align-items-center text-dark poppinsFont" onClick={() => {/* goToDetails(props) */ }}>
              <img data-cy="avatar2" src={plantImage} className="mr-2 ordersAvatar" />
              <div style={{ fontSize: 14 }}>{props.advertiser && props.advertiser.length > 16 ? props.advertiser.substring(0, 16).replace('_', ' ') + '..' : props.advertiser.replace('_', ' ')}</div>
            </div>)
        }
      },
      {
        accessor: "client.name"
      },
      {
        id: "Inizio/Fine",
        Header: "Inizio/Fine",
        accessor: (props: any) => {
          return <div style={{ fontSize: 14 }} onClick={() => {/* goToDetails(props) */ }}>{moment(props.from).format("DD-MMM-YYYY")} / {moment(props.to).format("DD-MMM-YYYY")}</div>
        }
      },
      // {
      //   id: "Data Ordine",
      //   Header: "Data Ordine",
      //   width: 31,
      //   accessor: (props: any) => <div style={{ fontSize: 14 }} onClick={() => {/* goToDetails(props) */ }}>{moment(props.order_date).format("DD-MMM-YYYY")}</div>
      // },
      {
        id: "Spesa Tot",
        Header: "Spesa Tot.",
        width: 26,
        accessor: (props: any) => <div style={{ fontSize: 14 }} onClick={() => {/* goToDetails(props) */ }}>{currencyFormatter(_.ceil(props.amount, 2))}</div>
      },
      {
        id: "Stato",
        width: 35,
        Header: "Stato",
        accessor: (props: any) => {
          // let invisible: boolean = false
          let invisible: boolean = setBadge(props.order_date, props.status, 500, props.billboards.length, props.from, props.to).invisible
          let notification = notificationTextChange(setBadge(props.order_date, props.status, 500, props.billboards.length, props.from, props.to).notification)
          let badgeStyle = setBadge(props.order_date, props.status, 500, props.billboards.length, props.from, props.to).style

          return (
            <div className="d-sm-flex align-items-center">
              {/* <Badge color="secondary" variant="dot" invisible={invisible} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} badgeContent="Ciao a tutti sono un cavallo"> */}
              <span style={{ fontSize: 14 }} className={statusColor(props.status)} onClick={() => {/* goToDetails(props) */ }}>{orderStatusSwitch(props.status, props.from, props.to)}</span>
              {/* /</Badge> */}
              {invisible ? null :
                <Tooltip
                  // style={tooltip}
                  // classes={{ tooltip: classes.tooltip }}
                  title={<span style={{ color: notification === "Attesa materiali" ? "#92929d" : "#fc5a5a" }}><Icon color={notification === "Attesa materiali" ? "#ffc542" : "#fc5a5a"} size={15} icon="info" /> {notification}</span>} TransitionComponent={Zoom}>
                  <div style={badgeStyle} />
                </Tooltip>}
            </div>
          )
        }
      }
    ],
    [orders]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data: orders,
      defaultColumn,
      initialState: {
        expanded: {},
        sortBy: orderColumn,
        hiddenColumns: ["client.name", "total_price", "order_date", "available", "city_code", "cod_cimasa", "lighting", "billalo_code", "authorization_expiration", "creationDate", "height", "height_ground", "id", "last_maintenance", "latitude", "longitude", "media_owner", "n_authorization", "n_sheet", "orientation", "property_type", "traffic", "updateDate", "discount"]
      },
      autoResetSelectedRows: false,
    } as any,
    useFlexLayout,
    useGroupBy,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    useRowSelect
  )

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div data-cy="orderRow" {...row.getRowProps({ className: `${row.isSelected ? "borderSelected" : ""} tr-group` })} style={style}
          onClick={() => {
            // dispatch(ContentActions.willSelectOrder(row.original));
            // // dispatch(ContentActions.willGetOrderBillboards({ orderBillboards: [] }));
            // dispatch(UiActions.goTo({ page: "/orders/orderdetails" }));
          }} >
          <div className="d-flex align-items-center tr">
            {row.cells.map((cell) => {
              return (
                <div {...cell.getCellProps()} className="td">
                  {cell.render("Cell")}
                </div>
              );
            })}
          </div>
        </div>
      );
    },
    [rows]
  );

  const fetchMoreData = () => {
    console.log('fetch More orders...')
    // dispatch(ContentActions.willFetchMoreFirstBillboards({ orderFilters: {}, fetchMore: true }))
  };

  useEffect(() => {
    // console.log('resetAfterIndex 12')
    listRef.current && (listRef.current! as any).resetAfterIndex(0, true);
    return () => { }
  }, [])

  const itemData = {
    rows
  }

  return (
    <div className='p-2' style={{ borderRadius: 15, backgroundColor: "white", overflow: "hidden" }}>
      <b className='ml-1 mt-1'>Ultimi ordini</b>
      <div {...getTableProps({ style: { marginTop: 20 } })} className="table">
        <div className="thead d-sm-flex align-items-center detailText">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps({ style: { backgroundColor: '#f8f9fa', borderRadius: 10, height: 40 } })} className="tr align-items-center">
              {headerGroup.headers.map((column) => (
                <div {...column.getHeaderProps()} className="th text-dark">
                  {column.render("Header")}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div data-cy="tableBody" className="tbody" {...getTableBodyProps({ style: { borderRadius: '15px 15px 0px 0px', borderBottom: '1px solid #e9ecef', overflow: "hidden" } })}>
          {orders[0] ? <AutoSizer onResize={rebuildTooltip}>
            {({ height, width }) => (
              <InfiniteLoader
                isItemLoaded={(index: any) => index < rows.length - 4 ? true : false}
                itemCount={rows.length}
                loadMoreItems={fetchMoreData}
              >
                {({ onItemsRendered, ref }: any) => (
                  <VariableSizeList
                    style={{ overflowX: "hidden" }}
                    height={height}
                    itemCount={rows.length}
                    itemData={itemData}
                    // Hardcoded values only :/
                    itemSize={(i) => (rows[i] && rows[i].isExpanded ? 250 : 80)}
                    width={width}
                    onScroll={rebuildTooltip}
                    onItemsRendered={onItemsRendered}
                    ref={listRef}
                  >
                    {RenderRow}
                  </VariableSizeList>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer> : <span style={{ fontSize: 30 }} className="d-sm-flex justify-content-center mt-5 poppinsFont">Nessun risultato trovato</span>}
        </div>
      </div>
      <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', color: '#0062cc' }} onClick={() => {
        dispatch(UiActions.goTo("/orders"))
      }} >Vedi tutti gli ordini</p>
    </div>
  );
}