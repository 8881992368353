import React, { useState, useEffect, useReducer } from 'react';
import { Button, CardBody, CardText, Col } from 'reactstrap';
import { actions as PaymentActions, selectors as PaymentSelectors } from '../store/slices/payment';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, IconButton, ButtonGroup, Avatar, Chip, Paper, InputAdornment, Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Accordion, AccordionSummary, AccordionDetails, buttonNotClicked, buttonClicked } from "./PricingCss";
import { useStyles } from "./PricingCss";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { pricingPercentage, conditionsTotalPrice, amountTotalPrice } from '../components/Autocomplete.options';
import Icon from "./IconsComponent";
import moment from 'moment';
import 'moment/locale/it';
import _ from 'lodash';

export const PricingPage = () => {
  //console.log("in Pricing with: ", props)
  const classes = useStyles()
  const onCheckPlant = () => { setPlantCheck(!plantCheck) }
  const dispatch = useDispatch()
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const [activeCondition, setActiveCondition] = useState("rule")
  const [plantCheck, setPlantCheck] = useState(true)
  const [firstRule, setFirstRule] = useState("")
  const [condition1, setCondition1] = useState("")
  const [hideFirst, setHideFirst] = useState(true)
  const [condition2, setCondition2] = useState("")
  const [hideSecond, setHideSecond] = useState(true)
  const [condition3, setCondition3] = useState("")
  const [hideThird, setHideThird] = useState(true)
  const properties = useSelector(UiSelectors.switchPricing)
  const [billboards, setBillboards] = useState(properties)

  if (_.isEmpty(billboards)) {
    console.log('impianti selezionati', billboards)
    // dispatch(UiActions.switchPricing('home'))
    dispatch(UiActions.goTo({ page: "/" }))
  }
  const pricingData = useSelector(ContentSelectors.getBillboardPricing)
  console.log('pricing data', pricingData)

  const [rule, setRule] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    { rule_type: null, percentage: null }
  );
  const [totalPrice, setTotalPrice] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    { condition_1: null, amount_1: null }
  );
  const [period, setPeriod] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    { from: null, to: null }
  );

  //CLIENT CODE
  const [selectedClients, setSelectedClients] = useState([]) as any
  const userList = useSelector(PaymentSelectors.listConditionUsers)
  // console.log("userList",userList,selectedClients)
  const [dataClients, setDataClients] = useState([] as any)

  //CONDITIONS CODE
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  const buttonExpanded = () => {
    if (expanded === 'panel1') {
      setExpanded('panel2')
      setActiveCondition("")
      setHideFirst(false)
    }
    else if (expanded === 'panel2') {
      setExpanded('panel3')
      setActiveCondition("")
      setHideSecond(false)
    }
    else if (expanded === 'panel3') {
      setExpanded('panel4')
      setActiveCondition("")
      setHideThird(false)
    }
    else {
      setExpanded('panel4')
    }
  }
  const Avatars = () => {
    return (
      <>
        {selectedClients ? <div className="d-sm-flex align-items-center">
          <AvatarGroup className="mr-2" max={4}>
            {selectedClients.map((client: any, i: any) => (
              <Avatar key={`avatar-${i}`} id={i} alt={client.name} src={client.image} />
            ))}
          </AvatarGroup>
          <span className="text-black-50 detailText">{selectedClients.length} Clienti selezionati</span>
        </div> : null}
      </>
    )
  }

  useEffect(() => {
    if (!_.isEmpty(pricingData)) {
      setExpanded('')
      setRule(pricingData.rule_type !== null ? { ["rule_type"]: pricingData.rule_type } : null)
      setRule(pricingData.percentage !== null ? { ["percentage"]: _.toString(pricingData.percentage) } : null)
      setFirstRule(pricingData.rule_type !== null ? pricingData.rule_type : null)
      setTotalPrice(pricingData.condition_1 !== null ? { ["condition_1"]: pricingData.condition_1 } : null)
      setTotalPrice(pricingData.amount_1 !== null ? { ["amount_1"]: _.toString(pricingData.amount_1) } : null)
      setPeriod(pricingData.from !== null ? { ["from"]: moment(pricingData.from).format("YYYY-MM-DD") } : null)
      setPeriod(pricingData.to !== null ? { ["to"]: moment(pricingData.to).format("YYYY-MM-DD") } : null)
      setSelectedClients(pricingData.customers ? pricingData.customers : null)
    }
    if (pricingData.condition_1) {
      setCondition1("Spesa totale")
      setActiveCondition("Spesa totale")
      setHideFirst(false)
    }
    if (pricingData.from || pricingData.to) {
      setCondition2("Periodo")
      setActiveCondition("Periodo")
      setHideSecond(false)
    }
    if (!_.isEmpty(pricingData.customers)) {
      setCondition3("Cliente")
      setActiveCondition("Cliente")
      setHideThird(false)
    }
    // dispatch(PaymentActions.willListConditionUsers())
    console.log("in useEffect Pricing")
  }, [pricingData])

  return (
    <div style={{ height: "91vh" }} className="d-flex poppinsFont">
      <div className="col-9">
        <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary data-cy="ruleResume" aria-controls="panel1d-content" id="panel1d-header">
            <Icon className="priceIcon" color="#00e68a" size={40} icon="task" /> <div className="p-2">Regola: <b className="ml-2">{firstRule}</b></div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="col">
              <h2>Imposta Regola di Pricing</h2>
              <span className="text-secondary">Segui la procedura guidata per impostare una regola di pricing sugli impianti selezionati. Ogni impianto può avere al massimo 1 regola di pricing con un massimo di 3 condizioni.<br /><br />
                Attenzione: Creando una regola di pricing su un gruppo di impianti verranno eliminate eventuali altre regole impostate sui singoli impianti.</span>
              <hr />
              <div className="d-sm-flex justify-content-between bd-highlight p-4 col">
                <h4 className="col-5">Che tipo di regola vuoi applicare?</h4>
                <div className="col-5 justify-content-between">
                  <Button data-cy="sconto" style={rule.rule_type === "Sconto" ? buttonClicked : buttonNotClicked} id="rule" onClick={() => {
                    setRule({ ["rule_type"]: "Sconto" })
                    setFirstRule("Sconto")
                  }}>Sconto</Button>
                  <Button className="ml-5" style={rule.rule_type === "Maggiorazione" ? buttonClicked : buttonNotClicked} onClick={() => {
                    setRule({ ["rule_type"]: "Maggiorazione" })
                    setFirstRule("Maggiorazione")
                  }}>Maggiorazione</Button>
                </div>
              </div>
              <hr />
              <Autocomplete
                className={`${classes.borderColor} col-3`}
                size="small"
                id="percentage"
                freeSolo
                value={pricingData ? _.toString(rule.percentage) : rule.percentage}
                options={pricingPercentage.map(option => option.percentage)}
                renderInput={(params) => <TextField className="col-10" {...params}
                  onChange={(e: any) => {
                    setRule({ ["percentage"]: e.target.value })
                  }} label="Imposta Percentuale" type="number" InputProps={{ startAdornment: <InputAdornment position="start">%</InputAdornment> }} />}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion hidden={hideFirst} square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary data-cy="condition1Resume" className="d-sm-flex align-items-center" aria-controls="panel2d-content" id="panel2d-header">
            <Icon className="priceIcon" color="#00e68a" size={40} icon="note" />
            <div className="d-sm-flex align-items-center">Condizione 1:
              <b className="ml-2">{condition1 == "Spesa totale" && totalPrice.condition_1 && totalPrice.amount_1 ? `Spesa ${totalPrice.condition_1} di ${totalPrice.amount_1}` :
                condition1 == "Periodo" && period.from && period.to ? `${condition1} da ${moment(period.from).format("DD-MM-YYYY")} a ${moment(period.to).format("DD-MM-YYYY")}` : !_.isEmpty(selectedClients) ? <Avatars /> : null} </b>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Col>
              <h2>Imposta una condizione</h2>
              <span className="text-secondary detailText">Imposta da un minimo di 1 ad un massimo di 3 condizioni.</span>
              <hr />
              <div className="d-sm-flex justify-content-between bd-highlight p-4 col">
                <h4 className="col-6">Che tipo di condizione vuoi applicare?</h4>
                <div className="col-7 justify-conten<t-between">
                  <Button data-cy="totalPrice" style={condition1 === "Spesa totale" ? buttonClicked : buttonNotClicked} onClick={() => {
                    setCondition1("Spesa totale")
                    setActiveCondition("Spesa totale")
                  }}>Spesa totale</Button>
                  <Button className="ml-5" style={condition1 === "Periodo" ? buttonClicked : buttonNotClicked} onClick={() => {
                    setCondition1("Periodo")
                    setActiveCondition("Periodo")
                  }}>Periodo</Button>
                  <Button className="ml-5" style={condition1 === "Cliente" ? buttonClicked : buttonNotClicked} onClick={() => {
                    dispatch(PaymentActions.willListConditionUsers())
                    setCondition1("Cliente")
                    setActiveCondition("Cliente")
                  }}>Cliente</Button>
                </div>
              </div>
              <hr />
              {condition1 === "Spesa totale" &&
                <div className="column">
                  <span className="text-secondary detailText">APPLICA CONDIZIONE SE L'ORDINE TOTALE E'</span>
                  <div className="d-sm-flex align-items-center">
                    <Autocomplete
                      className={`${classes.borderColor} col-3`}
                      size="small"
                      id="condition_1"
                      defaultValue={totalPrice.condition_1}
                      options={conditionsTotalPrice.map(option => option.condition1)}
                      onChange={(e: any, value: any) => setTotalPrice({ ["condition_1"]: value })}
                      renderInput={(params) => <TextField label="Scegli condizione.." className="col-10" {...params} />}
                    />
                    <Autocomplete
                      className={`${classes.borderColor} col-3 p-4`}
                      size="small"
                      id="amount_1"
                      freeSolo
                      defaultValue={totalPrice.amount_1}
                      options={amountTotalPrice.map(option => option.amount_1)}
                      renderInput={(params) => <TextField className="col-10" {...params}
                        onChange={(e: any) => {
                          setTotalPrice({ ["amount_1"]: e.target.value })
                        }}
                        label="Scegli prezzo.." type="number" InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} />}
                    />
                  </div></div>}
              {condition1 === "Periodo" &&
                <div className="column">
                  <span className="text-secondary detailText">INSERISCI DATE</span>
                  <div className="row">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        className={`${classes.borderColor} col-3 p-4`}
                        data-cy="datedateFrom"
                        disableToolbar
                        autoOk={true}
                        maxDate={period.to ? period.to : undefined}
                        format="DD/MM/YYYY"
                        variant="inline"
                        id="from"
                        label="Data inizio"
                        keyboardIcon={<Icon color="grey" size={25} icon="schedule" />}
                        value={period.from}
                        onChange={(e: any) => setPeriod({ ["from"]: e._d })}
                        InputAdornmentProps={{ position: "start" }}
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        className={`${classes.borderColor} col-3 p-4`}
                        data-cy="dateTo"
                        disableToolbar
                        autoOk={true}
                        minDate={period.from ? period.from : undefined}
                        format="DD/MM/YYYY"
                        variant="inline"
                        id="to"
                        label="Data fine"
                        keyboardIcon={<Icon color="grey" size={25} icon="schedule" />}
                        value={period.to}
                        onChange={(e: any) => setPeriod({ ["to"]: e._d })}
                        InputAdornmentProps={{ position: "start" }}
                      />
                    </MuiPickersUtilsProvider>
                  </div></div>}
              {condition1 === "Cliente" &&
                <div className="column">
                  <span style={{fontSize: 12, opacity: 0.4}}>nota: se non viene selezionato nessun cliente specifico, la regola verrà applicata automaticamente a tutti</span>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="client_select"
                    options={userList}
                    getOptionLabel={(option: any) => option.name}
                    defaultValue={[]}
                    onChange={(e: any, value: any) => { setSelectedClients(value) }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Seleziona cliente.."
                      />
                    )} />
                </div>}
            </Col>
          </AccordionDetails>
        </Accordion>
        <Accordion hidden={hideSecond} square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary data-cy="condition2Resume" aria-controls="panel3d-content" id="panel3d-header">
            <Icon className="priceIcon" color="#00e68a" size={40} icon="note" />
            <div className="d-sm-flex align-items-center">Condizione 2:
              <b className="ml-2">{condition2 == "Spesa totale" && totalPrice.condition_1 && totalPrice.amount_1 ? `Spesa ${totalPrice.condition_1} di ${totalPrice.amount_1}` :
                condition2 == "Periodo" && period.from && period.to ? `${condition2} da ${moment(period.from).format("DD-MM-YYYY")} a ${moment(period.to).format("DD-MM-YYYY")}` : !_.isEmpty(selectedClients) ? <Avatars /> : null} </b>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Col>
              <h2>Aggiungi condizione</h2>
              <span className="text-secondary detailText">Imposta da un minimo di 1 ad un massimo di 3 condizioni.</span>
              <hr />
              <div className="d-sm-flex justify-content-between bd-highlight p-4 col">
                <h4 className="col-6">Che tipo di condizione vuoi applicare?</h4>
                <div className="col-7 justify-conten<t-between">
                  <Button style={condition2 === "Spesa totale" ? buttonClicked : buttonNotClicked} hidden={condition1 == "Spesa totale" ? true : false} onClick={() => {
                    setCondition2("Spesa totale")
                    setActiveCondition("Spesa totale")
                  }}>Spesa totale</Button>
                  <Button data-cy="period" style={condition2 === "Periodo" ? buttonClicked : buttonNotClicked} hidden={condition1 == "Periodo" ? true : false} className="ml-5" color={condition2 === "Periodo" ? "primary" : ""} onClick={() => {
                    setCondition2("Periodo")
                    setActiveCondition("Periodo")
                  }}>Periodo</Button>
                  <Button style={condition2 === "Cliente" ? buttonClicked : buttonNotClicked} hidden={condition1 == "Cliente" ? true : false} className="ml-5" color={condition2 === "Cliente" ? "primary" : ""} onClick={() => {
                    dispatch(PaymentActions.willListConditionUsers())
                    setCondition2("Cliente")
                    setActiveCondition("Cliente")
                  }}>Cliente</Button>
                </div>
              </div>
              <hr />
              {condition2 === "Spesa totale" &&
                <div className="column">
                  <span className="text-secondary detailText">APPLICA CONDIZIONE SE L'ORDINE TOTALE E'</span>
                  <div className="row">
                    <Autocomplete
                      className={`${classes.borderColor} col-3 p-4`}
                      size="small"
                      id="condition_1"
                      defaultValue={""}
                      options={conditionsTotalPrice.map(option => option.condition1)}
                      onChange={(e: any, value: any) => setTotalPrice({ ["condition_1"]: value })}
                      renderInput={(params) => <TextField label="Scegli condizione.." className="col-10" {...params} />}
                    />
                    <Autocomplete
                      className={`${classes.borderColor} col-3 p-4`}
                      size="small"
                      id="amount_1"
                      freeSolo
                      defaultValue={""}
                      options={amountTotalPrice.map(option => option.amount_1)}
                      // onChange={(e: any, value: any) => setTotalPrice({ ["amount_1"]: value })}
                      renderInput={(params) => <TextField className="col-10"
                        onChange={(e: any) => {
                          setTotalPrice({ ["amount_1"]: e.target.value })
                        }}
                        {...params} label="Scegli prezzo.." type="number" InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} />}
                    />
                  </div></div>}
              {condition2 === "Periodo" &&
                <div className="row">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      className={`${classes.borderColor} col-3 p-4`}
                      data-cy="datedateFrom"
                      disableToolbar
                      autoOk={true}
                      maxDate={period.to ? period.to : undefined}
                      format="DD/MM/YYYY"
                      variant="inline"
                      id="from"
                      label="Data fine"
                      keyboardIcon={<Icon color="grey" size={25} icon="schedule" />}
                      value={period.from}
                      onChange={(e: any) => setPeriod({ ["from"]: e._d })}
                      InputAdornmentProps={{ position: "start" }}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      className={`${classes.borderColor} col-3 p-4`}
                      data-cy="dateTo"
                      disableToolbar
                      autoOk={true}
                      minDate={period.from ? period.from : undefined}
                      format="DD/MM/YYYY"
                      variant="inline"
                      id="to"
                      label="Data fine"
                      keyboardIcon={<Icon color="grey" size={25} icon="schedule" />}
                      value={period.to}
                      onChange={(e: any) => setPeriod({ ["to"]: e._d })}
                      InputAdornmentProps={{ position: "start" }}
                    />
                  </MuiPickersUtilsProvider>
                </div>}
              {condition2 === "Cliente" &&
                <div className="column">
                <span style={{fontSize: 12, opacity: 0.4}}>nota: se non viene selezionato nessun cliente specifico, la regola verrà applicata automaticamente a tutti</span>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="client_select"
                    options={userList}
                    getOptionLabel={(option: any) => option.name}
                    defaultValue={selectedClients}
                    onChange={(e: any, value: any) => { setSelectedClients(value) }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Seleziona cliente.."
                      />
                    )} />
                </div>}
            </Col>
          </AccordionDetails>
        </Accordion>
        <Accordion hidden={hideThird} square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary data-cy="condition3Resume" aria-controls="panel4d-content" id="panel4d-header">
            <Icon className="priceIcon" color="#00e68a" size={40} icon="note" /> <div className="d-sm-flex align-items-center">Condizione 3:
              <b className="ml-2">{condition3 == "Spesa totale" && totalPrice.condition_1 && totalPrice.amount_1 ? `Spesa ${totalPrice.condition_1} di ${totalPrice.amount_1}` :
                condition3 == "Periodo" && period.from && period.to ? `${condition3} da ${moment(period.from).format("DD-MM-YYYY")} a ${moment(period.to).format("DD-MM-YYYY")}` : !_.isEmpty(selectedClients) ? <Avatars /> : null} </b>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <Col>
              <h2>Aggiungi condizione</h2>
              <span className="text-secondary detailText">Imposta da un minimo di 1 ad un massimo di 3 condizioni.</span>
              <hr />
              <div className="d-sm-flex justify-content-between bd-highlight p-4 col">
                <h4 className="col-6">Che tipo di condizione vuoi applicare?</h4>
                <div className="col-7 justify-conten<t-between">
                  <Button style={condition3 === "Spesa totale" ? buttonClicked : buttonNotClicked} hidden={condition1 == "Spesa totale" || condition2 == "Spesa totale" ? true : false} onClick={() => {
                    setCondition3("Spesa totale")
                    setActiveCondition("Spesa totale")
                  }}>Spesa totale</Button>
                  <Button style={condition3 === "Periodo" ? buttonClicked : buttonNotClicked} hidden={condition1 == "Periodo" || condition2 == "Periodo" ? true : false} className="ml-5" color={condition3 === "Periodo" ? "primary" : ""} onClick={() => {
                    setCondition3("Periodo")
                    setActiveCondition("Periodo")
                  }}>Periodo</Button>
                  <Button data-cy="client" style={condition3 === "Cliente" ? buttonClicked : buttonNotClicked} hidden={condition1 == "Cliente" || condition2 == "Cliente" ? true : false} className="ml-5" color={condition3 === "Cliente" ? "primary" : ""} onClick={() => {
                    dispatch(PaymentActions.willListConditionUsers())
                    setCondition3("Cliente")
                    setActiveCondition("Cliente")
                  }}>Cliente</Button>
                </div>
              </div>
              <hr />
              {condition3 === "Spesa totale" &&
                <div className="column">
                  <span className="text-secondary detailText">APPLICA CONDIZIONE SE L'ORDINE TOTALE E'</span>
                  <div className="row">
                    <Autocomplete
                      className={`${classes.borderColor} col-3 p-4`}
                      size="small"
                      id="condition_1"
                      defaultValue={pricingData ? totalPrice.condition_1 : ""}
                      options={conditionsTotalPrice.map(option => option.condition1)}
                      onChange={(e: any, value: any) => setTotalPrice({ ["condition_1"]: value })}
                      renderInput={(params) => <TextField label="Scegli condizione.." className="col-10" {...params} />}
                    />
                    <Autocomplete
                      className={`${classes.borderColor} col-3 p-4`}
                      size="small"
                      id="amount_1"
                      freeSolo
                      defaultValue={pricingData ? totalPrice.amount_1 : ""}
                      options={amountTotalPrice.map(option => option.amount_1)}
                      // onChange={(e: any, value: any) => setTotalPrice({ ["amount_1"]: value })}
                      renderInput={(params) => <TextField className="col-10" {...params}
                        onChange={(e: any) => {
                          setTotalPrice({ ["amount_1"]: e.target.value })
                        }}
                        label="Scegli prezzo.." type="number" InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} />}
                    />
                  </div></div>}
              {condition3 === "Periodo" &&
                <div className="row">
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      className={`${classes.borderColor} col-3 p-4`}
                      data-cy="datedateFrom"
                      disableToolbar
                      autoOk={true}
                      maxDate={period.to ? period.to : undefined}
                      format="DD/MM/YYYY"
                      variant="inline"
                      id="from"
                      label="Data fine"
                      keyboardIcon={<Icon color="grey" size={25} icon="schedule" />}
                      value={period.from}
                      onChange={(e: any) => setPeriod({ ["from"]: e._d })}
                      InputAdornmentProps={{ position: "start" }}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      className={`${classes.borderColor} col-3 p-4`}
                      data-cy="dateTo"
                      disableToolbar
                      autoOk={true}
                      minDate={period.from ? period.from : undefined}
                      format="DD/MM/YYYY"
                      variant="inline"
                      id="to"
                      label="Data fine"
                      keyboardIcon={<Icon color="grey" size={25} icon="schedule" />}
                      value={period.to}
                      onChange={(e: any) => setPeriod({ ["to"]: e._d })}
                      InputAdornmentProps={{ position: "start" }}
                    />
                  </MuiPickersUtilsProvider>
                </div>}
              {condition3 === "Cliente" &&
                <div className="column">
                <span style={{fontSize: 12, opacity: 0.4}}>nota: se non viene selezionato nessun cliente specifico, la regola verrà applicata automaticamente a tutti</span>
                  <Autocomplete
                    multiple
                    freeSolo
                    id="client_select"
                    options={userList}
                    getOptionLabel={(option: any) => option.name}
                    defaultValue={[]}
                    onChange={(e: any, value: any) => {
                      console.log(value)
                      setSelectedClients(value)
                    }
                    }
                    // onChange={(e: any, value: any) => {
                    //   // console.log('clienti in onchange',e, _.map(value, 'media_owner') )
                    //   setSelectedClients(_.map(value, 'media_owner'))
                    // }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Seleziona cliente.."
                      />
                    )} />
                </div>}
            </Col>
          </AccordionDetails>
        </Accordion>
        <div className="d-sm-flex justify-content-between mt-5">
          <div className="d-sm-flex justify-content-start">
            <Button style={{ backgroundColor: "#E6E6E6", color: "#8C8C8C", border: "none", borderRadius: 10 }} onClick={() => dispatch(UiActions.switchPricing('home'))}>Annulla</Button>
          </div>
          <div className="d-sm-flex justify-content-end">
            <Button data-cy="addCondition" style={{ backgroundColor: "transparent", color: "black", borderRadius: 10, borderColor: "#E6E6E6", borderWidth: 1 }} hidden={condition3 !== "" || rule.rule_type == "" || rule.percentage == "" ? true : false} onClick={buttonExpanded}>Aggiungi Condizione</Button>
            {activeCondition === "rule" ? null : <Button data-cy="confirm" style={{ borderRadius: 10 }} className="ml-5" onClick={() => {
              console.log("Pricing with data: ", rule, totalPrice, period, selectedClients)
              dispatch(ContentActions.willAddPricing({ bil_list: _.map(billboards, function (el: any) { return { 'id': el.id, 'city_code': el.city_code } }), price_rule: { ...rule, ...totalPrice, ...period, customers: _.isEmpty(selectedClients) ? _.map(userList, 'advertiser') : _.map(selectedClients, 'advertiser') } }))
            }} color="primary">Conferma Regola</Button>}
          </div>
        </div>
      </div>
      <div className="col-3">
        <b>Impianti Selezionati</b>
        <p>Stai applicando una regola di pricing a:</p>
        <Paper className="h-50" elevation={3}>
          <div style={{ maxHeight: "40vh" }} className="overflow-auto p-3 bg-white rounded detailText">
            {!_.isEmpty(billboards) ? billboards.map((plant: any, index: any) => {
              const imageUrl = (plant.imageUrl ? plant.imageUrl : `https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg`)
              return (
                <Grid key={`plant-${index}`} container spacing={4} alignItems="center">
                  <Grid item xs={2}>
                    <img id={index} className="mr-3 plantAvatar" src={imageUrl} />
                  </Grid>
                  <Grid item xs={10}>
                    <div id={index}>{`${plant.city} - ${plant.address}`}</div>
                  </Grid>
                </Grid>
              )
            }) : (<></>)
            }
          </div>
        </Paper>
      </div>
    </div>
  )
}