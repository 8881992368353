import React from 'react';

/// INDOOR
import { ReactComponent as AltroIndoor } from "../assets/map-pins/indoor/altro.svg";
import { ReactComponent as DigitalIndoor } from "../assets/map-pins/indoor/digital.svg";
import { ReactComponent as IlluminatoIndoor } from "../assets/map-pins/indoor/illuminato.svg";
import { ReactComponent as OpacoIndoor } from "../assets/map-pins/indoor/opaco.svg";
import { ReactComponent as StriscioneIndoor } from "../assets/map-pins/indoor/striscione.svg";
import { ReactComponent as VetrinaIndoor } from "../assets/map-pins/indoor/vetrina.svg";

/// OUTDOOR
import { ReactComponent as AffissioneOutdoor } from "../assets/map-pins/outdoor/affissione.svg";
import { ReactComponent as AltroOutdoor } from "../assets/map-pins/outdoor/altro.svg";
import { ReactComponent as BannerOutdoor } from "../assets/map-pins/outdoor/banner.svg";
import { ReactComponent as CartellonisticaOutdoor } from "../assets/map-pins/outdoor/cartellonistica.svg";
import { ReactComponent as DisplayOutdoor } from "../assets/map-pins/outdoor/display-digital.svg";
import { ReactComponent as FacciataOutdoor } from "../assets/map-pins/outdoor/facciata.svg";
import { ReactComponent as MediapoleOutdoor } from "../assets/map-pins/outdoor/mediapole.svg";
import { ReactComponent as PensilinaOutdoor } from "../assets/map-pins/outdoor/pensilina.svg";
import { ReactComponent as TransenneOutdoor } from "../assets/map-pins/outdoor/transenne.svg";

/// DINAMICO
import { ReactComponent as Dinamico } from "../assets/map-pins/dinamico_tdb.svg";

/// PLACEHOLDER
import billboard_placeholder from '../assets/images/billboards_placeholder.svg'

// const Marker = ({ children }: any) => children;
{/* <Marker
key={`cluster-${cluster.id}`}
lat={latitude}
lng={longitude}
>
<div
  className="super-cluster"
  style={{
    width: 20 + (pointCount / points.length) * 30,
    height: 20 + (pointCount / points.length) * 30,
  }}
  onClick={() => { }}
>
  {pointCount}
</div>
</Marker> */}
// { billboardProps, key, lat, lng, pointCount, points, randomFacade }

export const customBillboardIcon = (type: string) => {

  // const selectedBillboard = plantSelected === billboardProps?.id ? "#0062FF": "none"

  // switch (randomFacade) {
  //   case 'Illuminazione opaco':
  //     return <OpacoIndoor fill={selectedBillboard} />
  // }

  switch (type) {
    case 'Altro':
      return <AltroIndoor />
    case 'Display digitale':
      return <DigitalIndoor />
    case 'Impianto illuminato':
      return <IlluminatoIndoor />
    case 'Impianto opaco':
      return <OpacoIndoor />
    case 'Striscione':
      return <StriscioneIndoor />
    case 'Vetrina':
      return <VetrinaIndoor />
    case 'Affissione':
      return <AffissioneOutdoor />
    case 'Cartellonistica':
      return <CartellonisticaOutdoor />
    case 'Pensilina':
      return <PensilinaOutdoor />
    case 'Mediapole':
      return <MediapoleOutdoor />
    case 'Display Digitale':
      return <DisplayOutdoor />
    case 'Facciata':
      return <FacciataOutdoor />
    case 'Banner':
      return <BannerOutdoor />
    case 'Transenne':
      return <TransenneOutdoor />
    case 'Altro':
      return <AltroOutdoor />
    case 'Dinamico':
      return <Dinamico />
    default:
      return <img data-cy="avatar2" width="42" height="48" src={billboard_placeholder} />
  }
}