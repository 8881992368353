import React, { useState, useEffect, useReducer } from 'react';
import { Button, CardBody, CardText, Col } from 'reactstrap';
import { actions as PaymentActions, selectors as PaymentSelectors } from '../store/slices/payment';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, IconButton, ButtonGroup, Avatar, Chip, Paper, InputAdornment } from '@material-ui/core';
import { Accordion, AccordionSummary, AccordionDetails } from "./PricingCss";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Icon from "./IconsComponent";
import 'moment/locale/it';
import _ from 'lodash';

export const Pricing = (props: any) => {
  //console.log("in Pricing with: ", props)
  const [expanded, setExpanded] = React.useState<string | false>('panel1');
  const [activeCondition, setActiveCondition] = useState("rule")
  const [plantCheck, setPlantCheck] = useState(true)
  const onCheckPlant = () => { setPlantCheck(!plantCheck) }
  const dispatch = useDispatch()
  const [billboards, setBillboards] = useState(_.map(props.items, function (el: any) { return { 'id': el.id, 'city_code': el.city_code } }))
  console.log('impianti selezionati', billboards, props)

  //RULE CODE
  const [rule, setRule] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      rule_type: "",
      percentage: ""
    }
  );
  const pricingPercentage = [
    { percentage: "10" },
    { percentage: "20" },
    { percentage: "30" },
    { percentage: "40" },
    { percentage: "50" },
    { percentage: "60" },
    { percentage: "70" },
    { percentage: "80" },
    { percentage: "90" },
    { percentage: "100" }
  ]
  //TOTAL PRICE CODE
  const [totalPrice, setTotalPrice] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      condition_1: "",
      amount_1: ""
    }
  );
  const conditionsTotalPrice = [
    { condition1: "Maggiore" },
    { condition1: "Minore" },
  ]
  const amountTotalPrice = [
    { amount_1: "10" },
    { amount_1: "20" },
    { amount_1: "30" },
    { amount_1: "40" },
    { amount_1: "50" },
    { amount_1: "60" },
    { amount_1: "70" },
    { amount_1: "80" },
    { amount_1: "90" },
    { amount_1: "100" }
  ]
  //PERIOD CODE
  const [period, setPeriod] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      from: "",
      to: ""
    }
  );
  const handleChangeInput = (evt: any) => {
    const key = evt.target.id;
    const value = evt.target.value;
    setPeriod({ [key]: value });
    console.log("in hangleChangeInput", key, value)
  }

  //CLIENT CODE
  const [selectedClients, setSelectedClients] = useState([]) as any
  const userList = useSelector(PaymentSelectors.listConditionUsers)
  useEffect(() => {
    dispatch(PaymentActions.willListConditionUsers())
    console.log("in useEffect Pricing")
  }, [])
  const [dataClients, setDataClients] = useState([] as any)

  // const clientsInfo = () => {
  //   console.log("clienti 1 selectedClients", selectedClients)

  //       const clients = selectedClients.map(((list: any) => {
  //     return { id: list.id, media_owner: list.media_owner }
  //   }))
  //   setDataClients(_.map(selectedClients, 'media_owner'))
  //   console.log("clienti 2", clients, dataClients)
  // }

  //CONDITIONS CODE
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  const buttonExpanded = () => {
    if (expanded === 'panel1') {
      setExpanded('panel2')
      setActiveCondition("")
      setHideFirst(false)
    }
    else if (expanded === 'panel2') {
      setExpanded('panel3')
      setActiveCondition("")
      setHideSecond(false)
    }
    else if (expanded === 'panel3') {
      setExpanded('panel4')
      setActiveCondition("")
      setHideThird(false)
    }
    else {
      setExpanded('panel4')
    }
  }
  const [firstRule, setFirstRule] = useState("")
  const [condition1, setCondition1] = useState("")
  const [hideFirst, setHideFirst] = useState(true)
  const [condition2, setCondition2] = useState("")
  const [hideSecond, setHideSecond] = useState(true)
  const [condition3, setCondition3] = useState("")
  const [hideThird, setHideThird] = useState(true)

  const Avatars = () => {
    return (
      <div className="d-sm-flex align-items-center">
        <AvatarGroup className="mr-2" max={4}>
          {selectedClients.map((client: any) => (
            <><Avatar alt={client.name} src={client.avatar} /></>
          ))}
        </AvatarGroup>
        <span className="text-black-50 detailText">{selectedClients.length} Clienti selezionati</span>
      </div>
    )
  }

  return (
    <>
      <div style={{ height: "85vh" }} className="d-flex poppinsFont">
        <div className="col-9">
          <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Icon className="priceIcon" color="#00e68a" size={40} icon="task" /> <div className="p-2">Regola: <b className="ml-2">{firstRule}</b></div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="col">
                <h2>Imposta Regola di Pricing</h2>
                <span className="text-secondary">Segui la procedura guidata per impostare una regola di pricing sugli impianti selezionati. Ogni impianto può avere un massimo di 3 regole.<br />Ogni regola può avere un massimo di 3 condizioni.</span>
                <hr />
                <div className="d-sm-flex justify-content-between bd-highlight p-4 col">
                  <h4 className="col-5">Che tipo di regola vuoi applicare?</h4>
                  <div className="col-5 justify-content-between">
                    <Button data-cy="sconto" style={{ borderRadius: 10, backgroundColor: rule.rule_type === "Sconto" ? "primary" : "#e6e6e6" }} id="rule" onClick={() => {
                      setRule({ ["rule_type"]: "Sconto" })
                      setFirstRule("Sconto")
                    }}>Sconto</Button>
                    <Button style={{ borderRadius: 10 }} className="ml-5" color={rule.rule_type === "Maggiorazione" ? "primary" : "#e6e6e6"} onClick={() => {
                      setRule({ ["rule_type"]: "Maggiorazione" })
                      setFirstRule("Maggiorazione")
                    }}>Maggiorazione</Button>
                  </div>
                </div>
                <hr />
                <Autocomplete
                  className="col-3"
                  size="small"
                  id="percentage"
                  freeSolo
                  defaultValue={""}
                  options={pricingPercentage.map(option => option.percentage)}
                  renderInput={(params) => <TextField type="number" className="col-10"
                    onChange={(e: any) => {
                      setRule({ ["percentage"]: e.target.value })
                    }}
                    {...params} label="Imposta Percentuale" variant="outlined" InputProps={{ startAdornment: <InputAdornment position="start">%</InputAdornment> }} />}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion hidden={hideFirst} square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary className="d-sm-flex align-items-center" aria-controls="panel2d-content" id="panel2d-header">
              <Icon className="priceIcon" color="#00e68a" size={40} icon="note" />
              <div className="d-sm-flex align-items-center">Condizione 1:
                  <b className="ml-2">{condition1 == "Spesa totale" ? `Spesa ${totalPrice.condition_1} di ${totalPrice.amount_1}` :
                  condition1 == "Periodo" ? `${condition1} da ${period.from} a ${period.to}` : <Avatars />} </b>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Col>
                <h2>Imposta una condizione</h2>
                <span className="text-secondary detailText">Imposta da un minimo di 1 ad un massimo di 3 condizioni.</span>
                <hr />
                <div className="d-sm-flex justify-content-between bd-highlight p-4 col">
                  <h4 className="col-5">Che tipo di condizione vuoi applicare?</h4>
                  <div className="col-5 justify-conten<t-between">
                    <Button data-cy="totalPrice" style={{ borderRadius: 10 }} color={condition1 === "Spesa totale" ? "primary" : "#e6e6e6"} onClick={() => {
                      setCondition1("Spesa totale")
                      setActiveCondition("Spesa totale")
                    }}>Spesa totale</Button>
                    <Button style={{ borderRadius: 10 }} className="ml-5" color={condition1 === "Periodo" ? "primary" : "#e6e6e6"} onClick={() => {
                      setCondition1("Periodo")
                      setActiveCondition("Periodo")
                    }}>Periodo</Button>
                    <Button style={{ borderRadius: 10 }} className="ml-5" color={condition1 === "Cliente" ? "primary" : "#e6e6e6"} onClick={() => {
                      setCondition1("Cliente")
                      setActiveCondition("Cliente")
                    }}>Cliente</Button>
                  </div>
                </div>
                <hr />
                {condition1 === "Spesa totale" &&
                  <div className="column">
                    <span className="text-secondary detailText">APPLICA CONDIZIONE SE L'ORDINE TOTALE E'</span>
                    <div className="row">
                      <Autocomplete
                        className="col-3 p-4"
                        size="small"
                        id="condition_1"
                        freeSolo
                        defaultValue={""}
                        options={conditionsTotalPrice.map(option => option.condition1)}
                        onChange={(e: any, value: any) => setTotalPrice({ ["condition_1"]: value })}
                        renderInput={(params) => <TextField className="col-10" {...params} />}
                      />
                      <Autocomplete
                        className="col-3 p-4"
                        size="small"
                        id="amount_1"
                        freeSolo
                        defaultValue={""}
                        value={totalPrice.amount_1}
                        options={amountTotalPrice.map(option => option.amount_1)}
                        renderInput={(params) => <TextField className="col-10" {...params}
                          onChange={(e: any) => {
                            setTotalPrice({ ["amount_1"]: e.target.value })
                          }}
                          label="Scegli prezzo..." type="number" variant="outlined" InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} />}
                      />
                    </div></div>}
                {condition1 === "Periodo" &&
                  <div className="column">
                    <span className="text-secondary detailText">INSERISCI DATE</span>
                    <div className="row">
                      <TextField
                        className="col-3 p-4"
                        id="from"
                        type="date"
                        size="small"
                        defaultValue=""
                        onChange={handleChangeInput}
                        InputLabelProps={{ shrink: true }}
                      />
                      <TextField
                        className="col-3 p-4"
                        id="to"
                        type="date"
                        size="small"
                        defaultValue=""
                        onChange={handleChangeInput}
                        InputLabelProps={{ shrink: true }}
                      />
                    </div></div>}
                {condition1 === "Cliente" &&
                  <div className="column">
                    <Autocomplete
                      multiple
                      freeSolo
                      id="client_select"
                      options={userList}
                      getOptionLabel={(option: any) => option.name}
                      defaultValue={[]}
                      onChange={(e: any, value: any) => { setSelectedClients(value) }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Seleziona cliente.."
                        />
                      )} />
                  </div>}
              </Col>
            </AccordionDetails>
          </Accordion>
          <Accordion hidden={hideSecond} square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
            <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
              <Icon className="priceIcon" color="#00e68a" size={40} icon="note" />
              <div className="d-sm-flex align-items-center">Condizione 2:
              <b className="ml-2">{condition2 == "Spesa totale" ? `Spesa ${totalPrice.condition_1} di ${totalPrice.amount_1}` :
                  condition2 == "Periodo" ? `${condition2} da ${period.from} a ${period.to}` : <Avatars />} </b></div>
            </AccordionSummary>
            <AccordionDetails>
              <Col>
                <h2>Aggiungi condizione</h2>
                <span className="text-secondary detailText">Imposta da un minimo di 1 ad un massimo di 3 condizioni.</span>
                <hr />
                <div className="d-sm-flex justify-content-between bd-highlight p-4 col">
                  <h4 className="col-5">Che tipo di condizione vuoi applicare?</h4>
                  <div className="col-5 justify-conten<t-between">
                    <Button style={{ borderRadius: 10 }} hidden={condition1 == "Spesa totale" ? true : false} color={condition2 === "Spesa totale" ? "primary" : ""} onClick={() => {
                      setCondition2("Spesa totale")
                      setActiveCondition("Spesa totale")
                    }}>Spesa totale</Button>
                    <Button data-cy="period" style={{ borderRadius: 10 }} hidden={condition1 == "Periodo" ? true : false} className="ml-5" color={condition2 === "Periodo" ? "primary" : ""} onClick={() => {
                      setCondition2("Periodo")
                      setActiveCondition("Periodo")
                    }}>Periodo</Button>
                    <Button style={{ borderRadius: 10 }} hidden={condition1 == "Cliente" ? true : false} className="ml-5" color={condition2 === "Cliente" ? "primary" : ""} onClick={() => {
                      setCondition2("Cliente")
                      setActiveCondition("Cliente")
                    }}>Cliente</Button>
                  </div>
                </div>
                <hr />
                {condition2 === "Spesa totale" &&
                  <div className="column">
                    <span className="text-secondary detailText">APPLICA CONDIZIONE SE L'ORDINE TOTALE E'</span>
                    <div className="row">
                      <Autocomplete
                        className="col-3 p-4"
                        size="small"
                        id="condition_1"
                        freeSolo
                        defaultValue={""}
                        options={conditionsTotalPrice.map(option => option.condition1)}
                        onChange={(e: any, value: any) => setTotalPrice({ ["condition_1"]: value })}
                        renderInput={(params) => <TextField className="col-10" {...params} />}
                      />
                      <Autocomplete
                        className="col-3 p-4"
                        size="small"
                        id="amount_1"
                        freeSolo
                        defaultValue={""}
                        value={totalPrice.amount_1}
                        options={amountTotalPrice.map(option => option.amount_1)}
                        // onChange={(e: any, value: any) => setTotalPrice({ ["amount_1"]: value })}
                        renderInput={(params) => <TextField className="col-10"
                          onChange={(e: any) => {
                            setTotalPrice({ ["amount_1"]: e.target.value })
                          }}
                          {...params} label="Scegli prezzo..." type="number" variant="outlined" InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} />}
                      />
                    </div></div>}
                {condition2 === "Periodo" &&
                  <div className="row">
                    <TextField
                      className="col-3 p-4"
                      id="from"
                      label="Ultimo Intervento"
                      type="date"
                      variant="outlined"
                      size="small"
                      defaultValue=""
                      onChange={handleChangeInput}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      className="col-3 p-4"
                      id="to"
                      label="Ultimo Intervento"
                      type="date"
                      variant="outlined"
                      size="small"
                      defaultValue=""
                      onChange={handleChangeInput}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>}
                {condition2 === "Cliente" &&
                  <div className="column">
                    <Autocomplete
                      multiple
                      freeSolo
                      id="client_select"
                      options={userList}
                      getOptionLabel={(option: any) => option.name}
                      defaultValue={[]}
                      onChange={(e: any, value: any) => { setSelectedClients(value) }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Seleziona cliente.."
                        />
                      )} />
                  </div>}
              </Col>
            </AccordionDetails>
          </Accordion>
          <Accordion hidden={hideThird} square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
              <Icon className="priceIcon" color="#00e68a" size={40} icon="note" /> <div className="d-sm-flex align-items-center">Condizione 3:
              <b className="ml-2">{condition3 == "Spesa totale" ? `Spesa ${totalPrice.condition_1} di ${totalPrice.amount_1}` :
                  condition3 == "Periodo" ? `${condition3} da ${period.from} a ${period.to}` : <Avatars />} </b>              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Col>
                <h2>Aggiungi condizione</h2>
                <span className="text-secondary detailText">Imposta da un minimo di 1 ad un massimo di 3 condizioni.</span>
                <hr />
                <div className="d-sm-flex justify-content-between bd-highlight p-4 col">
                  <h4 className="col-5">Che tipo di condizione vuoi applicare?</h4>
                  <div className="col-5 justify-conten<t-between">
                    <Button style={{ borderRadius: 10 }} hidden={condition1 == "Spesa totale" || condition2 == "Spesa totale" ? true : false} color={condition3 === "Spesa totale" ? "primary" : ""} onClick={() => {
                      setCondition3("Spesa totale")
                      setActiveCondition("Spesa totale")
                    }}>Spesa totale</Button>
                    <Button style={{ borderRadius: 10 }} hidden={condition1 == "Periodo" || condition2 == "Periodo" ? true : false} className="ml-5" color={condition3 === "Periodo" ? "primary" : ""} onClick={() => {
                      setCondition3("Periodo")
                      setActiveCondition("Periodo")
                    }}>Periodo</Button>
                    <Button data-cy="client" style={{ borderRadius: 10 }} hidden={condition1 == "Cliente" || condition2 == "Cliente" ? true : false} className="ml-5" color={condition3 === "Cliente" ? "primary" : ""} onClick={() => {
                      setCondition3("Cliente")
                      setActiveCondition("Cliente")
                    }}>Cliente</Button>
                  </div>
                </div>
                <hr />
                {condition3 === "Spesa totale" &&
                  <div className="column">
                    <span className="text-secondary detailText">APPLICA CONDIZIONE SE L'ORDINE TOTALE E'</span>
                    <div className="row">
                      <Autocomplete
                        className="col-3 p-4"
                        size="small"
                        id="condition_1"
                        freeSolo
                        defaultValue={""}
                        options={conditionsTotalPrice.map(option => option.condition1)}
                        onChange={(e: any, value: any) => setTotalPrice({ ["condition_1"]: value })}
                        renderInput={(params) => <TextField className="col-10" {...params} />}
                      />
                      <Autocomplete
                        className="col-3 p-4"
                        size="small"
                        id="amount_1"
                        freeSolo
                        defaultValue={""}
                        value={totalPrice.amount_1}
                        options={amountTotalPrice.map(option => option.amount_1)}
                        // onChange={(e: any, value: any) => setTotalPrice({ ["amount_1"]: value })}
                        renderInput={(params) => <TextField className="col-10" {...params}
                          onChange={(e: any) => {
                            setTotalPrice({ ["amount_1"]: e.target.value })
                          }}
                          label="Scegli prezzo..." type="number" variant="outlined" InputProps={{ startAdornment: <InputAdornment position="start">€</InputAdornment> }} />}
                      />
                    </div></div>}
                {condition3 === "Periodo" &&
                  <div className="row">
                    <TextField
                      className="col-3 p-4"
                      id="from"
                      label="Ultimo Intervento"
                      type="date"
                      variant="outlined"
                      size="small"
                      defaultValue=""
                      onChange={handleChangeInput}
                      InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                      className="col-3 p-4"
                      id="to"
                      label="Ultimo Intervento"
                      type="date"
                      variant="outlined"
                      size="small"
                      defaultValue=""
                      onChange={handleChangeInput}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>}
                {condition3 === "Cliente" &&
                  <div className="column">
                    <Autocomplete
                      multiple
                      freeSolo
                      id="client_select"
                      options={userList}
                      getOptionLabel={(option: any) => option.name}
                      defaultValue={[]}
                      onChange={(e: any, value: any) => {
                        // console.log('clienti in onchange',e, _.map(value, 'media_owner') )
                        setSelectedClients(_.map(value, 'media_owner'))
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Seleziona cliente.."
                        />
                      )} />
                  </div>}
              </Col>
            </AccordionDetails>
          </Accordion>
          <div className="d-sm-flex justify-content-between mt-5">
            <div className="d-sm-flex justify-content-start">
              <Button style={{ backgroundColor: "#e6e6e6", color: "#8c8c8c", border: "none", borderRadius: 10 }} onClick={() => dispatch(UiActions.switchPricing())}>Annulla</Button>
            </div>
            <div className="d-sm-flex justify-content-end">
              <Button data-cy="addCondition" hidden={condition3 !== "" || rule.rule_type == "" || rule.percentage == "" ? true : false} onClick={buttonExpanded}>Aggiungi Condizione</Button>
              {activeCondition === "rule" ? null : <Button data-cy="confirm" className="ml-5" onClick={() => {
                // clientsInfo()
                console.log("Pricing with data: ", rule, totalPrice, period, selectedClients)
                dispatch(ContentActions.willAddPricing({ bil_list: billboards, price_rule: { ...rule, ...totalPrice, ...period, customers: selectedClients } }))
              }} color="primary">Conferma Regola</Button>}
            </div>
          </div>
        </div>
        <div className="col-3">
          <b>Impianti Selezionati</b>
          <p>Stai applicando una regola di pricing a:</p>
          <Paper className="h-50" elevation={3}>
            <div style={{ maxHeight: "40vh" }} className="overflow-auto p-3 ml-3 bg-white rounded detailText">
              {props.items.map((plant: any) => {
                const imageUrl = (plant.imageUrl ? plant.imageUrl : `https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg`)
                return (
                  <div className="row d-sm-flex align-items-center text-dark poppinsFont mb-3">
                    <img className="mr-3 plantAvatar" src={imageUrl} />
                    {plant.Comune.props.children[1]}
                    <input className="ml-3" type="checkbox" color="#00e68a" checked={plantCheck} onClick={onCheckPlant} />
                  </div>
                )
              })}
            </div>
          </Paper>
        </div>
      </div>
    </>
  )
}