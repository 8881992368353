import React, { useState, useMemo, useCallback, useEffect, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ButtonDropdown, UncontrolledDropdown, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Button, Row, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader, Label } from "reactstrap";
import { useTable, useSortBy, useFlexLayout, useExpanded, useGlobalFilter, useAsyncDebounce, useRowSelect, useGroupBy } from "react-table";
import { VariableSizeList, FixedSizeList } from "react-window";
import { Grid, Paper } from '@material-ui/core';
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from 'react-window-infinite-loader';
import ReactTooltip from "react-tooltip";
import debounce from "lodash/debounce";
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { TextField, InputAdornment, Tooltip, Zoom, Backdrop, CircularProgress } from '@material-ui/core';
import { usePopupState, bindHover, bindPopover, } from 'material-ui-popup-state/hooks';
import { SetStatus } from '../components/SetStatus';
import Icon from "../components//IconsComponent";
import { Styles, useStyles } from "../components/plant-list/PlantListCss";
import { orderStatusSwitch, setBadge, statusColor, notificationTextChange, tooltip } from '../components/OrderDetails.css';
import { OrdersFilter } from "../components/OrdersFilter";
import { UploadImages } from '../components/UploadImagesModal';
import { BillaloSupport } from '../components/BillaloSupport';
import { NotificationContainer } from "../components/notification-layer/NotificationContainer";
import Papa from 'papaparse';
import _ from 'lodash';
import moment from 'moment';

const currencyFormatter = (value: number) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
}

const rebuildTooltip = debounce(() => ReactTooltip.rebuild(), 200, {
  leading: false,
  trailing: true
});

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: any, ref) => {
    const defaultRef = useRef() as any
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <div>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </div>
    )
  }
)

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: any) => {
  const classes = useStyles()
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <TextField
      data-cy="inputList"
      style={{ width: '100%' }}
      className={`${classes.noBordersAll} ml-2`}
      variant="outlined"
      id="searchInput"
      size="small"
      value={value || ""}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon color="gray" icon="search" size={20} />
          </InputAdornment>
        ),
        classes: { notchedOutline: classes.noBorders }
      }}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Cerca per cliente, nome campagna...`}
    />
  )
}

const Table = () => {
  const listRef = useRef(null);
  const dispatch = useDispatch()
  const classes = useStyles();
  const [orderColumn, setOrderColumn] = useState([] as any)
  const [actionOpen, setActionOpen] = useState(false);
  const toggleAction = () => setActionOpen(prevState => !prevState);
  const [expandedRows, setExpandedRows] = useState({})
  const [dropdownMenu, setDropdownOpen] = useState(false);
  const toggleMenu = () => setDropdownOpen(!dropdownMenu);
  const [orderProps, setOrderProps] = useState({})
  const [modalType, setModalType] = useState("")
  const [list, setList] = useState("in corso")

  let orders = useSelector(ContentSelectors.listOrders)
  let archivedOrders = useSelector(ContentSelectors.listArchivedOrders)
  let filteredOrders = useSelector(ContentSelectors.getFilteredOrders)
  const sortOrdersColumnValue = useSelector(ContentSelectors.getSortOrdersColumnValue)
  const sortOrdersOrderAsc = useSelector(ContentSelectors.getSortOrdersOrderAsc)
  const billboardAvailabilitiesByRange1 = useSelector(ContentSelectors.listBillboardAvailabilitiesByRange)
  let switchCloseAddNewPlant = useSelector(UiSelectors.switchCloseAddNewPlant)
  // let actionDone = useSelector(UiSelectors.getActionDone)

  let data = ['empty'] as any[]
  if (orders[0] === ['empty']) {
    data = []
  } else {
    if (list === "in corso") {
      data = orders
    }
    else if (list === "archivio") {
      data = archivedOrders
    }
  }


  useEffect(() => {
    // setBillboardAvailabilitiesByRange(billboardAvailabilitiesByRange1)

    console.log("orders list:", list)
    // return () => { }
  }, [billboardAvailabilitiesByRange1, orders, archivedOrders, list])
  // console.log('disponibilitá nel frontend:billboardAvailabilitiesByRange ', billboardAvailabilitiesByRange1)

  const defaultColumn = useMemo(
    () => ({
      width: 50,
      //expanded: false
    }),
    []
  );

  const goToDetails = (props: any) => {
    dispatch(ContentActions.willSelectOrder(props));
    dispatch(ContentActions.willListOrderImages({ order_id: props.id }))
    // dispatch(ContentActions.willGetOrderBillboards({ orderBillboards: [] }));
    dispatch(UiActions.goTo({ page: "/orders/orderdetails" }));
  }

  const onExpand = useCallback((row: any) => {
    row.isExpanded = !row.isExpanded;
    listRef.current && (listRef.current! as any).resetAfterIndex(0, true);
    rebuildTooltip();
  }, []);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  })

  const columns = useMemo(
    () => [
      {
        id: "Stato",
        width: 35,
        Header: "Stato",
        accessor: (props: any) => {
          // let invisible: boolean = false
          let invisible: boolean = setBadge(props.order_date, props.status, 500, props.billboards && props.billboards.length, props.from, props.to).invisible
          let notification = notificationTextChange(setBadge(props.order_date, props.status, 500, props.billboards && props.billboards.length, props.from, props.to).notification)
          let badgeStyle = setBadge(props.order_date, props.status, 500, props.billboards && props.billboards.length, props.from, props.to).style

          return (
            <div className="d-sm-flex align-items-center">
              {/* <Badge color="secondary" variant="dot" invisible={invisible} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} badgeContent="Ciao a tutti sono un cavallo"> */}
              <span className={statusColor(props.status)} onClick={() => goToDetails(props)}>{orderStatusSwitch(props.status, props.from, props.to)}</span>
              {/* /</Badge> */}
              {invisible ? null :
                <Tooltip
                  // style={tooltip}
                  // classes={{ tooltip: classes.tooltip }}
                  title={<span style={{ color: notification === "Attesa materiali" ? "#92929d" : "#fc5a5a" }}><Icon color={notification === "Attesa materiali" ? "#ffc542" : "#fc5a5a"} size={15} icon="info" /> {notification}</span>} TransitionComponent={Zoom}>
                  <div style={badgeStyle} />
                </Tooltip>}
            </div>
          )
        }
      },
      {
        id: "Cliente",
        Header: "Cliente",
        width: 60,
        accessor: (props: any) => {
          //console.log(props)
          const plantImage = `https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg`
          return (
            <div className="row d-sm-flex align-items-center text-dark poppinsFont" onClick={() => goToDetails(props)}>
              <img data-cy="avatar2" src={plantImage} className="mr-2 ordersAvatar" />
              <div>{props.advertiser ? props.advertiser.replace('_', ' ') : ''}</div>
            </div>)
        }
      },
      {
        id: "Nome Campagna",
        Header: "Nome Campagna",
        accessor: (props: any) => <div onClick={() => goToDetails(props)}>{props.campaign_name}</div>
      },
      {
        id: "Inizio/Fine",
        Header: "Inizio/Fine",
        accessor: (props: any) => {
          return <div onClick={() => goToDetails(props)}>{moment(props.from).format("DD-MM-YYYY")} / {moment(props.to).format("DD-MM-YYYY")}</div>
        }
      },
      {
        id: "Data Ordine",
        Header: "Data Ordine",
        width: 31,
        accessor: (props: any) => <div onClick={() => goToDetails(props)}>{moment(props.order_date).format("DD-MM-YYYY")}</div>
      },
      {
        id: "Stampa",
        Header: "Stampa",
        width: 27,
        accessor: (props: any) => <div onClick={() => goToDetails(props)}>{props.printer === "true" ? "Richiesta" : "Non richiesta"}</div>
      },
      {
        id: "Spesa Tot",
        Header: "Spesa Tot.",
        width: 30,
        accessor: (props: any) => <div onClick={() => goToDetails(props)}>{currencyFormatter(_.ceil(props.amount, 2))}</div>
      },
      {
        id: "icons",
        width: 20,
        accessor: (props: any) => <div style={{ zIndex: 4 }} className="d-sm-flex align-items-center">
          {/* <button className="d-sm-flex flex-row-reverse text-secondary bg-transparent border-0" data-cy="availabilities" onClick={() => {
            //dispatch(ContentActions.willListBillboardAvailabilities({ billboard_id: props.id }))
            //setModalAvailabilities(true)
          }} ><Icon color="#a64dff" size={25} icon="assignement" /></button>
          <button className="d-sm-flex flex-row-reverse text-secondary bg-transparent border-0 ml-2 mt-1" data-cy="assignement" onClick={() => { }}><Icon size={25} color="#ffa64d" icon="achievements" /></button> */}
          {/* {props.pricing_id ? <button className="d-sm-flex flex-row-reverse text-secondary bg-transparent border-0" data-cy="assignement" onClick={() => { }}><Icon size={25} color="#00e68a" icon="administration" /></button> : <Tooltip title="Tooltip da impostare" TransitionComponent={Zoom}>
            <button className="d-sm-flex flex-row-reverse text-secondary bg-transparent border-0" data-cy="achievements" onClick={() => { }}
            ><Icon size={25} color="#ffa64d" icon="achievements" /></button></Tooltip>} */}
          {props.status !== "Draft" && props.status !== "pass" && props.status !== "confirmed" && props.status !== "canceled" && moment().isAfter(moment(props.order_date).add(24, 'hours')) ?
            <button className="d-sm-flex flex-row-reverse text-secondary bg-transparent border-0 ml-2" data-cy="drafts" onClick={() => {
              dispatch(ContentActions.willGetOrderContract({ campaign: props.campaign, id: props.id }))
            }} ><Icon color="#66b0ff" size={25} icon="drafts" /></button> : <button className="d-sm-flex flex-row-reverse text-secondary bg-transparent border-0 ml-2" data-cy="drafts" ><Icon size={25} icon="drafts" /></button>}

        </div>
      },
      {
        id: "Opzioni",
        width: 20,
        accessor: (props: any) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle data-cy="plantMenu" className="bg-transparent border-0">
                <button className="bg-transparent border-0 ml-2 align-items-center right"><Icon className="backgroundIconColor" color="gray" size={25} icon="more" /></button>
              </DropdownToggle>
              <DropdownMenu  className={classes.drowdownMenu} >
                <DropdownItem data-cy="setStatusButton" className="text-secondary" onClick={() => {
                  setOrderProps(props)
                  setModalType("status")
                  dispatch(UiActions.switchCloseAddNewPlant())
                }} ><Icon color="gray" size={15} icon="refund" /> Aggiorna stato</DropdownItem>
                <DropdownItem className="text-secondary" onClick={() => {
                  dispatch(ContentActions.willListOrderImages({ order_id: props.id }))
                  setOrderProps(props)
                  setModalType("uploadImages")
                  dispatch(UiActions.switchCloseAddNewPlant())
                }} ><Icon color="gray" size={15} icon="photo" /> Carica Immagini</DropdownItem>
                <DropdownItem className="text-secondary" onClick={() => {
                  setModalType("billaloSupport")
                  dispatch(UiActions.switchCloseAddNewPlant())
                }} ><Icon color="gray" size={15} icon="chat" /> Richiedi Modifiche</DropdownItem>
                {/* <DropdownItem className="text-secondary" disabled onClick={() => { }} ><Icon color="gray" size={15} icon="block" /> Annulla ordine</DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          )
        }
      },
      {
        accessor: "advertiser"
      },
      {
        accessor: "client.name"
      },
      {
        accessor: "campaign_name"
      },
      {
        accessor: "amount"
      },
      {
        accessor: "order_date"
      },
      {
        accessor: "discount"
      }
    ],
    [onExpand, data]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    toggleSortBy,
    preGlobalFilteredRows,
    setGlobalFilter,
    prepareRow,
    selectedFlatRows,
    state
  } = useTable(
    {
      columns,
      // data: _.isEmpty(filteredOrders) ? orders ? orders : archivedOrders : filteredOrders, data
      data: _.isEmpty(filteredOrders) ? data : filteredOrders,
      defaultColumn,
      initialState: {
        expanded: {},
        sortBy: orderColumn,
        hiddenColumns: ["client.name", "campaign_name", "amount", "discount", "order_date", "advertiser"]
      },
      autoResetSelectedRows: false,
      // autoResetExpanded: false,
      // expandSubRows: false
    } as any,
    useFlexLayout,
    useGroupBy,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          width: 5,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div data-cy="selectAllrows">
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <div data-cy="orderRow" {...row.getRowProps({ className: `${row.isSelected ? "borderSelected" : ""} tr-group hoverPointerOrders` })} style={style}
          onClick={() => {
            // dispatch(ContentActions.willSelectOrder(row.original));
            // // dispatch(ContentActions.willGetOrderBillboards({ orderBillboards: [] }));
            // dispatch(UiActions.goTo({ page: "/orders/orderdetails" }));
          }} >
          <div className="d-flex align-items-center tr hoverPointerOrdersRow">
            {row.cells.map((cell) => {
              return (
                <div {...cell.getCellProps()} className="td hoverPointerOrdersRow">
                  {cell.render("Cell")}
                </div>
              );
            })}
          </div>
          {/* {row.isExpanded ? renderRowSubComponent({ row }) : null} */}
        </div>
      );
    },
    [prepareRow, rows]
  );

  const fetchMoreData = () => {
    console.log('fetch More orders...')
    // dispatch(ContentActions.willFetchMoreFirstBillboards({ orderFilters: {}, fetchMore: true }))
  };

  useEffect(() => {
    // console.log('resetAfterIndex 12')
    listRef.current && (listRef.current! as any).resetAfterIndex(0, true);
    return () => { }
  }, [])

  const itemData = {
    rows,
    prepareRow,
  }

  const SortColumn = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const [sorted, setSorted] = useState("")
    const sortOrdersColumnValue = useSelector(ContentSelectors.getSortOrdersColumnValue)

    useEffect(() => {
      setSorted(sortOrdersColumnValue)
    }, [sortOrdersColumnValue])

    return (
      <div>
        <Dropdown data-cy="orderBy" className="mr-2 ml-2" isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle data-cy="sortButton" className="bg-white text-dark border-0" caret>Ordina per: <b>{sorted}</b>  </DropdownToggle>
          <DropdownMenu className={classes.drowdownMenu}>
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps({ style: { width: 200 } })} >
                <div className="column">
                  {headerGroup.headers.filter((item) => { return item.id !== "selection" && item.id !== "expander" && item.id !== "Opzioni" && item.id !== "icons" && item.id !== "Stampa" }).map((column) => {
                    // console.log("COLUMS", column)
                    return (
                      <DropdownItem
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        onClick={() => {
                          console.log('in sorting orders: ', column.id)
                          dispatch(ContentActions.didSetOrdersSortColumnValue({ sortOrdersColumnValue: column.id, sortOrdersOrderAsc: true }))
                          // toggleSortBy(column.id, true, false)                                        //ordinamento
                          listRef.current && (listRef.current! as any).resetAfterIndex(0, true);
                        }}
                        style={{ width: 200 }}
                        data-cy={`choice${column.id}`}
                      >
                        <input className="mr-2" type="checkbox" checked={sorted == column.id ? true : false} readOnly />
                        {column.render("Header")}
                      </DropdownItem>
                    )
                  })}
                </div>
              </div>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }

  return (
    <>
      {/* <Backdrop className={classes.backdrop} open={actionDone} >
        <CircularProgress color="inherit" /> <br/>
        <span className="ml-2">Download contratto in corso..</span>
      </Backdrop> */}
      <Modal style={{ maxWidth: 600, width: '100%' }} backdrop="static" isOpen={switchCloseAddNewPlant} toggle={() => {
        dispatch(UiActions.switchCloseAddNewPlant())
        dispatch(UiActions.didSetActionDone({ done: null, action: "" }))
        setOrderProps({})
      }}>
        <ModalBody>
          {modalType === "status" && <SetStatus orderProps={orderProps} />}
          {modalType === "uploadImages" && <UploadImages orderProps={orderProps} />}
          {modalType === "billaloSupport" && <BillaloSupport />}
        </ModalBody>
      </Modal>
      <div className="d-sm-flex align-items-center border-bottom py-2 w-100">
        <div className="column mr-auto" >
          <div className="d-sm-flex align-items-center" >
            <Paper style={{ cursor: 'pointer' }} data-cy="actualOrders" className={`d-sm-flex align-items-center bg-white border-0 mb-2 ml-3 ${list === "in corso" ? "text-dark" : "text-black-50"} p-1`} onClick={() => setList("in corso")}>
              <Icon className="mr-1" size={20} color={list === "in corso" ? "#007bff" : "gray"} icon="activity" />In corso
            </Paper>
            <Paper style={{ cursor: 'pointer' }} data-cy="archivedOrders" className={`d-sm-flex align-items-center bg-white border-0 mb-2 ml-3 ${list === "archivio" ? "text-dark" : "text-black-50"} p-1`} onClick={() => setList("archivio")}>
              <Icon className="mr-1" size={20} color={list === "archivio" ? "#007bff" : "gray"} icon="library" />Archivio
            </Paper>
          </div>
        </div>
      </div>

      <div className="d-sm-flex align-items-center bd-highlight mb-2 mt-2 w-100">
        <Dropdown disabled={selectedFlatRows[0] ? false : true} isOpen={actionOpen} toggle={toggleAction}>
          <DropdownToggle data-cy="actionButton" className={`${selectedFlatRows[0] ? "backgroundSelected text-white" : "bg-transparent text-dark"} border`} caret><b>Azioni</b>  </DropdownToggle>
          <DropdownMenu className={classes.drowdownMenu}>
            <DropdownItem disabled onClick={() => { }}><Icon color="gray" icon="refund" /> Aggiorna Stato</DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter} />
        <SortColumn />
        <OrdersFilter orders={data} />
      </div>

      <Grid container item xs={10} justify="center">
        <div {...getTableProps()} className="table">
          <div className="thead">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr hoverPointer">
                {headerGroup.headers.map((column) => (
                  <div
                    {...column.getHeaderProps()}
                    className="th d-sm-flex align-items-center detailText"
                    onClick={() => {
                      if (column.id === "selection" || column.id === "expander" || column.id === "icons" || column.id === "Opzioni") {
                        console.log("not sortable to:", column.id)
                      } else {
                        dispatch(ContentActions.didSetOrdersSortColumnValue({ sortOrdersColumnValue: column.id, sortOrdersOrderAsc: !sortOrdersOrderAsc }))
                      }
                    }}
                  >
                    {column.render("Header")}
                    <span>
                      {sortOrdersColumnValue == column.id ? (sortOrdersOrderAsc ? " 🔼" : " 🔽") : ""}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div data-cy="tableBody" className="tbody" {...getTableBodyProps({ style: { borderRadius: 15, backgroundColor: "white", overflow: "hidden" } })}>

            {(data[0] && data[0] !== 'empty') || filteredOrders[0] ?
              <AutoSizer onResize={rebuildTooltip}>
                {({ height, width }) => (
                  <InfiniteLoader
                    isItemLoaded={(index: any) => index < rows.length - 4 ? true : false}
                    itemCount={rows.length}
                    loadMoreItems={fetchMoreData}
                  >
                    {({ onItemsRendered, ref }: any) => (
                      <VariableSizeList
                        style={{ overflowX: "hidden" }}
                        height={height}
                        itemCount={rows.length}
                        itemData={itemData}
                        // Hardcoded values only :/
                        itemSize={(i) => (rows[i] && rows[i].isExpanded ? 250 : 80)}
                        width={width}
                        onScroll={rebuildTooltip}
                        onItemsRendered={onItemsRendered}
                        ref={listRef}
                      >
                        {RenderRow}
                      </VariableSizeList>
                    )}
                  </InfiniteLoader>
                )}
              </AutoSizer> : <span style={{ fontSize: 30 }} className="d-sm-flex justify-content-center mt-5 poppinsFont">Nessun risultato trovato</span>}
          </div>
        </div>
      </Grid>
    </>
  );
}

export const Orders = () => {
  const dispatch = useDispatch()

  let orders = useSelector(ContentSelectors.listOrders)
  let archivedOrders = useSelector(ContentSelectors.listArchivedOrders)

  useEffect(() => {
    if (!_.isEmpty(orders) || !_.isEmpty(archivedOrders)) {
      console.log("orders already fetched")
    } else {
      dispatch(ContentActions.willListOrders("empty"))
    }

    dispatch(UiActions.setSelectedPage("Ordini"))
  }, [orders, archivedOrders])

  return (
    <div style={{ overflow: "hidden", height: 'calc(100vh - 83px)' }}>
      <NotificationContainer />
      <Styles>
        <Table />
      </Styles>
    </div>
  );
}
