import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Grid, Tooltip, Switch } from '@material-ui/core';
import { Modal, ModalBody, UncontrolledDropdown, Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
// import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import "react-circular-progressbar/dist/styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Chart, PieSeries, Title, Legend, ArgumentAxis, ValueAxis, LineSeries } from '@devexpress/dx-react-chart-material-ui';
import Icon from './IconsComponent';
import { SetStatus } from '../components/SetStatus';
import { useStyles, Divider, photoShape, orderStatusSwitch, statusColor } from './OrderDetails.css';
import { UploadImages } from '../components/UploadImagesModal';
import { ModalImage } from '../components/ModalImage';
import moment from 'moment';
import _ from 'lodash';

const currencyFormatter = (value: number) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
}

const numFormatter = (num: any) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
}

export const OrderDetailsLeft = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  let order = useSelector(ContentSelectors.getSelectOrder)
  let orderImages = useSelector(ContentSelectors.listOrderImages)

  const [orderLeft, setOrderLeft] = useState(false)
  const [orderStatus, setOrderStatus] = useState(order.status)
  const [modalType, setModalType] = useState("")
  const [orderProps, setOrderProps] = useState({})
  const [imageProps, setImageProps] = useState({})

  let selectedOrder = useSelector(ContentSelectors.getSelectOrder)
  let switchCloseAddNewPlant = useSelector(UiSelectors.switchCloseAddNewPlant)

  let orderBillboards = order.billboards
  // console.log('in orderDetailsleft with:', selectedOrder, orderBillboards, order)
  let data = [] as any
  const creationDate = moment().add(1, 'day')
  let now = moment()
  let immagini = [] as any

  if (_.isEmpty(order)) {
    data = selectedOrder
  } else {
    data = order
  }

  let pieChartStats = [] as any
  let size6x3 = 0 as number
  let size4x3 = 0 as number
  let sizeOthers = 0 as number
  let totalMunicipality = [] as any
  let totalNormalized = [] as any
  let meanVisibilityIndex = [] as any
  let ownersArray = [] as any
  // console.log("totalNormalized", totalNormalized)
  // console.log("totalMunicipality", totalMunicipality)

  orderBillboards.map((i: any) => {
    const mappedSize = `${i.base}x${i.height}`
    // sizeArray.push({ size: mappedSize })
    if (mappedSize === "600x300") {
      size6x3 = size6x3 + 1
    }
    else if (mappedSize === "400x300") {
      size4x3 = size4x3 + 1
    } else {
      sizeOthers = sizeOthers + 1
    }
  })
  // console.log("number of Sizes:", size6x3, size6x4, sizeOthers)

  orderBillboards.map((i: any) => {
    totalMunicipality.push(i.iV_municipality)
  })
  orderBillboards.map((i: any) => {
    totalNormalized.push(i.iV_normalized)
  })
  meanVisibilityIndex.push(_.mean(totalMunicipality), _.mean(totalNormalized))

  let obj = {} as any
  let obj2 = {} as any
  _.forEach(orderBillboards, (value: any) => {
    if (obj[value.municipality]) {
      obj[value.municipality]++
    } else {
      obj[value.municipality] = 1
    }
    if (obj2[value.media_owner]) {
      obj2[value.media_owner]++
    } else {
      obj2[value.media_owner] = 1
    }
  })
  _.forOwn(obj, (value: any, key: any) => {
    // console.log("value", value, _.keys(obj))
    pieChartStats.push({ municipality: key, plants: value })
    // console.log("value pieChartStats", pieChartStats)

  })
  _.forOwn(obj2, (value: any, key: any) => {
    ownersArray.push({ media_owner: key, plants: value })
  })

  const labelComponent = (props: any) => {
    //console.log("labelComponent",props)
    const item = pieChartStats.find((item: any) => item.municipality === props.text);
    return <Legend.Label className="d-sm-flex" {...props} text={`${props.text} ${numFormatter(item.plants)}`} />;
  };

  useEffect(() => {
    console.log("order data:", order)
    console.log("order status:", orderStatus)
  }, [order, orderStatus, orderImages])

  return (
    <>
      <Modal style={{ maxWidth: 600, width: '100%' }} backdrop="static" isOpen={switchCloseAddNewPlant} toggle={() => {
        dispatch(UiActions.switchCloseAddNewPlant())
        dispatch(UiActions.didSetActionDone({ done: null, action: "" }))
      }}>
        <ModalBody>
          {modalType === "status" && <SetStatus orderProps={order} />}
          {modalType === "uploadImages" && <UploadImages orderProps={orderProps} orderLeft={orderLeft} />}
          {modalType === "modalImage" && <ModalImage imageProps={imageProps} />}
        </ModalBody>
      </Modal>
      <Grid id="listInMap" className="p-2 orderDetailsLeft" container alignItems="center">
        <Grid className="p-2 mb-4 border-bottom" container item xs={12} alignItems="center">
          <Grid data-cy="orderId" style={{ color: "#0062cc" }} item xs={8}>
            ID ORDINE: <br /> {data.id.substring(0, 20)}..
          </Grid>
          {/* <Grid item xs={2}>
            <button className="bg-transparent border-0" data-cy="availabilities" onClick={() => { }} ><Icon color="#a64dff" size={25} icon="assignement" /></button>
          </Grid>
          <Grid item xs={2}>
            <button className="bg-transparent border-0 mt-1" data-cy="assignement" onClick={() => { }}><Icon size={25} color="#ffa64d" icon="achievements" /></button>
          </Grid> */}
          <Grid item xs={2}>
            {order.status !== "Draft" && order.status !== "pass" && order.status !== "confirmed" && order.status !== "canceled" && moment().isAfter(moment(order.order_date).add(24, 'hours')) ?
              <button className="bg-transparent border-0" data-cy="drafts" onClick={() => {
                dispatch(ContentActions.willGetOrderContract({ campaign: order.campaign, id: order.id }))
              }} ><Icon color="#66b0ff" size={25} icon="drafts" /></button> : <></>}
          </Grid>
          <Grid item xs={2}>
            <UncontrolledDropdown>
              <DropdownToggle data-cy="plantMenu" className="bg-transparent border-0">
                <button className="bg-transparent border-0 align-items-center right"><Icon className="backgroundIconColor" color="gray" size={25} icon="more" /></button>
              </DropdownToggle>
              <DropdownMenu className={classes.drowdownMenu}>
                <DropdownItem data-cy="setStatusButton" className="text-secondary" onClick={() => {
                  setModalType("status")
                  dispatch(UiActions.switchCloseAddNewPlant())
                }} ><Icon color="gray" size={15} icon="refund" /> Aggiorna stato</DropdownItem>
                <DropdownItem className="text-secondary" onClick={() => {
                  setOrderLeft(true)
                  setOrderProps(order)
                  setModalType("uploadImages")
                  dispatch(UiActions.switchCloseAddNewPlant())
                }} ><Icon color="gray" size={15} icon="photo" /> Carica Immagini</DropdownItem>
                <DropdownItem className="text-secondary" disabled onClick={() => { }} ><Icon color="gray" size={15} icon="chat" /> Richiedi Modifiche</DropdownItem>
                {/* <DropdownItem className="text-secondary" disabled onClick={() => { }} ><Icon color="gray" size={15} icon="block" /> Annula ordine</DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Grid>
        </Grid>
        <Grid className="mt-4 mb-4" item xs={12}>
          {_.isEmpty(orderImages) ?
            <Grid className="mt-3 mb-4" container item xs={12} alignItems="center" justify="center">
              <h3>Nessuna foto caricata</h3>
            </Grid>
            : <>
              <b>Foto</b>
              <Grid style={{ overflowX: "hidden", maxHeight: 315 }} container className="d-sm-flex">
                {orderImages.map((image: any, index: any) => {
                  let url = window.location.origin
                  if (window.location.origin.includes("8080") || window.location.origin.includes("3000")) {
                    url = "https://ssp-dev.billalo.it"
                  }
                  console.log('url immagine', window)
                  return (
                    <>
                      {image.caption ?
                        <Tooltip key={`imageTooltip-${index}`} title={<span style={{ fontSize: 15, color: "black" }}>{image.caption}</span>}>
                          <Grid key={`image-${index}`} item xs={6} className="d-sm-flex p-1">
                            <img style={photoShape} src={`${url}/resources/ordersImage/${image.order}/${image.id}`} alt={image.caption} width="160" height="150"  onClick={() => {
                              setImageProps(image)
                              setModalType("modalImage")
                              dispatch(UiActions.switchCloseAddNewPlant())
                            }}/>
                          </Grid>
                        </Tooltip> :
                        <Grid key={`image-${index}`} item xs={6} className="d-sm-flex p-1">
                          <img style={photoShape} src={`${url}/resources/ordersImage/${image.order}/${image.id}`} alt={image.caption} width="160" height="150"  onClick={() => {
                              setImageProps(image)
                              setModalType("modalImage")
                              dispatch(UiActions.switchCloseAddNewPlant())
                            }}/>
                        </Grid>
                      }
                    </>
                  )
                })}
              </Grid> </>}
        </Grid>
        <Divider />
        {/* <button onClick={() => setTest(!test)} >TEST</button> */}
        <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
          <Grid className="text-secondary" item xs={3}>Stato</Grid>
          {/* moment(now).isBefore(moment(order.order_date).add(24, 'hours')) /// prima usavo questo per farlo andare in automatico */}
          {/* order.status === "confirm" || order.status === "confirmed" || order.status === "rejected" && */}
          {order.status === "confirm" || order.status === "confirmed" || order.status === "rejected" && moment(now).isBefore(moment(order.order_date).add(24, 'hours')) ?
            <>
              <Grid item xs={7}></Grid>
              <Grid className="d-sm-flex flex-row-reverse align-items-center" item xs={2}><Switch checked={orderStatus === "confirm" || orderStatus === "confirmed" || orderStatus === "payed" ? true : false} onClick={() => {
                if (orderStatus === "confirm" || orderStatus === "confirmed") {
                  setOrderStatus("rejected")
                  dispatch(ContentActions.willUpdateOrderStatus({ campaign_id: order.campaign, id: order.id, action: "reject" }))
                } else {
                  setOrderStatus("confirmed")
                  dispatch(ContentActions.willUpdateOrderStatus({ campaign_id: order.campaign, id: order.id, action: "confirm" }))
                }
              }} color="primary" /> <span className="detailText">{orderStatus === "confirm" || orderStatus === "confirmed" || orderStatus === "payed" ? "Confermato" : "Rifiutato"}</span><span className="maintenanceOverview mr-2">Pending</span></Grid>
            </> :
            <Grid className="d-sm-flex flex-row-reverse" item xs={9}><span className={statusColor(order.status)}>{orderStatusSwitch(order.status, order.from, order.to)}</span></Grid>}
        </Grid>
        <Divider />
        <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
          <Grid className="text-secondary" item xs={3}>Cliente</Grid>
          <Grid className="d-sm-flex flex-row-reverse" item xs={9}>{data.advertiser}</Grid>
        </Grid>
        <Divider />
        <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
          <Grid className="text-secondary" item xs={4}>Inizio / Fine</Grid>
          <Grid className="d-sm-flex flex-row-reverse" item xs={8}>{moment(data.from).format("DD-MM-YYYY")} / {moment(data.to).format("DD-MM-YYYY")}</Grid>
        </Grid>
        <Divider />
        <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
          <Grid className="text-secondary" item xs={4}>Data ordine</Grid>
          <Grid className="d-sm-flex flex-row-reverse" item xs={8}>{moment(data.order_date).format("DD-MM-YYYY")}</Grid>
        </Grid>
        <Divider />
        <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
          <Grid className="text-secondary" item xs={6}>Stampa</Grid>
          <Grid className="d-sm-flex flex-row-reverse" item xs={6}>{data.printer === "true" ? "Richiesta" : "Non richiesta"}</Grid>
        </Grid>
        <Divider />
        <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
          <Grid className="text-secondary" item xs={4}>Spesa totale</Grid>
          <Grid className="d-sm-flex flex-row-reverse" item xs={8}>{currencyFormatter(_.ceil(data.amount, 2))}</Grid>
        </Grid>
        <Divider />
        <Grid className="d-sm-flex overflow-auto" container item xs={12} alignItems="center" justify="center">
          <span style={{ fontSize: 20 }} className="mt-3" >{_.size(pieChartStats)} {_.size(pieChartStats) > 1 ? "Comuni coinvolti" : "Comune coinvolto"}</span>
          <div className="w-75 h-75">
            <Chart data={pieChartStats} >
              <PieSeries
                valueField="plants"
                argumentField="municipality"
                innerRadius={0.9}
              />
              <Legend
                labelComponent={labelComponent}
                position="bottom"
              />
            </Chart>
          </div>
        </Grid>
        <Grid item xs={12}><div className="border-bottom" /></Grid>
        <Grid data-cy="billaloIndex" className="mt-3 mb-3" container alignItems="center" justify="center">
          <CircularProgressbarWithChildren
            className={classes.visibilityProgress}
            styles={buildStyles({
              //textColor: "red",
              pathColor: "#00e68a",
              trailColor: "#f8f9fa"
            })}
            maxValue={10}
            value={_.ceil(_.mean(totalNormalized), 2)}
            strokeWidth={5} >
            <Icon color="#00e68a" size={25} icon="leaderboard" />
          </CircularProgressbarWithChildren>
          <div className="d-sm-flex flex-column ml-3 detailText">
            <span><b style={{ fontSize: 30 }}>{_.ceil(_.mean(totalNormalized), 2)}</b> <br /><span style={{ fontSize: 15 }} className="text-muted">Indice Visibilità <br /> - Italia</span></span>
          </div>
          <Grid data-cy="billaloIndex" className="mt-3 mb-1" container alignItems="center" justify="center">
            <CircularProgressbarWithChildren
              className={classes.visibilityProgress}
              styles={buildStyles({
                pathColor: "#0066ff",
                trailColor: "#f8f9fa"
              })}
              maxValue={10}
              value={_.ceil(_.mean(totalMunicipality), 2)}
              strokeWidth={5} >
              <Icon color="#0062CC" size={25} icon="headquarters" />
            </CircularProgressbarWithChildren>
            <div className="d-sm-flex flex-column ml-3 detailText">
              <span><b style={{ fontSize: 30 }}>{_.ceil(_.mean(totalMunicipality), 2)}</b> <br /><span style={{ fontSize: 15 }} className="text-muted">Indice Visibilità <br /> - Comune</span></span>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}><div className="border-bottom" /></Grid>
        <b className="mt-5 mb-3">Formati</b>
        <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
          <Grid className="text-secondary" item xs={1}><Icon color="#cccccc" size={30} icon="full" /></Grid>
          <Grid item xs={7}>6x3</Grid>
          <Grid item xs={4}>{numFormatter(size6x3)}</Grid>
        </Grid>
        <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
          <Grid className="text-secondary" item xs={1}><Icon color="#cccccc" size={25} icon="full" /></Grid>
          <Grid item xs={7}>4x3</Grid>
          <Grid item xs={4}>{numFormatter(size4x3)}</Grid>
        </Grid>
        <Grid className="mt-4 mb-4" container item xs={12} alignItems="center">
          <Grid className="text-secondary" item xs={1}><Icon color="#cccccc" size={20} icon="full" /></Grid>
          <Grid item xs={7}>Altri</Grid>
          <Grid item xs={4}>{numFormatter(sizeOthers)}</Grid>
        </Grid>
        {/* <Grid className="mt-4" container item xs={12} alignItems="center">
        <Grid className="text-secondary" item xs={1}><Icon color="#cccccc" size={15} icon="full" /></Grid>
        <Grid item xs={7}>-x-</Grid>
        <Grid item xs={4}>{numFormatter(1489632)}</Grid>
      </Grid> */}
        {/* <Grid className="mb-4" container item xs={12} alignItems="center">
          <b className="mt-5 mb-2">Descrizione</b>
          <span className="text-secondary">{"is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."}</span>
        </Grid> */}
      </Grid>
    </>
  )
}