import React, { useState, useEffect } from 'react';
import { Grid, TextField, RadioGroup, Select, InputLabel, MenuItem } from '@material-ui/core';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, } from '@material-ui/pickers';
import { ActivityButton } from '../ActivityButton';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Icon from '../IconsComponent';
import styled from "styled-components";


export const Divider = () => <Grid className="border-bottom mt-2 mb-2" container item xs={12} alignItems="center"></Grid>

export const buttonSelected = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
  // borderWidth: 1,
  // borderColor: "#cccccc",
  // borderStyle: "solid",
  // borderRadius: 10,
  borderLeft: '2px solid #0062FF',
  // borderRadius: '0px 100px 100px 0px',
  padding: "10px 10px 10px 10px",
  // color: "#696974"
  color: '#0062FF'
}

export const buttonNotSelected = {
  display: "flex",
  alignItems: "center",
  backgroundColor: "transparent",
  border: "none",
  padding: "10px 10px 10px 10px",
  color: "#696974"
}

export const borderColorDatePicker = {
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "#e6e6e6",
  borderRadius: 10,
  // alignItems: "flex-end",
  justifyContent: "center",
  width: 160,
  marginLeft: 25,
  // height: 39,
  '& .MuiInputInput': {
    marginLeft: 15
  },
  "& .MuiOutlinedInputNotchedOutline": {
    border: 'none'
  }
}

export const confirmButton = {
  padding: '7px 17px 7px 17px',
  borderColor: "transparent",
  backgroundColor: "#007bff",
  color: "white",
  borderRadius: 10,
  "&:hover": {
    backgroundColor: "#007bff",
  }
}

export const mutableTextFieldProfilo = (id: string, placeholder: string, value: any, switchInput: boolean, profile: any, tab: string, gridNumber: any, className: string, setProfilo: any) => {
  let greedInput = (gridNumber - 1) as any
  // console.log("id", id)
  let date = value

  const switchInputComponent = () => {
    return (
      <>
        {id === "from" ?
          <Grid item xs={gridNumber}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                style={borderColorDatePicker}
                disableToolbar
                disableFuture
                autoOk={true}
                format="DD/MM/YYYY"
                id={id}
                placeholder={placeholder}
                label=""
                keyboardIcon={<Icon color="grey" size={20} icon="calendar" />}
                value={date}
                helperText=""
                inputValue={date}
                onChange={(e: any, value: any) => { profile[id] = value; date = e }}
                InputAdornmentProps={{ position: "end" }}
                InputProps={{ disableUnderline: true, className: "ml-3" }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          :
          <Grid className={className} item xs={gridNumber}><TextField style={{ width: 150 }} size="small" id={id} label={placeholder} onChange={(e: any) => setProfilo({ [id]: e.target.value })} defaultValue={value} /></Grid>
        }
      </>
    )
  }
  return (
    <>
      {switchInput
        ?
        switchInputComponent()
        : <Grid className={className} item xs={gridNumber}><span style={{ opacity: profile[id] === value ? 1 : 0.4 }}>{tab}</span>{value}</Grid>
      }
    </>
  )
}

export const mutableTextFieldAnagrafica = (id: string, placeholder: string, value: any, switchInput: boolean, profile: any, tab: string, gridNumber: any, setProfilo: any) => {
  return (
    <>
      {switchInput
        ?
        <Grid item xs={gridNumber}><TextField size="small" id={id} label={placeholder} onChange={(e: any) => setProfilo({ [id]: e.target.value })} defaultValue={value} /></Grid>
        : <Grid item xs={gridNumber}><span style={{ opacity: 0.4 }}>{tab}</span><br />{value}</Grid>
      }
    </>
  )
}

export const mutableTextFieldPagamenti = (id: string, placeholder: string, value: any, switchInput: boolean, profile: any, tab: string, gridNumber: any, setProfilo: any) => {
  return (
    <>
      {switchInput
        ?
        <Grid item xs={gridNumber}><TextField size="small" id={id} label={placeholder} onChange={(e: any) => setProfilo({ [id]: e.target.value })} defaultValue={value} /></Grid>
        : <Grid item xs={gridNumber}><span style={{ opacity: 0.4 }}>{tab}</span><br />{value}</Grid>
      }
    </>
  )
}

export const mutableTextFieldFundsTransfer = (id: string, placeholder: string, value: any, switchInput: boolean, profile: any, tab: string, gridNumber: any, setProfilo: any) => {
  return (
    <>
      {switchInput
        ?
        <Grid item xs={gridNumber}>

          {/* <TextField size="small" id={id} label={placeholder} onChange={(e: any) => setProfilo({ [id]: e.target.value })} defaultValue={value} /> */}
          <InputLabel id={id} >{placeholder}</InputLabel>
          <Select
            labelId={id}
            id={id}
            placeholder={placeholder}
            defaultValue={value || ""}
            label={placeholder}
            onChange={(e: any) => setProfilo({ [id]: e.target.value })}
          >
            <MenuItem value={"Mensile"}>Mensile</MenuItem>
            <MenuItem value={"Trimestrale"}>Trimestrale</MenuItem>
          </Select>

        </Grid>
        : <Grid item xs={gridNumber}><span style={{ opacity: 0.4 }}>{tab}</span><br />{value}</Grid>
      }
    </>
  )
}

export const mutableTextFieldUfficio = (id: string, placeholder: string, value: any, switchInput: boolean, ufficio: any, tab: string, gridNumber: any) => {

  return (
    <>
      {switchInput
        ?
        <Grid item xs={gridNumber}><TextField size="small" id={id} label={placeholder} onChange={(e: any) => ufficio[id] = e.target.value} defaultValue={value} /></Grid>
        : <Grid item xs={gridNumber}><span style={{ opacity: 0.4 }}>{tab}</span><br />{value}</Grid>
      }
    </>
  )
}

export const Styles = styled.div`
  // padding: 0.5rem;
  // margin-top: 5px;
  // padding: 13px;
  width: 100%;

.table {
  
  width: 100%;

  .thead {
    padding-right: 15px;
    font-family: 'Poppins', sans-serif;
  }

  .tbody {
    height: 60vh;
  //  padding: 17px;
  }

  .tr-group {
    display: flex;
    flex-direction: column;
    //  overflow-x: hidden;
    // border: 1px solid #00e68a;
    // border-radius: 10px;
  }

  .tr {
    //  overflow: hidden;
    display: flex;
    :last-child {
      .td {
        border-bottom: 0;
        color: #6c757d;
      }
    }
  }

  .th,
  .td {
    margin: 0;
    // padding: 0.5rem;
    margin-left: 10px;
    color: #6c757d;

    :last-child {
      border-right: 0;
    }
  }
}
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    borderColor: {
      '& label.Mui-focused': {
        color: '#00bfff',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#00bfff',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: 10
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00bfff',
        },
      },
    },
    modalInProfile: {
      // backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalInProfileHeader: {
      borderBottom: '1px solid #E2E2EA',
      fontFamily: 'Poppins, sans-serif',
      fontSize: 14,
      color: '#92929D',
      padding: 20,
      marginBottom: 20
    },
    cancelButtonModal: {
      fontSize: 12,
      width: 100,
      height: 38,
      border: '1px solid #E2E2EA',
      backgroundColor: "#FAFAFB",
      color: "#696974",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "#FAFAFB",
      }
    },
    confirmButtonModal: {
      fontSize: 12,
      width: 100,
      height: 38,
      borderColor: "transparent",
      backgroundColor: "#0062FF",
      color: "white",
      borderRadius: 10,
      "&:hover": {
        backgroundColor: "#0062FF",
      }
    },
    modalInProfileFooter: {
      borderTop: '1px solid #E2E2EA',
      paddingTop: 20,
      marginBottom: 20,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
    },
    circularProgressColor: {
      // '& .MuiCircularProgress-colorPrimary':{
      color: 'white'
      // }
    }
  }),
);

export const modalStyle = {
  justifyContent: 'space-between',
  backgroundColor: 'white',
  width: 750,
  minHeight: 400,
  borderRadius: 20
}

export const stripeButton = {
  display:'flex',
  alignItems: 'center',
  fontSize: 14,
  // width: 100,
  height: 30,
  borderColor: "transparent",
  backgroundColor: "#0062FF",
  color: "white",
  borderRadius: 10,
  "&:hover": {
    backgroundColor: "#0062FF",
  }
}