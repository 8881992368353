import { Field, Formik } from "formik";
import React, { useReducer, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, FormText } from "reactstrap";
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content'
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { selectors as NotificationSelectors } from '../store/slices/notification';
import Papa from 'papaparse';
import { Grid, Paper } from '@material-ui/core';
import { ListaModale } from '../components/ModalList';
import { PlantList } from "../components/plant-list/PlantList";
import { ListFilter } from "../components/plant-list/ListFilter";
import { PlantListMap } from "../components/plant-list/PlantListMap";
import { ExplorePlants } from '../components/explore/ExplorePlants';
import { ExploreMap } from '../components/explore/ExploreMap';
import { AddNewPlant } from "../components/AddNewPlant";
import { AddNewBillboardContainer } from "../components/add-new-billboard/AddNewBillboardContainer";
import { CSVUpload } from '../components/CSVUpload';
import { Maintenance } from '../components/Maintenance';
import { Disponibility } from '../components/Disponibility';
import { Availabilities } from '../components/Availabilities';
import { addButton, csvButton } from "../components/plant-list/PlantListCss";
import { NotificationContainer } from "../components/notification-layer/NotificationContainer";
import { DeleteBillboardModal } from "../components/DeleteBillboardModal";
// import { NoGroupAdvise } from "../components/NoGroupAdvise";
import Icon from "../components/IconsComponent";
import { actionButton } from '../components/explore/Explore.css';
import { v4 as uuidv4 } from 'uuid';

export const Billboards = () => {
  const dispatch = useDispatch();

  const [explore, setExplore] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [plantProps, setPlantProps] = useState({})
  const [modalType, setModalType] = useState('')
  const [plantData, setPlantData] = useState(null) as any
  const [maintenanceGroup, setMaintenanceGroup] = useState(false)
  const [billboardId, setBillboardId] = useState("")

  let listView = useSelector(UiSelectors.getMapListView)
  let noGroup = useSelector(UiSelectors.getNoGroup)
  let switchCloseAddNewPlant = useSelector(UiSelectors.switchCloseAddNewPlant)
  const notification = useSelector(NotificationSelectors.getNotification)
  // const notification = {visible: true}

  useEffect(() => {
    console.log("listView switched")
  }, [listView])


  const modalStyle = {
    maxWidth:
      modalType === "availabilities" || modalType === "disponibility"
        ? "unset"
        : 600,
    width: modalType === "availabilities" || modalType === "disponibility" ? "auto" : "100%",
  };

  const modalBodyStyle = {
    padding: modalType === "addNewBillboard" ? 0 : "1rem",
  };

  return (
    <>
      <Modal modalClassName="d-flex" style={modalStyle} backdrop="static" isOpen={switchCloseAddNewPlant} toggle={() => {
        dispatch(ContentActions.didListBillboardAvailabilities({}))
        dispatch(UiActions.switchCloseAddNewPlant())
        setPlantData(null)
      }}>
        <ModalBody style={modalBodyStyle}>
          {modalType === "addNewBillboard" && <AddNewBillboardContainer item={plantData} setModalType={setModalType} />}
          {modalType === "addNewPlant" && <AddNewPlant item={plantData} />}
          {modalType === "maintenance" && <Maintenance item={plantData} maintenanceGroup={maintenanceGroup} setMaintenanceGroup={setMaintenanceGroup} />}
          {modalType === "disponibilityGroup" && <Maintenance item={plantData} maintenanceGroup={maintenanceGroup} setMaintenanceGroup={setMaintenanceGroup} modalType={modalType} />}
          {modalType === "disponibility" && <Disponibility billboardId={billboardId} item={plantData} />}
          {modalType === "availabilities" && <Availabilities item={plantData} onClose={() => dispatch(UiActions.switchCloseAddNewPlant())} />}
          {modalType === "csvUpload" && <CSVUpload />}
          {modalType === "deleteBillboard" && <DeleteBillboardModal item={plantData} setModalType={setModalType} />}
        </ModalBody>
      </Modal>
      <NotificationContainer />
      <div style={{ overflow: "hidden", height: `calc(100vh - ${notification.visible ? '183px' : '83px'})` }}>
        <>
          <div className="d-sm-flex align-items-center border-bottom py-2 w-100">
            <div className="column mr-auto ml-3" >
              <div className="d-sm-flex align-items-center">
                <Paper style={{ cursor: 'pointer', height: addButton.height }} data-cy="myPlants" className={`d-sm-flex align-items-center ${explore ? "bg-transparent" : "bg-white"} text-dark p-2 ml-2`} onClick={(e) => { setExplore(false) }} elevation={explore ? 0 : 3}>
                  <Icon className="mr-1" size={20} color={explore ? "gray" : "#007bff"} icon="spreadSheet" />I miei impianti
                </Paper>
                <Paper style={{ cursor: 'pointer', height: addButton.height }} data-cy="myPlants" className={`d-sm-flex align-items-center ${!explore ? "bg-transparent" : "bg-white"} text-dark p-2 ml-2`} onClick={(e) => { setExplore(true) }} elevation={!explore ? 0 : 3}>
                  <Icon className="mr-1" size={20} color={!explore ? "gray" : "#007bff"} icon="world" />Esplora
                </Paper>
              </div>
            </div>
            {explore
              ? null
              : <div className="d-sm-flex align-items-center">
                <button id="addCsv" data-cy="addCSV" style={csvButton} onClick={(e) => { setModalType("csvUpload"); dispatch(UiActions.switchCloseAddNewPlant()); }}>
                  <Icon className='mr-2' color="#92929D" size={18} icon="add" /><>CSV</>
                </button>
                <Button style={addButton} data-cy="addNewBillboard" color="primary" onClick={(e) => {
                  setModalType("addNewBillboard")
                  dispatch(UiActions.switchCloseAddNewPlant())
                }}>Nuovo</Button>
                <button data-cy="listView" className={`bg-white border-0 switchMapButtonLeft`} onClick={() => dispatch(UiActions.switchMapListView())}><Icon color={!listView ? '#0062FF' : "#6c757d"} size={21} icon="grantt" /></button>
                <button data-cy="mapView" className={`bg-white border-0 switchMapButtonRight`} onClick={() => dispatch(UiActions.switchMapListView())}><Icon color={listView ? '#0062FF' : "#6c757d"} size={21} icon="place" /></button>
              </div>
            }
          </div>
          {explore
            ? <Grid container style={{ height: 'calc(100vh - 83px)' }}>
              <Grid item xs={3}>
                <div id="listInMap" style={{ backgroundColor: "#fafafb" }}>
                  <ExplorePlants setAnchorEl={setAnchorEl} setPlantProps={setPlantProps} anchorEl={anchorEl} plantProps={plantProps} />
                </div>
              </Grid>
              <Grid id="mapComponent" style={{ position: 'relative' }} item xs={9}>
                <ExploreMap setAnchorEl={setAnchorEl} setPlantProps={setPlantProps} anchorEl={anchorEl} plantProps={plantProps} />
              </Grid>
            </Grid>
            : <div id="listInMap" style={{ height: 'calc(100vh - 83px)' }}>
              {listView
                ? <PlantListMap setPlantData={setPlantData} setBillboardId={setBillboardId} setMaintenanceGroup={setMaintenanceGroup} explore={explore} setModalType={setModalType} />
                : <PlantList setPlantData={setPlantData} setBillboardId={setBillboardId} setMaintenanceGroup={setMaintenanceGroup} setModalType={setModalType} />
              }
            </div>}
        </>
      </div>
    </>
  )
}