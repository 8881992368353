import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Card, Button } from 'reactstrap';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import moment from 'moment';
import { Pricing } from './Pricing';
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui'
import Icon from "./IconsComponent";
import { getBoundingBox } from 'geolocation-utils'
import { AddNewPlant } from "./AddNewPlant";
import Supercluster from 'supercluster';
import useSupercluster from 'use-supercluster';
import { ListFilter } from './plant-list/ListFilter';
import { mapCssStyle } from './Map.css';
import { customMarker } from './CustomMarker';
import _ from 'lodash';

const Marker = ({ children }: any) => children;

const geolib = require('geolib');

export const PlantDetailsMap = (props: any) => {
  // console.log("in PlantDetailsMap withe:", props)
  const { setModalType, setAnchorEl, setPlantProps, setSelectedInMap, plantSelected, setPlantSelected } = props
  const dispatch = useDispatch()

  const data = props.item
  const [addNewPlant, setAddNewPlant] = useState(false);
  const toggleNewPlant = () => setAddNewPlant(!addNewPlant);
  // const [plantData, setPlantData] = useState(null)
  // const [plantSelected, setPlantSelected] = useState(null)

  let switchCloseAddNewPlant = useSelector(UiSelectors.switchCloseAddNewPlant)
  let selectedBillboardId = useSelector(UiSelectors.getSelectedBillboardId)
  let billboardCoordinates = useSelector(ContentSelectors.getBillboardCoordinates)
  const myBillboardsInMap = useSelector(ContentSelectors.listAllBillboardsInMap)

  if (_.isEmpty(billboardCoordinates)) {
    // billboardCoordinates = [{ lat: 39, lng: 9 }, { lat: 39.4, lng: 9.09 }]
    billboardCoordinates = [{ lat: 45, lng: 9 }, { lat: 39.4, lng: 9.09 }]
    console.log('true', billboardCoordinates)
  }

  useEffect(() => {
    console.log('true', billboardCoordinates)
    return () => { }
  }, [switchCloseAddNewPlant, billboardCoordinates, myBillboardsInMap])

  let bounds1 = geolib.getBounds(billboardCoordinates);
  let size = {
    width: 1200,
    height: 600
  }
  if (document.getElementById("mappa")) {
    size = {
      width: document.getElementById("mappa")!.offsetWidth,
      height: document.getElementById("mappa")!.offsetHeight,
    }
  }
  bounds1 = {
    ne: {
      lat: bounds1.maxLat,
      lng: bounds1.maxLng
    },
    sw: {
      lat: bounds1.minLat,
      lng: bounds1.minLng
    }
  };

  const { center, zoom } = fitBounds(bounds1, size);
  // console.log('center and zoom changed', center, zoom)
  // console.log("after selector myBillboards", myBillboardsInMap, data)
  const objectPlants = _.keyBy(myBillboardsInMap, 'id');

  const [bounds, setBound] = useState([] as any)
  const [zoom1, setZoom] = useState(10 as any)

  let array = [] as any

  // array = [{location:{ lat: 39.2359517, lon: 9.1240543 }}, { lat: 43.691214, lon: 12.882055 }, { lat: 43.319617, lon: -8.027466 }, { lat: 43.69214, lon: 12.822055 }, { lat: 43.339617, lon: -8.04427466 }, { lat: 143.691214, lon: 122.882055 }, { lat: 43.319333617, lon: -8.033327466 }]
  array = [{ location: { lat: 41.115070, lon: 8.945523 } }, { location: { lat: 39.430418, lon: 8.945523 } }, { location: { lat: 39.430418, lon: 8.945523 } }, { location: { lat: 39.856429, lon: 8.996323 } }, { location: { lat: 39.812398, lon: 9.135766 } }, { location: { lat: 39.220518, lon: 9.115419 } }, { location: { lat: 39.223186, lon: 9.106342 } }, { location: { lat: 39.223826, lon: 9.095517 } }]
  let points = myBillboardsInMap.map((bill: any) => ({
    type: "Feature",
    properties: {
      cluster: false,
      "cluster_id": bill.id,
      "point_count": bill.id,
      "is_selected": bill.id === plantSelected
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(bill.longitude),
        parseFloat(bill.latitude)
      ]
    }
  }));
  const mapRef = useRef();
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: zoom1,
    options: { radius: 75, maxZoom: 20 }
  });

  // console.log('clusters', clusters, points)
  let coords = { lat: 39.303494, lng: 9.120857 }

  const [disp, setDisp] = useState(false)
  const [price, setPrice] = useState(false)
  const [daysSelected, setDaysSelected] = useState({ from: "", to: "" })

  const selectedDays = (day: any) => {
    // console.log("Selected day: ", moment(day).format("DD/MM/YYYY"))
    const formattedDays = {
      from: moment(day).subtract(7, "days").format("DD/MM/YYYY"),
      to: moment(day).add(7, "days").format("DD/MM/YYYY")
    }
    const newRange = {
      from: moment(day).subtract(7, "days").format("M,DD,YYYY"),
      to: moment(day).add(7, "days").format("M,DD,YYYY")
    }
    setDaysSelected({ from: newRange.from, to: newRange.to })
    // console.log("Selected disponibility from:", formattedDays.from + " to:", formattedDays.to)
  }

  const onChangeMarginBounds = (bounds: any) => {
    console.log("Funcion", bounds)
    dispatch(ContentActions.willListBillboardsInMap({ lat_bot_rigth: bounds.se.lat, lat_top_left: bounds.nw.lat, lon_bot_rigth: bounds.se.lng, lon_top_left: bounds.nw.lng }))
  }

  let listView = useSelector(UiSelectors.getMapListView)

  useEffect(() => {
  }, [listView])

  return (
    <>
      {price == false ?
        <Card className="w-100 ml-3" style={{ border: 'none' }}>
            <div className="w-100" id='mappa'>
              <GoogleMapReact
                style={{ height: 'calc(100vh - 149px)'}}
                bootstrapURLKeys={{
                  key: "AIzaSyAMhpfSL4SkLCu2kSu-8oXXfTRtxddzYcE",
                  libraries: ['places', 'geometry', 'drawing', 'visualization']
                }}
                defaultCenter={center}
                center={center}
                defaultZoom={zoom}
                zoom={zoom}
                options={{ fullscreenControl: false, styles: mapCssStyle }}
                layerTypes={['TrafficLayer', 'TransitLayer']}
                onChange={({ zoom, bounds }) => {
                  // setTimeout(function () { onChangeMarginBounds(bounds) }, 500)
                  setZoom(zoom);
                  setBound([
                    bounds.nw.lng,
                    bounds.se.lat,
                    bounds.se.lng,
                    bounds.nw.lat
                  ]);
                }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {

                  map.streetViewControl = true;
                  map.mapTypeControl = true;
                }}
              // onChange={({ bounds, marginBounds }) => {
              //     setTimeout(function () { onChangeMarginBounds(bounds) }, 500)
              // }}
              >
                {clusters.map((cluster: any) => {
                  const [longitude, latitude] = cluster.geometry.coordinates;
                  const {
                    cluster: isCluster,
                    point_count: pointCount
                  } = cluster.properties;
                  // console.log("in render:", pointCount, isCluster, cluster)
                  if (isCluster) {
                    return (
                      <Marker
                        key={`cluster-${cluster.id}`}
                        lat={latitude}
                        lng={longitude}
                      >
                        <div
                          className="super-cluster"
                          style={{
                            width: 20 + (pointCount / points.length) * 30,
                            height: 20 + (pointCount / points.length) * 30,
                          }}
                          onClick={() => { }}
                        >
                          {pointCount}
                        </div>
                      </Marker>
                    );
                  }
                  // console.log('second render button:', cluster, latitude)
                  return (
                    <Marker
                      key={`crime-${cluster.properties.cluster_id}`}
                      lat={latitude}
                      lng={longitude}
                    >
                      <div
                        id={`crime-${cluster.properties.cluster_id}`}
                        // className={`cluster-marker-${cluster.properties.is_selected}`}
                        style={{
                          width: 15,
                          height: 15,
                          cursor: "pointer",
                          zIndex: 4
                        }}
                        onClick={(event: any) => {
                          dispatch(UiActions.setSelectedBillboardId(cluster.properties.cluster_id))
                          setSelectedInMap(true)
                          setPlantProps(objectPlants[cluster.properties.cluster_id])
                          // console.log('cluster props', event.currentTarget);
                          setAnchorEl(document.getElementById('mappa'))
                          setPlantSelected(cluster.properties.cluster_id)
                        }}
                      >
                        {customMarker(objectPlants[cluster.properties.cluster_id], selectedBillboardId)}
                      </div>
                    </Marker>
                  );
                })}

                {/* <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  anchorReference="anchorPosition"
                  anchorPosition={{ top: 100, left: 560 }}
                  onClose={() => {
                    setAnchorEl(null);
                    setPlantSelected(null)
                    // setPlantProps(props)
                    // toggleRowSelected(selectedRowOverview, false) 
                  }}
                  anchorOrigin={{ vertical: 'center', horizontal: 'right', }}
                  transformOrigin={{ vertical: 'center', horizontal: 'left', }}
                >
                  <PlantDetailOverview item={plantProps} />
                </Popover> */}

                {/* {billboardInMap.map((mark: any) => {
                  return (
                    mark.billboardCount != undefined ?
                      <MarkComponent
                        lat={mark.location.lat}
                        lng={mark.location.lon}
                        text={""}
                        height={50}
                        width={50}
                        count={mark.billboardCount}
                        id={mark.city_code}
                      //description={<div>{mark.billboardCount} impianti disponibili a {mark.city_code}</div>}
                      />
                      : <MarkComponent
                        lat={mark.location.lat}
                        lng={mark.location.lon}
                        text={""}
                        height={20}
                        width={20}
                        id={mark.city_code}
                      //description={<div><CgArrowDownR size={15}/></div>}
                      />
                  )
                })
                } */}
              </GoogleMapReact>
            </div>
        </Card> : <div className="d-flex justify-content-between bd-highlight mb-3">
          <div className="p-2 bd-highlight"><Pricing items={data} /></div>
          <div className="p-2 bd-highlight"><Button close style={{ position: "absolute" }} onClick={() => setPrice(!price)} /></div>
        </div>
      }

    </>
  )
}