import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import GoogleMapReact, { fitBounds } from 'google-map-react';
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { Popover } from '@material-ui/core';
import { OrderDetailsLeft } from '../components/OrderDetailsLeft';
import { OrderDetailsRight } from '../components/OrderDetailsRight';
import { PlantDetailOverview } from '../components/PlantDetailOverview';
import { backButton, useStyles, setBadge, notificationTextChange, confirmButton } from '../components/OrderDetails.css';
import { mapCssStyle } from '../components/Map.css';
import Icon from './IconsComponent';
import useSupercluster from 'use-supercluster';
import _ from 'lodash';
import { customMarker } from '../components/CustomMarker';

const geolib = require('geolib');
const Marker = ({ children }: any) => children;

const OrderedPlants = (props: any) => {
  const { orderPlants } = props
  return (
    <div id="ordered-plants" className="d-sm-flex align-items-center justify-content-center p-3 orderNumberMap">
      <div style={{ textAlign: "center" }}>
        <b style={{ fontSize: 30 }}>{orderPlants}</b> <br />
        <span style={{ fontSize: 14, color: "gray" }}>Impianti prenotati</span>
      </div>
      {/* <div>
        <button style={{ borderRadius: 10 }} className="border-0 bg-white"><Icon color="gray" size={30} icon="filter" /></button>
        <button style={{ borderRadius: 10 }} className="border bg-white ml-2 mr-2"><Icon color="#0062cc" size={30} icon="task" /></button>
      </div> */}
    </div>
  )
}

export const OrderDetails = (props: any) => {
  // console.log('in orderDetails with:', props)
  const dispatch = useDispatch()
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [plantProps, setPlantProps] = useState({});
  // const [plantSelected, setPlantSelected] = useState('') as any

  let selectedBillboardId = useSelector(UiSelectors.getSelectedBillboardId)
  let order = useSelector(ContentSelectors.getSelectOrder)

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let punti = [{ lat: 39.907780323055086, lng: 9.237492267012982 }, { lat: 39.437432624875754, lng: 8.53038607583149 }, { lat: 39.34972998992784, lng: 9.499383448932052 }]

  const objectPlants = _.keyBy(order.billboards, 'id');

  const [notification, setNotification] = useState(notificationTextChange(setBadge(order.order_date, order.status, 500, order.billboards.length, order.from, order.to).notification))
  // const [notification, setNotification] = useState(notificationTextChange("Alert Foto Leggero"))

  let bounds1 = geolib.getBounds(punti);
  let size = {
    width: 1200,
    height: 600
  }
  if (document.getElementById("mappa")) {
    size = {
      width: document.getElementById("mappa")!.offsetWidth,
      height: document.getElementById("mappa")!.offsetHeight,
    }
  }
  bounds1 = {
    ne: {
      lat: bounds1.maxLat,
      lng: bounds1.maxLng
    },
    sw: {
      lat: bounds1.minLat,
      lng: bounds1.minLng
    }
  };
  const [bounds, setBound] = useState([] as any)
  const [zoom1, setZoom] = useState(10 as any)

  let points = order.billboards.map((bill: any) => ({
    type: "Feature",
    properties: {
      cluster: false,
      "cluster_id": bill.id,
      "point_count": bill.id
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(bill.longitude),
        parseFloat(bill.latitude)
      ]
    }
  }));
  const mapRef = useRef();
  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: zoom1,
    options: { radius: 75, maxZoom: 20 }
  });
  const { center, zoom } = fitBounds(bounds1, size);

  useEffect(() => {
    dispatch(UiActions.setSelectedPage("Ordini"))

    // notificationTextChange(setBadge(order.order_date, order.status, 10, order.billboards.length, order.from, order.to).notification)
  }, [notification])

  return (
    <div className="poppinsFont" id='mappa'>
      <div style={{ zIndex: 3, right: 0 }} className="d-sm-flex flex-column p-2 position-absolute align-items-center mr-5">
        <OrderedPlants orderPlants={_.size(order.billboards)} />
        <OrderDetailsRight />
      </div>
      <div style={{ zIndex: 3, left: 0 }} className="d-sm-flex flex-column p-2 position-absolute ml-3">
        <button style={backButton} onClick={() => {
          // dispatch(ContentActions.willListOrders("empty"))
          dispatch(ContentActions.didListOrderImages("empty"));
          dispatch(UiActions.goTo({ page: "backToOrders" }));
        }}><Icon color="gray" size={25} icon="burger" /> Torna alla lista</button>
        <OrderDetailsLeft />
      </div>
      <GoogleMapReact
        style={{ height: '89.5vh' }}
        bootstrapURLKeys={{
          key: "AIzaSyAMhpfSL4SkLCu2kSu-8oXXfTRtxddzYcE",
          libraries: ['places', 'geometry', 'drawing', 'visualization']
        }}
        defaultCenter={center}
        center={center}
        defaultZoom={zoom}
        options={{ fullscreenControl: false, styles: mapCssStyle }}
        layerTypes={['TrafficLayer', 'TransitLayer']}
        zoom={zoom}
        onChange={({ zoom, bounds }) => {
          // setTimeout(function () { onChangeMarginBounds(bounds) }, 500)
          setZoom(zoom);
          setBound([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map }) => {

          map.streetViewControl = true;
          map.mapTypeControl = true;
        }}
      // onChange={({ bounds, marginBounds }) => {
      //     setTimeout(function () { onChangeMarginBounds(bounds) }, 500)
      // }}
      >
        {clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const {
            cluster: isCluster,
            point_count: pointCount
          } = cluster.properties;
          // console.log("in render:", pointCount, isCluster, cluster)
          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <div
                  className="super-cluster"
                  style={{
                    width: 20 + (pointCount / points.length) * 30,
                    height: 20 + (pointCount / points.length) * 30,
                  }}
                  onClick={() => { }}
                >
                  {pointCount}
                </div>
              </Marker>
            );
          }
          return (
            <Marker
              key={`crime-${cluster.properties.cluster_id}`}
              lat={latitude}
              lng={longitude}
            >
              <div
                id={`crime-${cluster.properties.cluster_id}`}
                // className="cluster-marker"
                style={{
                  width: 15,
                  height: 15,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  dispatch(UiActions.setSelectedBillboardId(cluster.properties.cluster_id))
                  setPlantProps(objectPlants[cluster.properties.cluster_id]);
                  setAnchorEl(document.getElementById("listInMap"));
                }}
              >
                {customMarker(objectPlants[cluster.properties.cluster_id], selectedBillboardId)}
              </div>
            </Marker>
          );
        })}
      </GoogleMapReact>
      <Popover
        className={classes.popoverContainer}
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={{ top: 100, left: 712 }}
        onClose={() => {
          setAnchorEl(null);
          dispatch(ContentActions.didListBillboardAvailabilities({ availabilities: undefined }));
          dispatch(UiActions.setSelectedBillboardId(''));
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
      >
        <PlantDetailOverview item={plantProps} setAnchorEl={setAnchorEl} setPlantProps={setPlantProps} />
      </Popover>
    </div>
  )
}