import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ExploreActions, selectors as ExploreSelectors } from '../../../store/slices/explore';
import { useStyles, cancelButtonFilters, confirmButtonFilters } from '../Explore.css';
import { Grid, Chip, FormControlLabel, Checkbox } from '@material-ui/core';
import Icon from '../../IconsComponent';
import _ from 'lodash';
import { billboardsTypes } from '../../Autocomplete.options'

const EmptyCheck = () => {
  return (
    <div data-cy="emptyCheck" style={{ width: 25, height: 25, borderWidth: 1, borderStyle: "solid", borderColor: "#92929D", borderRadius: 5 }} />
  )
}
const FilledCheck = () => {
  return (
    <div style={{ width: 25, height: 25, borderWidth: 1, borderStyle: "solid", borderColor: "#00e68a", borderRadius: 5, position: "relative" }} >
      <div className="d-sm-flex align-items-center">
        <Icon size={25} color="#00e68a" icon="check" />
      </div>
    </div>
  )
}

const CustomCheckBox = (props: any) => {
  const { value, type, setData } = props

  let array = [...value] as any[]

  const updateArray = (typology: string) => {
    // let array: any[] = [...data]
    if (array.includes(typology)) {
      setData(array.filter((o: string) => { return o !== typology }))
    } else {
      array.push(typology)
      setData(array)
    }
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={array.includes(type)}
          onClick={() => updateArray(type)}
          icon={<EmptyCheck />}
          checkedIcon={<FilledCheck />}
          name={type} />} label=""
    />
  )
}

export const Typology = (props: any) => {
  const { setAnchor, setPlanningType } = props

  const classes = useStyles();
  const dispatch = useDispatch()

  const filters = useSelector(ExploreSelectors.didGetExploreFilter)
  const [data, setData] = useState(filters.media_type ? filters.media_type : [] as any)

  const applyFunction = () => {
    dispatch(ExploreActions.willExploreUploadFilter({ key: "media_type", value: data }))
    dispatch(ExploreActions.willExploreFilterBillboards({ exploreFilters: {} }))
    setAnchor(null)
    setPlanningType("")
  }

  return (
    <div className={`${classes.root} poppinsFont`}>
      <Grid container spacing={3}>
        <Grid item xs={12}><b>Tipologia</b></Grid>
        <Grid className={classes.filterBody} container item xs={12}>

          {billboardsTypes.map((type: any, index: number) => {
            return (
              <Grid key={index} container alignItems="center" >
                <Grid item xs={10}>{type.typology}</Grid>
                <Grid item xs={2}><CustomCheckBox value={data} type={type.typology} setData={setData} /></Grid>
              </Grid>
            )
          })}
          
        </Grid>
        <Grid className={classes.buttonsFilterContainer} container>
          <Grid container item xs={6} alignItems="center" justify="center">
            <button
              style={cancelButtonFilters}
              data-cy="goBack"
              onClick={() => { setAnchor(null); setPlanningType("") }}
            >Annulla</button>
          </Grid>
          <Grid container item xs={6} alignItems="center" justify="center">
            <button data-cy="apply" style={confirmButtonFilters} onClick={applyFunction}>Applica</button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}