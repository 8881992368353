import React, { useState, useEffect } from 'react';
import { Grid, Chip } from '@material-ui/core';
import Autosuggest, { BlurEvent, ChangeEvent } from 'react-autosuggest';
import { useSuggestion } from "../Suggestion.hooks";
import { useStyles } from '../plant-list/ListFilter.css';
import Icon from '../IconsComponent';
import { mutableTextFieldUfficio } from './Profile.css';
import _ from 'lodash';

export const OfficeComponent = ({ index, o, array, setProfilo }: any) => {
  const classes = useStyles()

  const [editUfficio, setEditUfficio] = useState(false)
  const [suggestions, setSuggestions] = useState([] as any)
  //// questi tre sotto servono per AutoSuggest, non eliminateli!
  const [data1, setData] = useState("")
  const [fieldValue, setFieldValue] = useState("")
  const [city, setCity] = useState("")

  const name: string = o.name

  const {
    renderSuggestion,
    renderSectionTitle,
    getSectionSuggestions,
    onChange,
    value,
    onSuggestionsFetchRequested,
    onSuggestionsClearRequested,
  }: any = useSuggestion({
    setFieldValue,
    setData,
    setSuggestions,
    setCity,
    // pushCity,
  })

  const inputProps: any = {
    placeholder: "Comune",
    value,
    onChange: onChange,
    className: "autosuggestFilter",
    id: "comune"
  };

  const modifyArray = (city: string, index: number) => { /// questa funzione elimina o inserisce la città nell'array delle città degli uffici
    let newArray: any[] = [...o.municipalities]
    if (newArray.includes(city)) {
      newArray = newArray.filter((i: any) => {
        return i != city
      })
    } else {
      newArray.push(city)
    }
    o.municipalities = newArray
    setProfilo({ ['offices']: array })

    // console.log("o.municipalities",o.municipalities, newArray)
  }

  return (
    <Grid key={`office-${index}`} style={{ borderRadius: 13, fontSize: 14 }} className="d-sm-flex bg-white p-2 mt-5" container item xs={12}>
      <Grid className="d-sm-flex align-items-center ml-3" container item xs={11} spacing={2}>
        <Grid className="d-sm-flex align-items-center p-2" item xs={12}>
          <Grid style={{ fontSize: 20 }} item xs={11}>
            {mutableTextFieldUfficio("name", "Nome ufficio", o.name 
            ? o.name 
            : editUfficio ? '' : <span style={{ opacity: 0.4, fontSize: 14 }}>Inserire nome ufficio ricezione materiali</span>, 
            editUfficio, o, name && name.length < 0 ? "(nessun nome inserito)" : "", array.length - 1 === index ? 5 : 11)}
            {/* {o.name
              ? <>{mutableTextFieldUfficio("name", "Nome ufficio", o.name, editUfficio, o, name && name.length < 0 ? "(nessun nome inserito)" : "", array.length - 1 === index ? 5 : 11)}</>
              : <span style={{ opacity: 0.4, fontSize: 14 }}>Inserire nome ufficio ricezione materiali</span>} */}
          </Grid>
          <Grid item xs={1}><button className="border-0 bg-transparent" onClick={() => setEditUfficio(true)}><Icon size={25} icon="edit" /></button></Grid>
        </Grid>

        <Grid className="d-sm-flex align-items-center" item xs={12}>
          {mutableTextFieldUfficio("address", "Indirizzo", o.address, editUfficio, o, "Indirizzo", 4)}
          {mutableTextFieldUfficio("phone", "Telefono", o.phone, editUfficio, o, "Telefono", 4)}
          {mutableTextFieldUfficio("email", "Email", o.email, editUfficio, o, "Email", 4)}
        </Grid>

        <Grid className="d-sm-flex align-items-center" item xs={12}>
          {editUfficio ?
            <Grid item xs={12}>
              {!_.isEmpty(o.municipalities) ? o.municipalities.map((city: any, index: any) => {
                return (
                  <Chip
                    data-cy="cityChip"
                    variant="outlined"
                    label={city}
                    key={index}
                    onDelete={() => modifyArray(city, index)} />
                )
              }) : null}
              <Autosuggest
                multiSection={true}
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={(suggestion: any) => {
                  // console.log('getSuggestionValue in profile', suggestion)
                  modifyArray(suggestion.name, index)
                  return suggestion.name;
                }}
                renderSuggestion={renderSuggestion}
                renderSectionTitle={renderSectionTitle}
                getSectionSuggestions={getSectionSuggestions}
                inputProps={inputProps}
                theme={{
                  input: classes.autosuggest,
                  inputOpen: classes.autosuggest,
                  inputFocused: classes.inputFocused,
                  suggestionsContainer: classes.absolute,
                  suggestionsContainerOpen: classes.absolute,
                }}
              />
            </Grid>
            :
            <Grid item xs={6}><span style={{ opacity: 0.4 }}>Comuni associati</span><br />{o.municipalities.toString().replace(/,/g, ', ')}</Grid>
          }
        </Grid>
        {/* <Grid className="d-sm-flex align-items-center mb-4" item xs={11}>
            </Grid> */}
      </Grid>
      {
        editUfficio ?
          <Grid className="d-sm-flex flex-row-reverse" item xs={12}>
            <button className="border rounded bg-transparent" onClick={() => {
              setProfilo({ ["offices"]: array })
              setEditUfficio(false)
            }}>
              <Icon size={25} icon="check" />Applica</button>
          </Grid>
          : null
      }
    </Grid >
  )
}