import React, { useState, useMemo, useCallback, useEffect, forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ButtonDropdown, UncontrolledDropdown, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Button, Row, Col, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Modal, ModalBody, ModalFooter, ModalHeader, Label } from "reactstrap";
import { useTable, useSortBy, useFlexLayout, useExpanded, useGlobalFilter, useRowSelect, useGroupBy } from "react-table";
import { VariableSizeList, FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import InfiniteLoader from 'react-window-infinite-loader';
import ReactTooltip from "react-tooltip";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { selectors as ProfileSelectors } from '../store/slices/profile';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { TextField, InputAdornment, Tooltip, Zoom, Backdrop, CircularProgress, Grid } from '@material-ui/core';
import Icon from "../components/IconsComponent";
import { useStyles } from "../components/plant-list/PlantListCss";
import { useStylesDashboards, Styles } from "../components/dashboard/Dashboard.css";
import { orderStatusSwitch, setBadge, statusColor, notificationTextChange, tooltip } from '../components/OrderDetails.css';
import _ from 'lodash';
import moment from 'moment';

import debounce from "lodash/debounce";
import { DashboardList } from '../components/dashboard/DashboardList'
import { BillboardsMap } from '../components/dashboard/BillboardsMap'

const fontsizeInRightComponents = 20

const currencyFormatter = (value: number) => {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
}

const numFormatter = (num: any) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
  } else if (num < 900) {
    return num; // if value < 1000, nothing to do
  }
}

export const Dashboard = () => {
  const dispatch = useDispatch()
  const classes = useStylesDashboards()

  const orders: any[] = useSelector(ContentSelectors.listOrders)
  const profile = useSelector(ProfileSelectors.getProfile)
  const userProfile = useSelector(ProfileSelectors.getMediaOwner)
  // console.log("Dashboard userProfile",userProfile)

  let totalBillboardsSold: number = 0
  let totalBillboardsSoldLastYear: number = 0
  let billboards: any[] = []

  let size6x3 = 0 as number
  let size4x3 = 0 as number
  let sizeOthers = 0 as number
  let totalMunicipality = [] as any
  let totalNormalized = [] as any
  let meanVisibilityIndex = [] as any

  const ordersToShow: any[] = _.compact(_.map(orders, (order: any, index: any) => {
    if (index <= 4) {
      return order
    }
  }))

  if (!_.isEmpty(orders)) {
    _.map(orders, (order: any) => {
      if (order.billboards) {
        if (order.status !== 'canceled') {
          if (moment(order.order_date).year() < moment().year()) {
            totalBillboardsSoldLastYear = totalBillboardsSoldLastYear + order.billboards.length
          }
          totalBillboardsSold = totalBillboardsSold + order.billboards.length
          billboards.push(...order.billboards)
        }
      }

    })

    billboards.map((i: any) => {
      const mappedSize = `${i.base}x${i.height}`
      // sizeArray.push({ size: mappedSize })
      if (mappedSize === "600x300") {
        size6x3 = size6x3 + 1
      }
      else if (mappedSize === "400x300") {
        size4x3 = size4x3 + 1
      } else {
        sizeOthers = sizeOthers + 1
      }
    })
    console.log("number of Sizes:", size6x3, size4x3, sizeOthers)

    billboards.map((i: any) => {
      totalMunicipality.push(i.iV_municipality)
    })
    billboards.map((i: any) => {
      totalNormalized.push(i.iV_normalized)
    })
    meanVisibilityIndex.push(_.mean(totalMunicipality), _.mean(totalNormalized))
  }

  const totalOrdersAmount: any = _.map(orders, 'amount')
  // const totalOrdersAmountLastYear: any = _.map(orders, (order: any) => {
  //   if (moment(order.order_date).year() < moment().year()) {
  //     return order.amount
  //   }
  // })

  // if (!_.isEmpty(billboards)) {
  //   billboards.map((i: any) => {
  //     const mappedSize = `${i.base}x${i.height}`
  //     // sizeArray.push({ size: mappedSize })
  //     if (mappedSize === "600x300") {
  //       size6x3 = size6x3 + 1
  //     }
  //     else if (mappedSize === "400x300") {
  //       size4x3 = size4x3 + 1
  //     } else {
  //       sizeOthers = sizeOthers + 1
  //     }
  //   })
  //   console.log("number of Sizes:", size6x3, size4x3, sizeOthers)

  //   billboards.map((i: any) => {
  //     totalMunicipality.push(i.iV_municipality)
  //   })
  //   billboards.map((i: any) => {
  //     totalNormalized.push(i.iV_normalized)
  //   })
  //   meanVisibilityIndex.push(_.mean(totalMunicipality), _.mean(totalNormalized))
  // }

  useEffect(() => {
    // if (!_.isEmpty(orders)) {
    //   console.log("orders already fetched")
    // } else {
    //   dispatch(ContentActions.willListOrders("empty"))
    // }

    dispatch(UiActions.setSelectedPage("Dashboard"))

  }, [])

  return (
    <>
      {orders[0] === 'empty'
        ? <h1 className='d-flex align-items-center justify-content-center w-100 bg-white' >Non sono stati creati ordini</h1>
        : <>
          {orders.length
            ? <>
              <Grid container className="poppinsFont" style={{ minHeight: '100vh' }}>
                <Grid container item xs={12} alignItems="center" justify="space-around">
                  <Grid className='p-2' item xs={11} >
                    <h1>Ciao {profile.given_name ? profile.given_name : ''}!</h1>
                    {/* <p className="text-muted">, qui trovi la tua dashboard</p> */}
                  </Grid>

                  <Grid className={classes.confirmStepBorders} item xs={3} /* justify="center" */>
                    <div className="d-sm-flex flex-column ml-3">
                      <b style={{ marginBottom: 30 }} >Totale vendite</b>
                      <h4><b>{currencyFormatter(_.sum(totalOrdersAmount))}</b></h4>
                      {/* <span className="text-muted" style={{ fontSize: fontsizeInRightComponents / 1.5 }}>€ {_.sum(totalOrdersAmountLastYear)} anno precedente</span> */}
                    </div>
                  </Grid>
                  <Grid className={classes.confirmStepBorders} item xs={3}>
                    <div className="d-sm-flex flex-column ml-3">
                      <b style={{ marginBottom: 30 }} >Spazi Venduti</b>
                      <h4><b>{totalBillboardsSold}</b></h4>
                      {/* <span className="text-muted" style={{ fontSize: fontsizeInRightComponents / 1.5 }}>{totalBillboardsSoldLastYear} anno precedente</span> */}
                    </div>
                  </Grid>
                  <Grid className={classes.confirmStepBorders} item xs={3}>
                    <div className="d-sm-flex flex-column ml-3">
                      <b style={{ marginBottom: 30 }} >Ordini ricevuti</b>
                      <h4>{/* <Icon size={25} icon="impianto" /> */} <b>{totalOrdersAmount.length}</b></h4>
                      {/* <span className="text-muted" style={{ fontSize: fontsizeInRightComponents / 1.5 }}>{totalOrdersAmountLastYear.length} anno precedente</span> */}
                    </div>
                  </Grid>
                </Grid>
                <Grid className='mt-3' container item xs={12} justify="space-around">
                  <Grid item xs={7}>
                    <Styles>
                      <DashboardList orders={ordersToShow} />
                    </Styles>
                  </Grid>
                  <Grid container item xs={3} /* style={{minHeight:'auto'}} */ /* className={classes.confirmStepBorders} */ /* direction="column" */ /* alignItems="center" */ /* justify="space-around" */ /* spacing={2} */>
                    <Grid className={`${classes.confirmStepBorders}`} container item xs={12} justify="center">
                      <Grid className="mt-3 mb-4" item xs={10}><b>I miei impianti</b></Grid>
                      <Grid className="mb-4" item xs={10}>
                        <h4><b>{billboards.length}</b></h4>
                        <p className="text-muted" style={{ fontSize: fontsizeInRightComponents / 1.5 }}>Numero impianti totali</p>
                      </Grid>
                      <Grid className="mt-2" container item xs={10}>
                        <Grid item xs={1}><Icon size={25} icon="impianto" /></Grid>
                        <Grid item xs={9}>600x300</Grid>
                        <Grid item xs={2}>{numFormatter(size6x3)}</Grid>
                      </Grid>
                      <Grid className="mt-3 mb-3" container item xs={10}>
                        <Grid item xs={1}><Icon size={25} icon="impianto" /></Grid>
                        <Grid item xs={9}>400x300</Grid>
                        <Grid item xs={2}>{numFormatter(size4x3)}</Grid>
                      </Grid>
                      <Grid className="mb-3" container item xs={10}>
                        <Grid item xs={1}><Icon size={25} icon="impianto" /></Grid>
                        <Grid item xs={9}>Altri</Grid>
                        <Grid item xs={2}>{numFormatter(sizeOthers)}</Grid>
                      </Grid>
                    </Grid>
                    <Grid className={`${classes.confirmStepBorders} mt-3 mb-3`} item xs={12}>
                      <Grid data-cy="billaloIndex" className="mb-3" container alignItems="center" justify="center">
                        <CircularProgressbarWithChildren
                          className={classes.visibilityProgress}
                          styles={buildStyles({
                            pathColor: "#0066ff",
                            trailColor: "#f8f9fa"
                          })}
                          maxValue={10}
                          value={_.ceil(_.mean(totalMunicipality), 2)}
                          strokeWidth={5} >
                          <Icon /* color="#0062CC" */ size={25} icon="goal" />
                        </CircularProgressbarWithChildren>
                        <div className="d-sm-flex flex-column ml-3 detailText">
                          <span><b style={{ fontSize: 30 }}>{_.ceil(_.mean(totalMunicipality), 2)}</b> <br /><span style={{ fontSize: 15 }} className="text-muted">Indice Visibilità <br />medio Comune</span></span>
                        </div>
                      </Grid>
                      <Grid item xs={12}><div className="border-bottom" /></Grid>
                      <Grid data-cy="billaloIndex" className="mt-3 mb-3" container alignItems="center" justify="center">
                        <CircularProgressbarWithChildren
                          className={classes.visibilityProgress}
                          styles={buildStyles({
                            //textColor: "red",
                            pathColor: "#00e68a",
                            trailColor: "#f8f9fa"
                          })}
                          maxValue={10}
                          value={_.ceil(_.mean(totalNormalized), 2)}
                          strokeWidth={5} >
                          <Icon /* color="#00e68a" */ size={25} icon="working" />
                        </CircularProgressbarWithChildren>
                        <div className="d-sm-flex flex-column ml-3 detailText">
                          <span><b style={{ fontSize: 30 }}>{_.ceil(_.mean(totalNormalized), 2)}</b> <br /><span style={{ fontSize: 15 }} className="text-muted">Indice Visibilità <br />medio Nazionale</span></span>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* <BillboardsMap /> */}
            </>
            : <h1 className='d-flex align-items-center justify-content-center w-100 bg-white' >La tua dashboard sta caricando <CircularProgress className='ml-2' /></h1>
          }
        </>}

    </>
  );
}
