import React from "react";
import "./App.scss";

import { Provider } from "react-redux";
import { configureStore } from "./store";
import { AppRouter } from "./router";
import { NotificationLayer } from "./notification";
import { NotificationContainer } from './components/notification-layer/NotificationContainer';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { Provider as URQLProvider } from "urql";
import urqlClient from "./graphql/client";

Sentry.init({
  dsn: "https://841399e4edaf43e4a04d35d6866d7da3@logs.radixcloud.org/10",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

export default function App() {

  // console.log("window width", document.body.clientWidth)
  return (
    <URQLProvider value={urqlClient}>
      <Provider store={configureStore({})}>
        <AppRouter />
      </Provider>
    </URQLProvider>
  );
}
