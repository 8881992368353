import * as React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { useStyles } from './Profile.css';

import { selectors as UISelectors } from '../../store/slices/ui';

export const ActivityButtonModal = ({ name, disabled, children, ...rest }: any) => {
  const classes = useStyles()

  const isActivityRunning = useSelector(state => UISelectors.activityRunningSelector(state, name));

  return (
    <>
      {
        isActivityRunning ? (
          <button
            style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'space-around', justifyContent: 'space-around' }}
            className="border-0 bg-transparent"
            disabled
            {...rest}>
            {children} <CircularProgress className={classes.circularProgressColor} size={18} />
          </button >
        ) : (
          < button className="border-0 bg-transparent" {...rest}>{children}</button >
        )
      }
    </>
  )
}