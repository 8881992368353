import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles, sizeButton } from "./PlantDetailOverview.css";
import { Modal, ModalBody } from "reactstrap";
import {
  Popover,
  Typography,
  Button,
  Paper,
  Grid,
  Divider,
} from "@material-ui/core";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import {
  actions as ContentActions,
  selectors as ContentSelectors,
} from "../store/slices/content";
import {
  actions as UiActions,
  selectors as UiSelectors,
} from "../store/slices/ui";
import { Availabilities } from "./Availabilities";
import Icon from "./IconsComponent";
import moment from "moment";
import _ from "lodash";
// import billboards_placeholder from '../assets/images/billboards_placeholder.svg'
import { ReactComponent as BillboardsPlaceholder } from "../assets/images/billboards_placeholder.svg";
import { customIcon } from './CustomMarker';

const currencyFormatter = (value: number) => {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(value);
};

export const PlantDetailOverview = (props: any) => {
  console.log("in Plant Detail Overview map with:", props);
  const { item, hideIcons } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const data = item;
  let order = useSelector(ContentSelectors.getSelectOrder)

  const address = data
    ? data.location_name && data.location_name !== "null"
      ? data.location_name
      : data.address
    : ''

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [plantDetailtDisponibility, setPlantDetailtDisponibility] =
    useState(false);

  const availabilities: any[] = useSelector(
    ContentSelectors.listBillboardAvailabilities
  );
  const selectedPage = useSelector(UiSelectors.getSelectedPage);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const maintenanceFilter: any[] = availabilities.filter((availability: any) =>
    moment(availability.start_busy_date).isBefore(moment())
  );
  let lastMaintenance = maintenanceFilter.length
    ? moment(
      maintenanceFilter[maintenanceFilter.length - 1].start_busy_date
    ).format("DD/MM/YYYY")
    : "-";

  useEffect(() => {
    if (!availabilities.length) {
      dispatch(
        ContentActions.willListBillboardAvailabilities({
          billboard_id: data.id,
        })
      );
    }

    return () => { };
  }, []);

  const onClose = () => {
    setAnchorEl(null);
    dispatch(
      ContentActions.didListBillboardAvailabilities({
        availabilities: undefined,
      })
    );
    dispatch(UiActions.setSelectedBillboardId(""));
  };

  return (
    <div className={`${classes.root} robotoFont`}>
      <Grid container>
        {_.isEmpty(data) ? (
          <div className="d-sm-flex align-items-center justify-content-center">
            Caricamento in corso..
          </div>
        ) : (
          <>
            <Grid
              className="d-sm-flex align-items-center justify-content-center"
              item
              xs={12}
            >
              {data.imageUrl ? (
                <img
                  style={{ borderRadius: 10, padding: 10 }}
                  src={data.imageUrl}
                  width="100%"
                />
              ) : (
                customIcon(data.billboard_class, data.media_type, 150)
              )}
            </Grid>
            <Grid
              container
              item
              xs={12}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <h4 style={{ textAlign: 'center', marginLeft: 10, marginRight: 10 }} data-cy="minucipality">{address}</h4>
              <p data-cy="address" className="text-muted">
                {data.municipality}
              </p>
            </Grid>
            {hideIcons ? null : (
              <Grid
                container
                item
                xs={12}
                justify="center"
                direction="row"
                alignItems="center"
              >
                <button
                  className="border-0 bg-transparent"
                  onClick={(event: any) => {
                    dispatch(
                      ContentActions.willListBillboardAvailabilities({
                        billboard_id: data.id,
                      })
                    );
                    setPlantDetailtDisponibility(true);
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <Icon
                    data-cy="calendarIcon"
                    className="border-0"
                    color="#92929D"
                    size={30}
                    icon="calendar"
                  />
                </button>
                {data.pricing_id ? (
                  <button
                    className="d-sm-flex flex-row-reverse text-secondary bg-transparent border-0"
                    data-cy="pricingPlant"
                    onClick={() => {
                      if (data.pricing_id) {
                        dispatch(
                          ContentActions.willGetBillboardPricing({
                            pricing_id: data.pricing_id,
                          })
                        );
                        dispatch(
                          UiActions.switchPricing({
                            items: [
                              {
                                id: data.id,
                                city_code: data.city_code,
                                city: data.municipality,
                                address: address,
                              },
                            ],
                          })
                        );
                      }
                    }}
                  >
                    <Icon size={30} color="#00e68a" icon="administration" />
                  </button>
                ) : (
                  <button
                    className="d-sm-flex flex-row-reverse text-secondary bg-transparent border-0"
                    data-cy="pricingDisabled"
                    onClick={() => {
                      dispatch(
                        ContentActions.didGetBillboardPricing({ pricing: {} })
                      );
                      dispatch(
                        UiActions.switchPricing({
                          items: [
                            {
                              id: data.id,
                              city_code: data.city_code,
                              city: data.municipality,
                              address: address,
                            },
                          ],
                        })
                      );
                    }}
                  >
                    <Icon size={30} color="#92929D" icon="administration" />
                  </button>
                )}
              </Grid>
            )}
            <Grid className="p-4" container spacing={3}>
              <Grid item xs={12}>
                <div className="border-bottom" />
              </Grid>
              {selectedPage === "Ordini" && (
                <>
                  <Grid item xs={5}>
                    <span data-cy="typology" className="text-muted">
                      Prenotazione
                    </span>
                  </Grid>
                  <Grid className="d-sm-flex flex-row-reverse" item xs={7}>
                    <span>da {moment(order.from).format("DD/MM/YYYY")} a {moment(order.to).format("DD/MM/YYYY")}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="border-bottom" />
                  </Grid>
                </>
              )}
              <Grid item xs={7}>
                <span data-cy="typology" className="text-muted">
                  Classe
                </span>
              </Grid>
              <Grid
                className={`${data.billboard_class === "Outdoor"
                  ? classes.outdoorClass
                  : classes.indoorClass
                  } d-sm-flex flex-row-reverse`}
                item
                xs={5}
              >
                <span>{data.billboard_class}</span>
              </Grid>
              <Grid item xs={12}>
                <div className="border-bottom" />
              </Grid>
              <Grid item xs={7}>
                <span data-cy="format" className="text-muted">
                  Formato
                </span>
              </Grid>
              <Grid className="d-sm-flex flex-row-reverse" item xs={5}>
                <span style={sizeButton} className="text-muted">
                  {data.base}x{data.height}
                </span>
              </Grid>
              <Grid item xs={12}>
                <div className="border-bottom" />
              </Grid>
              <Grid item xs={3}>
                <span data-cy="cimasa" className="text-muted">
                  {data.billboard_class === "Outdoor" ? 'Cimasa' : 'Codice'}
                </span>
              </Grid>
              <Grid className="d-sm-flex flex-row-reverse" item xs={9}>
                <span>{data.cod_cimasa}</span>
              </Grid>
              <Grid item xs={12}>
                <div className="border-bottom" />
              </Grid>
              <Grid item xs={5}>
                <span data-cy="cimasa" className="text-muted">
                  Uscita Minima
                </span>
              </Grid>
              <Grid className="d-sm-flex flex-row-reverse" item xs={7}>
                <span>{data.min_period ? data.min_period : '-'}</span>
              </Grid>
              <Grid item xs={12}>
                <div className="border-bottom" />
              </Grid>
              <Grid item xs={8}>
                <span data-cy="price" className="text-muted">
                  Prezzo
                </span>
              </Grid>
              <Grid className="d-sm-flex flex-row-reverse" item xs={4}>
                <span>{currencyFormatter(data.price)}</span>
              </Grid>
              <Grid item xs={12}>
                <div className="border-bottom" />
              </Grid>
              {data.billboard_class === "Outdoor" &&
                <>
                  <div className="d-sm-flex justify-content-center">
                    <Grid
                      className={classes.circularProgressContainer}
                      container
                      item
                      xs={10}
                    >
                      <>
                        <Grid
                          className="mt-4 mb-4"
                          data-cy="billaloIndex"
                          container
                          item
                          xs={6}
                          alignItems="center"
                          justify="flex-end"
                        >
                          {/* <span className={`${classes.billaloIcon} billaloIcon`}></span> */}
                          <CircularProgressbarWithChildren
                            className={classes.visibilityProgress}
                            styles={buildStyles({
                              pathColor: "rgba(0, 98, 255, 1)",
                              trailColor: "rgba(0, 98, 255, 0.1)",
                            })}
                            maxValue={10}
                            value={
                              data
                                ? data.billalo_index
                                  ? data.billalo_index.iV_municipality
                                  : data.iV_municipality
                                : 0
                            }
                            strokeWidth={5}
                          >
                            <Icon color="#0062CC" size={25} icon="headquarters" />
                          </CircularProgressbarWithChildren>
                        </Grid>
                        <Grid className="mt-3 ml-3" container item xs={5}>
                          <span>
                            <b style={{ fontSize: 30 }}>
                              {data
                                ? data.billalo_index
                                  ? _.ceil(data.billalo_index.iV_municipality, 2)
                                  : _.ceil(data.iV_municipality, 2)
                                : 0}
                            </b>{" "}
                            <br />
                            <span className="text-muted">
                              Indice Visibilità <br /> - Comune
                            </span>
                          </span>
                        </Grid>
                      </>
                      <Grid item xs={12}>
                        <div className="border-bottom" />
                      </Grid>
                      <>
                        <Grid
                          className="mt-4 mb-4"
                          data-cy="billaloIndex"
                          container
                          item
                          xs={6}
                          alignItems="center"
                          justify="flex-end"
                        >
                          <CircularProgressbarWithChildren
                            className={classes.visibilityProgress}
                            styles={buildStyles({
                              //textColor: "red",
                              pathColor: "rgba(61, 213, 152, 1)",
                              trailColor: "rgba(61, 213, 152, 0.1)",
                            })}
                            maxValue={10}
                            value={
                              data
                                ? data.billalo_index
                                  ? data.billalo_index.iV_normalized
                                  : data.iV_normalized
                                : 0
                            }
                            strokeWidth={5}
                          >
                            <Icon color="#00e68a" size={25} icon="leaderboard" />
                          </CircularProgressbarWithChildren>
                        </Grid>
                        <Grid className="mt-3 ml-3" container item xs={5}>
                          <span>
                            <b style={{ fontSize: 30 }}>
                              {data
                                ? data.billalo_index
                                  ? _.ceil(data.billalo_index.iV_normalized, 2)
                                  : _.ceil(data.iV_normalized, 2)
                                : 0}
                            </b>{" "}
                            <br />
                            <span className="text-muted">
                              Indice Visibilità <br /> - Italia
                            </span>
                          </span>
                        </Grid>
                      </>
                    </Grid>
                  </div>
                  <Grid data-cy="visibility" item xs={7}>
                    <Icon
                      className="p-2 iconOverview"
                      color="#50B5FF"
                      size={40}
                      icon="doc"
                    />{" "}
                    <span>Pannello</span>
                  </Grid>
                  <Grid className="d-sm-flex flex-row-reverse" item xs={5}>
                    <span className="text-muted">
                      <b style={{ color: "black" }}>
                        {data
                          ? data.billalo_index
                            ? _.ceil(data.billalo_index.panel_index, 2)
                            : _.ceil(data.panel_index, 2)
                          : 0}
                      </b>{" "}
                      su 10
                    </span>
                  </Grid>
                  <Grid data-cy="position" item xs={7}>
                    <Icon
                      className="p-2 iconOverview"
                      color="#50B5FF"
                      size={40}
                      icon="doc"
                    />{" "}
                    <span>Posizione</span>
                  </Grid>
                  <Grid className="d-sm-flex flex-row-reverse" item xs={5}>
                    <span className="text-muted">
                      <b style={{ color: "black" }}>
                        {data
                          ? data.billalo_index
                            ? _.ceil(data.billalo_index.indice_visibilita, 2)
                            : _.ceil(data.indice_visibilita, 2)
                          : 0}
                      </b>{" "}
                      su 10
                    </span>
                  </Grid>
                  <Grid item xs={7}>
                    <Icon
                      data-cy="traffic"
                      className="p-2 iconOverview"
                      color="#50B5FF"
                      size={40}
                      icon="doc"
                    />{" "}
                    <span>Traffico</span>
                  </Grid>
                  <Grid className="d-sm-flex flex-row-reverse" item xs={5}>
                    <span className="text-muted">*Lento</span>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="border-bottom" />
                  </Grid>
                </>}

              <Grid item xs={5}>
                <span data-cy="price" className="text-muted">
                  Ubicazione
                </span>
              </Grid>
              <Grid className="d-sm-flex flex-row-reverse" item xs={7}>
                <span>{data.location
                  ? data.location === "Altro"
                    ? data.other_location
                    : data.location
                  : "-"}</span>
              </Grid>
              <Grid item xs={12}>
                <div className="border-bottom" />
              </Grid>
              <Grid item xs={5}>
                <span data-cy="price" className="text-muted">
                  Facciate
                </span>
              </Grid>
              <Grid className="d-sm-flex flex-row-reverse" item xs={7}>
                <span>{data.facade ? data.facade : "-"}</span>
              </Grid>
              <Grid item xs={12}>
                <div className="border-bottom" />
              </Grid>
              <Grid item xs={7}>
                <span data-cy="price" className="text-muted">
                  Altezza
                </span>
              </Grid>
              <Grid className="d-sm-flex flex-row-reverse" item xs={5}>
                <span>{data.height_ground ? data.height_ground : "-"}</span>
              </Grid>
              <Grid item xs={12}>
                <div className="border-bottom" />
              </Grid>
              {data.billboard_class === "Outdoor" &&
                <>
                  <Grid item xs={7}>
                    <span data-cy="price" className="text-muted">
                      Illuminazione
                    </span>
                  </Grid>
                  <Grid className="d-sm-flex flex-row-reverse" item xs={5}>
                    <span>{data.lighting ? data.lighting : "-"}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="border-bottom" />
                  </Grid>
                </>}
              <Grid item xs={5}>
                <span data-cy="price" className="text-muted">
                  Tipo contenuto
                </span>
              </Grid>
              <Grid className="d-sm-flex flex-row-reverse" item xs={7}>
                <span>{data.press_type ? data.press_type : "-"}</span>
              </Grid>
              <Grid item xs={12}>
                <div className="border-bottom" />
              </Grid>
              {data.billboard_class === "Outdoor" &&
                <>
                  <Grid item xs={5}>
                    <span data-cy="price" className="text-muted">
                      Manutenzione
                    </span>
                  </Grid>
                  <Grid className="d-sm-flex flex-row-reverse" item xs={7}>
                    <span>{lastMaintenance}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="border-bottom" />
                  </Grid>
                  <Grid item xs={5}>
                    <span data-cy="price" className="text-muted">
                      Ultimo intervento
                    </span>
                  </Grid>
                  <Grid className="d-sm-flex flex-row-reverse" item xs={7}>
                    <span>
                      {data.last_maintenance
                        ? moment(data.last_maintenance).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="border-bottom" />
                  </Grid>
                  <Grid item xs={5}>
                    <span data-cy="price" className="text-muted">
                      Tributi
                    </span>
                  </Grid>
                  <Grid className="d-sm-flex flex-row-reverse" item xs={7}>
                    <span>
                      {data.taxes?.municipal_tax
                        ? currencyFormatter(data.taxes.municipal_tax)
                        : "0 €"}
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="border-bottom" />
                  </Grid>
                  <Grid item xs={5}>
                    <span data-cy="price" className="text-muted">
                      Ultimo pagamento
                    </span>
                  </Grid>
                  <Grid className="d-sm-flex flex-row-reverse" item xs={7}>
                    <span>
                      {data.taxes?.last_payment
                        ? moment(data.taxes.last_payment).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="border-bottom" />
                  </Grid>
                  <Grid item xs={5}>
                    <span data-cy="price" className="text-muted">
                      Numero SCIA
                    </span>
                  </Grid>
                  <Grid className="d-sm-flex flex-row-reverse" item xs={7}>
                    <span>{data.n_authorization ? data.n_authorization : "-"}</span>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="border-bottom" />
                  </Grid>
                  <Grid item xs={5}>
                    <span data-cy="price" className="text-muted">
                      Rilascio SCIA
                    </span>
                  </Grid>
                  <Grid className="d-sm-flex flex-row-reverse" item xs={7}>
                    <span>
                      {data.authorization_expiration
                        ? moment(data.authorization_expiration).format("DD/MM/YYYY")
                        : "-"}
                    </span>
                  </Grid>
                </>}
            </Grid>{" "}
            {data.notes &&
              <div className="px-4 mb-3">
                <Grid className="text-muted mb-2" item xs={12}>
                  <span data-cy="price">
                    Descrizione
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <span data-cy="price">
                    {data.notes}
                  </span>
                </Grid>
              </div>}
          </>
        )}
      </Grid>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        // anchorReference="anchorPosition"
        // anchorPosition={{ top: 100, left: 560 }}
        onClose={onClose}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        transformOrigin={{ vertical: "center", horizontal: "left" }}
      >
        <div style={{ width: 600, padding: 15 }}>
          <Availabilities item={data} onClose={onClose} />
        </div>
      </Popover>
    </div>
  );
};