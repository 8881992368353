import React from 'react';
import * as Yup from 'yup';

export const emptyValidations = Yup.object().shape({})

/// primo step creazione impianto
const OutdoorValidationFirstStep = Yup.object().shape({
  billboard_class: Yup.string()
    .required('Required'),
  base: Yup.number()
    .required('Required'),
  height: Yup.number()
    .required('Required'),
  cod_cimasa: Yup.string()
    .required('Required'),
  media_type: Yup.string()
    .required('Required'),
  height_ground: Yup.string()
    .required('Required'),
  orientation: Yup.string()
    .required('Required'),
  lighting: Yup.string()
    .required('Required'),
  price: Yup.number()
    .required('Required')
    .test(
      "maxDigitsAfterDecimal",
      "Massimo due valori decimali",
      (value: any) => /^\d+(\.\d{1,2})?$/.test(value?.toString() || "")
    )
})

const IndoorValidationFirstStep = Yup.object().shape({
  billboard_class: Yup.string()
    .required('Required'),
  base: Yup.number()
    .required('Required'),
  height: Yup.number()
    .required('Required'),
  cod_cimasa: Yup.string()
    .required('Required'),
  media_type: Yup.string()
    .required('Required'),
  height_ground: Yup.string()
    .required('Required'),
  price: Yup.number()
    .required('Required')
    .test(
      "maxDigitsAfterDecimal",
      "Massimo due valori decimali",
      (value: any) => /^\d+(\.\d{1,2})?$/.test(value?.toString() || "")
    )
})

export const stepOneValidations = {
  Outdoor: OutdoorValidationFirstStep,
  Indoor: IndoorValidationFirstStep
}

/// secondo step creazione impianto
export const OutdoorValidationSecondStep = Yup.object().shape({
  municipality: Yup.string()
    .required('Required'),
  latitude: Yup.number()
    .required('Required'),
  longitude: Yup.number()
    .required('Required'),
  address: Yup.string()
    .required('Required'),
  location: Yup.string()
    .required('Required'),
})

export const IndoorValidationSecondStep = Yup.object().shape({
  municipality: Yup.string()
    .required('Required'),
  location: Yup.string()
    .required('Required'),
  latitude: Yup.number()
    .required('Required'),
  longitude: Yup.number()
    .required('Required')
})

export const stepTwoValidations = {
  Outdoor: OutdoorValidationSecondStep,
  Indoor: IndoorValidationSecondStep
}

export const stepThreeValidations = Yup.object().shape({
  municipality: Yup.string()
    .required('Required'),
  latitude: Yup.number()
    .required('Required'),
  longitude: Yup.number()
    .required('Required'),
  address: Yup.string()
    .required('Required')
})




/// vecchie validations
export const addNewPlantValidation = Yup.object().shape({
  municipality: Yup.string()
    .test({
      name: "municipality",
      message: "Required",
      test: (value: unknown, context?: object) => {
        //console.log("CONTEXT", context)
        return (context! as any).parent.city === undefined ? false : true
      }
    }),
  latitude: Yup.number()
    //.min(5, 'Too Short!')
    .min(34, 'Coordinata fuori dal territorio italiano')
    .max(48, 'Coordinata fuori dal territorio italiano')
    .required('Required'),
  longitude: Yup.number()
    .min(5, 'Coordinata fuori dal territorio italiano')
    .max(20, 'Coordinata fuori dal territorio italiano')
    .required('Required'),
  base: Yup.string()
    .required('Required'),
  height: Yup.string()
    .required('Required'),
  // typology: Yup.string()
  //   .required('Required'),
  cod_cimasa: Yup.string()
    .required('Required'),
  n_sheet: Yup.string()
    .required('Required'),
  // price: Yup.number()
  //   .required('Required'),
  price: Yup.number()
    .required('Required')
    .test(
      "maxDigitsAfterDecimal",
      "Massimo due valori decimali",
      (value: any) => /^\d+(\.\d{1,2})?$/.test(value?.toString() || "")
    )
  // .min(2.5, 'Too Few!')
  // .max(500, 'The limit during beta testing is 500,00.')
})