import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ProfileActions, selectors as ProfileSelectors } from '../../store/slices/profile';
import { actions as ContentActions, selectors as ContentSelectors } from '../../store/slices/content';
import { Grid, Paper, Chip } from '@material-ui/core';
import Autosuggest, { BlurEvent, ChangeEvent } from 'react-autosuggest';
import { useSuggestion } from "../Suggestion.hooks";
import { useStyles } from '../plant-list/ListFilter.css';
import Icon from '../IconsComponent';
import { OfficeComponent } from './OfficeComponent';
import { ReactComponent as PaymentIcon } from "../../assets/icons/Payments_logo.svg";
import { ReactComponent as StripeIcon } from "../../assets/icons/Stripe_black.svg";
import { ReactComponent as StripeMulticolorIcon } from "../../assets/icons/Stripe_logo_multicolor.svg";
import { ActivityButton } from '../ActivityButton';
import { stripeButton, mutableTextFieldProfilo, mutableTextFieldAnagrafica, mutableTextFieldPagamenti, mutableTextFieldFundsTransfer } from './Profile.css';
import placeholder from '../../assets/placeholder_profile.png';
import moment from 'moment';
import _ from 'lodash';


export const Company = ({ setModalType }: any) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const userProfile = useSelector(ProfileSelectors.getMediaOwner)
  const profile = useSelector(ProfileSelectors.getProfile)
  const orders: any[] = useSelector(ContentSelectors.listOrders)

  let totalBillboardsSold: number = 0
  let totalBillboardsSoldLastYear: number = 0
  // let billboards: any[] = []

  if (orders.length) {
    _.map(orders, (order: any) => {
      if (order.status !== 'canceled' && order.billboards) {
        if (moment(order.order_date).year() < moment().year()) {
          totalBillboardsSoldLastYear = totalBillboardsSoldLastYear + order.billboards.length
        }
        totalBillboardsSold = totalBillboardsSold + order.billboards.length
        // billboards.push(...order.billboards)
      }
    })
  }

  const [editProfilo, setEditProfilo] = useState(false)
  const [editAnagrafica, setEditAnagrafica] = useState(false)
  const [editPagamenti, setEditPagamenti] = useState(false)

  const newOffice = {
    name: "",
    address: "",
    phone: "",
    email: "",
    municipalities: [],
  }

  const [profilo, setProfilo] = useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }), {
    // mediaowner: _.replace(profileName, ' ', '_'),
    avatar: "",
    name: "",
    subscription_date: moment().format("YYYY-MM-DD"),
    n_orders: 0,
    fees: 0,
    p_iva: "",
    phone: "",
    website: "",
    registered_office: "",
    rea: "",
    pec: "",
    society_form: "",
    bank_account: "",
    payment_mode: "",
    payment_registered_office: "",
    offices: [
      {
        name: "",
        address: "",
        phone: "",
        email: "",
        municipalities: [],
      }
    ],
    recipient_code: "",
    funds_transfer: "",
    stripe_actived: false
  })
  // console.log("Profile profilo", profilo)
  // const mediaOwner = profilo.name.replace(/ /g, '_'); /// questo è il mediaOwner da mandare per cognito

  // let array = [...profilo.offices] /// serve per modificare l'array degli uffici
  let array = JSON.parse(JSON.stringify(profilo.offices)) /// serve per modificare l'array degli uffici
  // console.log("array",profilo)

  useEffect(() => {
    if (!_.isEmpty(orders)) {
      console.log("orders already fetched")
    } else {
      dispatch(ContentActions.willListOrders("empty"))
    }

  }, [])

  useEffect(() => {
    if (userProfile) {
      setProfilo(userProfile)
    }
  }, [userProfile])

  return (
    <Grid className="d-sm-flex mt-4" container item xs={11}>
      <Grid container item xs={3} direction="column" alignItems="center" justify="center" />
      <Grid container item xs={9} justify="flex-end">
        <ActivityButton data-cy="confirm" style={{ height: 40 }} className={classes.confirmButton} name="willAddMediaOwner" color="primary" onClick={() => {
          dispatch(ProfileActions.willAddMediaOwner(profilo))
        }}>Salva modifiche</ActivityButton>
        {/* <button data-cy="confirm" className={classes.confirmButton} onClick={() => {
          dispatch(ProfileActions.willAddMediaOwner(profilo))
        }} >Salva modifiche</button> */}
      </Grid>

      <Grid style={{ borderRadius: 13, fontSize: 14 }} className="d-sm-flex bg-white p-2 mt-3" container item xs={12} >
        <Grid className="d-sm-flex ml-3" container item xs={11} spacing={4}>
          <Grid className="d-sm-flex align-items-center p-2 mt-4" item xs={12}>
            <Grid style={{ fontSize: 20 }} item xs={11}>
              <Grid></Grid>
              <Grid></Grid>
              {mutableTextFieldProfilo("name", "inserire nome", profilo.name
                ? profilo.name
                : editProfilo ? '' : <span style={{ opacity: 0.4, fontSize: 14 }}>Inserire nome </span>, editProfilo, profilo, "", 12, "d-sm-flex p-2", setProfilo)}
            </Grid>
            <Grid item xs={1}><button className="border-0 bg-transparent" onClick={() => setEditProfilo(true)}><Icon size={25} icon="edit" /></button></Grid>
          </Grid>
          <Grid className="d-sm-flex align-items-center" item xs={6}>
            <Grid style={{ opacity: 0.4 }} item xs={5}>Spazi venduti</Grid>
            <Grid className="d-sm-flex flex-row-reverse" item xs={7}>{totalBillboardsSold}</Grid>
          </Grid>
          <Grid className="d-sm-flex align-items-center" item xs={6}>
            <Grid style={{ opacity: 0.4 }} item xs={8}>Piano di abbonamento</Grid>
            <Grid className="d-sm-flex flex-row-reverse" item xs={3}>FREE</Grid>
          </Grid>
          <Grid className="d-sm-flex align-items-center" item xs={6}>
            <Grid style={{ opacity: 0.4 }} item xs={5}>Iscritto dal</Grid>
            <Grid className="d-sm-flex flex-row-reverse" item xs={7}>{profilo.subscription_date ? moment(profilo.subscription_date).format("DD-MM-YYYY") : profilo.subscription_date}
            </Grid>
          </Grid>
          <Grid className="d-sm-flex align-items-center" item xs={6}>
            <Grid style={{ opacity: 0.4 }} item xs={4}>Livello fee</Grid>
            <Grid className="d-sm-flex flex-row-reverse" item xs={7}>{profilo.fees}%</Grid>
          </Grid>
          {editProfilo ? <Grid className="d-sm-flex flex-row-reverse" item xs={12}><button className="border rounded bg-transparent" onClick={() => setEditProfilo(false)}><Icon size={25} icon="check" />Applica</button></Grid> : null}
        </Grid>
      </Grid>

      <Grid style={{ borderRadius: 13, fontSize: 14 }} className="d-sm-flex bg-white p-2 mt-5" container item xs={12} >
        <Grid className="d-sm-flex align-items-center ml-3" container item xs={11} spacing={4}>
          <Grid className="d-sm-flex align-items-center p-2 mt-4" item xs={12}>
            <Grid style={{ fontSize: 20 }} item xs={11}>Anagrafica</Grid>
            <Grid item xs={1}><button className="border-0 bg-transparent" onClick={() => setEditAnagrafica(true)}><Icon size={25} icon="edit" /></button></Grid>
          </Grid>
          <Grid className="d-sm-flex align-items-center" item xs={12}>
            {mutableTextFieldAnagrafica("p_iva", "P.IVA", profilo.p_iva, editAnagrafica, profilo, "P.IVA", 3, setProfilo)}
            {mutableTextFieldAnagrafica("phone", "Telefono", profilo.phone, editAnagrafica, profilo, "Telefono", 3, setProfilo)}
            {mutableTextFieldAnagrafica("website", "Sito Web", profilo.website, editAnagrafica, profilo, "Sito Web", 3, setProfilo)}
            <Grid item xs={3}><span style={{ opacity: 0.4 }}>Email</span><br />{profile.email}</Grid>
          </Grid>
          <Grid className="d-sm-flex align-items-center" item xs={12}>
            {mutableTextFieldAnagrafica("rea", "REA", profilo.rea, editAnagrafica, profilo, "REA", 3, setProfilo)}
            {mutableTextFieldAnagrafica("pec", "Pec", profilo.pec, editAnagrafica, profilo, "Pec", 3, setProfilo)}
            {mutableTextFieldAnagrafica("society_form", "Forma societaria", profilo.society_form, editAnagrafica, profilo, "Forma societaria", 3, setProfilo)}
            {mutableTextFieldAnagrafica("registered_office", "Sede legale", profilo.registered_office, editAnagrafica, profilo, "Sede legale", 3, setProfilo)}
          </Grid>
          {/* <Grid className="d-sm-flex align-items-center mb-4" item xs={11}>
              </Grid> */}
        </Grid>
        {editAnagrafica ? <Grid className="d-sm-flex flex-row-reverse" item xs={12}><button className="border rounded bg-transparent" onClick={() => setEditAnagrafica(false)}><Icon size={25} icon="check" />Applica</button></Grid> : null}
      </Grid>

      <Grid style={{ borderRadius: 13, fontSize: 14 }} className="d-sm-flex bg-white p-2 mt-5" container item xs={12}>
        <Grid className="d-sm-flex align-items-center ml-3" container item xs={11} spacing={4}>
          <Grid className="d-sm-flex align-items-center p-2 mt-4" item xs={12}>
            <Grid style={{ fontSize: 20 }} item xs={11}>Pagamenti</Grid>
            <Grid item xs={1}><button className="border-0 bg-transparent" onClick={() => setEditPagamenti(true)}><Icon size={25} icon="edit" /></button></Grid>
          </Grid>
          <Grid className="d-sm-flex align-items-center" item xs={12}>
            {mutableTextFieldPagamenti("recipient_code", "Codice destinatario", profilo.recipient_code, editPagamenti, profilo, "Codice destinatario", 5, setProfilo)}
            {mutableTextFieldFundsTransfer("funds_transfer", "Trasferimento fondi", profilo.funds_transfer, editPagamenti, profilo, "Trasferimento fondi", 5, setProfilo)}
          </Grid>

          <Grid className="d-sm-flex align-items-center" item xs={12}>
            {profilo.stripe_actived
              ? <>
                <Grid item xs={5}><span style={{ opacity: 0.4 }}>Account Stripe</span><br />Attivo</Grid>
                <Grid container item xs={3} alignItems="center" justify="space-around">
                  <StripeIcon />
                  <StripeMulticolorIcon />
                  <PaymentIcon />
                </Grid>
                <Grid container item xs={4} alignItems="center" justify="space-around">
                  <ActivityButton style={stripeButton} name="willManageStripeAccount" onClick={() => { dispatch(ProfileActions.willManageStripeAccount(profilo)) }}>
                    Vai alla dashboard stripe
                  </ActivityButton>
                </Grid>
              </>
              : <Grid container>
                <Grid container>
                  <Grid style={{ opacity: 0.4 }} item xs={10}>
                    "Per ricevere ordini su Billalo è necessario attivare l'Account Concessionaria su Stripe."
                  </Grid>
                  <Grid item xs={2}><StripeIcon /></Grid>
                </Grid>
                <Grid item xs={12}>
                  <ActivityButton style={stripeButton} name="willManageStripeAccount" onClick={() => { dispatch(ProfileActions.willManageStripeAccount(profilo)) }}>
                    Attiva Stripe
                  </ActivityButton>
                </Grid>
              </Grid>
            }
          </Grid>
          {/* <Grid className="d-sm-flex align-items-center mb-4" item xs={11}>
                {mutableTextFieldPagamenti("payment_registered_office", "Indirizzo Sede legale", profilo.payment_registered_office, editPagamenti, profilo, "Sede legale", 11, setProfilo)}
              </Grid> */}

        </Grid>
        {editPagamenti ? <Grid className="d-sm-flex flex-row-reverse" item xs={12}><button className="border rounded bg-transparent" onClick={() => setEditPagamenti(false)}><Icon size={25} icon="check" />Applica</button></Grid> : null}
      </Grid>

      {_.map(array, (o: any, index: number) => {
        return (
          <React.Fragment key={index}>
            <OfficeComponent index={index} o={o} array={array} setProfilo={setProfilo} />
          </React.Fragment>
        )
      })
      }
      <Grid style={{ color: "#0062FF" }} className="mt-4 mb-6" container item xs={12} alignItems="center" justify="flex-end">
        <button style={{ backgroundColor: "#F1F1F5", border: "none", borderRadius: 10, height: 38 }} onClick={() => {
          array.push(newOffice)
          setProfilo({ ["offices"]: array })
          // setUffici(array)
          // console.log("uffici", array)
        }}>{/* <Icon size={17} color="white" icon="add" /> */}Aggiungi ufficio</button>
      </Grid>
    </Grid>
  )
}