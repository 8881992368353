import { call, put, takeEvery, takeLatest, delay } from 'redux-saga/effects'

import { actions as ProfileActions } from '../slices/profile'
import { actions as AuthActions } from '../slices/auth'
import { actions as NotificationActions } from '../slices/notification'
import { actions as UiActions, selectors as UiSelectors } from '../slices/ui';
import * as AuthApi from '../../api/auth'
import * as ProfileApi from '../../api/profile'
import _ from 'lodash';

export function* sagas() {
  console.log('inside profile saga')
  yield takeLatest(ProfileActions.willRetrieveProfileData.type, willRetrieveProfileData)
  yield takeLatest(AuthActions.didLoginUserSuccess.type, willRetrieveProfileData)
  yield takeLatest(ProfileActions.willAddMediaOwner.type, willAddMediaOwner)
  yield takeLatest(ProfileActions.willGetMediaOwner.type, willGetMediaOwner)
  yield takeLatest(ProfileActions.willGetMediaOwnerUsers.type, willGetMediaOwnerUsers)
  yield takeLatest(ProfileActions.willManageStripeAccount.type, willManageStripeAccount)

}

function* willRetrieveProfileData(action: any) {
  console.log('in willGetProfileData function');
  try {
    const user = yield call(AuthApi.getAuthenticatedUser);
    // let mediaOwner = {} as any
    // console.log('with user in willRetrieveProfileData: ', user, !user.signInUserSession.accessToken.payload["cognito:groups"])

    if (!user.signInUserSession.accessToken.payload["cognito:groups"]) { /// se l'utente non appartiene a nessun gruppo
      yield put(UiActions.didSetNoGroup(true))
    } else {
      const mediaOwner = yield call(AuthApi.getMediaOwner, action);
      yield put(ProfileActions.didRetrieveProfileData(user.attributes))
      yield put(UiActions.didSetNoGroup(false))
      if (_.isEmpty(mediaOwner)) { /// se l'utente non ha nessun profilo
        // console.log("in willGetProfileData function mediaOwner true", mediaOwner)
        yield put(UiActions.didSetNoProfile(true))
        yield put(ProfileActions.didAddMediaOwner({}))
      } else {
        // console.log("in willGetProfileData function mediaOwner false", mediaOwner)
        yield put(ProfileActions.didAddMediaOwner(mediaOwner))
      }
    }
  } catch (error: any) {
    console.log('Error retriving profile data', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }

}

function* willAddMediaOwner(action: any) {
  console.log('in willAddMediaOwner saga');

  yield put(UiActions.startActivityRunning("willAddMediaOwner"));

  try {
    const result = yield call(AuthApi.addMediaOwner, action.payload);
    console.log('with user in willAddMediaOwner: ', result)
    yield put(ProfileActions.didAddMediaOwner(result))
    yield put(NotificationActions.willShowNotification({ message: "Modifiche salvate con successo", type: "success" }));
  } catch (error: any) {
    console.log('Error retriving profile data', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }
  yield put(UiActions.stopActivityRunning("willAddMediaOwner"));
}

function* willGetMediaOwner(action: any) {
  console.log('in willGetMediaOwner saga');

  try {
    const result = yield call(AuthApi.getMediaOwner, action);
    console.log('with user in getMediaOwner: ', result)
    yield put(ProfileActions.didAddMediaOwner(result))
  } catch (error: any) {
    console.log('Error retriving profile data', error);
  }

}

function* willGetMediaOwnerUsers(action: any) {
  console.log('in willGetMediaOwnerUsers saga');

  try {
    const result = yield call(ProfileApi.listMediaOwnerUsers);
    console.log('with user in getMediaOwner: ', result)
    yield put(ProfileActions.didGetMediaOwnerUsers({ listMediaOwnerUsers: result.data.listMediaOwnerUsers }))
  } catch (error: any) {
    console.log('Error retriving profile data', error);
  }

}

function* willManageStripeAccount(action: any) {
  console.log('in willManageStripeAccount saga');

  yield put(UiActions.startActivityRunning("willManageStripeAccount"));

  const profile = action.payload

  try {
    if (profile.stripe_actived) {
      const stripe_account: string = profile.stripe_account

      const result = yield call(ProfileApi.createStripeLoginLink, stripe_account);

      if (result) {
        window.open(result)
      } else {
        yield put(NotificationActions.willShowNotification({ message: 'Richiesta a Stripe fallita. Ritentare', type: "danger" }));
      }

    } else {
      const result = yield call(ProfileApi.registerStripeAccount);

      if (result) {
        window.open(result)
      } else {
        yield put(NotificationActions.willShowNotification({ message: 'Richiesta a Stripe fallita. Ritentare', type: "danger" }));
      }

    }

  } catch (error: any) {
    console.log('Error willManageStripeAccount', error);
    yield put(NotificationActions.willShowNotification({ message: error.message, type: "danger" }));
  }

  yield put(UiActions.stopActivityRunning("willManageStripeAccount"));
}