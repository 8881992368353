import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useStyles, cancelButton, confirmButton } from "./plant-list/PlantListCss";
import { statusChange } from './Autocomplete.options';
import Icon from "./IconsComponent";
import moment from 'moment';


export const SetStatus = (props: any) => {
  const { orderProps } = props
  // console.log("in SetStatus with",orderProps)

  const classes = useStyles()
  const dispatch = useDispatch()

  const [pagato, setPagato] = useState(false)
  const [statusVisual, setStatusVisual] = useState(undefined) as any
  const [status, setStatus] = useState("") as any

  let actionDone = useSelector(UiSelectors.getActionDone)

  return (
    <>
      <div className="d-sm-flex flex-column p-4 poppinsFont">
        <b>Aggiorna stato</b>
        <div className="border-bottom mt-3 mb-5" />
        <span className="disponibility">Seleziona lo stato e conferma per aggiornare l'impianto</span>
        <Grid className="mt-2" container justify="center">
          <Grid item xs={12}>
            <Autocomplete
              id="status"
              data-cy="statusInput"
              options={statusChange(orderProps.status) as any}
              getOptionLabel={(option) => option}
              style={{ width: 300 }}
              value={statusVisual}
              onChange={(e: any, value: any) => {
                if (value) {
                  if (value === "Rifiutato") {
                    setStatus("reject")
                  }
                  else if (value === "Accettato") {
                    setStatus("confirm")
                  }
                  else if (value === "Confermato") {
                    setStatus("confirm")
                  }
                  else if (value === "Materiali Consegnati") {
                    setStatus("put_materials")
                  }
                  else if (value === "In uscita") {
                    setStatus("complete")
                  }
                  else if (value === "Stato attuale non modificabile") {
                    setStatus(value)
                  }
                }
                setStatusVisual(value)
              }}
              renderInput={(params) => <TextField {...params} label="Seleziona stato" variant="standard" />}
            />
          </Grid>
        </Grid>
        {actionDone.action ? actionDone.action === "orderStatus" ?
          <div className="d-sm-flex align-items-center mt-4">
            <Icon size={20} color="#00e68a" icon="taskList" />
            <span style={{ color: "#00e68a" }} className="ml-3 robotoFont">Stato aggiornato correttamente</span>
          </div>
          : <div className="d-sm-flex align-items-center mt-4">
            <Icon size={20} color="red" icon="error" />
            <span className="ml-3 notDisponibility"> Lo stato non è stato aggiornato</span>
          </div>
          : null}
        <Grid className="mt-4" container item xs={12} spacing={1}>
          <Grid container item xs={3}>
            <button data-cy="cancel" style={cancelButton} onClick={() => {
              setStatus("")
              setStatusVisual("")
              dispatch(UiActions.switchCloseAddNewPlant())
              dispatch(UiActions.didSetActionDone({}))
            }}>Chiudi</button>
          </Grid>
          <Grid container item xs={9}>
            {actionDone
              ? actionDone.action === "orderStatus"
                ? <button data-cy="exit" style={confirmButton} onClick={() => {
                  dispatch(UiActions.switchCloseAddNewPlant())
                }}>Chiudi</button>
                : <button disabled={!status || status === "Stato attuale non modificabile"} data-cy="confirm" style={confirmButton} onClick={() => {
                  // if (status === "accepted" || status === "rejected") {
                  dispatch(ContentActions.willUpdateOrderStatus({ campaign_id: orderProps.campaign, id: orderProps.id, action: status }))
                  // }
                }}>Conferma</button>
              : <button disabled={!status || status === "Stato attuale non modificabile"} data-cy="confirm" style={confirmButton} onClick={() => {
                // if (status === "accepted" || status === "rejected") {
                dispatch(ContentActions.willUpdateOrderStatus({ campaign_id: orderProps.campaign, id: orderProps.id, action: status }))
                // }
              }}>Conferma</button>}
          </Grid>
        </Grid>
      </div>
    </>
  )
}