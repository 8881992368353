import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { AvailabilityStatus } from "./PlantAvailability.types";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1, 2),
      display: "flex",
      flexDirection: "column",
    },
    button: {
      margin: theme.spacing(1, 0),
      color: theme.palette.common.white,
      backgroundColor: theme.palette.info.main,
      fontWeight: "bolder",
    },
    [`button_${AvailabilityStatus.BOOKED}`]: {
      backgroundColor: "rgba(80, 181, 255, 1)",
    },
    [`button_${AvailabilityStatus.MAINTENANCE}`]: {
      backgroundColor: "rgba(255, 197, 65, 1)",
    },
    [`button_${AvailabilityStatus.UNAVAILABLE}`]: {
      backgroundColor: "rgba(259, 90, 90, 1)",
    },
    loadingContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: theme.zIndex.modal,
      backgroundColor: theme.palette.background.paper
    },
  })
);
