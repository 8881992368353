import React from 'react';
import styled from "styled-components";
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';

export const useStylesDashboards = makeStyles((theme: Theme) =>
  createStyles({
    visibilityProgress: {
      width: 70,
      height: 70,
    },
    confirmStepBorders: {
      // width: '100%',
      // height: '100%',
      backgroundColor: "white",
      borderRadius: 14,
      // marginTop: 15,
      padding: 10
    },
    percentageProgress: {
      color: 'black',
      alignItems: "center",
      justifyContent: "center",
      fontSize: 25
      // position: 'absolute',
    },
  }),
);

export const Styles = styled.div`
  // padding: 0.5rem;
  // margin-top: 5px;
  // padding: 13px;

.table {
  
  width: 100%;

  .thead {
    padding-right: 15px;
    font-family: 'Poppins', sans-serif;
  }

  .tbody {
    height: 50vh;
  //  padding: 17px;
  }

  .tbodyMap {
    height: 72vh;
  }

  .tr-group {
    display: flex;
    flex-direction: column;
    //  overflow-x: hidden;
    // border: 1px solid #00e68a;
    // border-radius: 10px;
  }

  .tr {
    //  overflow: hidden;
    display: flex;
    :last-child {
      .td {
        border-bottom: 0;
        color: #6c757d;
      }
    }
  }

  .th,
  .td {
    margin: 0;
    // padding: 0.5rem;
    margin-left: 10px;
    color: #6c757d;

    :last-child {
      border-right: 0;
    }
  }
}
`;