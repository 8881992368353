import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actions as ExploreActions, selectors as ExploreSelectors } from '../store/slices/explore';
import { actions as UiActions, selectors as UiSelectors } from '../store/slices/ui';
import { actions as ContentActions, selectors as ContentSelectors } from '../store/slices/content';
import { useStyles, cancelButton, confirmButton } from './plant-list/PlantListCss';
import { Grid, IconButton } from '@material-ui/core';
import Icon from './IconsComponent';
import { AvailabilityComponent } from './explore/AvailabilityComponent';
import { ActivityButton } from './ActivityButton';
import _ from 'lodash';
import moment from 'moment';


export const Maintenance = (props: any) => {
  // console.log("in Maintenance with:", props)
  const { item, plantData, setMaintenance, plantDetailtDisponibility, maintenanceGroup, setMaintenanceGroup, maintenance, setAnchorEl, availabilities, setAvailabilities, modalType } = props
  // console.log("item", item)
  // console.log("plantData", plantData)
  let data = [] as any
  const classes = useStyles();
  const dispatch = useDispatch()
  const [date, setDate] = useState() as any
  const [filter, setFilter] = useState({}) as any
  const [maintenanceDates, setMaintenanceDates] = useState([]) as any
  const [addedMainten, setAddedMainten] = useState([]) as any
  const [deletedMainten, setDeletedMainten] = useState([]) as any

  if (item) {
    if (item.length > 0) {
      data = _.map(item, 'original')
    } else { data = item }
  }
  else if (plantData) {
    data = plantData
  }

  return (
    <div className={`${classes.rootMaintenance} poppinsFont`}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="d-sm-flex justify-content-between align-items-center border-bottom">
            {availabilities || modalType === "disponibilityGroup" ? <b className="ml-3">Modifica disponibilità</b> : <b className="ml-3">Pianifica manutenzione</b>}
            <div>
              {/* <IconButton className="bg-transparent border-0 mr-1 right">
                <Icon color="#bfbfbf" size={20} icon="more" />
              </IconButton> */}
              <IconButton className="bg-transparent border-0 mr-1" onClick={() => {
                if (plantData) {
                  setMaintenance(null)
                  setAvailabilities(false)
                  // dispatch(ContentActions.didListBillboardAvailabilities({ availabilities: [] }))
                }
                else if (plantDetailtDisponibility === true) {
                  setMaintenance(null)
                } else {
                  setMaintenanceGroup(false)
                  dispatch(ContentActions.didListBillboardAvailabilities({ availabilities: [] }))
                  dispatch(UiActions.switchCloseAddNewPlant())
                }
              }}>
                <Icon color="#bfbfbf" size={20} icon="close" />
              </IconButton>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          {!_.isEmpty(filter) ? <>
            {maintenance === false || maintenance === true || maintenanceGroup ? null : <p>Periodo scelto: da {filter.date.from} a {filter.date.to}</p>}
            <p>Uscite selezionate: {filter.uscite.length}</p>
          </> : null}
        </Grid>
        <Grid container item xs={12}>
          {maintenanceGroup === true || modalType === "disponibilityGroup" ? <AvailabilityComponent data={data} setDate={setDate} setFilter={setFilter} filter={filter} setMaintenanceDates={setMaintenanceDates} maintenanceGroup={maintenanceGroup} maintenanceDates={maintenanceDates} maintenance={maintenance}
            availabilities={availabilities} modalType={modalType} />
            :
            <AvailabilityComponent data={data} setDate={setDate} setFilter={setFilter} filter={filter} setMaintenanceDates={setMaintenanceDates} maintenanceDates={maintenanceDates} maintenance={maintenance}
              availabilities={availabilities} addedMainten={addedMainten} setAddedMainten={setAddedMainten} deletedMainten={deletedMainten} setDeletedMainten={setDeletedMainten} />}
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid container item xs={3}>
            <button data-cy="cancel" style={cancelButton} onClick={() => {
              if (plantData) {
                setMaintenance(null)
                setAvailabilities(false)
                // dispatch(ContentActions.didListBillboardAvailabilities({ availabilities: [] }))
              }
              else if (plantDetailtDisponibility === true) {
                setMaintenance(null)
              } else {
                setMaintenanceGroup(false)
                dispatch(ContentActions.didListBillboardAvailabilities({ availabilities: [] }))
                dispatch(UiActions.switchCloseAddNewPlant())
              }
            }}>Annulla</button>
          </Grid>
          <Grid container item xs={9}>
            <ActivityButton data-cy="confirm" style={confirmButton} name="confirmMaintenance" color="primary" disabled block onClick={() => {
              let array = [] as any
              let array2 = [] as any

              if (maintenance === false || modalType === "disponibilityGroup") { /// per differenziare tra manutenzione (maintenance === false) e disponibilità
                if (availabilities && modalType === "disponibilityGroup") { /// per selezione multipla di n impianti
                  console.log("disponibilityGroup", availabilities)
                  console.log("maintenanceDates disponibilityGroup", maintenanceDates)
                  dispatch(ContentActions.willSetMaintenance({ date: _.uniq(maintenanceDates), billboards: data, is_maintenance: false }));
                  setMaintenance(null)
                  dispatch(UiActions.switchCloseAddNewPlant());
                } else {
                  // console.log("addedMainten", addedMainten, deletedMainten)
                  if (!_.isEmpty(deletedMainten)) { /// quando dobbiamo eliminare disponibilità
                    // console.log("maintenance === false, !_.isEmpty(deletedMainten)",!_.isEmpty(deletedMainten))
                    _.forEach(deletedMainten, (o: any) => {
                      // console.log("forEach,deletedMainten", deletedMainten, o)
                      array.push(moment(o))
                    })
                    if (!_.isEmpty(addedMainten)) {
                      _.forEach(addedMainten, (o: any) => {
                        array2.push(moment(o))
                      })
                      dispatch(ContentActions.willSetMaintenance({ date: array2, billboards: data, is_maintenance: false }));
                    }
                    // console.log("da qui usa le action")
                    dispatch(ContentActions.willDeleteBillboardAvailabilities({ date: array, input: { id: plantData.id, city_code: plantData.city_code } }));
                    setMaintenance(null)
                    dispatch(UiActions.switchCloseAddNewPlant());
                  } else { /// solo se deve settare le disponibilità
                    // console.log("solo se deve settare le disponibilità")
                    // console.log("maintenanceDates", maintenanceDates)
                    dispatch(ContentActions.willSetMaintenance({ date: _.uniq(maintenanceDates), billboards: data, is_maintenance: false }));
                    // setMaintenance(null)
                    dispatch(UiActions.switchCloseAddNewPlant());
                  }
                }
                if (setAnchorEl) {
                  setAnchorEl(null)
                }
              } else { /// questo setta la manutenzione
                if (!_.isEmpty(deletedMainten)) { /// quando dobbiamo eliminare la disponibilità
                  // console.log("quando dobbiamo eliminare disponibilità")

                  _.forEach(deletedMainten, (o: any) => {
                    array.push(moment(o))
                  })
                  if (!_.isEmpty(addedMainten)) {
                    _.forEach(addedMainten, (o: any) => {
                      array2.push(moment(o))
                    })
                    dispatch(ContentActions.willSetMaintenance({ date: array2, billboards: data, is_maintenance: true }));
                  }
                  dispatch(ContentActions.willDeleteBillboardAvailabilities({ date: array, input: { id: plantData.id, city_code: plantData.city_code } }));
                  setMaintenance(null)
                  dispatch(UiActions.switchCloseAddNewPlant());
                } else { /// solo se deve settare la manutenzione
                  // console.log("solo se deve settare la manutenzione")
                  // console.log("maintenanceDates", maintenanceDates)
                  console.log("maintenanceDates false", maintenanceDates)
                  dispatch(ContentActions.willSetMaintenance({ date: _.uniq(maintenanceDates), billboards: data, is_maintenance: true }));
                  // setMaintenance(null)
                  dispatch(UiActions.switchCloseAddNewPlant());
                }
              }
              // dispatch(ContentActions.willListBillboardAvailabilities({ billboard_id: data.id }))
              // test()
            }}>Conferma</ActivityButton>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}