import React from "react";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { createStyles, Theme, makeStyles, withStyles } from '@material-ui/core/styles';

export const Accordion = withStyles({
    root: {
        margin: 15,
        borderRadius: 14,
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            //borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
            border: '1px solid #00bfff',
            borderRadius: 14,
        },
    },
    expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
    root: {
        //backgroundColor: 'white',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
            borderBottom: '1px solid rgba(0, 0, 0, .125)',
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export const styles = (theme: any) => ({
    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: "blue",
        fontSize: 14
      }
    }
  });