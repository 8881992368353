import { useDispatch } from "react-redux";

import { actions as UiActions } from "../store/slices/ui";
import { PlantAvailability } from "./PlantAvailability";

import { IconButton } from "@material-ui/core";
import Icon from "./IconsComponent";
import { Billboard } from "../graphql/generated";

export const Availabilities = ({
  item,
  onClose,
}: {
  item: Billboard;
  onClose: () => void;
}) => {
  return (
    <>
      <div className="d-sm-flex justify-content-between align-items-center border-bottom p-2">
        <b className="ml-3">Disponibilità impianto</b>
        <div className="d-sm-flex">
          <IconButton
            data-cy="closeAvailability"
            className="bg-transparent border-0 mr-1 p-0"
            onClick={onClose}
          >
            <Icon color="#bfbfbf" size={20} icon="close" />
          </IconButton>
        </div>
      </div>
      <PlantAvailability plant={item} />
    </>
  );
};
