import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Profile {
  publicKey: string,
  mediaOwnerData: any
}

export const currentSlice = createSlice({
  name: 'profile',
  initialState: {
    publicKey: "",
    attributes: {},
    mediaOwnerData: {},
    mediaOwnerUsers: [] as any
  },
  reducers: {
    addPublicKey: (state, action: PayloadAction<any>) => state.publicKey = action.payload,
    willRetrieveProfileData: (state, action: PayloadAction<any>) => state,
    didRetrieveProfileData: (state, action: PayloadAction<any>) => void (state.attributes = action.payload),
    willAddMediaOwner: (state, action: PayloadAction<any>) => {
      console.log("in willAddMediaOwner")
    },

    willGetMediaOwner: (state, action: PayloadAction<any>) => state,
    didAddMediaOwner: (state, action: PayloadAction<any>) => {
      // console.log("in didAddMediaOwner")
      state.mediaOwnerData = action.payload
    },

    willGetMediaOwnerUsers: (state, action: PayloadAction<any>) => {
      console.log("in willGetMediaOwnerUsers")
    },
    didGetMediaOwnerUsers: (state, action: PayloadAction<any>) => {
      // console.log("in didAddMediaOwner")
      state.mediaOwnerUsers = action.payload.listMediaOwnerUsers
    },
    willManageStripeAccount: (state, action: PayloadAction<any>) => state,
  }
})

export const { actions, reducer }: any = currentSlice
export const { 
  addPublicKey, 
  willRetrieveProfileData, 
  didRetrieveProfileData, 
  willAddMediaOwner, 
  didAddMediaOwner,
  willGetMediaOwner,
  willGetMediaOwnerUsers,
  didGetMediaOwnerUsers,
  willManageStripeAccount

} = actions
export const selectors = {
  getPublicKey: (state: any) => state.profile.publicKey,
  getProfile: (state: any) => state.profile.attributes,
  getMediaOwner: (state: any) => state.profile.mediaOwnerData,
  getMediaOwnerUsers: (state: any) => state.profile.mediaOwnerUsers
  
}
